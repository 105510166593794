import { Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomGridToolbar, LoadingIcon } from '../../../components';
import tags from '../../../services/tags';

//Samples

//Sample Pickup
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useLocale } from '../../../hooks';
import useFormatDateTime from '../../../hooks/useFormatDateTime';
import StatusChip from '../../../components/StatusChip';
import { EditCardLaboratory } from '../forms/EditCardLaboratory';
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from 'formik';

const SamplePickupInfo = ({ samplePickupQuery, reftechTable }) => {
  const text = useLocale();
  const { formatTime, formatDateTime } = useFormatDateTime();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [initialValues, setInitialValues] = useState({
    pickupDateTime: null,
    dropoffDateTime: null,
    additionalInformation: '',
    samples: [],
    status: '',
  });

  useEffect(() => {
    if (samplePickupQuery.data) {
      formik.setFieldValue('laboratoryID', samplePickupQuery?.data.payload?.workOrder?.laboratory?.id)
      setInitialValues({
        pickupDateTime: samplePickupQuery.data.payload.pickupDateTime,
        dropoffDateTime: samplePickupQuery.data.payload.dropoffDateTime,
        additionalInformation: samplePickupQuery.data.payload.additionalInformation,
        samples: samplePickupQuery.data.payload.samples,
        status: samplePickupQuery.data.payload.status,
        laboratoryID: samplePickupQuery?.data.payload?.workOrder?.laboratory?.id
      });
    }
  }, [samplePickupQuery.data]);

  const formik = useFormik({
    initialValues: initialValues,
  });

  const columns = [
    {
      field: 'number',
      headerName: text.Samples.LABEL_SAMPLE_NUMBER,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'type',
      headerName: text.Samples.LABEL_TYPE,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return <Typography>{text.SampleType[params.value]}</Typography>;
      },
    },
    {
      field: 'timeOfMoulding',
      headerName: text.Samples.LABEL_TIME_OF_MOULDING,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => {
        return formatDateTime(params.value);
      },
    },
    {
      field: 'minimumTemperature',
      headerName: text.Samples.MINIMUM_TEMPERATURE + '* (C)',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'maximumTemperature',
      headerName: text.Samples.MAXIMUM_TEMPERATURE + '* (C)',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'typeOfMould',
      headerName: text.Samples.LABEL_TYPE_OF_MOULD,
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'sizeOfMould',
      headerName: text.Samples.LABEL_SIZE_OF_MOULD,
      minWidth: 120,
      flex: 1,
    },

    {
      field: 'status',
      headerName: text.STATUS,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return <StatusChip status={params.value} />;
      },
    },
  ];

  return (
    <React.Fragment>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {text.SamplePickups.SAMPLES}
      </Typography>

      {!samplePickupQuery.isLoading && samplePickupQuery.data ? (
        <DataGridPro
          rowHeight={80}
          slots={{
            toolbar: () => CustomGridToolbar({ tags: [tags.SAMPLE_PICKUP, tags.SAMPLE] }),
          }}
          rows={samplePickupQuery.data?.payload.samples}
          columns={columns}
          sx={{ height: '40vh' }}
          pagination
        />
      ) : (
        <LoadingIcon />
      )}
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container sx={{ alignItems: 'center', mb: 1 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography>
            {text.SamplePickups.COLLECTOR}
            {': '}
            {samplePickupQuery.data?.payload.collector.firstName +
              ' ' +
              samplePickupQuery.data?.payload.collector.lastName}
          </Typography>
          <Grid container spacing={1}>
            {console.log(samplePickupQuery.data?.payload,'check payload')}
            <Grid item>
              <Typography>
                {text.Laboratory.TITLE}
                {': '}
                {samplePickupQuery.data?.payload.workOrder.laboratory.name} ({samplePickupQuery.data?.payload?.workOrder.laboratory.region.name})
              </Typography>
            </Grid>
            <Grid item>
              <EditIcon
                onClick={handleOpen}
                variant="contained"
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography>
            {text.SamplePickups.PICKUP_DATE_TIME}
            {': '} {formatDateTime(samplePickupQuery.data?.payload.dropoffDateTime)}
          </Typography>
          <Typography>
            {text.SamplePickups.DROPOFF_DATE_TIME}
            {': '}
            {formatDateTime(samplePickupQuery.data?.payload.pickupDateTime)}
          </Typography>
        </Grid>
      </Grid>
      <Typography>
        {text.SamplePickups.STATUS}
        {': '}
        <StatusChip status={samplePickupQuery.data?.payload.status} />
      </Typography>
      <Typography>
        {text.SamplePickups.ADDITIONAL_INFORMATION}
        {': '}
        {samplePickupQuery.data?.payload.additionalInformation}
      </Typography>

      <EditCardLaboratory
        reftechTable={reftechTable}
        open={open}
        handleClose={() => setOpen(false)}
        workOrderId={samplePickupQuery?.data?.payload?.workOrderID}
        laboratoryId={samplePickupQuery?.data?.payload?.workOrder?.laboratory?.id}
        formik={formik}
      />
    </React.Fragment>
  );
};

export default SamplePickupInfo;
