import { Dialog, SwipeableDrawer } from '@mui/material';
import React from 'react';

import useIsMobileOrTablet from '../../../hooks/useIsMobileOrTablet';
import CylindersDialogContents from './CylindersDialogContents';
const CylindersDialog = ({ formikCylinder, samples, isOpen, onClose, onOpen, isConcreteSequeceEdit }) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  return (
    <React.Fragment>
      {isMobileOrTablet ? (
        <SwipeableDrawer anchor={'bottom'} open={isOpen} onClose={onClose} onOpen={onOpen}>
          <CylindersDialogContents formikCylinder={formikCylinder} samples={samples} isConcreteSequeceEdit={isConcreteSequeceEdit} />
        </SwipeableDrawer>
      ) : (
        <Dialog fullWidth open={isOpen} maxWidth="lg">
          <CylindersDialogContents
            formikCylinder={formikCylinder}
            samples={samples}
            onClose={onClose}
            isConcreteSequeceEdit={isConcreteSequeceEdit}
          />
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default CylindersDialog;
