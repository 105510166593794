import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import tags from './tags';

const tagsArray = Object.keys(tags).map((key) => tags[key]);

// Your standard http setup
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

// A wrapper for your standard http setup so that other services may use it
export const api = createApi({
  baseQuery: baseQuery,
  tagTypes: tagsArray,
  endpoints: () => ({
    // Inject endpoints
  }),
});
