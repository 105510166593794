export const Statuses = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  PENDING_PICKUP: 'PENDING PICKUP',
  ONSITE: 'ONSITE',
};

export const WorkOrderVisitTypes = {
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME',
};

export const CuringConditions = {
  STORED_IN_A_COOLER: 'STORED_IN_A_COOLER',
  STORED_UNDER_A_TARP: 'STORED_UNDER_A_TARP',
  MIN_MAX_THERMOMETER_USED: 'MIN_MAX_THERMOMETER_USED',
  STORED_IN_A_CURING_BOX: 'STORED_IN_A_CURING_BOX',
  STORED_UNDER_A_HEATED_TARP: 'STORED_UNDER_A_HEATED_TARP',
  HEATER_USED: 'HEATER_USED',
  INSULATION_USED: 'INSULATION_USED',
  ICE_USED: 'ICE_USED',
  STORED_INSIDE_A_BUILDING: 'STORED_INSIDE_A_BUILDING',
  STORED_INSIDE_A_FIELD_TRAILER: 'STORED_INSIDE_A_FIELD_TRAILER',
  LEFT_OUTDOORS: 'LEFT_OUTDOORS',
};

export const SampleType = {
  LABORATORY_CURED: 'LABORATORY_CURED',
  FIELD_CURED: 'FIELD_CURED',
};

export const SamplePickupStatus = {
  PENDING_PICKUP: 'PENDING_PICKUP', //  Changed when sample pickup is assigned
  SAMPLES_DELIVERED: 'SAMPLES_DELIVERED', // Changed when FT delivers samples and marks it as delivery completed
  DELIVERY_COMPLETED: 'DELIVERY_COMPLETED', // Marked as completed by the Lab people
};

export const SampleStatus = {
  ONSITE: 'ONSITE', // On sample pickup creation
  ASSIGNED_FOR_PICKUP: 'ASSIGNED_FOR_PICKUP', // Changed when sample pickup is assigned
  SAMPLES_DELIVERED: 'SAMPLES_DELIVERED', // Changed when FT delivers samples and marks it as delivery completed
  PENDING_TESTS: 'PENDING_TESTS', //
  TESTING_COMPLETED: 'TESTING_COMPLETED',
};

export const Weather = {
  SUNNY: 'SUNNY',
  PARTLY_CLOUDY: 'PARTLY_CLOUDY',
  CLOUDY: 'CLOUDY',
  RAINY: 'RAINY',
  WINDY: 'WINDY',
  SNOWY: 'SNOWY',
};
export const PreTestingChecklist = {
  PERSONAL_PROTECTIVE_EQUIPMENT: 'PERSONAL_PROTECTIVE_EQUIPMENT',
  REQUIRED_TRAINING: 'REQUIRED_TRAINING',
  COMPLETED_PRE_WORK_ASSESSMENT: 'COMPLETED_PRE_WORK_ASSESSMENT',
  JOB_SAFETY_ANALYSIS: 'JOB_SAFETY_ANALYSIS',
};

export const ConcretePourTest = {
  SLUMP_TEST: 'SLUMP_TEST',
  SLUMP_FLOW_TEST: 'SLUMP_FLOW_TEST',
};

export const SampleSize = {
  SIZE_20L: '20 L',
  SIZE_30L: '30 L',
  SIZE_40L: '40 L',
  SIZE_50L: '50 L',
};

export const TypeOfMould = {
  PLASTIC: 'Plastic',
};

export const SizeOfMould = {
  SIZE_100_200 : '100 * 200',
  SIZE_150_300 :'150 * 300',
  CUBES:'cubes',
  BEAMS:'beams'
};

export const roleType = {
  DISPATCHER : 'Dispatcher',
  PROJECT_MANAGER:'Project Manager',
  SUPER_USER:'Super User',
  FIELD_TECHNICIAN:'Field Technician',
  LAB_SUPERVISOR:'Laboratory Supervisor'
};