// This component asks the user whether they want to close the form

import { IconButton } from '@mui/material';
import ConfirmationDialog from './ConfirmationDialog';
import Icon from './Icon';
import React, { useState } from 'react';
import { useLocale } from '../hooks';

const CloseDialog = ({ handleClose, handleOpenDialog }) => {
  const text = useLocale();

  // Pass setIsOpen as a callback in handleOpenDialog,
  // so that the confirmation dialog opens
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <IconButton onClick={() => handleOpenDialog(setIsOpen)}>
        <Icon iconName="Cancel" />
      </IconButton>
      <ConfirmationDialog
        title={text.CONFIRMATION_QUESTION}
        contentText={text.DATA_LOSS_WARNING}
        actionText={text.Buttons.CONFIRM}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        handleOpen={() => setIsOpen(true)}
        handleAction={handleClose}
      />
    </React.Fragment>
  );
};

export default CloseDialog;
