import { createAsyncThunk } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import actions from '../constants/actions';
import { clearLoading, setLoading } from '../slices/loadingSlice';
import { useSubmitConcreteFieldInformation } from './useSubmitConcreteFieldInformation';
import { useSubmitConcreteMixDesign } from './useSubmitConcreteMixDesign';
import { useSubmitConcretingSequence } from './useSubmitConcretingSequence';
import { useSubmitCylinder } from './useSubmitCylinder';
import { useSubmitSample } from './useSubmitSample';
import { formType } from '../slices/offlineQueueSlice';
import tags from '../services/tags';
import refreshCache from '../utils/refreshCache';
import routes from '../constants/routes';

const useSubmitConcreteFieldReport = () => {
  const navigate = useNavigate();

  const { submitConcreteFieldInformation } = useSubmitConcreteFieldInformation();
  const { submitConcreteMixDesign } = useSubmitConcreteMixDesign();
  const { submitConcretingSequence } = useSubmitConcretingSequence();
  const { submitSample } = useSubmitSample();
  const { submitCylinder } = useSubmitCylinder();

  const submitConcreteFieldReport = createAsyncThunk(
    actions.SUBMIT_CONCRETE_FIELD_REPORT,
    async (workOrderID, thunkAPI) => {
      try {
        let finished = false;
        thunkAPI.dispatch(setLoading());

        while (!finished) {
          const queue = thunkAPI
            .getState()
            .offlineQueue.filter((item) => item.data.workOrderID === workOrderID);

          if (queue.length === 0) {
            finished = true;
            break;
          }

          for (const item of queue) {
            switch (item.type) {
              case formType.CONCRETE_FIELD_INFORMATION:
                await thunkAPI.dispatch(submitConcreteFieldInformation(item.data));
                break;

              case formType.CONCRETE_MIX_DESIGN:
                await thunkAPI.dispatch(submitConcreteMixDesign(item.data));
                break;

              case formType.CONCRETING_SEQUENCE:
                await thunkAPI.dispatch(submitConcretingSequence(item.data));
                break;

              case formType.SAMPLE:
                await thunkAPI.dispatch(submitSample(item.data));
                break;

              case formType.CYLINDER:
                await thunkAPI.dispatch(submitCylinder(item.data));
                break;

              default:
                break;
            }
            // We want to break here to re-run the loop again
            break;
          }
        }

        // Clear loading on success
        thunkAPI.dispatch(clearLoading());

        // Invalidate Tags
        refreshCache(thunkAPI.dispatch, [tags.CONCRETE_FIELD_INFORMATION]);

        // Navigate to the work order details page
        navigate(routes.WORKORDER_DETAILS.split(':')[0] + workOrderID);
      } catch (e) {
        console.error(e);
        thunkAPI.thunkAPI.dispatch(clearLoading());
      }
    }
  );

  return { submitConcreteFieldReport };
};

export default useSubmitConcreteFieldReport;
