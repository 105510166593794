import { useSelector } from 'react-redux';
import { selectLocale } from '../slices/localeSlice';
import { DateTime } from 'luxon';
export const useFormatDateTime = () => {
  const locale = useSelector(selectLocale);

  const formatDate = (isoDateTime) => {
    const dateTimeObject = DateTime.fromISO(isoDateTime, { zone: 'utc' });
    if (dateTimeObject.c === null) return '';

    return dateTimeObject.toLocaleString(DateTime.DATE_SHORT, { locale: locale });
  };

  const formatTime = (isoDateTime) => {
    const dateTimeObject = DateTime.fromISO(isoDateTime, { zone: 'utc' });
    if (dateTimeObject.c === null) return '';

    return dateTimeObject.toLocaleString(DateTime.TIME_SIMPLE, { locale: locale });
  };

  const formatDateTime = (isoDateTime) => {
    const dateTimeObject = DateTime.fromISO(isoDateTime, { zone: 'utc' });
    if (dateTimeObject.c === null) return '';

    return dateTimeObject.toLocaleString(DateTime.DATETIME_MED, { locale: locale });
  };

  return { formatDate, formatTime, formatDateTime };
};

export default useFormatDateTime;
