import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
const CustomLinearStepper = ({ steps, activeStep, ...props }) => {
  return (
    <Stepper activeStep={activeStep} {...props}>
      {steps.map((label, index) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default CustomLinearStepper;
