import React, { useState } from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useIsMobileOrTablet from '../../hooks/useIsMobileOrTablet';
import useLocale from '../../hooks/useLocale';
import CustomLinearMobileStepper from '../customMUI/CustomLinearMobileStepper';
import CustomLinearStepper from '../customMUI/CustomLinearStepper';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useRequest from '../../hooks/useRequest';
import { useConcreteFieldInformationSchema } from '../../schemas/concreteFieldInformation.schema';
import { useGetWorkOrderQuery } from '../../services/workordersApi';
import {
  addToQueue,
  formType,
  removeAllFromWorkOrder,
  selectAllTypeFromQueue,
  updateQueue,
  selectSampleWithCylindersFromQueue
} from '../../slices/offlineQueueSlice';
import ConfirmationDialog from '../ConfirmationDialog';
import ConcreteFieldInformation from './ConcreteFieldInformation';
import ConcreteMixDesigns from './ConcreteMixDesigns';
import ConcretingSequence from './ConcretingSequence';
import ReviewAndSubmit from './ReviewAndSubmit';
import SampleInformation from './SampleInformation';
import { useMemo } from 'react';

const ConcreteFieldReportAdd = () => {
  const { id: workOrderID } = useParams();
  const filteredSamplesOnWorkOrder = useSelector(selectSampleWithCylindersFromQueue).filter(
    (item) => item.data.workOrderID === workOrderID
  );

  const samplesAndCylinders = useMemo(() => {
    // Return only distinct samples (distinct by sample number) and the sum of cylinders
    let samples = []
    for (const sampleData of filteredSamplesOnWorkOrder) {
      const numberOfCylinders = sampleData.cylinders && sampleData.cylinders.length > 0 ? sampleData.cylinders.length : 0
      const samplesFilteredByNumber = samples.filter((s) => s.number === sampleData.data.number)
      if (samplesFilteredByNumber.length !== 0) {
        let sample = samplesFilteredByNumber[0]
        sample.cylinders = sample.cylinders + numberOfCylinders
      } else {
        samples.push({ number: sampleData.data.number, cylinders: numberOfCylinders, workOrderID: sampleData.data.workOrderID })
      }
    }

    return samples
  }, [filteredSamplesOnWorkOrder])
  const navigate = useNavigate();

  const workOrderQuery = useGetWorkOrderQuery(workOrderID, { refetchOnMountOrArgChange: true });
  const { concreteFieldInformationCreateSchema, concreteSampleInformationCreateSchema } = useConcreteFieldInformationSchema();

  const isMobileOrTablet = useIsMobileOrTablet();
  const text = useLocale();
  const dispatch = useDispatch();

  const [fieldReportID, setFieldReportID] = useState(null);

  // Get Current field report info
  const currentFieldReports = useSelector((state) => {
    return selectAllTypeFromQueue(state, formType.CONCRETE_FIELD_INFORMATION).filter(
      (item) => item.workOrderID === workOrderID
    );
  });

  // Get Concrete Mix Designs
  const concreteMixDesigns = useSelector((state) => {
    return selectAllTypeFromQueue(state, formType.CONCRETE_MIX_DESIGN).filter(
      (item) => item.workOrderID === workOrderID
    );
  });

  // Get Concreting Sequences
  const concretingSequences = useSelector((state) => {
    return selectAllTypeFromQueue(state, formType.CONCRETING_SEQUENCE).filter(
      (item) => item.workOrderID === workOrderID
    );
  });



  // Field Information
  const [initialValuesFieldInformation, setInitialValuesFieldInformation] = useState({
    workOrderID: workOrderID,
    fieldTechnicianID: '',
    dateOfVisit: '',
    equipmentNumber: '',
    transportationTime: 0,
    mileage: 0,
    checklist: [],
    unexpectedHazards: '',
    weather: [],
    subContractorOrganisation: '',
    subContractorContactName: '',
    subContractorContactTelephone: '',
    subContractorContactEmail: '',
  });

  // Sample Information & Additional Information
  const [initialValuesSampleInformation, setInitialValuesSampleInformation] = useState({
    sampleLocationDescription: '',
    additionalInformation: '',
  });

  const formikFieldInformation = useFormik({
    initialValues: initialValuesFieldInformation,
    enableReinitialize: true,
    validationSchema: concreteFieldInformationCreateSchema,
    onSubmit: (values) => {
      let fieldReportValues = {};
      let id = null;
      if (fieldReportID === null) {
        id = uuidv4();
        fieldReportValues = values;
        setFieldReportID(id);
        dispatch(
          addToQueue({
            id: id,
            type: formType.CONCRETE_FIELD_INFORMATION,
            data: { ...fieldReportValues, id: id },
          })
        );
      } else {
        // Include the currentFieldReport for sample
        // and additional information upon navigating
        // back to the first step.
        id = fieldReportID;
        fieldReportValues = { ...currentFieldReports[0], ...values };
        dispatch(
          updateQueue({
            id: id,
            type: formType.CONCRETE_FIELD_INFORMATION,
            data: { ...fieldReportValues, id: id },
          })
        );
      }

      setInitialValuesFieldInformation(values);

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
  });

  const formikSampleInformation = useFormik({
    initialValues: initialValuesSampleInformation,
    enableReinitialize: true,
    validationSchema: concreteSampleInformationCreateSchema,
    onSubmit: (values) => {
      if (fieldReportID !== null) {
        const fieldReportValues = { ...currentFieldReports[0], ...values };
        dispatch(
          updateQueue({
            id: fieldReportID,
            type: formType.CONCRETE_FIELD_INFORMATION,
            data: { ...fieldReportValues, id: fieldReportID },
          })
        );
        setInitialValuesSampleInformation(values)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    },
  });

  // Stepper
  const steps = [
    text.ConcreteFieldInformation.TITLE,
    text.ConcretingSequence.TITLE,
    text.SampleInformation.TITLE,
    text.ReviewAndSubmit.TITLE,
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      if (activeStep === 0) {
        formikFieldInformation.handleSubmit();
      } else if (activeStep === 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === 2) {
        formikSampleInformation.handleSubmit();
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [backDialogOpen, setBackDialogOpen] = useState(false);
  const handleBackNavigation = () => {
    if (
      formikFieldInformation.dirty ||
      formikSampleInformation.dirty ||
      currentFieldReports.length > 0 ||
      concreteMixDesigns.length > 0 ||
      concretingSequences.length > 0
    ) {
      dispatch(removeAllFromWorkOrder(workOrderID));
    }
    navigate(-1);
  };

  // Get field technician name
  useEffect(() => {
    if (workOrderQuery.data) {
      setInitialValuesFieldInformation({
        ...initialValuesFieldInformation,
        fieldTechnicianID: workOrderQuery.data.payload.fieldTechnician.id,
        dateOfVisit: workOrderQuery.data.payload.siteVisitDate,
        transportationTime: workOrderQuery.data.payload.project.transportationTime,
        mileage: workOrderQuery.data.payload.project.mileage,
        // subContractorOrganisation: workOrderQuery.data.payload.project.onSiteContactOrganisation,
        // subContractorContactName: workOrderQuery.data.payload.project.onSiteContactName,
        // subContractorContactTelephone: workOrderQuery.data.payload.project.onSiteContactTelephone,
        // subContractorContactEmail: workOrderQuery.data.payload.project.onSiteContactEmail,
      });
    }
  }, [workOrderQuery.data]);

  // Load the field report if there is any

  useEffect(() => {
    if (currentFieldReports.length > 0) {
      setInitialValuesFieldInformation({ ...currentFieldReports[0] });
      setInitialValuesSampleInformation({ ...currentFieldReports[0] })
      setFieldReportID(currentFieldReports[0].id);
    }
  }, [currentFieldReports.length]);
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, mt: 2 }}>
        <Button startIcon={<ArrowBack />} onClick={() => setBackDialogOpen(true)} sx={{ mr: 2 }}>
          {text.Buttons.BACK}
        </Button>
        {/* <Button onClick={() => dispatch(resetCache())}>Reset Cache</Button> */}
        <ConfirmationDialog
          title={text.CONFIRMATION_QUESTION}
          contentText={text.DATA_LOSS_WARNING}
          actionText={text.Buttons.CONFIRM}
          isOpen={backDialogOpen}
          handleClose={() => setBackDialogOpen(false)}
          handleOpen={() => setBackDialogOpen(true)}
          handleAction={handleBackNavigation}
        />
        <Typography variant="h4" fontWeight="bold">
          {text.WorkOrders.WORK_ORDER + ' #' + workOrderQuery?.data?.payload?.number}
        </Typography>
      </Box>
      {!isMobileOrTablet ? (
        <CustomLinearStepper activeStep={activeStep} steps={steps} sx={{ p: 2 }} />
      ) : (
        <></>
      )}

      {activeStep === 0 ? (
        <React.Fragment>
          <ConcreteFieldInformation formikFieldInformation={formikFieldInformation} />
          <br />
          <ConcreteMixDesigns
            rows={concreteMixDesigns}
            fieldReportExists={false}
            assignedTechnicianID={workOrderQuery.data?.payload.fieldTechnician.id}
          />
        </React.Fragment>
      ) : activeStep === 1 ? (
        <ConcretingSequence
          rows={concretingSequences}
          fieldReportExists={false}
          assignedTechnicianID={workOrderQuery.data?.payload.fieldTechnician.id}
        />
      ) : activeStep === 2 ? (
        <Grid container direction="column">
          <Grid item>
            <SampleInformation filteredSamplesOnWorkOrder={samplesAndCylinders} formikSampleInformation={formikSampleInformation} />
          </Grid>
        </Grid>
      ) : activeStep === 3 ? (
        <ReviewAndSubmit />
      ) : (
        <></>
      )}

      {!isMobileOrTablet ? (
        <Grid container>
          <Grid item sx={{ flex: 1, mt: 2, mb: 2 }}>
            <Button onClick={handleBack} disabled={activeStep === 0}>
              {text.Buttons.BACK}
            </Button>
          </Grid>
          <Grid item sx={{ mt: 2, mb: 2 }}>
            {concreteMixDesigns.length > 0 ? (
              <Button variant="contained" onClick={handleNext} disabled={activeStep === steps.length - 1}>
                {text.Buttons.SAVE_AND_CONTINUE}
              </Button>
            ) : (
              <Button onClick={handleNext} disabled={true}>
                {text.NEED_TO_ADD_CONCRETE_MIX_DESIGN}
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <CustomLinearMobileStepper
          sx={{ mt: 2 }}
          maxSteps={steps.length}
          handleBack={handleBack}
          handleNext={handleNext}
          activeStep={activeStep}
        />
      )}
    </Box>
  );
};

export default ConcreteFieldReportAdd;
