import { Box, Button, Grid, IconButton } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocale } from "../../../hooks";
import {
  formType,
  removeFromQueue,
  selectAllTypeFromQueue,
} from "../../../slices/offlineQueueSlice";
import DeleteDialog from "../../DeleteDialog";
import Icon from "../../Icon";
import CylindersAdd from "./CylindersAdd";
import CylindersAddChange from "./CylindersAddChange";
import useFormatDateTime from "../../../hooks/useFormatDateTime";
import CylindersEdit from "./CylinderEdit.";
import { useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { addToQueue } from "../../../slices/offlineQueueSlice";
import { useAddCylinderMutation } from "../../../services/cylindersApi";
import routes from "../../../constants/routes";

const EditDialogWrapper = ({
  id,
  fieldCuredSampleID,
  laboratoryCuredSampleID,
  setSubmitted,
  isConcreteSequeceEdit,
}) => {
  const dispatch = useDispatch();
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  return (
    <React.Fragment>
      <IconButton onClick={() => setIsEditFormOpen(true)}>
        <Icon iconName={"Edit"} />
      </IconButton>
      {isConcreteSequeceEdit ? (
        <CylindersEdit
          cylinderID={id}
          isOpen={isEditFormOpen}
          setIsOpen={setIsEditFormOpen}
          fieldCuredSampleID={fieldCuredSampleID}
          laboratoryCuredSampleID={laboratoryCuredSampleID}
          setSubmitted={setSubmitted}
          isConcreteSequeceEdit={isConcreteSequeceEdit}
        />
      ) : (
        <CylindersAddChange
          cylinderID={id}
          isOpen={isEditFormOpen}
          setIsOpen={setIsEditFormOpen}
          fieldCuredSampleID={fieldCuredSampleID}
          laboratoryCuredSampleID={laboratoryCuredSampleID}
          setSubmitted={setSubmitted}
          isConcreteSequeceEdit={isConcreteSequeceEdit}
        />
      )}
    </React.Fragment>
  );
};

const Cylinders = ({
  laboratoryCuredSampleID,
  fieldCuredSampleID,
  commonSampleFields,
  isConcreteSequeceEdit,
  cylindersQuery,
  formikSamplesLaboratoryCured,
  fromWorkOrderDetails
}) => {
  const { id: workOrderID } = useParams();
  const text = useLocale();
  const dispatch = useDispatch();
  const [cylinders, setCylinders] = useState([]);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [lastCharCode, setcharCode] = useState(64);
  const { formatTime } = useFormatDateTime();
  const { concretingSequenceID } = useParams();
  const [customizeRow, setCustomizedRow] = useState([]);
  const [addCylinder] = useAddCylinderMutation();
  const location = useLocation();
  // console.log("cylindersQuery.data.payload")
  useEffect(() => {
    if (cylindersQuery) {
      let sortedCylinders;
      if (cylinders) {
        sortedCylinders = [...cylindersQuery.data.payload].sort((a, b) => {
          if (a.customCylinderID.slice(-1) < b.customCylinderID.slice(-1)) return -1;
          return a.customCylinderID > b.customCylinderID ? 1 : 0;
        });
      }
      if (commonSampleFields) {
        const arrCylinders = sortedCylinders.map((cylinder) => {
          // const splitNum = cylinder.customCylinderID.split('-');
          // console.log("splitNumsplitNum", cylinder.customCylinderID, splitNum)
          // const replacedNum = commonSampleFields.number + '-' + splitNum.slice(1).join('-');
          return {
            ...cylinder,
            number: commonSampleFields.number,
            customCylinderID: cylinder.customCylinderID,
            sample: {
              ...cylinder.sample,
              number: commonSampleFields.number,
              timeOfMoulding: commonSampleFields.timeOfMoulding,
              timeOfSampling: commonSampleFields.timeOfSampling,
              customCylinderID: cylinder.customCylinderID,
            },
          };
        });
        setCylinders(arrCylinders);
      }
    }
  }, [cylindersQuery, isConcreteSequeceEdit, fromWorkOrderDetails]);

  // Retrieve cylinders from local storage
  const laboratoryCuredCylinders = useSelector((state) =>
    selectAllTypeFromQueue(state, formType.CYLINDER).filter(
      (item) => item.sampleID === laboratoryCuredSampleID
    )
  );

  const fieldCuredCylinders = useSelector((state) =>
    selectAllTypeFromQueue(state, formType.CYLINDER).filter(
      (item) => item.sampleID === fieldCuredSampleID
    )
  );

  // End of retrieve cylinders from local storage
  // Populate cylinders
  // If the number of cylinders or 'submitted' has changed (For editing)

  useEffect(() => {
    let cylinders = [];

    if (laboratoryCuredCylinders.length > 0) {
      cylinders.push(...laboratoryCuredCylinders);
    }

    if (fieldCuredCylinders.length > 0) {
      cylinders.push(...fieldCuredCylinders);
    }
    let sortedCylinders;
    if (cylinders) {
      sortedCylinders = [...cylinders].sort((a, b) => {
        if (a.customCylinderID.slice(-1) < b.customCylinderID.slice(-1)) return -1;
        return a.customCylinderID > b.customCylinderID ? 1 : 0;
      });
    }
    if (commonSampleFields) {
      const arrCylinders = sortedCylinders.map((cylinder) => {
        const splitNum = cylinder.customCylinderID.split("-");
        const replacedNum = commonSampleFields.number + "-" + splitNum.slice(1).join("-");
        return {
          ...cylinder,
          number: commonSampleFields.number,
          customCylinderID: replacedNum,
          sample: {
            ...cylinder.sample,
            number: commonSampleFields.number,
            timeOfMoulding: commonSampleFields.timeOfMoulding,
            timeOfSampling: commonSampleFields.timeOfSampling,
            customCylinderID: replacedNum,
          },
        };
      });
      setCylinders(arrCylinders);
    }

    if (submitted) {
      setSubmitted(false);
    }
  }, [laboratoryCuredCylinders.length, fieldCuredCylinders.length, submitted, commonSampleFields]);
  // End of cylinders populate cylinders

  const addDefaultCylinders = async () => {
    let sampleNumber = commonSampleFields.number;
    let fromCharCode = lastCharCode;
    for (let i = 0; i < 3; i++) {
      const id = uuidv4();
      const initialVal = await getCylinderID(fromCharCode);
      fromCharCode = initialVal.charcode;
      setcharCode(fromCharCode);
      const values = {
        customCylinderID: sampleNumber + "-L" + initialVal.initial,
        sampleID: laboratoryCuredSampleID,
        daysToBeCured: i > 0 ? 28 : 7,
        workOrderID: workOrderID,
        sampleNumber: sampleNumber,
      };
      if (!location.pathname.includes(routes.CONCRETING_SEQUENCES)) {
        await addCylinder({ ...values });
      }

      dispatch(addToQueue({ id: id, type: formType.CYLINDER, data: { ...values, id: id } }));
    }
  };

  async function getCylinderID(fromCharCode) {
    let Initial = "";
    let returnCharCode = fromCharCode;
    if (cylinders?.length > 0) {
      const sortedCylinders = [...cylinders].sort((a, b) => {
        if (a.customCylinderID.slice(-1) < b.customCylinderID.slice(-1)) return -1;
        return a.customCylinderID > b.customCylinderID ? 1 : 0;
      });
      const lastCylinderId = sortedCylinders[sortedCylinders?.length - 1]?.customCylinderID;
      let nextCharCode = lastCylinderId.charCodeAt(lastCylinderId.length - 1);
      if (nextCharCode > fromCharCode) {
        returnCharCode = nextCharCode + 1;
      } else {
        returnCharCode = fromCharCode + 1;
      }
      Initial = String.fromCharCode(returnCharCode);
    } else {
      returnCharCode = fromCharCode + 1;
      Initial = String.fromCharCode(returnCharCode);
    }
    return { initial: Initial, charcode: returnCharCode };
  }

  const removeItem = (id) => {
    dispatch(removeFromQueue(id));
    setcharCode(lastCharCode - 1);
  };

  const columns = useMemo(
    () => fromWorkOrderDetails ? [
      {
        field: "customCylinderID",
        headerName: text.Cylinders.LABEL_ID,
        flex: 1,
        minWidth: 120,
      },
      {
        field: "sampleNumber",
        headerName: text.Samples.LABEL_SAMPLE_NUMBER,
        flex: 1,
        minWidth: 120,
        renderCell: (params) => params.row.sample.number,
      },
      {
        field: "timeOfMoulding",
        headerName: text.Samples.LABEL_TIME_OF_MOULDING,
        flex: 1,
        minWidth: 120,
        renderCell: (params) => formatTime(params.row.sample.timeOfMoulding),
      },
      {
        field: "sample",
        flex: 1,
        minWidth: 100,
        headerName: text.Cylinders.LABEL_SAMPLE_TYPE,
        renderCell: (params) => {
          if (cylindersQuery) {
            if (params?.value?.customCylinderID) {
              const custId = params.value.customCylinderID;
              if (custId.split("-")[1].charAt(0) === "L") {
                return "Laboratory Cured";
              } else {
                return "Field Cured";
              }
            }
          }
          return text.SampleType[params.value.type];
        },
      },
      {
        field: "daysToBeCured",
        flex: 1,
        minWidth: 100,
        headerName: text.Cylinders.LABEL_DAYS_TO_BE_CURED,
      },
    ] : [{
      field: "customCylinderID",
      headerName: text.Cylinders.LABEL_ID,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "sampleNumber",
      headerName: text.Samples.LABEL_SAMPLE_NUMBER,
      flex: 1,
      minWidth: 120,
      renderCell: (params) => params.row.sample.number,
    },
    {
      field: "timeOfMoulding",
      headerName: text.Samples.LABEL_TIME_OF_MOULDING,
      flex: 1,
      minWidth: 120,
      renderCell: (params) => formatTime(params.row.sample.timeOfMoulding),
    },
    {
      field: "sample",
      flex: 1,
      minWidth: 100,
      headerName: text.Cylinders.LABEL_SAMPLE_TYPE,
      renderCell: (params) => {
        if (cylindersQuery) {
          if (params?.value?.customCylinderID) {
            const custId = params.value.customCylinderID;
            if (custId.split("-")[1].charAt(0) === "L") {
              return "Laboratory Cured";
            } else {
              return "Field Cured";
            }
          }
        }
        return text.SampleType[params.value.type];
      },
    },
    {
      field: "daysToBeCured",
      flex: 1,
      minWidth: 100,
      headerName: text.Cylinders.LABEL_DAYS_TO_BE_CURED,
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        console.log(params, 'checkcylparam')
        return (
          <EditDialogWrapper
            id={params.row.id || params?.id}
            fieldCuredSampleID={fieldCuredSampleID}
            laboratoryCuredSampleID={laboratoryCuredSampleID}
            setSubmitted={setSubmitted}
            isConcreteSequeceEdit={isConcreteSequeceEdit}
            fromWorkOrderDetails={fromWorkOrderDetails}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return !isConcreteSequeceEdit ? (
          <DeleteDialog handleDelete={() => removeItem(params.id)} />
        ) : (
          ""
        );
      },
    },],
    [
      text,
      cylindersQuery,
      formatTime,
      fieldCuredSampleID,
      laboratoryCuredSampleID,
      setSubmitted,
      isConcreteSequeceEdit,
      removeItem,
    ]
  );

  useEffect(() => {
    const filCyl = cylinders.filter((cylinder) => {
      return cylinder?.sample?.concretingSequence?.id === concretingSequenceID
    })
    const filCylEdit = cylinders.filter((cylinder) => {
      return cylinder?.sample?.concretingSequenceID === concretingSequenceID
    })
    if (filCyl.length > 0) {
      setCustomizedRow(filCyl);
      return
    }
    if (filCylEdit.length > 0) {
      setCustomizedRow(filCylEdit);
      return
    }
  }, [cylinders])


  return (
    <React.Fragment>
      {!isConcreteSequeceEdit && (
        <Grid container display={"flex"} justifyContent={"end"} columnGap={2}>
          <Grid item display={"flex"} >
            <Button
              startIcon={<Icon iconName="AddCircle" />}
              variant="contained"
              color="primary"
              onClick={() => setIsAddFormOpen(true)}
              sx={{ mb: 2 }}
            >
              {text.Buttons.ADD}
            </Button>
          </Grid>
          <Grid item display={"flex"} justifyContent={"end"}>
            <Button
              startIcon={<Icon iconName="AddCircle" />}
              variant="contained"
              color="primary"
              disabled={customizeRow.length}
              onClick={() => addDefaultCylinders()}
              sx={{ mb: 2 }}
            >
              {text.Cylinders.ADD_DEFAULT_CYLINDERS}
            </Button>
          </Grid>
        </Grid>
      )}

      <CylindersAdd
        isOpen={isAddFormOpen}
        setIsOpen={setIsAddFormOpen}
        fieldCuredSampleID={fieldCuredSampleID}
        laboratoryCuredSampleID={laboratoryCuredSampleID}
        sampleNumber={commonSampleFields?.number}
        cylinders={cylinders}
      />

      <DataGridPro
        columns={columns}
        rows={customizeRow}
        sx={{ width: "100%", height: "40vh", minHeight: 300 }}
        disableRowSelectionOnClick={true}
        pagination
      />
    </React.Fragment>
  );
};

export default Cylinders;
