// a queue of notifications

import { createSlice } from '@reduxjs/toolkit';

const initialState = [];
const notificationSlice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    addNotification(state, action) {
      let newState = [...state];
      if (newState.length === 5) newState.pop();
      newState.push(action.payload);
      return newState;
    },
    removeNotification(state, action) {
      const newState = state.filter((item, index) => {
        return index !== action.payload;
      });
      console.log(newState);
      return newState;
    },
  },
});

// Actions
export const { addNotification, removeNotification } = notificationSlice.actions;

// Selecters
export const selectNotifications = (state) => state.notifications;

export default notificationSlice;
