import React from 'react';
import { CustomGridToolbar, FlexBetween, Header, LoadingIcon } from '../../components';
import routes from '../../constants/routes';
import { useGetElement, useLocale } from '../../hooks';
import tags from '../../services/tags';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TextField, Autocomplete, Card, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddCircle } from '@mui/icons-material';
import useRequest from '../../hooks/useRequest';
import { useGetUsersQuery } from '../../services/userApi';
import { DataGridPro } from '@mui/x-data-grid-pro';

function Users() {
  const text = useLocale();
  const navItem = useGetElement(text.Users.NAME);
  const navigate = useNavigate();
  const usersQuery = useRequest(useGetUsersQuery);
  // MODAL
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const dataGridBoxStyle = {
    width: '100%',
    '& .MuiDataGrid-root': { border: 'none' },
    '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
  };

  let varHeight;
  varHeight = '70vh';

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };
  const typeInsp = [
    { label: 'Concrete', disable: 'n' },
    { label: 'Compaction and Concrete', disable: 'y' },
    { label: 'Compaction and Paving', disable: 'y' },
  ];

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return params.row.firstName + params.row.lastName;
      },
    },
    {
      field: 'roles',
      headerName: text.Users.USERTYPE,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return `${params.row.roles[0]} ${params.row?.preferredLaboratory?.name ? '(' + params.row?.preferredLaboratory?.name + ')' : ''}`
      },
    },
    {
      field: 'userType',
      headerName: text.Users.TYPEUSER,
      flex: 1,
      minWidth: 160,
      valueGetter: (params) => params.row.userType == 'GHD_USER' ? 'GHD User' : 'External User',
    },
    {
      field: 'email',
      headerName: text.Users.EMAIL,
      flex: 1,
      minWidth: 160,
      valueGetter: (params) => params.row.email,
    },

  ];

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.Users.TABLENAME} />
        <Button
          onClick={() => {
            navigate(routes.USER_ADD)
          }}
          variant="contained"
          size="large"
          color="primary"
          startIcon={<AddCircle />}
        >
          Add
        </Button>
      </FlexBetween>

      <Card height={varHeight} sx={{ ...dataGridBoxStyle }}>
        <CardContent>
          <Box>
            {usersQuery.isLoading ? (
              <LoadingIcon />
            ) : (
              <DataGridPro
                rows={usersQuery.data ? usersQuery?.data?.payload : []}
                slots={{ toolbar: () => CustomGridToolbar({ tags: tags }) }}
                columns={columns}
                columnHeader
                pagination
              />
            )}
          </Box>
        </CardContent>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Autocomplete
            disablePortal
            id="combo-modal"
            options={typeInsp}
            fullWidth
            getOptionDisabled={(option) => !!typeInsp.find((element) => option.disable === 'y')}
            renderInput={(params) => <TextField {...params} label={text.WorkOrders.Modal_Type} />}
          />
          <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate(routes.WORKORDER_ADD)}>
            Confirm
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default Users;
