import React, { useState } from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';

import { useFormik } from 'formik';
import { useParams } from 'react-router';
import useIsMobileOrTablet from '../../hooks/useIsMobileOrTablet';
import useLocale from '../../hooks/useLocale';
import CustomLinearMobileStepper from '../customMUI/CustomLinearMobileStepper';
import CustomLinearStepper from '../customMUI/CustomLinearStepper';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHandleError } from '../../hooks/useHandleError';
import useHandleSuccess from '../../hooks/useHandleSuccess';
import useRequest from '../../hooks/useRequest';
import { useConcreteFieldInformationSchema } from '../../schemas/concreteFieldInformation.schema';
import {
  useGetConcreteFieldInformationQuery,
  useUpdateConcreteFieldInformationMutation,
} from '../../services/concreteFieldInformationApi';
import { useGetConcreteMixDesignsQuery } from '../../services/concreteMixDesignApi';
import { useGetConcretingSequencesQuery } from '../../services/concretingSequenceApi';
import { useGetCylindersQuery } from '../../services/cylindersApi';
import { useGetSamplesQuery } from '../../services/samplesApi';
import { useGetWorkOrderQuery } from '../../services/workordersApi';
import { getObjectChanges } from '../../utils/form';
import ConfirmationDialog from '../ConfirmationDialog';
import ConcreteFieldInformation from './ConcreteFieldInformation';
import ConcreteMixDesigns from './ConcreteMixDesigns';
import SampleInformation from './SampleInformation';
import ConcretingSequence from './ConcretingSequence';
import { useSelector } from 'react-redux';
import { formType, selectAllTypeFromQueue } from '../../slices/offlineQueueSlice';
const ConcreteFieldReportEdit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { handleErrorResponse } = useHandleError();

  const { handleSuccessResponse } = useHandleSuccess();

  const workOrderQuery = useRequest(useGetWorkOrderQuery, id);
  const { concreteFieldInformationEditSchema } = useConcreteFieldInformationSchema();
  const [updateFieldInfo] = useUpdateConcreteFieldInformationMutation();

  const isMobileOrTablet = useIsMobileOrTablet();
  const text = useLocale();
  const { id: workOrderID } = useParams();
  // Get Current field report info
  const fieldInfoQuery = useRequest(useGetConcreteFieldInformationQuery, {
    'filter[workOrderID]': `equals,${id}`,
  });

  // Get Concrete Mix Designs
  const concreteMixDesignsQuery = useRequest(useGetConcreteMixDesignsQuery, {
    'filter[workOrderID]': `equals,${id}`,
  });

  // Get Concreting Sequences
  const concretingSequencesQuery = useRequest(useGetConcretingSequencesQuery, {
    'filter[workOrderID]': `equals,${id}`,
  });

  // Get Samples
  const samplesQuery = useRequest(useGetSamplesQuery, { 'filter[workOrderID]': `equals,${id}` });

  const cylindersQuery = useRequest(useGetCylindersQuery, {
    'filter[workOrderID]': `equals,${id}`,
  });

  // Field Information
  const [initialValuesFieldInformation, setInitialValuesFieldInformation] = useState({
    workOrderID: id,
    fieldTechnicianID: '',
    dateOfVisit: '',
    equipmentNumber: '',
    transportationTime: 0,
    mileage: 0,
    checklist: [],
    unexpectedHazards: '',
    weather: [],
    subContractorOrganisation: '',
    subContractorContactName: '',
    subContractorContactTelephone: '',
    subContractorContactEmail: '',
  });

  // Sample Information & Additional Information
  const [initialValuesSampleInformation, setInitialValuesSampleInformation] = useState({
    sampleLocationDescription: '',
    additionalInformation: '',
  });

  const formikFieldInformation = useFormik({
    initialValues: initialValuesFieldInformation,
    enableReinitialize: true,
    validationSchema: concreteFieldInformationEditSchema,
    onSubmit: async (values) => {
      try {
        const changes = getObjectChanges(values, initialValuesFieldInformation);
        if (Object.keys(changes).length !== 0) {
          await updateFieldInfo({
            changes: changes,
            id: fieldInfoQuery.data.payload[0].id,
          }).unwrap();
          setInitialValuesFieldInformation(values);
          handleSuccessResponse(
            text.SUCCESSFUL_UPDATE,
            text.ConcreteFieldInformation.SUCCESSFUL_UPDATE
          );
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (e) {
        handleErrorResponse(e, text.ConcreteFieldInformation.UNSUCCESSFUL_UPDATE);
      }
    },
  });

  const formikSampleInformation = useFormik({
    initialValues: initialValuesSampleInformation,
    enableReinitialize: true,

    onSubmit: async (values) => {
      try {
        const changes = getObjectChanges(values, initialValuesSampleInformation);
        if (Object.keys(changes).length !== 0) {
          await updateFieldInfo({
            changes: changes,
            id: fieldInfoQuery.data.payload[0].id,
          }).unwrap();

          setInitialValuesSampleInformation(values);
          handleSuccessResponse(
            text.SUCCESSFUL_UPDATE,
            text.ConcreteFieldInformation.SUCCESSFUL_UPDATE
          );
          navigate("/workorders/assigned");
        } else {
          navigate("/workorders/assigned");
        }
      } catch (e) {
        handleErrorResponse(e, text.ConcreteFieldInformation.UNSUCCESSFUL_UPDATE);
      }
    },
  });

  // Stepper
  const steps = [text.ConcreteFieldInformation.TITLE, text.ConcretingSequence.TITLE, text.SampleInformation.TITLE,];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      if (activeStep === 0) {
         formikFieldInformation.handleSubmit();
      } else if (activeStep === 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === steps.length - 1) {
      formikSampleInformation.handleSubmit();
    }

  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [backDialogOpen, setBackDialogOpen] = useState(false);
  const handleBackNavigation = () => {
    navigate(-1);
  };

  // Load the field report
  useEffect(() => {
    if (fieldInfoQuery.data) {
      const { sampleLocationDescription, additionalInformation, ...fieldInfo } =
        fieldInfoQuery.data.payload[0];
      setInitialValuesFieldInformation({
        workOrderID: fieldInfo.id,
        fieldTechnicianID: fieldInfo.fieldTechnicianID,
        dateOfVisit: fieldInfo.dateOfVisit ?? '',
        equipmentNumber: fieldInfo.equipmentNumber ?? '',
        transportationTime: fieldInfo.transportationTime ?? 0,
        mileage: fieldInfo.mileage ?? 0,
        checklist: fieldInfo.checklist ?? [],
        unexpectedHazards: fieldInfo.unexpectedHazards ?? '',
        weather: fieldInfo.weather ?? [],
        subContractorOrganisation: fieldInfo.subContractorOrganisation ?? '',
        subContractorContactName: fieldInfo.subContractorContactName ?? '',
        subContractorContactTelephone: fieldInfo.subContractorContactTelephone ?? '',
        subContractorContactEmail: fieldInfo.subContractorContactEmail ?? '',
        
      });
      setInitialValuesSampleInformation({ sampleLocationDescription, additionalInformation });
    }
  }, [fieldInfoQuery.data]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, mt: 2 }}>
        <Button startIcon={<ArrowBack />} onClick={() => setBackDialogOpen(true)} sx={{ mr: 2 }}>
          {text.Buttons.BACK}
        </Button>
        <ConfirmationDialog
          title={text.CONFIRMATION_QUESTION}
          contentText={text.DATA_LOSS_WARNING}
          actionText={text.Buttons.CONFIRM}
          isOpen={backDialogOpen}
          handleClose={() => setBackDialogOpen(false)}
          handleOpen={() => setBackDialogOpen(true)}
          handleAction={handleBackNavigation}
        />
        <Typography variant="h4" fontWeight="bold">
          {text.WorkOrders.WORK_ORDER + ' #' + workOrderQuery.data?.payload.number}
        </Typography>
      </Box>
      {!isMobileOrTablet ? (
        <CustomLinearStepper activeStep={activeStep} steps={steps} sx={{ p: 2 }} />
      ) : (
        <></>
      )}

      {activeStep === 0 ? (
        <React.Fragment>
          <ConcreteFieldInformation isConcreteReportEdit={true}  formikFieldInformation={formikFieldInformation} />
          <br />
          <ConcreteMixDesigns
            rows={concreteMixDesignsQuery.data?.payload}
            fieldReportExists={true}
            assignedTechnicianID={workOrderQuery.data?.payload.fieldTechnician.id}
          />
        </React.Fragment>
      ) : activeStep == 1 ? (
        <ConcretingSequence
          rows={concretingSequencesQuery?.data?.payload}
          fieldReportExists={false}
          assignedTechnicianID={workOrderQuery.data?.payload.fieldTechnician.id}
          isConcreteSequeceEdit={true}
        />
      ) : (
        <Grid container direction="column">
          <Grid item>
            <SampleInformation
              formikSampleInformation={formikSampleInformation}
              fieldReportExists={false}
            />
          </Grid>
        </Grid>
      )}

      {!isMobileOrTablet ? (
        <Grid container>
          <Grid item sx={{ flex: 1, mt: 2, mb: 2 }}>
            <Button onClick={handleBack} disabled={activeStep === 0}>
              {text.Buttons.BACK}
            </Button>
          </Grid>
          <Grid item sx={{ mt: 2, mb: 2 }}>
            {concreteMixDesignsQuery.data?.payload.length > 0 ? (<>
              {activeStep === steps.length - 1 && formikSampleInformation.dirty && formikSampleInformation.touched ?
                  <Button variant="contained" onClick={handleNext}>{text.Buttons.SAVE}</Button> : activeStep === steps.length - 1 ?
                  <Button variant="contained" onClick={handleNext}>{text.Buttons.COMPLETE}</Button> :
                  <Button variant="contained" onClick={handleNext}>{text.Buttons.SAVE_AND_CONTINUE}</Button>
              }
            </>
            ) : (
              <Button onClick={handleNext} disabled={true}>
                {text.NEED_TO_ADD_CONCRETE_MIX_DESIGN}
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <CustomLinearMobileStepper
          sx={{ mt: 2 }}
          maxSteps={steps.length}
          handleBack={handleBack}
          handleNext={handleNext}
          activeStep={activeStep}
        />
      )}
    </Box>
  );
};

export default ConcreteFieldReportEdit;
