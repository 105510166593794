import { ConcretingSequenceDialog } from "./ConcretingSequenceDialog"
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SampleType } from '../../../constants/options';
import useConcretingSequenceAdd from '../../../hooks/useConcretingSequenceAdd';
import {
    addToQueue,
    formType,
    selectAllTypeFromQueue,
    selectFromQueue,
    updateQueue,
} from '../../../slices/offlineQueueSlice';
import { useGetConcretingSequenceQuery, useGetConcretingSequencesQuery, useUpdateConcretingSequenceMutation } from "../../../services/concretingSequenceApi";
import useRequest from "../../../hooks/useRequest";
import { useGetConcreteMixDesignsQuery } from "../../../services/concreteMixDesignApi";
import { useGetSampleByWorkOrderQuery, useGetSamplesQuery, useUpdateSampleMutation } from "../../../services/samplesApi";
import { useGetCylindersQuery } from "../../../services/cylindersApi";
import { useHandleError } from "../../../hooks/useHandleError";
import useHandleSuccess from "../../../hooks/useHandleSuccess";


const ConcretingSequenceEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: workOrderID, concretingSequenceID: urlConcretingSequenceID } = useParams();
    const [updateSample] = useUpdateSampleMutation()
    const {
        STEPS,
        laboratoryCuredSampleID,
        setLaboratoryCuredSampleID,
        fieldCuredSampleID,
        setFieldCuredSampleID,
        commonSampleFields,
        setCommonSampleFields,
        activeStep,
        setActiveStep,
        submitted,
        setSubmitted,
        concretingSequenceID,
        setConcretingSequenceID,
        initialValuesConcretingSequence,
        setInitialValuesConcretingSequence,
        initialValuesLaboratoryCured,
        setInitialValuesLaboratoryCured,
        initialValuesFieldCured,
        setInitialValuesFieldCured,
        openLaboratorySample,
        setOpenLaboratorySample,
        openFieldSample,
        setOpenFieldSample,
        handleBack,
        handleContinue,
        handleSave,
        handleSaveAndComplete,
        handleSetCommonSampleField,
        handleRemoveLaboratorySample,
        handleRemoveFieldSample,
        handleAddLaboratorySample,
        handleAddFieldSample,
        formikConcretingSequence,
        formikSamplesLaboratoryCured,
        formikSamplesFieldCured,
    } = useConcretingSequenceAdd({
        submitConcretingSequence: submitConcretingSequence,
        submitLaboratoryCuredSample: submitLaboratoryCuredSample,
        submitFieldCuredSample: submitFieldCuredSample,
    });
    
    const samples = useSelector((state) => selectAllTypeFromQueue(state, formType.SAMPLE)).filter(
        (item) => item.concretingSequenceID === urlConcretingSequenceID
    );
    const { data } = useGetConcretingSequenceQuery(urlConcretingSequenceID, { refetchOnMountOrArgChange: true });
    const [updateConcreteSequence] = useUpdateConcretingSequenceMutation();
    const { handleErrorResponse } = useHandleError();
    const { handleSuccessResponse } = useHandleSuccess();

    // Get Concrete Mix Designs
    const concreteMixDesignsQuery = useRequest(useGetConcreteMixDesignsQuery, {
        'filter[workOrderID]': `equals,${workOrderID}`,
    });

    // Get Concreting Sequences
    const concretingSequencesQuery = useRequest(useGetConcretingSequencesQuery, {
        'filter[workOrderID]': `equals,${workOrderID}`,
    });

    // Get Samples
    const samplesQuery = useRequest(useGetSamplesQuery, { 'filter[workOrderID]': `equals,${workOrderID}` });

    const cylindersQuery = useRequest(useGetCylindersQuery, {
        'filter[workOrderID]': `equals,${workOrderID}`,
    });

    useEffect(() => {
        if (data) {
            const concreteData = data.payload;
            formikConcretingSequence.setValues({
                ...formikConcretingSequence.values,
                loadNumber: concreteData?.loadNumber,
                truckNumber: concreteData?.truckNumber,
                ticketNumber: concreteData?.ticketNumber,
                quantityTruckLoad: concreteData?.quantityTruckLoad,
                quantityCumulative: concreteData?.quantityCumulative,
                batchTime: concreteData?.batchTime,
                arrivedOnSiteTime: concreteData?.arrivedOnSiteTime,
                startTime: concreteData?.startTime,
                endTime: concreteData?.endTime,
                concreteMixDesignID: concreteData?.concreteMixDesignID,
                airContent: concreteData?.airContent,
                adjustedAirContent: concreteData?.adjustedAirContent,
                waterAdditive: concreteData?.waterAdditive,
                spAdditive: concreteData?.spAdditive,
                concretePourTest: concreteData.concretePourTest,
                vsiValue: concreteData?.vsiValue,
                t50Time: concreteData?.t50Time,
                slumpFlow: concreteData?.slumpFlow,
                slump: concreteData?.slump,
                adjustedSlump: concreteData?.adjustedSlump,
                ambientTemperature: concreteData?.ambientTemperature,
                concreteTemperature: concreteData?.concreteTemperature,
                conformity: concreteData?.conformity,
                additionalRemarks: concreteData?.additionalRemarks,
                collectSamples: concreteData?.collectSamples,
                authorizedBy: concreteData?.authorizedBy || "",
                typeOfAdmixtures: concreteData?.typeOfAdmixtures || "",
            })
        }
    }, [data]);

    useEffect(() => {
        if (samplesQuery.data) {
            for (let i in samplesQuery.data.payload) {
                if(samplesQuery.data.payload[i].concretingSequenceID === urlConcretingSequenceID){
                    if (samplesQuery.data.payload[i].type === SampleType.LABORATORY_CURED) {
                        setLaboratoryCuredSampleID(samplesQuery.data.payload[i].id);
                        setInitialValuesLaboratoryCured(samplesQuery.data.payload[i]);
                        setOpenLaboratorySample(true);
                    }
                    if (samplesQuery.data.payload[i].type === SampleType.FIELD_CURED) {
                        setFieldCuredSampleID(samplesQuery.data.payload[i].id);
                        setInitialValuesFieldCured(samplesQuery.data.payload[i]);
                        setOpenFieldSample(true);
                    }
    
                    setCommonSampleFields(() => {
                        return {
                            number: samplesQuery.data.payload[i].number,
                            sizeOfSample: samplesQuery.data.payload[i].sizeOfSample,
                            locationInStructure: samplesQuery?.data?.payload[i]?.locationInStructure,
                            timeOfMoulding: samplesQuery.data.payload[i].timeOfMoulding,
                            timeOfSampling: samplesQuery.data.payload[i].timeOfSampling,
                        };
                    });
                }
            }
        }
    }, [samplesQuery.data])
    
    function submitLaboratoryCuredSample(values) {
        // const sample = { ...values, id: laboratoryCuredSampleID };

        const labSamples = samples.filter((item) => item.type === SampleType.LABORATORY_CURED);
        if (labSamples.length > 0) {
            dispatch(
                updateQueue({
                    id: laboratoryCuredSampleID,
                    type: formType.SAMPLE,
                    data: { ...values, id: laboratoryCuredSampleID },
                })
            );
        } else {
            dispatch(
                addToQueue({
                    id: laboratoryCuredSampleID,
                    type: formType.SAMPLE,
                    data: { ...values, id: laboratoryCuredSampleID },
                })
            );
        }

        // Need to set initial values for select boxes
        setInitialValuesLaboratoryCured(values);
    }

    function submitFieldCuredSample(values) {
        // const sample = { ...values, id: fieldCuredSampleID };
        const fieldSamples = samples.filter((item) => item.type === SampleType.FIELD_CURED);
        if (fieldSamples.length > 0) {
            dispatch(
                updateQueue({
                    id: fieldCuredSampleID,
                    type: formType.SAMPLE,
                    data: { ...values, id: fieldCuredSampleID },
                })
            );
        } else {
            dispatch(
                addToQueue({
                    id: fieldCuredSampleID,
                    type: formType.SAMPLE,
                    data: { ...values, id: fieldCuredSampleID },
                })
            );
        }

        // Need to set initial values for select boxes
        setInitialValuesFieldCured(values);
    }

    function submitConcretingSequence(values) {
        // const concretingSequence = { ...values, id: concretingSequenceID };

        dispatch(
            updateQueue({
                id: concretingSequenceID,
                type: formType.CONCRETING_SEQUENCE,
                data: { ...values, id: concretingSequenceID },
            })
        );

        // Need to set initial values for select boxes
        setInitialValuesConcretingSequence(values);
    }

    return (<>
        <ConcretingSequenceDialog
            isConcreteSequeceEdit={true}
            formikConcretingSequence={formikConcretingSequence}
            formikSamplesLaboratoryCured={formikSamplesLaboratoryCured}
            formikSamplesFieldCured={formikSamplesFieldCured}
            laboratoryCuredSampleID={laboratoryCuredSampleID}
            fieldCuredSampleID={fieldCuredSampleID}
            commonSampleFields={commonSampleFields}
            handleSetCommonSampleField={handleSetCommonSampleField}
            setCommonSampleFields={setCommonSampleFields}
            openLaboratorySample={openLaboratorySample}
            openFieldSample={openFieldSample}
            concreteMixDesignsQuery={concreteMixDesignsQuery?.data?.payload}
            handleSaveAndComplete={async () => {
                try {
                    const changes ={
                        ...formikConcretingSequence.values
                    }
                    console.log(changes,'changes')
                    await updateConcreteSequence({
                        changes,
                        id: urlConcretingSequenceID,
                    }).unwrap();
                    const arr = [formikSamplesFieldCured.values, formikSamplesLaboratoryCured.values];
                    for (let i = 0; i < arr.length; i++) {
                        const data = { changes: arr[i], id: arr[i].id }
                        await updateSample(data);
                    }
                    handleSuccessResponse('Succefully Updated');
                    concretingSequencesQuery.refetch(); 
                    handleSaveAndComplete();
                } catch (error) {
                    handleErrorResponse('Something Went Wrong');
                }
            }}
            // handleSaveAndComplete={handleSaveAndComplete}
            handleContinue={handleContinue}
            handleBack={handleBack}
            handleRemoveLaboratorySample={handleRemoveLaboratorySample}
            handleRemoveFieldSample={handleRemoveFieldSample}
            handleAddLaboratorySample={handleAddLaboratorySample}
            handleAddFieldSample={handleAddFieldSample}
            activeStep={activeStep}
            steps={STEPS}
            isOpen={true}
            onClose={() => navigate(-1)}
            onOpen={(location) => navigate(location.pathname)}
            cylindersQuery={cylindersQuery}
        />
    </>)
}

export default ConcretingSequenceEdit