import { createSlice } from "@reduxjs/toolkit";

const initialState = { id: null, email: null, name: null };
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      return { ...action.payload };
    },
    clearUser(state, action) {
      return initialState;
    },
  },
});

// Actions
export const { setUser, clearUser } = userSlice.actions;

// Selecters
export const selectUser = (state) => state.user;

export default userSlice;
