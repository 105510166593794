// Component for handling changes made to concreting
// sequence before submitting the field report

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SampleType } from '../../../constants/options';
import useConcretingSequenceAdd from '../../../hooks/useConcretingSequenceAdd';
import {
  addToQueue,
  formType,
  selectAllTypeFromQueue,
  selectFromQueue,
  updateQueue,
} from '../../../slices/offlineQueueSlice';
import { ConcretingSequenceDialog } from './ConcretingSequenceDialog';

export const ConcretingSequenceAddChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { concretingSequenceID: urlConcretingSequenceID } = useParams();

  const {
    STEPS,
    laboratoryCuredSampleID,
    setLaboratoryCuredSampleID,
    fieldCuredSampleID,
    setFieldCuredSampleID,
    commonSampleFields,
    setCommonSampleFields,
    activeStep,
    setActiveStep,
    submitted,
    setSubmitted,
    concretingSequenceID,
    setConcretingSequenceID,
    initialValuesConcretingSequence,
    setInitialValuesConcretingSequence,
    initialValuesLaboratoryCured,
    setInitialValuesLaboratoryCured,
    initialValuesFieldCured,
    setInitialValuesFieldCured,
    openLaboratorySample,
    setOpenLaboratorySample,
    openFieldSample,
    setOpenFieldSample,
    handleBack,
    handleContinue,
    handleSave,
    handleSaveAndComplete,
    handleSetCommonSampleField,
    handleRemoveLaboratorySample,
    handleRemoveFieldSample,
    handleAddLaboratorySample,
    handleAddFieldSample,
    formikConcretingSequence,
    formikSamplesLaboratoryCured,
    formikSamplesFieldCured,
  } = useConcretingSequenceAdd({
    submitConcretingSequence: submitConcretingSequence,
    submitLaboratoryCuredSample: submitLaboratoryCuredSample,
    submitFieldCuredSample: submitFieldCuredSample,
  });

  // Retrieve data from Local Storage
  const concretingSequence = useSelector(
    (state) => selectFromQueue(state, urlConcretingSequenceID).data
  );
  const samples = useSelector((state) => selectAllTypeFromQueue(state, formType.SAMPLE)).filter(
    (item) => item.concretingSequenceID === urlConcretingSequenceID
  );

  function submitLaboratoryCuredSample(values) {
    // const sample = { ...values, id: laboratoryCuredSampleID };

    const labSamples = samples.filter((item) => item.type === SampleType.LABORATORY_CURED);
    if (labSamples.length > 0) {
      dispatch(
        updateQueue({
          id: laboratoryCuredSampleID,
          type: formType.SAMPLE,
          data: { ...values, id: laboratoryCuredSampleID },
        })
      );
    } else {
      dispatch(
        addToQueue({
          id: laboratoryCuredSampleID,
          type: formType.SAMPLE,
          data: { ...values, id: laboratoryCuredSampleID },
        })
      );
    }

    // Need to set initial values for select boxes
    setInitialValuesLaboratoryCured(values);
  }

  function submitFieldCuredSample(values) {
    // const sample = { ...values, id: fieldCuredSampleID };
    const fieldSamples = samples.filter((item) => item.type === SampleType.FIELD_CURED);

    if (fieldSamples.length > 0) {
      dispatch(
        updateQueue({
          id: fieldCuredSampleID,
          type: formType.SAMPLE,
          data: { ...values, id: fieldCuredSampleID },
        })
      );
    } else {
      dispatch(
        addToQueue({
          id: fieldCuredSampleID,
          type: formType.SAMPLE,
          data: { ...values, id: fieldCuredSampleID },
        })
      );
    }

    // Need to set initial values for select boxes
    setInitialValuesFieldCured(values);
  }

  function submitConcretingSequence(values) {
    // const concretingSequence = { ...values, id: concretingSequenceID };

    dispatch(
      updateQueue({
        id: concretingSequenceID,
        type: formType.CONCRETING_SEQUENCE,
        data: { ...values, id: concretingSequenceID },
      })
    );

    // Need to set initial values for select boxes
    setInitialValuesConcretingSequence(values);
  }

  useEffect(() => {
    if (urlConcretingSequenceID) {
      setInitialValuesConcretingSequence({ ...concretingSequence });
      setConcretingSequenceID(urlConcretingSequenceID);
    }
  }, [urlConcretingSequenceID]);

  useEffect(() => {
    if (samples && samples.length > 0) {
      for (const sample of samples) {
        if (sample.type === SampleType.LABORATORY_CURED) {
          setLaboratoryCuredSampleID(sample.id);
          setInitialValuesLaboratoryCured(sample);
          setOpenLaboratorySample(true);
        }

        if (sample.type === SampleType.FIELD_CURED) {
          setFieldCuredSampleID(sample.id);
          setInitialValuesFieldCured(sample);
          setOpenFieldSample(true);
        }

        setCommonSampleFields(() => {
          return {
            number: sample.number,
            sizeOfSample: sample.sizeOfSample,
            locationInStructure: sample.locationInStructure,
            timeOfMoulding:  sample.timeOfMoulding,
            timeOfSampling :  sample.timeOfSampling
          };
        });
      }
    }
  }, []);

  return (
    <ConcretingSequenceDialog
      formikConcretingSequence={formikConcretingSequence}
      formikSamplesLaboratoryCured={formikSamplesLaboratoryCured}
      formikSamplesFieldCured={formikSamplesFieldCured}
      laboratoryCuredSampleID={laboratoryCuredSampleID}
      fieldCuredSampleID={fieldCuredSampleID}
      commonSampleFields={commonSampleFields}
      handleSetCommonSampleField={handleSetCommonSampleField}
      setCommonSampleFields={setCommonSampleFields}
      openLaboratorySample={openLaboratorySample}
      openFieldSample={openFieldSample}
      handleSaveAndComplete={handleSaveAndComplete}
      handleContinue={handleContinue}
      handleBack={handleBack}
      handleRemoveLaboratorySample={handleRemoveLaboratorySample}
      handleRemoveFieldSample={handleRemoveFieldSample}
      handleAddLaboratorySample={handleAddLaboratorySample}
      handleAddFieldSample={handleAddFieldSample}
      activeStep={activeStep}
      steps={STEPS}
      isOpen={true}
      onClose={() => navigate(-1)}
      onOpen={(location) => navigate(location.pathname)}
    />
  );
};
