export const getObjectChanges = (values, initialValues) => {
  let changes = {};
  for (const key of Object.keys(initialValues)) {
    if (values[key] !== initialValues[key]) {
      changes = { ...changes, [key]: values[key] };
    }
  }
  return changes;
};

// Remove empty/null/undefined fields from an object
export const getPopulated = (values) => {
  let object = {};
  for (const key of Object.keys(values)) {
    if (values[key] !== '' && values[key] !== null && values[key] !== undefined) {
      object = { ...object, [key]: values[key] };
    }
  }
  return object;
};
