import { createTheme } from '@mui/material/styles';
import { green, grey } from '@mui/material/colors';

let theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      normal: '#000000',
    },
    secondary: {
      main: '#ff0055',
    },
    white: {
      main: '#ffffff',
    },
    grey: {
      main: '#c1c1c1',
    },
    neutral: {
      light: grey[50],
      medium: grey[200],
      normal: grey[700],
      main: grey[900],
    },
    green: {
      main: green[800],
    },
    success: {
      light: '#aee6bd',
      secondary: '#1dc24e',
      main: '#4bb86a',
    },
    error: {
      secondary: '#b84b4b',
      main: '#f23427',
      light: '#fabdb9',
    },
  },
});

theme = createTheme(theme, {
  typography: {
    link: {
      fontSize: '0.8rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem',
      },
      fontWeight: 500,
      color: theme.palette.primary.normal,
      display: 'block',
      cursor: 'pointer',
    },
    cardTitle: {
      fontSize: '1.2rem',
      display: 'block',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
    },
    h7: {
      fontSize: '0.8rem',
    },
    h8: {
      fontSize: '0.7rem',
    },
  },
});

export default theme;
