import routes from "../constants/routes";
import { featurePermissions } from "../constants/permissions";
import useLocale from "./useLocale";

export const navItemType = {
  ITEM: "item",
  GROUP: "group",
};

export const useNavItems = () => {
  const text = useLocale();

  const navItems = [
    {
      name: text.Navigation.HOME,
      navItemType: navItemType.ITEM,
      to: routes.HOME,
      icon: "HomeOutlined",
      permissions: [
        featurePermissions.PROJECTS_READ,
        featurePermissions.WORK_ORDERS_READ,
        featurePermissions.CLIENTS_READ,
      ],
    },
    {
      name: text.Navigation.PROJECTS,
      navItemType: navItemType.ITEM,
      to: routes.PROJECTS,
      icon: "SourceOutlined",
      permissions: [featurePermissions.PROJECTS_CREATE],
    },

    {
      name: text.Navigation.CLIENTS,
      navItemType: navItemType.ITEM,
      to: routes.CLIENTS,
      icon: "AssignmentIndOutlined",
      permissions: [featurePermissions.CLIENTS_CREATE],
    },
    {
      name: text.Navigation.WORKORDERS,
      navItemType: navItemType.ITEM,
      to: routes.WORKORDERS,
      icon: "AnalyticsOutlined",
      permissions: [featurePermissions.WORK_ORDERS_CREATE],
    },
    {
      name: text.Navigation.ASSIGNED_WORKORDERS,
      navItemType: navItemType.ITEM,
      to: routes.WORKORDERS_ASSIGNED,
      icon: "AnalyticsOutlined",
      permissions: [
        featurePermissions.CONCRETE_FIELD_INFORMATION_CREATE,
        featurePermissions.CONCRETE_MIX_DESIGN_CREATE,
        featurePermissions.CONCRETING_SEQUENCES_CREATE,
        featurePermissions.CYLINDERS,
        featurePermissions.SAMPLES_CREATE,
      ],
    },
    {
      name: text.Navigation.SAMPLE_DELIVERIES,
      navItemType: navItemType.ITEM,
      to: routes.SAMPLE_PICKUPS,
      icon: "ScienceOutlined",
      permissions: [featurePermissions.CYLINDERS_UPDATE, featurePermissions.SAMPLES_UPDATE],
    },
    {
      name: text.Navigation.ASSIGNED_SAMPLE_DELIVERIES,
      navItemType: navItemType.ITEM,
      to: routes.SAMPLE_PICKUPS_ASSIGNED,
      icon: "ScienceOutlined",
      permissions: [featurePermissions.SAMPLES_CREATE],
    },
    {
      name: text.Navigation.LABCYLINDERS,
      navItemType: navItemType.ITEM,
      to: routes.LAB_CYLINDERS,
      icon: "ScienceOutlined",
      permissions: [featurePermissions.CYLINDERS_UPDATE],
    },
    {
      name: text.Navigation.USERS,
      navItemType: navItemType.ITEM,
      to: routes.USERS,
      icon: "Settings",
      permissions: [
        featurePermissions.USERS_CREATE,
        featurePermissions.USERS_DELETE,
        featurePermissions.USERS_UPDATE,
      ],
    },
    {
      name: text.Navigation.DATA_VIEW,
      navItemType: navItemType.ITEM,
      to: routes.DATA_VIEW,
      icon: "DataSaverOff",
      permissions: [
        featurePermissions.USERS_CREATE,
        featurePermissions.USERS_DELETE,
        featurePermissions.USERS_UPDATE,
      ],
    },
    {
      name: text.Navigation.EQUIPMENT_SET,
      navItemType: navItemType.ITEM,
      to: routes.EQUIPMENT_SET,
      icon: "Construction",
      permissions: [
        featurePermissions.USERS_CREATE,
        featurePermissions.USERS_DELETE,
        featurePermissions.USERS_UPDATE,
      ],
    },
  ];
  return navItems;
};

export default useNavItems;
