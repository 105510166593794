// For refreshing tokens

import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import actions from '../constants/actions';
import { useRefreshMutation } from '../services/authApi';
import { sleep } from '../config/utils';
import useLogout from './useLogout';
import settings from '../constants/settings';
const useTokenRefresh = () => {
  const [refresh] = useRefreshMutation();
  const logoutThunk = useLogout();
  const tokenRefresh = createAsyncThunk(actions.POLL_AUTH_REFRESH, async (params, thunkAPI) => {
    try {
      const auth = thunkAPI.getState().auth;

      if (!auth.token || !auth.expiry) return;
      const now = moment();

      if (now.unix() < auth.expiry) {
        const timeDiff = auth.expiry - now.unix();
        if (timeDiff < 30) {
          refresh();
        }
      } else {
        thunkAPI.dispatch(logoutThunk());
      }
      await sleep(settings.REFRESH_POLL_RATE);
      thunkAPI.dispatch(tokenRefresh());
    } catch (e) {
      console.log(e);
    }

    return params;
  });
  return tokenRefresh;
};

export default useTokenRefresh;
