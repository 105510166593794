import React from 'react';
import routes from '../../constants/routes';
import { FlexBetween, Header, CustomDataGrid } from '../../components';
import { useGetElement, useLocale } from '../../hooks';
import tags from '../../services/tags';
import { useGetClientsQuery } from '../../services/clientsApi';
import useRequest from '../../hooks/useRequest';

function Clients() {
  const text = useLocale();
  // const navigate = useNavigate();
  const navItem = useGetElement(text.Clients.NAME);

  // READ Functions
  const clientsQuery = useRequest(useGetClientsQuery);

  const columns = [
    { field: 'organizationName', headerName: 'Organization', flex: 1, minWidth: 150 },
    { field: 'clientContactName', headerName: 'Client Contact', flex: 1, minWidth: 150 },
    { field: 'clientContactTelephone', headerName: 'Telephone', flex: 1, minWidth: 100 },
    { field: 'clientContactEmail', headerName: 'Email', flex: 1, minWidth: 250 },
  ];

  return (
    <React.Fragment>
      <FlexBetween>
        <Header
          title={navItem.name}
          icon={navItem.icon}
          bcrumb={text.Clients.TABLENAME}
          AddTo={routes.CLIENT_ADD}
        />
      </FlexBetween>
      <CustomDataGrid
        col={columns}
        to={routes.CLIENTS}
        rQuery={clientsQuery}
        tags={[tags.CLIENT]}
      />
    </React.Fragment>
  );
}

export default Clients;
