import { createAsyncThunk } from '@reduxjs/toolkit';
import actions from '../constants/actions';
import cacheKeys from '../constants/cacheKeys';
import { useLoginMutation } from '../services/authApi';
import { setToken } from '../slices/authSlice';
import { setLoading, clearLoading } from '../slices/loadingSlice';
import { useDispatch } from 'react-redux';

const useLogin = () => {
  const dispatch = useDispatch();

  const [login] = useLoginMutation({ fixedCacheKey: cacheKeys.LOGIN_MUTATION });
  const loginThunk = createAsyncThunk(actions.AUTH_LOGIN, async (params, thunkAPI) => {
    try {
      dispatch(setLoading());
      const result = await login(params).unwrap();
      thunkAPI.dispatch(setToken(result));
    } catch (e) {
      console.error(e);
    }
    dispatch(clearLoading());
  });
  return loginThunk;
};

export default useLogin;
