import { TextField } from "@mui/material";
import { useLocale } from "../../hooks";

const CustomTextField = ({ formik, tool, name, type, ...props }) => {
  const text = useLocale();
  let uppername = name.toUpperCase(); //To convert Upper Case
  let varType = '';
  type ? varType = type : varType = 'text'

  return (
    <TextField
      label={text[tool][uppername]}
      fullWidth
      sx={{ mt: 2 }}
      type={varType}
      name={name}
      onChange={formik.handleChange}
      value={formik.values[name]}
      defaultValue={formik.initialValues[name]}
      {...props}
    />
  );
};

export default CustomTextField;
