// import * as yup from "yup";

import { SampleSize, SampleType } from '../constants/options';
import { object, string, array, date, number } from 'yup';
import useLocale from '../hooks/useLocale';

export const useSampleSchema = () => {
  const text = useLocale();
  const sampleCreateSchema = object({
    concretingSequenceID: string().required(),
    type: string().oneOf(Object.keys(SampleType)),
    number: string().required(text.REQUIRED),
    timeOfSampling: string(),
    timeOfMoulding: string(),
    // samplingLocation: string(),
    typeOfMould: string(),
    sizeOfMould: string(),
    curingConditions: array(string()),
    sizeOfSample: string().oneOf(Object.keys(SampleSize)),
    locationInStructure: string().required(),
  });

  const sampleEditSchema = object({
    type: string().oneOf(Object.keys(SampleType)),
    number: string(),
    timeOfSampling: string(),
    timeOfMoulding: string(),
    // samplingLocation: string(),
    typeOfMould: string(),
    sizeOfMould: string(),
    curingConditions: array(string()),
    minimumTemperature: number(),
    maximumTemperature: number(),
    sizeOfSample: string().oneOf(Object.keys(SampleSize)),
    locationInStructure: string(),
  });

  return { sampleCreateSchema, sampleEditSchema };
};
