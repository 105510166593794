import * as yup from 'yup';
import { useLocale } from '../hooks';

export const useClientSchema = () => {
  const text = useLocale();

  const clientCreateSchema = yup.object().shape({
    organizationName: yup.string().required(text.REQUIRED),
    clientContactName: yup.string().required(text.REQUIRED),
    clientContactTelephone: yup.string().required(text.REQUIRED),
    clientContactEmail: yup.string().email(text.INVALID_EMAIL).required(text.REQUIRED),
  });

  const clientEditSchema = yup.object().shape({
    organizationName: yup.string().nullable().required(text.REQUIRED),
    clientContactName: yup.string().nullable().required(text.REQUIRED),
    clientContactTelephone: yup.string().nullable().required(text.REQUIRED),
    clientContactEmail: yup.string().email(text.INVALID_EMAIL).nullable().required(text.REQUIRED),
  });
  return { clientCreateSchema, clientEditSchema };
};
