import { Box, Button, Card, CardContent } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import useSubmitConcreteFieldReport from '../../hooks/useSubmitConcreteFieldReport';
import { useLocale } from '../../hooks';
import { useDispatch } from 'react-redux';
const ReviewAndSubmit = () => {
  const text = useLocale();
  const dispatch = useDispatch();
  const { id: workOrderID } = useParams();
  const { submitConcreteFieldReport } = useSubmitConcreteFieldReport();

  const submit = async () => {
    dispatch(submitConcreteFieldReport(workOrderID));
  };
  return (
    <Card>
      <CardContent>
        <Box
          sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', m: 3 }}
        >
          <Button variant="contained" color={'secondary'} onClick={submit}>
            {text.Buttons.SAVE_AND_SUBMIT}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ReviewAndSubmit;
