import { Modal, Box, Button, Typography } from "@mui/material";
import React from "react";
import Icon from "../Icon";
import { useUpdateProjectMutation } from "../../services/projectsApi";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const CustomModal = ({ bodytext, value, projectID }) => {
  const [updateProject] = useUpdateProjectMutation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let color = "";
  let varIcon = "";
  if (value === "Add") {
    varIcon = "AddBox";
    color = "#000000";
  } else if (value === "NoteAlt") {
    varIcon = "NoteAlt";
    color = "#000000";
  } else if (value === "COMPLETED") {
    varIcon = "AssignmentTurnedIn";
    color = "#4bb86a";
  } else if (value === "CANCELLED") {
    varIcon = "Delete";
    color = "#ff0000";
  }

  function updateProjectStatus(value, projectID) {
    updateProject({ changes: { status: value }, id: projectID })
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button
        startIcon={<Icon iconName={varIcon} sx={{ color: color }} />}
        size="large"
        onClick={handleOpen}
        sx={{ mb: 2, mr: 2 }}
        variant={"contained"}
        color={"white"}
      >
        Mark as {value}
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...styleModal, width: 400 }}>
          <Typography>
            {bodytext} {value}?
          </Typography>
          <Button
            startIcon={<Icon iconName={"ArrowBack"} />}
            variant="outlined"
            onClick={handleClose}
            sx={{ mt: 2, mr: 2 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => updateProjectStatus(value, projectID)}
            color="primary"
            sx={{ mt: 2 }}
          >
            Mark as {value}
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default CustomModal;
