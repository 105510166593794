import { createSlice } from '@reduxjs/toolkit';

const initialState = {};
const samplePickupSlice = createSlice({
  name: 'samplePickups',
  initialState: initialState,
  reducers: {
    updateSamplePickup(state, action) {
      return { ...state, [action.payload.id]: action.payload };
    },
    removeSamplePickup(state, action) {
      let newState = {};
      for (const key of Object.keys(state)) {
        if (key !== action.payload) {
          newState = { ...newState, [key]: state[key] };
        }
      }
      return newState;
    },
    clearSamplePickups(state, action) {
      return initialState;
    },
  },
});

// Actions
export const { updateSamplePickup, removeSamplePickup, clearSamplePickups } =
  samplePickupSlice.actions;

// Selecters
export const selectSamplePickups = (state) => state.samplePickups;
export const selectSamplePickupByID = (state, id) => state.samplePickups[id];

export default samplePickupSlice;
