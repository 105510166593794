import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '../../components';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import CylindersPanel from '../../components/lab/CylindersPanel';
import LabInfoPanel from '../../components/lab/LabInfoPanel';
import { useLocale } from '../../hooks';

function LabCylindersDetails() {
  const text = useLocale();
  const navigate = useNavigate();
  const { id } = useParams();
  const [fields, setFields] = useState({});
  const [backDialogOpen, setBackDialogOpen] = useState(false);
  const handleNavigationCheck = () => {
    if (isDirty(fields)) {
      setBackDialogOpen(true);
    } else {
      handleBackNavigation();
    }
  };

  const handleBackNavigation = () => {
    navigate(-1);
  };
  // Render
  return (
    <React.Fragment>
      <Button
        startIcon={<Icon iconName={'ArrowBack'} />}
        onClick={handleNavigationCheck}
        sx={{ mr: 2 }}
      >
        {text.Buttons.BACK}
      </Button>
      <ConfirmationDialog
        title={text.CONFIRMATION_QUESTION}
        contentText={text.DATA_LOSS_WARNING}
        actionText={text.Buttons.CONFIRM}
        isOpen={backDialogOpen}
        handleClose={() => setBackDialogOpen(false)}
        handleOpen={() => setBackDialogOpen(true)}
        handleAction={handleBackNavigation}
      />

      <LabInfoPanel sampleID={id} />
      <Box sx={{ mt: 2 }}>
        <CylindersPanel sampleID={id} fields={fields} setFields={setFields} />
      </Box>
    </React.Fragment>
  );
}

function isDirty(allFields) {
  for (const key of Object.keys(allFields)) {
    if (allFields[key] === true) {
      return true;
    }
  }
  return false;
}

export default LabCylindersDetails;
