import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingIcon } from '../../components';
import { useLocale } from '../../hooks';

import { useSelector } from 'react-redux';
import ConcreteFieldReportAdd from '../../components/ConcreteFieldReport/ConcreteFieldReportAdd';
import PermissionGate from '../../components/PermissionGate';
import { featurePermissions } from '../../constants/permissions';
import { useGetWorkOrderQuery } from '../../services/workordersApi';
import { selectPermissions } from '../../slices/permissionSlice';
import useRequest from '../../hooks/useRequest';

function WorkOrderConcreteFieldReportAdd() {
  const userPermissions = useSelector(selectPermissions);
  const { id } = useParams();

  const workorderQuery = useRequest(useGetWorkOrderQuery, id);

  // Render
  return workorderQuery.isLoading ? (
    <LoadingIcon />
  ) : (
    <React.Fragment>
      <PermissionGate
        userPermissions={userPermissions}
        componentPermissions={[
          featurePermissions.CONCRETE_FIELD_INFORMATION_CREATE,
          featurePermissions.CONCRETE_MIX_DESIGN_CREATE,
          featurePermissions.CONCRETING_SEQUENCES_CREATE,
          featurePermissions.CYLINDERS,
          featurePermissions.SAMPLES_CREATE,
        ]}
      >
        <ConcreteFieldReportAdd />
      </PermissionGate>
    </React.Fragment>
  );
}

export default WorkOrderConcreteFieldReportAdd;
