import React, { useState } from 'react';
import { FlexBetween, Header } from '../../components';
import routes from '../../constants/routes';
import { useGetElement, useLocale } from '../../hooks';
import { useFormatDateTime } from '../../hooks/useFormatDateTime';
import { DateTime } from 'luxon';
import tags from '../../services/tags';

import { useNavigate } from 'react-router-dom';
import StatusChip from '../../components/StatusChip';
import CustomDataGrid2 from '../../components/customMUI/CustomDataGrid2';
import useRequest from '../../hooks/useRequest';
import { useGetCylindersQuery } from '../../services/cylindersApi';
import PermissionGate from '../../components/PermissionGate';
import { useSelector } from 'react-redux';
import { selectPermissions } from '../../slices/permissionSlice';
import { SampleStatus } from '../../constants/options';
import { Box, Card, CardContent, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { selectAuth } from '../../slices/authSlice';
import { selectUser } from '../../slices/userSlice';

function LabCylinders() {
  const text = useLocale();
  const userPermissions = useSelector(selectPermissions);
  const navItem = useGetElement(text.LabCylinders.NAME);
  const minColumnWidth = 150;
  const user = useSelector(selectUser);
  const [status, setStatus] = useState(user.roles.includes('Laboratory Supervisor') ? text.LabCylinders.PENDING_PREFERRED_LAB : text.LabCylinders.ACTIVE_STATUS);
  const { formatDate } = useFormatDateTime();

  const cylindersQueryTestingNotCompletedWithId = useRequest(useGetCylindersQuery, {
    'filter[statusFilter]': `notEquals,TESTING_COMPLETED`, 'filter[preferredLab]': `equals,${user?.preferredLaboratoryId}`
  });

  const cylindersQueryTestingNotCompleted = useRequest(useGetCylindersQuery, {
    'filter[statusFilter]': `notEquals,TESTING_COMPLETED`
  });

  const cylindersQueryOtherLab = useRequest(useGetCylindersQuery, {
    'filter[statusFilter]': `notEquals,TESTING_COMPLETED`, 'filter[preferredLab]': `notEquals,${user?.preferredLaboratoryId}`
  });

  const cylindersQueryTestingCompleted = useRequest(useGetCylindersQuery, {
    'filter[statusFilter]': `equals,TESTING_COMPLETED`
  });




  const handleChangeLabCylinderStatus = (event, newValue) => {
    setStatus(newValue);
  };

  const columns = [
    {
      field: 'projectCode',
      headerName: text.LabCylinders.PROJECTCODE,
      minWidth: minColumnWidth,
      flex: 1,
      valueGetter: (params) => {
        return params.row.sample.concretingSequence.workOrder.project.projectCode;
      },
    },
    {
      field: 'projectName',
      headerName: text.LabCylinders.PROJECTNAME,
      minWidth: minColumnWidth,
      flex: 1,
      valueGetter: (params) => {
        return params.row.sample.concretingSequence.workOrder.project.projectName;
      },
    },
    {
      field: 'workOrder',
      headerName: text.WorkOrders.NUMBER,
      minWidth: minColumnWidth,
      flex: 1,
      valueGetter: (params) => {
        return params.row.sample.concretingSequence.workOrder.number;
      },
    },
    {
      field: "laboratory",
      headerName: text.Laboratory.TITLE,
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.sample.concretingSequence.workOrder.laboratory.name;
      },
    },
    {
      field: 'sampleNumber',
      headerName: text.LabCylinders.SAMPLE_NUMBER,
      minWidth: minColumnWidth,
      flex: 1,
      valueGetter: (params) => {
        return params.row.sample.number;
      },
    },
    {
      field: 'customCylinderID',
      headerName: text.LabCylinders.CYLINDER_ID,
      minWidth: minColumnWidth,
      flex: 1,
    },
    {
      field: 'sampleType',
      headerName: text.LabCylinders.SAMPLE_TYPE,
      minWidth: minColumnWidth,
      flex: 1,
      valueGetter: (params) => {
        let val = 'Laboratory Cured'
        if (params.row.sample.type == 'FIELD_CURED') {
          val = 'Field Cured'
        }
        return val;
      },
    },

    {
      field: 'daysToBeCured',
      headerName: text.LabCylinders.DAYS_TO_BE_CURED,
      minWidth: minColumnWidth,
      flex: 1,
    },
    {
      field: 'dateToBeTested',
      headerName: text.LabCylinders.DATE_TO_BE_TESTED,
      minWidth: minColumnWidth,
      flex: 1,
      valueGetter: (params) => {
        const dateCastedISO =
          params.row.sample.concretingSequence.workOrder.concreteFieldInformation.dateOfVisit;
        const dateTimeObject = DateTime.fromISO(dateCastedISO, { zone: 'local' }).plus({
          days: params.row.daysToBeCured,
        });
        return formatDate(dateTimeObject.toISO());
      },
    },
    {
      field: 'status',
      headerName: text.LabCylinders.STATUS,
      minWidth: minColumnWidth,
      flex: 1,
      renderCell: (params) => {

        // console.log('paramsparamsparamsparamsparams', params.value);
        // if (!params.value) {cylindersQueryTestingNotCompleted
        return <StatusChip status={params.row.sample.status} />;
        // } else {
        //   return <StatusChip status={params.value} />;
        // }
      },
      valueGetter: (params) => {

        return params.row.sample.status;
      },
    },
  ];
  const navigate = useNavigate();

  const handleRowClick = (params) => {
    if (params.row.sample.status === SampleStatus.PENDING_TESTS || params.row.sample.status === SampleStatus.TESTING_COMPLETED) {
      navigate(routes.LAB_CYLINDERS_DETAILS.split(':')[0] + params.row.sample.id);
    }
  };

  return (
    <React.Fragment>
      <PermissionGate userPermissions={userPermissions} componentPermissions={navItem.permissions}>
        <FlexBetween>
          <Header title={navItem.name} icon={navItem.icon} bcrumb={text.LabCylinders.TABLENAME} />
        </FlexBetween>
        <Box>
          <Card>
            <CardContent>
              {
                user.roles.includes('Laboratory Supervisor') ? <>
                  <ToggleButtonGroup
                    sx={{ my: 2, height: '40px' }}
                    value={status}
                    exclusive
                    onChange={handleChangeLabCylinderStatus}
                  >
                    <ToggleButton value={text.LabCylinders.PENDING_PREFERRED_LAB}>
                      {text.LabCylinders.PENDING_PREFERRED_LAB}
                    </ToggleButton>
                    <ToggleButton value={text.LabCylinders.PENDING_OTHER_LAB}>
                      {text.LabCylinders.PENDING_OTHER_LAB}
                    </ToggleButton>
                    <ToggleButton value={text.LabCylinders.TESTING_COMPLETED}>
                      {text.LabCylinders.TESTING_COMPLETED}
                    </ToggleButton>
                  </ToggleButtonGroup>

                </>
                  :
                  <ToggleButtonGroup
                    sx={{ my: 2, height: '40px' }}
                    value={status}
                    exclusive
                    onChange={handleChangeLabCylinderStatus}
                  >
                    <ToggleButton value={text.LabCylinders.ACTIVE_STATUS}>
                      {text.LabCylinders.ACTIVE_STATUS}
                    </ToggleButton>
                    <ToggleButton value={text.LabCylinders.COMPLETED_TEST}>
                      {text.LabCylinders.COMPLETED_TEST}
                    </ToggleButton>
                  </ToggleButtonGroup>
              }

              {
                status == text.LabCylinders.PENDING_PREFERRED_LAB ?
                  <CustomDataGrid2
                    columns={columns}
                    query={cylindersQueryTestingNotCompletedWithId}
                    tags={[tags.CYLINDER]}
                    handleRowClick={handleRowClick}
                  />
                  :
                  status == text.LabCylinders.PENDING_OTHER_LAB ?
                    <CustomDataGrid2
                      columns={columns}
                      query={cylindersQueryOtherLab}
                      tags={[tags.CYLINDER]}
                      handleRowClick={handleRowClick}
                    />
                    :
                    status == text.LabCylinders.ACTIVE_STATUS ?
                      <CustomDataGrid2
                        columns={columns}
                        query={cylindersQueryTestingNotCompleted}
                        tags={[tags.CYLINDER]}
                        handleRowClick={handleRowClick}
                      />
                      :
                      status == text.LabCylinders.COMPLETED_TEST ?
                        <CustomDataGrid2
                          columns={columns}
                          query={cylindersQueryTestingCompleted}
                          tags={[tags.CYLINDER]}
                          handleRowClick={handleRowClick}
                        />
                        :
                        <CustomDataGrid2
                          columns={columns}
                          query={cylindersQueryTestingCompleted}
                          tags={[tags.CYLINDER]}
                          handleRowClick={handleRowClick}
                        />
              }
            </CardContent>
          </Card>
        </Box>

      </PermissionGate>
    </React.Fragment>
  );
}

export default LabCylinders;
