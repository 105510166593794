import { Dialog, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useIsMobileOrTablet from '../../../hooks/useIsMobileOrTablet';
import { ConcretingSequenceDialogContents } from './ConcretingSequenceDialogContents';

export const ConcretingSequenceDialog = (props) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  const location = useLocation();
  return (
    <React.Fragment>
      {isMobileOrTablet ? (
        <SwipeableDrawer
          anchor={'bottom'}
          open={true}
          onClose={props.onClose}
          onOpen={() => props.onOpen(location)} // SwipeableDrawer needs to have the prop 'onOpen'
        >
          <ConcretingSequenceDialogContents {...props} />
        </SwipeableDrawer>
      ) : (
        <Dialog fullWidth open={true} maxWidth="lg">
          <ConcretingSequenceDialogContents {...props} />
        </Dialog>
      )}
    </React.Fragment>
  );
};
