import { FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocale } from '../../hooks';
import CustomAutocomplete from '../customMUI/CustomAutocomplete';
import useRequest from '../../hooks/useRequest';
import { useParams } from 'react-router-dom';

const FormCylinder = ({ formik, samples, isConcreteSequeceEdit }) => {
  const text = useLocale();
  const adadada = useParams();

  // console.log("formik.values.customCylinderID", formik.values.customCylinderID,)
  // console.log("isConcreteSequeceEdit", isConcreteSequeceEdit)
  const makeCylinderId = (sampleType) => {
    let sampleTypeInitial = 'F';
    let cylinderId = formik.values.customCylinderID;
    if (sampleType == 'LABORATORY_CURED') {
       sampleTypeInitial = 'L';
    }
    if (formik.values.customCylinderID) {

      if(isConcreteSequeceEdit){
        let sampleNumber = null;
        let sampleIDCustom = ""

        samples.forEach(element => {
          if (element.type == sampleType){
            sampleNumber = element.number;
            sampleIDCustom = element.id
          }
          
        });
        formik.setValues({
          ...formik.values,
          customCylinderID:
          sampleNumber + "-" + sampleTypeInitial + cylinderId.slice(-1),
          sampleID: sampleIDCustom
        });
        return
      }
     
      formik.setValues({
        ...formik.values,
        customCylinderID:
          formik.values.sampleNumber  + "-" + sampleTypeInitial + cylinderId.slice(-1),
      });
    }
  }

  useEffect(() => {
    if(isConcreteSequeceEdit){
      if(formik.values?.customCylinderID && samples?.length){
        for(let i =0; i < samples.length; i++){
          for(let j =0; j < samples[i]?.cylinders?.length; j++){
              if(samples[i].cylinders[j]?.customCylinderID == formik?.values?.customCylinderID){
                  makeCylinderId(samples[i]?.type || 'LABORATORY_CURED')
              }
          }
        }
        // const defaultSample = samples.find(sample => sample.id == formik.values.sampleID2);
        // makeCylinderId(defaultSample.type || 'LABORATORY_CURED')
      }
      
      console.log("formik.values.sampleID", formik.values.customCylinderID, samples)
    }
    
  }, []);

  return (
    <React.Fragment>
      <Grid container direction="column" rowGap={2}>
        <Grid item>
          <CustomAutocomplete
            label={text.Cylinders.LABEL_SAMPLE_TYPE}
            initialValue={isConcreteSequeceEdit ? formik.values.sampleID : samples[0]?.id}
            itemsArray={samples}
            itemsMapper={(item) => {
              return { id: item?.id, label: text.SampleType[item?.type] };
            }}
            makeRequest={useRequest}
            name="sampleID"
            onChange={(name, value) => {
              let sampleType;
              for (let i in samples) {
                if (samples[i]?.id == value) {
                  sampleType = samples[i]?.type;
                  break;
                }
              }
              makeCylinderId(sampleType);
              formik.setFieldValue(name, value);
            }}
          />
          <FormHelperText error={formik.touched.sampleID && formik.errors.sampleID ? true : false}>
            {formik.errors.sampleID}
          </FormHelperText>
        </Grid>
        <Grid item>
        
          <TextField
            label={text.Cylinders.LABEL_ID}
            value={formik.values.customCylinderID}
            name={'customCylinderID'}
            onChange={formik.handleChange}
            fullWidth
            type={'text'}
            disabled
          />
          <FormHelperText
            error={formik.touched.customCylinderID && formik.errors.customCylinderID ? true : false}
          >
            {formik.errors.customCylinderID}
          </FormHelperText>
        </Grid>
        <Grid item>
          <TextField
            label={text.Cylinders.LABEL_DAYS_TO_BE_CURED}
            value={formik.values.daysToBeCured}
            name={'daysToBeCured'}
            onChange={formik.handleChange}
            fullWidth
            type={'number'}
          />
          <FormHelperText
            error={formik.touched.daysToBeCured && formik.errors.daysToBeCured ? true : false}
          >
            {formik.errors.daysToBeCured}
          </FormHelperText>

          <Typography>{text.Cylinders.HOLD_MESSAGE}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FormCylinder;