import React from 'react';
import routes from '../../constants/routes';
import FormClients from './forms/FormClients';
import { clientSchema } from '../../schemas/client.schema';
import { useGetElement, useLocale } from '../../hooks';
import { Header, FlexBetween } from '../../components';
import { useAddClientMutation } from '../../services/clientsApi';

import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Box } from '@mui/material';
import { useFormik } from 'formik';

// import loading
import { useDispatch } from 'react-redux';
import { setLoading, clearLoading } from '../../slices/loadingSlice';
import { useClientSchema } from '../../schemas/client.schema';

function ClientAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientCreateSchema } = useClientSchema();
  const text = useLocale();
  const navItem = useGetElement(text.Navigation.CLIENTS);
  const initialValues = {
    organizationName: '',
    clientContactName: '',
    clientContactTelephone: '',
    clientContactEmail: '',
  };

  const [addClient] = useAddClientMutation();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: clientCreateSchema,
    onSubmit: (values) => addRecord(values),
  });

  async function addRecord(values) {
    dispatch(setLoading());
    try {
      await addClient(values);
      navigate(routes.CLIENTS);
    } catch (e) {
      console.log(e);
    }
    dispatch(clearLoading());
  }

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.Clients.ADD_CLIENT} />
      </FlexBetween>

      <Box height="70vh" sx={{ maxWidth: 700 }}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <FormClients formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
          </CardContent>
        </Card>
      </Box>
    </React.Fragment>
  );
}

export default ClientAdd;
