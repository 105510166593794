import React from 'react';
import useIsMobileOrTablet from '../hooks/useIsMobileOrTablet';
import { Dialog, SwipeableDrawer } from '@mui/material';

export const MobileResponsiveDialog = ({ open, children,fullWidth }) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  const onClose = () => {};

  const onOpen = () => {};

  return (
    <React.Fragment>
      {isMobileOrTablet ? (
        <SwipeableDrawer anchor={'bottom'} open={open} onClose={onClose} onOpen={onOpen}>
          {children}
        </SwipeableDrawer>
      ) : (
        <Dialog fullWidth open={open} maxWidth={fullWidth ? "xl": "lg"}>
          {children}
        </Dialog>
      )}
    </React.Fragment>
  );
};
