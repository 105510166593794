import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { FlexBetween } from "..";
import { useNavigate } from "react-router-dom";


export const AppHeaderDetails = ({ btName, toolName, to }) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <FlexBetween>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box mb="20px">
            <Typography variant="h4" fontWeight="bold">
              <Button startIcon={<ArrowBack />} onClick={()=>navigate(to)} >{btName}</Button>{' '}
              {toolName}
            </Typography>
          </Box>
        </Box>
      </FlexBetween>
    </React.Fragment>
  );
};

export default AppHeaderDetails;