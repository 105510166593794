import useNavItems from "./useNavItems";

const useGetElement = (name) => {
  const navItems = useNavItems();
  return navItems.find((element) => {
    return element.name === name;
  });
};

export default useGetElement;
