import { Autocomplete, Box, FormHelperText, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocale } from '../../../hooks';


const FormViewCompressiveStrength = ({ formik, btIcon, btText }) => {
    const text = useLocale();
    const labelStyle = { fontWeight: 'bold', fontSize: 18, mt: 2 };
    const [selected, setSelected] = useState();
    const [conformity, setConformity] = useState("");

    useEffect(() => {
        formik.setTouched(true, false);
    }, []);
    const CONFORMITY = "conformity";
    const NON_CONFORMITY = "non-conformity";
    const handleChangeConformity = (event, newValue) => {
        if (newValue === CONFORMITY) {
            setSelected(CONFORMITY);
            formik.setFieldValue("conformity", true);
        } else if (newValue === NON_CONFORMITY) {
            formik.setFieldValue("conformity", false);
            setSelected(NON_CONFORMITY);
        }
        setConformity(newValue);
    };

    return (
        <div >
            <Typography sx={labelStyle}>Compressive Strength</Typography>
            <Grid container>
                <Grid item xs={6} lg={6}>
                    <TextField
                        label={text.DataView.SPECIFIED_COMPRESSIVE_STRENGTH}
                        value={formik.values.airContent}
                        name={'airContent'}
                        onChange={formik.handleChange}
                        sx={{ mt: 2, display: 'flex', flex: 1 }}
                        type={'number'}
                    />
                    <TextField
                        label={text.DataView.LABEL_SPECIFIED_COMPRESSIVE_STRENGTH}
                        value={formik.values.adjustedAirContent}
                        name={'adjustedAirContent'}
                        onChange={formik.handleChange}
                        sx={{ mt: 2, display: 'flex', flex: 1 }}
                        type={'number'}
                    />
                    <TextField
                        label={text.DataView.DAYS_TO_BE_CURED}
                        value={formik.values.plasticizer}
                        name={"plasticizer"}
                        onChange={formik.handleChange}
                        sx={{ display: "flex", flex: 1, mt: 2 }}
                        type={"number"}
                    />
                </Grid>
                <Grid item xs={6} lg={6} pl={1}>
                    <TextField
                        label={text.DataView.LABEL_DATE_TO_BE_TESTED}
                        value={formik.values.concreteTemperature}
                        name={'concreteTemperature'}
                        onChange={formik.handleChange}
                        sx={{ mt: 2, display: 'flex', flex: 1 }}
                        type={'number'}
                    />
                    <ToggleButtonGroup sx={{
                        mt: 2
                    }} value={conformity} exclusive onChange={handleChangeConformity}>
                        <ToggleButton
                            selected={selected === CONFORMITY}
                            sx={{
                                pr: 4,
                                pl: 4,
                                height: 37,
                                "&.Mui-selected": {
                                    backgroundColor: "green !important",
                                    color: "white !important",
                                },
                            }}
                            value={CONFORMITY}
                        >
                            {text.ConcretingSequence.LABEL_CONFORMITY}
                        </ToggleButton>
                        <ToggleButton
                            selected={selected === NON_CONFORMITY}
                            sx={{
                                height: 37,
                                pr: 4,
                                pl: 4,
                                "&.Mui-selected": {
                                    backgroundColor: "#ff0055 !important",
                                    color: "white !important",
                                },
                            }}
                            value={NON_CONFORMITY}
                        >
                            {text.ConcretingSequence.LABEL_NON_CONFORMITY}
                        </ToggleButton>
                        <Typography sx={{
                            alignItems: 'center',
                            display: "flex",
                            ml: 2,
                            color: 'gray'
                        }}>
                            C/NC
                        </Typography>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

        </div>
    );
};

export default FormViewCompressiveStrength;
