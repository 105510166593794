import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Divider, FormHelperText, IconButton, Modal, TextField, Typography } from '@mui/material';

import { useHandleError } from '../../../hooks/useHandleError';
import useHandleSuccess from '../../../hooks/useHandleSuccess';
import { clearLoading, setLoading } from '../../../slices/loadingSlice';
import { useLocale } from '../../../hooks';
import { Icon } from '../../../components';
import { useUpdateWorkOrderMutation } from '../../../services/workordersApi';
import useRequest from '../../../hooks/useRequest';
import CustomAutocomplete from '../../../components/customMUI/CustomAutocomplete';
import { useGetLabsQuery } from '../../../services/laboratoriesApi';


export const EditCardLaboratory = ({
    open,
    handleClose,
    workOrderId,
    initialValue,
    laboratoryId,
    formik,
    reftechTable
}) => {
    const dispatch = useDispatch();
    const text = useLocale();
    const { handleErrorResponse } = useHandleError();
    const { handleSuccessResponse } = useHandleSuccess();
    const [updateObj] = useUpdateWorkOrderMutation();
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #fff !important',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4,
        outline: 'none !important',
    };


    async function updateRecord() {
        dispatch(setLoading());
        try {
            // Only find values that have changed
            let changes = { laboratoryID: formik.values.laboratoryID };
            const data = { changes: changes, id: workOrderId };
            await updateObj(data);
            reftechTable();
            handleClose();
            handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.Cylinders.SUCCESSFUL_UPDATE);
        } catch (e) {
            console.log(e);
            handleClose();
            handleErrorResponse(e, text.Cylinders.UNSUCCESSFUL_UPDATE);
        }
        dispatch(clearLoading());
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between' }}>
                    <Typography variant={'h6'} pb={2} sx={{ flex: 1 }}>
                        {text.Projects.LABEL_LABORATORY}
                    </Typography>

                    <IconButton onClick={handleClose} variant="contained" sx={{ marginBottom: '14px' }}>
                        <Icon iconName={'Cancel'} />
                    </IconButton>
                </Box>
                <Divider mb={3} />
                 <CustomAutocomplete
                    makeRequest={useRequest}
                    initialValue={formik.values.laboratoryID}
                    itemsQuery={useGetLabsQuery}
                    itemsMapper={(item) => {                   
                      return { id: item.id, label: item.name + ` (${item.region.name})` };
                    }}
                    sx={{ mt: 2 }}
                    name="laboratoryID"
                    onChange={(name, value) => {formik.setFieldValue(name, value)}}
                    label={text.Projects.LABEL_LABORATORY}
                  />
                  <FormHelperText
                    error={formik.touched.laboratoryID && formik.errors.laboratoryID ? true : false}
                  >
                    {formik.errors.laboratoryID}
                  </FormHelperText>
                <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={updateRecord}
                >
                    Submit
                </Button>
            </Box>
        </Modal>
    );
};
