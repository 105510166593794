import { api } from './api';
import urls from '../constants/urls';
import tags from './tags';

export const concreteFieldInformationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addConcreteFieldInformation: builder.mutation({
      query: (data) => ({
        url: urls.CONCRETE_FIELD_INFORMATION,
        method: 'POST',
        body: { ...data },
      }),
    }),

    // GET All Concrete Field Info
    getConcreteFieldInformation: builder.query({
      query: (params) => ({
        url: urls.CONCRETE_FIELD_INFORMATION,
        params: params,
      }),
      providesTags: [tags.CONCRETE_FIELD_INFORMATION],
    }),

    getConcreteFieldInformationByID: builder.query({
      query: (id) => ({
        url: urls.CONCRETE_FIELD_INFORMATION + '/' + id,
      }),
    }),

    updateConcreteFieldInformation: builder.mutation({
      query: (data) => ({
        url: urls.CONCRETE_FIELD_INFORMATION + '/' + data.id,
        method: 'PATCH',
        body: { ...data.changes },
      }),
    }),

    deleteConcreteFieldInformation: builder.mutation({
      query: (id) => ({
        url: urls.CONCRETE_FIELD_INFORMATION + '/' + id,
      }),
    }),
  }),
});

export const {
  useAddConcreteFieldInformationMutation,
  useGetConcreteFieldInformationQuery,
  useGetConcreteFieldInformationByIDQuery,
  useUpdateConcreteFieldInformationMutation
} = concreteFieldInformationApi;
