import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
const permissionSlice = createSlice({
  name: "permissions",
  initialState: initialState,
  reducers: {
    setPermissions(state, action) {
      return [...action.payload];
    },
    clearPermissions(state, action) {
      return initialState;
    },
  },
});

// Actions
export const { setPermissions, clearPermissions } = permissionSlice.actions;

// Selecters
export const selectPermissions = (state) => state.permissions;

export default permissionSlice;
