import { Grid, Card, CardContent, Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FlexBetween, HeaderDetails, LoadingIcon } from '../../components';
import CustomButton from '../../components/customMUI/CustomButton';
import routes from '../../constants/routes';
import { useLocale } from '../../hooks';
import useFormatDateTime from '../../hooks/useFormatDateTime';
import { useGetProjectQuery } from '../../services/projectsApi';
import { useGetWorkOrdersQuery } from '../../services/workordersApi';
import StatusChip from '../../components/StatusChip';
import ViewProject from './forms/ViewProject';
import ViewClient from '../clients/forms/ViewClient';
import CustomModal from '../../components/customMUI/CustomModalProjects';
import CustomDataGrid from '../../components/customMUI/CustomDataGrid';
import tags from '../../services/tags';
import useRequest from '../../hooks/useRequest';
import PermissionGate from '../../components/PermissionGate';
import { featurePermissions } from '../../constants/permissions';
import { selectPermissions } from '../../slices/permissionSlice';
import { useSelector } from 'react-redux';

function ProjectDetails() {
  const { formatDate } = useFormatDateTime();
  const userPermissions = useSelector(selectPermissions);
  const text = useLocale();
  const { id } = useParams();
  const projectQuery = useRequest(useGetProjectQuery, id);
  const workOrdersQuery = useRequest(useGetWorkOrdersQuery, {
    'filter[projectID]': `equals,${id}`,
  });
  const project = projectQuery.data ? projectQuery.data.payload : {};

  // Work Order Columns
  const workOrderColumns = [
    {
      field: 'number',
      headerName: text.WorkOrdersDataGrid.NUMBER,
      minWidth: 100,
    },
    // {
    //   field: "project",
    //   headerName: text.WorkOrdersDataGrid.PROJECT_NAME,
    //   minWidth: 150,
    //   valueGetter: (params) => {
    //     return params.row.project.projectName;
    //   },
    // },
    {
      field: 'inspectionType',
      headerName: text.WorkOrdersDataGrid.TYPE,
      minWidth: 150,
    },

    {
      field: 'siteVisitDate',
      headerName: text.WorkOrdersDataGrid.SITE_VISIT_DATE,
      minWidth: 150,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: 'workLocation',
      headerName: text.WorkOrdersDataGrid.WORK_LOCATION,
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'status',
      headerName: text.WorkOrdersDataGrid.STATUS,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return <StatusChip status={params.row.status} />;
      },
    },
  ];

  // Render
  return projectQuery.isLoading ? (
    <LoadingIcon />
  ) : (
    <React.Fragment>
      <FlexBetween>
        <HeaderDetails btName={text.Projects.BT_RETURN} to={-1} toolName={project.projectName} />
      </FlexBetween>

      <CustomButton
        route={routes.PROJECT_EDIT.split(':')[0] + project.id}
        text={text.Projects.EDIT_PROJECT}
        sx={{ mr: 2 ,mb:2}}
        type="NoteAlt"
      />

      <CustomModal value={'COMPLETED'} bodytext={text.Projects.MARKAS} projectID={id} />
      <CustomModal value={'CANCELLED'} bodytext={text.Projects.MARKAS} projectID={id} />

      {/* Project Details */}

      <Card sx={{ m: 2, p: 0 }}>
        <CardContent>
          <ViewProject id={project.id} />
        </CardContent>
      </Card>

      {/* Client Details */}
      <PermissionGate userPermissions={userPermissions} componentPermissions={[featurePermissions.CLIENTS_READ]}>
      <Card sx={{ m: 2, p: 0 }}>
        <CardContent>
          <ViewClient id={project.id} />
        </CardContent>
      </Card>
      </PermissionGate>
      {/* Display Work Orders */}
      <Box sx={{ m: 2 }}>
        <CustomDataGrid
          col={workOrderColumns}
          to={routes.WORKORDERS}
          rQuery={workOrdersQuery}
          tags={[tags.WORKORDER]}
          title={text.Projects.TITLE_WORK_ORDERS}
          dgHeight={'40vh'}
        />
      </Box>
    </React.Fragment>
  );
}

export default ProjectDetails;
