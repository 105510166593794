import React from "react";
import useHasPermission from "../hooks/useHasPermission";

const PermissionGate = ({ children, fallback, userPermissions, componentPermissions }) => {
  const hasPermission = useHasPermission(componentPermissions, userPermissions);

  return (
    <React.Fragment>
      {hasPermission ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <React.Fragment>{fallback}</React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PermissionGate;