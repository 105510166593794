import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocale } from '.';
import { addNotification } from '../slices/notificationSlice';
export const useHandleError = () => {
  const text = useLocale();
  const dispatch = useDispatch();

  function handleErrorResponse(e, description) {
    if (e.status === 400) {
      toast.error(text.INVALID_FORM_INPUT);
      dispatch(
        addNotification({
          title: text.INVALID_FORM_INPUT,
          description: description,
          color: 'error.light',
        })
      );
    }
    if (e.status === 401) {
      toast.error(text.UNAUTHORISED);
      dispatch(
        addNotification({
          title: text.UNAUTHORISED,
          description: description,
          color: 'error.light',
        })
      );
    }
    if (e.status === 404) {
      toast.error(text.NOT_FOUND);
      dispatch(
        addNotification({
          title: text.NOT_FOUND,
          description: description,
          color: 'error.light',
        })
      );
    }
    if (e.status === 500) {
      toast.error(text.SERVER_ERROR);
      dispatch(
        addNotification({
          title: text.SERVER_ERROR,
          description: description,
          color: 'error.light',
        })
      );
    }
    console.log(e);
  }
  return { handleErrorResponse };
};
