// Component for handling changes made to concrete mix design
// before submitting the field report

import React from 'react';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useConcreteMixDesignSchema } from '../../../schemas/concreteMixDesign.schema';
import { formType, selectFromQueue, updateQueue } from '../../../slices/offlineQueueSlice';
import { getPopulated } from '../../../utils/form';
import { ConcreteMixDesignDialog } from './ConcreteMixDesignDialog';

export const ConcreteMixDesignAddChange = () => {
  const dispatch = useDispatch();
  const { concreteMixDesignID } = useParams();
  const { concreteMixDesignCreateSchema } = useConcreteMixDesignSchema();

  const { data: concreteMixDesign } = useSelector((state) =>
    selectFromQueue(state, concreteMixDesignID)
  );
  const navigate = useNavigate();

  const formikConcreteMixDesign = useFormik({
    initialValues: concreteMixDesign,
    validationSchema: concreteMixDesignCreateSchema,
    onSubmit: (values, { resetForm }) => {
      const specifiedConcreteClass = 'Class ' +
        values.csaExposureClass +
        ", " +
        values.specifiedCompressiveStrength +
        " Mpa, Aggregates " +
        values.nominalAggregateSize +
        " mm,  Air Content " +
        values.minAirContent +
        "-" +
        values.maxAirContent +
        " AEA, Slump " +
        values.slump +
        " ± " +
        values.slumpRange + " mm";
      const object = getPopulated({ ...values, specifiedConcreteClass });
      dispatch(
        updateQueue({
          id: concreteMixDesignID,
          type: formType.CONCRETE_MIX_DESIGN,
          data: { ...object, id: concreteMixDesignID },
        })
      );

      resetForm();
    },
  });

  const handleSubmit = () => {
    formikConcreteMixDesign.handleSubmit();
    navigate(-1);
  };

  return (
    <ConcreteMixDesignDialog
      formikConcreteMixDesign={formikConcreteMixDesign}
      handleSubmit={handleSubmit}
    />
  );
};
