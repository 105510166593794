import React from 'react';
import routes from '../../constants/routes';
import FormProject from './forms/FormProject';
import { useParams } from 'react-router-dom';
import { useFormikEdit } from '../../hooks/useFormikEdit';
import { useProjectSchema } from '../../schemas/project.schema';
import { useGetElement, useLocale } from '../../hooks';
import { FlexBetween, Header, LoadingIcon } from '../../components';
import { useGetProjectQuery, useUpdateProjectMutation } from '../../services/projectsApi';
import useRequest from '../../hooks/useRequest';

function ProjectEdit() {
  const text = useLocale();
  const { projectEditSchema } = useProjectSchema();
  const navItem = useGetElement(text.Navigation.PROJECTS);
  const { id } = useParams();
  const projectQuery = useRequest(useGetProjectQuery, id);

  const initialValues = {
    onSiteContactOrganisation: '',
    projectCode: '',
    projectName: '',
    regionID: '',
    clientPurchaseOrder: '',
    address: '',
    transportationTime: 0,
    mileage: 0,
    projectManagerID: '',
    dispatcherID: '',
    laboratorySupervisorID: '',
    clientID: '',
    laboratoryID: '',
    onSiteContactName: '',
    onSiteContactTelephone: '',
    onSiteContactEmail: '',
    clientFullName: '',
    clientPhoneNumber: '',
    clientEmail: '',
  };

  const setInitialValues = {
    onSiteContactOrganisation: projectQuery.data.payload.onSiteContactOrganisation,
    projectCode: projectQuery.data.payload.projectCode,
    projectName: projectQuery.data.payload.projectName,
    regionID: projectQuery.data.payload.region.id,
    clientPurchaseOrder: projectQuery.data.payload.clientPurchaseOrder,
    address: projectQuery.data.payload.address,
    transportationTime: projectQuery.data.payload.transportationTime,
    mileage: projectQuery.data.payload.mileage,
    projectManagerID: projectQuery.data.payload.projectManager.id,
    dispatcherID: projectQuery.data.payload.dispatcher.id,
    laboratorySupervisorID: projectQuery.data.payload.laboratorySupervisor.id,
    clientID: projectQuery.data.payload.client.id,
    laboratoryID: projectQuery.data.payload.laboratory.id,
    onSiteContactName: projectQuery.data.payload.onSiteContactName,
    onSiteContactTelephone: projectQuery.data.payload.onSiteContactTelephone,
    onSiteContactEmail: projectQuery.data.payload.onSiteContactEmail,
    clientFullName: projectQuery.data.payload.clientFullName,
    clientPhoneNumber: projectQuery.data.payload.clientPhoneNumber,
    clientEmail: projectQuery.data.payload.clientEmail,
  };

  const formik = useFormikEdit(
    useGetProjectQuery,
    initialValues,
    projectEditSchema,
    -1,
    useUpdateProjectMutation,
    setInitialValues
  );

  return projectQuery.isLoading ? (
    <LoadingIcon />
  ) : (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} />
      </FlexBetween>
      <FormProject formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
    </React.Fragment>
  );
}

export default ProjectEdit;