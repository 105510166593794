import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Grid, FormHelperText, Divider, Autocomplete } from "@mui/material";
import useLocale from "../../hooks/useLocale";
import useRequest from "../../hooks/useRequest";
import { useGetConcretesQuery } from "../../services/concreteApi";
import { useParams } from "react-router-dom";

const FormConcreteMixDesign = ({ formik }) => {
  const text = useLocale();
  const { id } = useParams();
  const concreteQuery = useRequest(useGetConcretesQuery, {
    'filter[workOrderID]': `equals,${id}`,
  });
  const [concreteElementLocationOptions, setConcreteElementLocationOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const arr = [];
    for (let i in concreteQuery?.data?.payload) {
      arr.push({
        id: concreteQuery?.data?.payload[i].id,
        label: concreteQuery?.data?.payload[i].area
      })
    }
    setConcreteElementLocationOptions(arr);
  }, []);

  const handleChange = (event, value) => {
    if (!value && inputValue.trim() !== "") {
      formik.setValues({ ...formik.values, concreteElementLocation: inputValue })
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    formik.setValues({ ...formik.values, concreteElementLocation: newInputValue })
  };

  useEffect(() => {
    if (formik.initialValues.concreteElementLocation) {
      setInputValue(formik.initialValues.concreteElementLocation)
    }
  }, [formik.initialValues.concreteElementLocation]);

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Grid container columnSpacing={2}>
        {/* First Half */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container direction="column">
            <Grid item>
              <TextField
                label={text.ConcreteMixDesign.LABEL_CONCRETE_SUPPLIER_ORGANISATION}
                value={formik.values.supplierOrganisation}
                name={"supplierOrganisation"}
                onChange={formik.handleChange}
                sx={{ display: "flex", flex: 1, mt: 2 }}
                type={"text"}
              />
            </Grid>
            <Grid item>
              <TextField
                label={text.ConcreteMixDesign.LABEL_PLANT}
                value={formik.values.plant}
                name={"plant"}
                onChange={formik.handleChange}
                sx={{ display: "flex", flex: 1, mt: 2 }}
                type={"text"}
              />
            </Grid>
            <Grid item>
              <TextField
                label={text.ConcreteMixDesign.LABEL_FORMULA}
                value={formik.values.formula}
                name={"formula"}
                onChange={formik.handleChange}
                sx={{ display: "flex", flex: 1, mt: 2 }}
                type={"text"}
              />
              <FormHelperText
                error={formik.touched.formula && formik.errors.formula ? true : false}
              >
                {formik.errors.formula}
              </FormHelperText>
            </Grid>
            <Grid item my={2}>
              <Autocomplete
                fullWidth
                disablePortal
                options={concreteElementLocationOptions}
                value={inputValue}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                renderInput={(params) => <TextField {...params} label={text.ConcreteMixDesign.LABEL_LOCATION_OF_CONCRETE_ELEMENT} />
                }
              />
            </Grid>
            <Grid item>
              <TextField
                label={text.ConcreteMixDesign.LABEL_CSA_EXPOSURE_CLASS}
                value={formik.values.csaExposureClass}
                name={"csaExposureClass"}
                onChange={formik.handleChange}
                sx={{ display: "flex", flex: 1, mt: 2 }}
                type={"text"}
              />
              <FormHelperText
                error={formik.touched.csaExposureClass && formik.errors.csaExposureClass ? true : false}
              >
                {formik.errors.csaExposureClass}
              </FormHelperText>
              <Typography sx={{ my: 1, color: 'black', fontSize: '0.9em' }}>Note: use class "N" if none</Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  minWidth: 220,
                  bgcolor: "#f0f0f0",
                  p: 1,
                  borderRadius: 1,
                }}
              >
                <Typography sx={{ color: "grey", fontSize: 12 }}>
                  {text.ConcreteMixDesign.LABEL_SPECIFIED_CONCRETE_GLASS}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ fontSize: 15 }}>
                  {
                  [
                    formik.values.csaExposureClass ? 'Class ' + formik.values.csaExposureClass : "",
                    formik.values.specifiedCompressiveStrength ? formik.values.specifiedCompressiveStrength + ' Mpa' : '',
                    formik.values.nominalAggregateSize ? 'Aggregates ' + formik.values.nominalAggregateSize + ' mm' : '',
                    (formik.values.minAirContent !== undefined && formik.values.maxAirContent !== undefined) ? `${formik.values.minAirContent ? 'Air Content ' + formik.values.minAirContent + '-' : ''}${formik.values.maxAirContent ? '' + formik.values.maxAirContent + ' AEA' : ''}` : "",
                    (formik.values.slump !== undefined && formik.values.slumpRange !== undefined) ? `${formik.values.slump ? 'Slump ' + formik.values.slump : ''} ${formik.values.slumpRange ? '± ' + formik.values.slumpRange + 'mm' : ''}` : ""
                  ].filter(value => value !== undefined && value !== "").join(", ")
                }
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* Second Half */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container direction="column">
            <Grid item>
              <TextField
                label={text.ConcreteMixDesign.LABEL_SPECIFIED_COMPRESSIVE_STRENGTH}
                value={formik.values.specifiedCompressiveStrength}
                name={"specifiedCompressiveStrength"}
                onChange={formik.handleChange}
                sx={{ display: "flex", flex: 1, mt: 2 }}
                type={"number"}
              />
              <FormHelperText
                error={formik.touched.specifiedCompressiveStrength && formik.errors.specifiedCompressiveStrength ? true : false}
              >
                {formik.errors.specifiedCompressiveStrength}
              </FormHelperText>
            </Grid>

            <Grid item>
              <TextField
                label={text.ConcreteMixDesign.LABEL_MIN_AIR_CONTENT}
                value={formik.values.minAirContent}
                name={"minAirContent"}
                onChange={formik.handleChange}
                sx={{ display: "flex", flex: 1, mt: 2 }}
                type={"number"}
              />

            </Grid>

            <FormHelperText
              error={formik.touched.minAirContent && formik.errors.minAirContent ? true : false}
            >
              {formik.errors.minAirContent}
            </FormHelperText>

            <Grid item>
              <TextField
                label={text.ConcreteMixDesign.LABEL_MAX_AIR_CONTENT}
                value={formik.values.maxAirContent}
                name={"maxAirContent"}
                onChange={formik.handleChange}
                sx={{ display: "flex", flex: 1, mt: 2 }}
                type={"number"}
              />
              <FormHelperText
                error={formik.touched.maxAirContent && formik.errors.maxAirContent ? true : false}
              >
                {formik.errors.maxAirContent}
              </FormHelperText>
            </Grid>

            <Grid item>
              <TextField
                label={text.ConcreteMixDesign.LABEL_NOMINAL_AGGREGATE_SIZE}
                value={formik.values.nominalAggregateSize}
                name={"nominalAggregateSize"}
                onChange={formik.handleChange}
                sx={{ display: "flex", flex: 1, mt: 2 }}
                type={"number"}
              />
              <FormHelperText
                error={formik.touched.nominalAggregateSize && formik.errors.nominalAggregateSize ? true : false}
              >
                {formik.errors.nominalAggregateSize}
              </FormHelperText>
            </Grid>
            <Grid container alignItems={"center"}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <TextField
                  label={text.ConcreteMixDesign.LABEL_SLUMP}
                  value={formik.values.slump}
                  name={"slump"}
                  onChange={formik.handleChange}
                  sx={{ display: "flex", flex: 1, mt: 2 }}
                  type={"number"}
                />
                <FormHelperText
                  error={formik.touched.slump && formik.errors.slump ? true : false}
                >
                  {formik.errors.slump}
                </FormHelperText>
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                justifyContent={"center"}
                sx={{ display: "flex", pr: 2, pl: 2, mt: 2 }}
              >
                <Typography sx={{ display: "inline-block" }}>{"+/-"}</Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <TextField
                  value={formik.values.slumpRange}
                  name={"slumpRange"}
                  onChange={formik.handleChange}
                  sx={{ display: "inline-block", mt: 2 }}
                  type={"number"}
                />
                <FormHelperText
                  error={formik.touched.slumpRange && formik.errors.slumpRange ? true : false}
                >
                  {formik.errors.slumpRange}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid container alignItems={"center"}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <TextField
                  label={text.ConcreteMixDesign.LABEL_PLASTICIZER}
                  value={formik.values.plasticizer}
                  name={"plasticizer"}
                  onChange={formik.handleChange}
                  sx={{ display: "flex", flex: 1, mt: 2 }}
                  type={"number"}
                />
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                justifyContent={"center"}
                sx={{ display: "flex", pr: 2, pl: 2, mt: 2 }}
              >
                <Typography sx={{ display: "inline-block" }}>{"+/-"}</Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <TextField
                  value={formik.values.plasticizerRange}
                  name={"plasticizerRange"}
                  onChange={formik.handleChange}
                  sx={{ display: "inline-block", mt: 2 }}
                  type={"number"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <TextField
        label={text.ADDITIONAL_INFORMATION}
        value={formik.values.additionalInformation}
        name={"additionalInformation"}
        multiline
        minRows={1}
        maxRows={5}
        onChange={formik.handleChange}
        sx={{ display: "flex", flex: 1, mt: 2 }}
        type={"text"}
      />
    </Box>
  );
};

export default FormConcreteMixDesign;
