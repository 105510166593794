import { createSlice } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
  name: "loading",
  initialState: { isLoading: false },
  reducers: {
    setLoading(state, action) {
      return { isLoading: true };
    },
    clearLoading(state, action) {
      return { isLoading: false };
    },
  },
});

// Actions
export const { setLoading, clearLoading } = loadingSlice.actions;

// Selecters
export const selectLoading = (state) => state.loading;

export default loadingSlice;
