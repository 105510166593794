import { useSelector } from 'react-redux';
import { selectLocale } from '../slices/localeSlice';
import en_us from '../constants/locales/en-US.json';
import fr_ca from '../constants/locales/fr-CA.json';
import { EN_US, FR_CA } from '../constants/locale';

export const useLocale = () => {
  const locale = useSelector(selectLocale);
  const text = { [EN_US]: en_us, [FR_CA]: fr_ca };
  return text[locale];
};

export default useLocale;
