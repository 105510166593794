// Injecting endpoints

import { setToken } from "../slices/authSlice";
import { api } from "./api";
import urls from "../constants/urls";
/**
  Any logic around handling data in the response 
  can be placed in the onQueryStarted function

  onQueryStarted 2nd parameter:
  {
    dispatch,
    getState,
    extra,
    requestId,
    cacheEntryRemoved,
    cacheDataLoaded,
    getCacheEntry,
  }
*/
export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: urls.LOGIN,
        method: "POST",
        body: { ...credentials },
      }),
    }),

    refresh: builder.mutation({
      query: () => ({
        url: urls.REFRESH,
        method: "POST",
        body: {},
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          const cache = getCacheEntry();
          dispatch(setToken(cache.data));
        } catch (e) {}
      },
    }),

    logout: builder.mutation({
      query: () => ({
        url: urls.LOGOUT,
        method: "POST",
        body: {},
      }),
    }),
  }),
});

export const { useLoginMutation, useRefreshMutation, useLogoutMutation } = authApi;
