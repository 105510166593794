import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const LoadingIcon = () => {
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <CircularProgress sx={{ m: 5 }} />
      </Grid>
    </Grid>
  );
};

export default LoadingIcon;