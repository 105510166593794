import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Divider, IconButton, Modal, TextField, Typography } from '@mui/material';
// import { useUpdateCylinderMutation } from '../../services/cylindersApi';
import { useHandleError } from '../../../hooks/useHandleError';
import useHandleSuccess from '../../../hooks/useHandleSuccess';
import { clearLoading, setLoading } from '../../../slices/loadingSlice';
import { useLocale } from '../../../hooks';
import { Icon } from '../../../components';
import { useUpdateWorkOrderMutation } from '../../../services/workordersApi';

export const EditAdditonalInfoDialog = ({
  open,
  handleClose,
  workOrderId,
  initialValue
}) => {
  const dispatch = useDispatch();
  const text = useLocale();
  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();
  const [valueAdditionalInstruction,setValueAdditionalInstruction] = useState('');
  const [updateObj]= useUpdateWorkOrderMutation();
  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff !important',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    outline: 'none !important',
  };

  useEffect(()=>{
    setValueAdditionalInstruction(initialValue)
  },[initialValue])

  function handleAdditionalInstructionField(e) {
    setValueAdditionalInstruction(e.target.value);
  }
  async function updateRecord() {
    dispatch(setLoading());
    try {
      // Only find values that have changed
        let changes = { additionalInstructions: valueAdditionalInstruction };
        const data = { changes: changes,id:workOrderId};
        await updateObj(data);
      handleClose();
      handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.Cylinders.SUCCESSFUL_UPDATE);
    } catch (e) {
      console.log(e);
      handleClose();
      handleErrorResponse(e, text.Cylinders.UNSUCCESSFUL_UPDATE);
    }
    dispatch(clearLoading());
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between' }}>
          <Typography variant={'h6'} pb={2} sx={{ flex: 1 }}>
            {text.WorkOrders.FORM_ADD4}
          </Typography>

          <IconButton onClick={handleClose} variant="contained" sx={{ marginBottom: '14px' }}>
            <Icon iconName={'Cancel'} />
          </IconButton>
        </Box>
        <Divider mb={3} />
        <TextField
          label={text.WorkOrders.FORM_ADD4}
          value={valueAdditionalInstruction}
          name="additionalInstructions"
          sx={{ mt: 2, display: 'flex', flex: 1 }}
          type={'text'}
          onChange={handleAdditionalInstructionField}
        />
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={updateRecord}
           disabled={!valueAdditionalInstruction?.length}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};
