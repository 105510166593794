import { DesktopTimePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import useIsMobileOrTablet from '../../hooks/useIsMobileOrTablet';

const CustomTimePicker = ({ name, onChange, initialValue, ...props }) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const [selected, setSelected] = useState(null);

  const initialDateTime = useMemo(() => {
    if (initialValue) {     
      return DateTime.fromISO(initialValue, { zone: 'utc' });
    }
    return DateTime.utc().set({ hour: 6, minute: 0 });
  }, [initialValue]);

  useEffect(() => {
    setSelected(initialDateTime);
    handleChange(initialDateTime || null);
  }, [initialDateTime]);

  const handleChange = useCallback(
    (newValue) => {
      setSelected(newValue);
      if (newValue) {
        const { year, month, day, hour, minute, second, millisecond } = newValue;
        onChange(name, DateTime.utc(year,month,day,hour,minute,second,millisecond
          ).toISO()
        );
      } else {
        onChange(name, '');
      }
    },
    [name, onChange]
  );

  return (
    <>
      {isMobileOrTablet ? (
        <MobileTimePicker
          ampm={true}
          value={selected}
          views={["hours", "minutes"]}
          onChange={handleChange}
          {...props}
        />
      ) : (
        <DesktopTimePicker
          ampm={true}
          value={selected}
          onChange={handleChange}
          {...props}
        />
      )}
    </>
  );
};

export default CustomTimePicker;
