import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MobileStepper,
  Typography,
 } from '@mui/material';
 import React from 'react';
 import { useLocale } from '../../../hooks';
 import CloseDialog from '../../CloseDialog';
 import FormConcretingSequence from '../../forms/FormConcretingSequence';
 import Cylinders from '../Cylinders';
 import Samples from '../Samples';
 
 
 export const ConcretingSequenceDialogContents = (props) => {
  const text = useLocale();
  const handleOpenDialog = (setIsOpen) => {
    if (
      props.formikConcretingSequence.dirty ||
      props.formikSamplesFieldCured.dirty ||
      props.formikSamplesLaboratoryCured.dirty
    ) {
      setIsOpen(true);
    } else {
      props.onClose();
    }
  };
  return (
    <React.Fragment>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', pb: 1, pt: 1, pr: 1, pl: 2 }}>
        <Typography sx={{ flex: 1, fontWeight: 'bold', fontSize: 22 }}>
          {text.ConcretingSequence.TITLE}
        </Typography>
 
 
        <CloseDialog handleOpenDialog={handleOpenDialog} handleClose={props.onClose} />
      </DialogTitle>
 
 
      <DialogContent dividers={true}>
        {props.activeStep === 0 ? (
          <FormConcretingSequence
            isConcreteSequeceEdit={props.isConcreteSequeceEdit}
            formik={props.formikConcretingSequence}
            concreteMixDesignsQuery={props.concreteMixDesignsQuery}
            fromWorkOrderDetails={props.fromWorkOrderDetails}
          />
        ) : props.activeStep === 1 ? (
          <React.Fragment>
            {props.formikConcretingSequence.values.collectSamples ? (
              <React.Fragment>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, mt: 2 }}>
                  {text.Samples.TITLE}
                </Typography>
                <Samples
                  formikSamplesFieldCured={props.formikSamplesFieldCured}
                  formikSamplesLaboratoryCured={props.formikSamplesLaboratoryCured}
                  commonSampleFields={props.commonSampleFields}
                  handleSetCommonSampleField={props.handleSetCommonSampleField}
                  setCommonSampleFields={props.setCommonSampleFields}
                  openLaboratorySample={props.openLaboratorySample}
                  openFieldSample={props.openFieldSample}
                  handleRemoveLaboratorySample={props.handleRemoveLaboratorySample}
                  handleRemoveFieldSample={props.handleRemoveFieldSample}
                  handleAddLaboratorySample={props.handleAddLaboratorySample}
                  handleAddFieldSample={props.handleAddFieldSample}
                  isConcreteSequeceEdit={props.isConcreteSequeceEdit}
                />
              </React.Fragment>
            ) : (
              <></>
            )}
          </React.Fragment>
        ) : props.activeStep === 2 ? (
          <Cylinders
            isConcreteSequeceEdit={props.isConcreteSequeceEdit}
            laboratoryCuredSampleID={props.laboratoryCuredSampleID}
            fieldCuredSampleID={props.fieldCuredSampleID}
            commonSampleFields={props?.commonSampleFields}
            cylindersQuery={props.cylindersQuery}
            fromWorkOrderDetails={props?.fromWorkOrderDetails}
          />
        ) : (
          <></>
        )}
      </DialogContent>
 
      <DialogActions>
        <MobileStepper
          activeStep={props.activeStep}
          variant="dots"
          position="static"
          steps={props.steps}
          sx={{ flexGrow: 1 }}
          nextButton={
            (props.activeStep === 0 && props.formikConcretingSequence.values.collectSamples) ||
              props.activeStep === 1 ? (
              <Button onClick={props.handleContinue}>{text.Buttons.SAVE_AND_CONTINUE}</Button>
            ) : props.activeStep === 2 ||
              (props.activeStep === 0 && !props.formikConcretingSequence.values.collectSamples) ? (
              <Button onClick={props.handleSaveAndComplete} variant="contained" color="primary">
                {text.Buttons.SAVE}
              </Button>
            ) : (
              <></>
            )
          }
          backButton={<Button onClick={props.handleBack}>{text.Buttons.BACK}</Button>}
        />
      </DialogActions>
    </React.Fragment>
  );
 };
 
 