const useRequest = (useQuery, params, options) => {
  // Check if offline
  let query = null;

  // If not offline
  query = useQuery(params, { refetchOnMountOrArgChange: true, ...options });
  const refetch = () => {
    query.refetch();
  };

  return { ...query, refetch };
};

export default useRequest;
