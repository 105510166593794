import {
    Box,
    Card,
    CardContent,
    Divider,
    FormHelperText,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import { useLocale } from '../../../hooks';
import { ButtonBack } from '../../../components/customMUI/ButtonBack';
import { ButtonSubmit } from '../../../components/customMUI/ButtonSubmit';

const FormEquipment = ({ formik, btIcon, btText }) => {
    const text = useLocale();
    const labelStyle = { fontWeight: 'bold', fontSize: 18 };
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} alignItems="stretch">
                {/** First Half */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container direction={'column'} rowGap={2}>
                        {/* PROJECT DETAILS */}
                        <Grid item>
                            <Card sx={{ minHeight: Object.keys(formik.errors).length > 0 ? '90vh' : '75vh' }}>
                                <CardContent>
                                    <Typography sx={labelStyle}>{text.EquipmentSet.SUBTITLE_EQUIPMENT_SET_DETAILS}</Typography>
                                    <Box>
                                        <TextField
                                            label={text.EquipmentSet.EQUIPMENT_NAME}
                                            value={formik.values.equipmentName}
                                            name={'equipmentName'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2 }}
                                            required
                                            type={'text'}
                                        />
                                        <FormHelperText
                                            error={formik.touched.equipmentName && formik.errors.equipmentName ? true : false}
                                        >
                                            {formik.errors.equipmentName}
                                        </FormHelperText>

                                        <TextField
                                            label={text.EquipmentSet.F921_EQ}
                                            value={formik.values.squareTemplate}
                                            name={'squareTemplate'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2 }}
                                            type={'text'}
                                        />
                                        <FormHelperText
                                            error={formik.touched.projectCode && formik.errors.projectCode ? true : false}
                                        >
                                            {formik.errors.projectCode}
                                        </FormHelperText>
                                        <TextField
                                            label={text.EquipmentSet.F921_RE}
                                            value={formik.values.straightRulerTemplate}
                                            name={'straightRulerTemplate'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2 }}
                                            type={'text'}
                                        />
                                        <FormHelperText
                                            error={formik.touched.projectName && formik.errors.projectName ? true : false}
                                        >
                                            {formik.errors.projectName}
                                        </FormHelperText>
                                        <TextField
                                            label={text.EquipmentSet.F921_512}
                                            value={formik.values.thermometer}
                                            name={'thermometer'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2 }}
                                            type={'text'}
                                        />
                                        <FormHelperText
                                            error={formik.touched.address && formik.errors.address ? true : false}
                                        >
                                            {formik.errors.address}
                                        </FormHelperText>
                                        <TextField
                                            label={text.EquipmentSet.F921_521}
                                            value={formik.values.thicknessGuage}
                                            name={'thicknessGuage'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2 }}
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                },
                                            }}
                                        />
                                        <FormHelperText
                                            error={
                                                formik.touched.thicknessGuage && formik.errors.thicknessGuage
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {formik.errors.thicknessGuage}
                                        </FormHelperText>
                                        <TextField
                                            label={text.EquipmentSet.F921_522}
                                            value={formik.values.vernierHeight}
                                            name={'vernierHeight'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2, display: 'flex', flex: 1 }}
                                        />
                                        <FormHelperText
                                            error={formik.touched.vernierHeight && formik.errors.vernierHeight ? true : false}
                                        >
                                            {formik.errors.vernierHeight}
                                        </FormHelperText>
                                        <TextField
                                            label={text.EquipmentSet.F921_523}
                                            value={formik.values.scaleProtractor}
                                            name={'scaleProtractor'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2, display: 'flex', flex: 1 }}
                                        />
                                        <FormHelperText
                                            error={formik.touched.scaleProtractor && formik.errors.scaleProtractor ? true : false}
                                        >
                                            {formik.errors.scaleProtractor}
                                        </FormHelperText>
                                        <TextField
                                            label={text.EquipmentSet.F921_525}
                                            value={formik.values.concretePress}
                                            name={'concretePress'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2, display: 'flex', flex: 1 }}
                                        />
                                        <FormHelperText
                                            error={formik.touched.concretePress && formik.errors.concretePress ? true : false}
                                        >
                                            {formik.errors.concretePress}
                                        </FormHelperText>
                                        <TextField
                                            label={text.EquipmentSet.F921_532}
                                            value={formik.values.standardStopWatch}
                                            name={'standardStopWatch'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2, display: 'flex', flex: 1 }}
                                        />
                                        <FormHelperText
                                            error={formik.touched.standardStopWatch && formik.errors.standardStopWatch ? true : false}
                                        >
                                            {formik.errors.standardStopWatch}
                                        </FormHelperText>
                                        <TextField
                                            label={text.EquipmentSet.F921_540}
                                            value={formik.values.copMaterialStrength}
                                            name={'copMaterialStrength'}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ mt: 2, display: 'flex', flex: 1 }}
                                        />
                                        <FormHelperText
                                            error={formik.touched.copMaterialStrength && formik.errors.copMaterialStrength ? true : false}
                                        >
                                            {formik.errors.copMaterialStrength}
                                        </FormHelperText>
                                    </Box>
                                    <br />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

         

            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                    <ButtonBack />
                </Grid>
                <Grid item>
                    <ButtonSubmit btIcon={btIcon} btText={btText} />
                </Grid>
            </Grid>
        </form>
    );
};

export default FormEquipment;
