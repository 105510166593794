import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams ,useLocation} from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { useCylinderSchema } from '../../../schemas/cylinder.schema';
import { addToQueue, formType, selectFromQueue } from '../../../slices/offlineQueueSlice';
import CylindersDialog from './CylindersDialog';
import routes from '../../../constants/routes';
import { useAddCylinderMutation } from '../../../services/cylindersApi';

const CylindersAdd = ({ laboratoryCuredSampleID, fieldCuredSampleID, isOpen, setIsOpen, sampleNumber, cylinders }) => {
  const { id: workOrderID } = useParams();
  const dispatch = useDispatch();
  const { cylinderCreateSchema } = useCylinderSchema();
  const location = useLocation();
  const laboratoryCuredSample = useSelector((state) => {
    const sample = selectFromQueue(state, laboratoryCuredSampleID);
    return sample ? sample.data : null;
  });

  const fieldCuredSample = useSelector((state) => {
    const sample = selectFromQueue(state, fieldCuredSampleID);
    return sample ? sample.data : null;
  });

  const [samples, setSamples] = useState([]);
  const [addCylinder] = useAddCylinderMutation()
  const formikCylinder = useFormik({
    initialValues: {
      customCylinderID: '',
      sampleID: '',
      daysToBeCured: 7,
      workOrderID: workOrderID,
      sampleNumber: sampleNumber,
    },
    validationSchema: cylinderCreateSchema,
    onSubmit: async(values, { resetForm }) => {
      const id = uuidv4();
      dispatch(addToQueue({ id: id, type: formType.CYLINDER, data: { ...values, id: id } }));
      resetForm();
      setIsOpen(false);
      if(!location.pathname.includes(routes.CONCRETING_SEQUENCES)){
       await addCylinder({ ...values });
      }
   
    },
  });

  useEffect(() => {
    if (isOpen) {
      let nextCylinderId = ""
      if (cylinders?.length > 0) {
        const sortedCylinders = [...cylinders].sort((a, b) => {
          if (a.customCylinderID.slice(-1) < b.customCylinderID.slice(-1)) return -1
          return a.customCylinderID > b.customCylinderID ? 1 : 0
        });
        const lastCylinderId = sortedCylinders[sortedCylinders?.length - 1]?.customCylinderID;
        const nextCharCode = lastCylinderId.charCodeAt(lastCylinderId.length - 1);
        nextCylinderId = sampleNumber + String.fromCharCode(nextCharCode + 1);
      } else {
        nextCylinderId = sampleNumber + String.fromCharCode(65);
      }
      formikCylinder.setFieldValue('customCylinderID', nextCylinderId);
    }
  }, [isOpen])

  useEffect(() => {
    let samples = [];
    if (laboratoryCuredSampleID) {
      samples.push(laboratoryCuredSample);
    }
    if (fieldCuredSampleID) {
      samples.push(fieldCuredSample);
    }
    setSamples(samples);
  }, [laboratoryCuredSample, fieldCuredSample]);

  const onClose = () => {
    formikCylinder.resetForm();
    setIsOpen(false);
  };

  return (
    <CylindersDialog
      formikCylinder={formikCylinder}
      samples={samples}
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={onClose}
    />
  );
};

export default CylindersAdd;
