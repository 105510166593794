// Each Data Fragment has a label and its data field
// This component is for displaying these to pieces of information

import { Box, Typography } from '@mui/material';
import React from 'react';

const DataFragment = ({ label, data }) => {
  const labelStyle = { fontWeight: 'bold', display: 'inline-block', mr: 2, fontSize: 16 };
  const dataFieldStyle = { display: 'inline-block', fontSize: 16 };
  return (
    <Box sx={{ mr: 1, mt: 1, mb: 1 }}>
      <Typography sx={labelStyle}>{label}:</Typography>
      <Typography sx={dataFieldStyle}>{data}</Typography>
    </Box>
  );
};

export default DataFragment;
