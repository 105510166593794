import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomDataGrid, FlexBetween, Header, LoadingIcon } from "../../components";
import StatusChip from "../../components/StatusChip";
import routes from "../../constants/routes";
import { useGetElement, useLocale } from "../../hooks";
import { useFormatDateTime } from "../../hooks/useFormatDateTime";
import useRequest from "../../hooks/useRequest";
import {
  useGetSamplePickupsQuery,
  useUpdateAssignedSamplePickupMutation,
  useUpdateSamplePickupMutation,
} from "../../services/samplePickUpApi";
import tags from "../../services/tags";
import { selectUser } from "../../slices/userSlice";
import { Box, Button, Card, CardContent, Modal, Tab, Tabs, Typography } from "@mui/material";
import ViewFieldInfoSamples from "../workorders/forms/ViewFieldInfoSamples";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FormSampleCollector from "../workorders/forms/FormSampleCollector";
import { useFormik } from "formik";
import { useGetSamplesQuery } from "../../services/samplesApi";
import { addNotification } from "../../slices/notificationSlice";
import { useSamplePickupSchema } from "../../schemas/samplePickup.schema";
import { useHandleError } from "../../hooks/useHandleError";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { selectPermissions } from "../../slices/permissionSlice";
import PermissionGate from "../../components/PermissionGate";
import { featurePermissions } from "../../constants/permissions";
const SamplePickups = () => {
  const text = useLocale();
  const user = useSelector(selectUser);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const navItem = useGetElement(text.Navigation.ASSIGNED_SAMPLE_DELIVERIES);
  const { formatDateTime } = useFormatDateTime();
  const [updateSample] = useUpdateAssignedSamplePickupMutation();
  const dispatch = useDispatch();
  const updateDeliverySchema = useSamplePickupSchema();
  const { handleErrorResponse } = useHandleError();
  const [searchParams, setSearchParams] = useSearchParams();
  const userPermissions = useSelector(selectPermissions);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [showAssignedOnly, setShowAssignedOnly] = useState(false)
  useEffect(() => {
    if (user.roles.includes('Laboratory Supervisor')) {
      setShowAssignedOnly(true)
    }
  }, [user])

  // Get sample pickups assigned to user
  const samplePickupsQuery = useGetSamplePickupsQuery("samplePickup", {
    refetchOnMountOrArgChange: true,
  });
  const samplesQuery = useGetSamplesQuery({
    isassign: true
  }, { refetchOnMountOrArgChange: true });
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ my: 3, p: 0 }}>{children}</Box>}
      </div>
    );
  }

  const [initialValues, setInitialValues] = useState({
    assignedPickupDateTime: "",
    collectorID: "",
    pickupInstructions: "",
  });



  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    zIndex: 200,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAssign = (params) => {
    setSearchParams({ id: params.row.id });
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        id: searchParams.get("id") ? searchParams.get("id") : "",
        changes: { ...values },
      };

      try {
        await updateSample(data).unwrap();
        toast.success(text.SUCCESSFUL_ASSIGN);
        dispatch(
          addNotification({
            title: text.SUCCESSFUL_ASSIGN,
            description: text.SamplePickups.SUCCESSFUL_ASSIGN,
            color: "success.secondary",
          })
        );
        navigate(routes.SAMPLE_PICKUPS);
        setOpen(false);
      } catch (e) {
        handleErrorResponse(e, text.SamplePickups.UNSUCCESSFUL_UPDATE);
      }
    },
  });
  useEffect(() => {
    if (searchParams.get("id")) {
      for (let i in samplePickupsQuery?.data?.payload) {
        if (samplePickupsQuery?.data?.payload[i]?.id === searchParams.get("id")) {
          setInitialValues({
            assignedPickupDateTime: samplePickupsQuery?.data?.payload[i]?.assignedPickupDateTime,
            collectorID: samplePickupsQuery?.data?.payload[i]?.collector?.id,
            pickupInstructions: samplePickupsQuery?.data?.payload[i]?.pickupInstructions
          })
        }
      }
    }
  }, [searchParams.get("id"), samplePickupsQuery])

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const columns = [
    {
      field: "projectCode",
      headerName: text.LabCylinders.PROJECTCODE,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return params.row.workOrder.project.projectCode;
      },
    },
    {
      field: "projectName",
      headerName: text.LabCylinders.PROJECTNAME,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return params.row.workOrder.project.projectName;
      },
    },
    {
      field: "workOrder",
      headerName: text.WorkOrders.WORK_ORDER,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return params.value.number;
      },
    },
    {
      field: "laboratory",
      headerName: text.Laboratory.TITLE,
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.workOrder.laboratory.name;
      },
    },
    {
      field: "sampleNumber",
      headerName: text.Samples.LABEL_SAMPLE_NUMBER,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.samples[0]?.number;
      },
    },
    {
      field: 'type',
      headerName: text.Samples.LABEL_TYPE,
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <Typography>{text.SampleType[params.row.samples[0]?.type]}</Typography>;
      },
    },
    {
      field: "assignedTechnician",
      headerName: text.SamplePickups.FIELDTECHNICIANID,
      minWidth: 190,
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.collector?.name;
      },
    },
    {
      field: "assignedPickupDateTime",
      headerName: text.SamplePickups.ASSIGNED_PICKUP_DATE_TIME,
      minWidth: 180,
      flex: 1,
      valueGetter: (params) => {
        return formatDateTime(params?.row?.assignedPickupDateTime);
      },
    },
    {
      field: "pickupDateTime",
      headerName: text.SamplePickups.ACTUAL_PICKUP,
      minWidth: 180,
      flex: 1,
      valueFormatter: (params) => {
        if (params.value) {
          return formatDateTime(params.value);
        }
        return "";
      },
    },
    //dispatcher
    {
      field: "pickupInstructions",
      headerName: text.SamplePickups.COMMENTS_BY_DISPATCHER,
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.pickupInstructions;
      },
    },
    //technician
    {
      field: "additionalInformation",
      headerName: text.SamplePickups.COMMENTS_BY_TECHNICIAN,
      minWidth: 240,
      flex: 1,
    },
    {
      field: "dropoffDateTime",
      headerName: text.SamplePickups.DROPOFF_DATE_TIME,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => {
        if (params.value) {
          return formatDateTime(params.value);
        }
        return "";
      },
    },
   
    {
      field: "status",
      headerName: text.SamplePickups.STATUS,
      minWidth: 290,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <StatusChip status={params.row.status} />
            {params.row.status == "PENDING_PICKUP" ? (
              <PermissionGate
                userPermissions={userPermissions}
                componentPermissions={[featurePermissions.SAMPLES_PICKUP_UPDATE]}
              >
                <Button
                  sx={{ ml: 2 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAssign(params);
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  {text.Buttons.RE_ASSIGN}
                </Button>
              </PermissionGate>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={text.SamplePickups.TITLE} icon={navItem.icon} bcrumb={text.Samples.TITLE} />
      </FlexBetween>

      {showAssignedOnly ?
        <>
          {!samplePickupsQuery.isLoading && samplePickupsQuery.data ? (
            <CustomDataGrid
              col={columns}
              to={routes.SAMPLE_PICKUPS}
              rQuery={samplePickupsQuery}
              tags={[tags.SAMPLE_PICKUP]}
            />
          ) : (
            <LoadingIcon />
          )}
        </>
        :
        <Card sx={{ p: 1 }}>
          <CardContent>
            <Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Assigned" {...a11yProps(0)} />
                  <Tab label="Pending Assignment" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {!samplePickupsQuery.isLoading && samplePickupsQuery.data ? (
                  <CustomDataGrid
                    col={columns}
                    to={routes.SAMPLE_PICKUPS}
                    rQuery={samplePickupsQuery}
                    tags={[tags.SAMPLE_PICKUP]}
                  />
                ) : (
                  <LoadingIcon />
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ViewFieldInfoSamples samplesQuery={samplesQuery} samplePickupsQuery={samplePickupsQuery} fromSampleDeliveries={true} />
              </TabPanel>
            </Box>
          </CardContent>
        </Card>
      }

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <FormSampleCollector formik={formik} btIcon={"AddCircle"} btText={text.Buttons.ASSIGN} />
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default SamplePickups;
