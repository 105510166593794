import React from 'react';
import routes from '../../constants/routes';
import HomeCards from './HomeCards';
import StatusChip from '../../components/StatusChip';
import CustomCard from '../../components/customMUI/CustomCard';
import PermissionGate from '../../components/PermissionGate';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { selectPermissions } from '../../slices/permissionSlice';
import { featurePermissions } from '../../constants/permissions';
import { FlexBetween, Header } from '../../components';
import { useGetProjectsQuery, useGetProjectsRelatedToUserQuery } from '../../services/projectsApi';
import {
  useGetWorkOrdersQuery,
  useGetWorkOrdersRelatedToUserQuery,
} from '../../services/workordersApi';
import { useGetElement, useLocale } from '../../hooks';
import SamplesCards from './SamplesCards';
import Icon from '../../components/Icon';
import { selectUser } from '../../slices/userSlice';
import useRequest from '../../hooks/useRequest';

function Home() {
  const text = useLocale();
  const navItem = useGetElement(text.Home.NAME);
  const userPermissions = useSelector(selectPermissions);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const projectsQuery = useRequest(useGetProjectsRelatedToUserQuery, user.id);
  const workordersQuery = useRequest(useGetWorkOrdersRelatedToUserQuery, user.id);

  return (
    <Box>
      <Header title={navItem.name} subtitle={text.Home.HOME_SUBTITLE} icon={navItem.icon} />

      {/* Projects */}
      <PermissionGate
        userPermissions={userPermissions}
        componentPermissions={[featurePermissions.PROJECTS_CREATE]}
      >
        <HomeCards
          tool={'Projects'}
          addPermissions={[featurePermissions.PROJECTS_CREATE]}
          cardContent={
            projectsQuery?.data?.payload &&
            projectsQuery?.data?.payload.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <CustomCard
                    click={() => navigate(routes.PROJECTS + '/details/' + item.id)}
                    cContent={
                      <React.Fragment>
                        <FlexBetween>
                          <Typography sx={{ fontSize: '0.85rem' }}>#{item.projectCode}</Typography>
                          <StatusChip status={item.status} />
                        </FlexBetween>
                        <Typography sx={{ fontSize: '0.85rem' }}>
                          Laboratory: {item.region.name}
                        </Typography>
                        <Typography sx={{ fontSize: '0.85rem' }}>
                          Project Manager: {item.projectManager.name}
                        </Typography>
                      </React.Fragment>
                    }
                    cActions={
                      <Typography>
                        <b>Project Name: </b>
                        {item.projectName}
                      </Typography>
                    }
                  />
                </React.Fragment>
              );
            })
          }
        />
        <br />
        <br />
        <br />
      </PermissionGate>

      {/* Work Orders */}
      <PermissionGate
        userPermissions={userPermissions}
        componentPermissions={[featurePermissions.WORK_ORDERS_CREATE]}
      >
        <HomeCards
          tool={'WorkOrders'}
          addPermissions={[featurePermissions.WORK_ORDERS_CREATE]}
          cardContent={
            workordersQuery?.data?.payload &&
            workordersQuery?.data?.payload.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <CustomCard
                    click={() => navigate(routes.WORKORDERS + '/details/' + item.id)}
                    cContent={
                      <React.Fragment>
                        <FlexBetween>
                          <Typography sx={{ fontSize: '0.85rem' }}>
                            Project #{item.project.projectCode}
                          </Typography>
                          <StatusChip status={item.status} />
                        </FlexBetween>
                        <Typography sx={{ fontSize: '0.85rem' }}>
                          Project Name: {item.project.projectName}
                        </Typography>
                        <Typography sx={{ fontSize: '0.85rem' }}>
                          Field Technician:{' '}
                          {item.fieldTechnician ? item.fieldTechnician.firstName : ''}
                        </Typography>
                        <Typography sx={{ fontSize: '0.85rem' }}>
                          Lab: {item.laboratory.name}
                        </Typography>
                      </React.Fragment>
                    }
                    cActions={<Typography>Work Order Number: {item.number}</Typography>}
                  />
                </React.Fragment>
              );
            })
          }
        />
        <br />
        <br />
        <br />
      </PermissionGate>

      {/* Samples */}
      {/* <PermissionGate
        userPermissions={userPermissions}
        componentPermissions={[featurePermissions.SAMPLES_CREATE]}
      >
        <HomeCards
          tool={'Samples'}
          addPermissions={[featurePermissions.SAMPLES_CREATE]}
          cardContent={
            workordersQuery?.data?.payload &&
            workordersQuery?.data?.payload.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <CustomCard
                    click={() => navigate(routes.WORKORDERS + '/details/' + item.id)}
                    cContent={<React.Fragment>Sample Content</React.Fragment>}
                    cActions={<Typography>Sample # Cylinders # </Typography>}
                  />
                </React.Fragment>
              );
            })
          }
        />
        <br />
        <br />
        <br />
      </PermissionGate> */}

       {/* Add New Workorder For Field Technician */}
       {user.roles.includes('Field Technician') && <Grid container mb={8} spacing={2}>
        <CustomCard
          cContent={
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              onClick={() => navigate('/workorders/add/')}
            >
              <Icon iconName="Add" sx={{ fontSize: "80px" }} />
            </Box>
          }
          cActions={
            <Typography>
              Add new <b>WorkOrders</b>
            </Typography>
          }
        />
      </Grid>
      }
      {/** Work Orders Assigned to Me */}

      <PermissionGate
        userPermissions={userPermissions}
        componentPermissions={[
          featurePermissions.CONCRETE_FIELD_INFORMATION_CREATE,
          featurePermissions.CONCRETE_MIX_DESIGN_CREATE,
          featurePermissions.CONCRETING_SEQUENCES_CREATE,
          featurePermissions.CYLINDERS,
          featurePermissions.SAMPLES_CREATE,
        ]}
      >
        <Grid container>
          <Grid item xs={12} md={3} lg={3}>
            <Box component={Link} to={routes.WORKORDERS_ASSIGNED} sx={{ textDecoration: 'none' }}>
              <Card>
                <CardContent sx={{ height: 125, display: 'flex', justifyContent: 'center' }}>
                  <Icon iconName={'Assignment'} sx={{ fontSize: 90 }} />
                </CardContent>
                <CardActions sx={{ backgroundColor: '#000', color: '#fff' }}>
                  {text.WorkOrders.WORK_ORDERS_ASSIGNED}
                </CardActions>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </PermissionGate>
      <br />
      {/* Samples */}
      <PermissionGate
        userPermissions={userPermissions}
        componentPermissions={[featurePermissions.SAMPLES_CREATE]}
      >
        <SamplesCards tool={'Samples'} addPermissions={[featurePermissions.SAMPLES_CREATE]} />
        <br />
        <br />
        <br />
      </PermissionGate>
    </Box>
  );
}

export default Home;
