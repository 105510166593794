import * as yup from 'yup';
import { useLocale } from '../hooks';
import { Statuses } from '../constants/options';

export const useWorkOrderSchema = () => {
  const text = useLocale();
  const workorderCreateSchema = yup.object().shape({
    projectID: yup.string().required(text.REQUIRED),
    siteVisitDate: yup.string().required(text.REQUIRED),
    workLocation: yup.string().required(text.REQUIRED),
    siteVisitType: yup.string().required(text.REQUIRED),
    arrivalTime: yup.date().required(text.REQUIRED),
    timeOnSite: yup.number().notRequired(),
    laboratoryID: yup.string().required(text.REQUIRED),
    fieldTechnicianID: yup.string().notRequired(),
    additionalInstructions: yup.string().notRequired(),
  });

  const workorderEditSchema = yup.object().shape({
    projectID: yup.string().nullable().required(text.REQUIRED),
    siteVisitDate: yup.string().nullable().required(text.REQUIRED),
    workLocation: yup.string().nullable().required(text.REQUIRED),
    siteVisitType: yup.string().nullable().required(text.REQUIRED),
    arrivalTime: yup.date().nullable().required(text.REQUIRED),
    timeOnSite: yup.number().nullable().notRequired(),
    laboratoryID: yup.string().nullable().required(text.REQUIRED),
    fieldTechnicianID: yup.string().nullable().notRequired(),
    additionalInstructions: yup.string().notRequired(),
    status: yup.string().oneOf(Object.keys(Statuses)),
  });
  return { workorderCreateSchema, workorderEditSchema };
};

export const workOrderInitialValues = {
  projectID: '',
  siteVisitDate: '',
  siteVisitType: '',
  arrivalTime: '',
  timeOnSite: 0,
  inspectionType: '',
  workLocation: '',
  laboratoryID: '',
  fieldTechnicianID: '',
  additionalInstructions: '',
};
