import { createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import actions from '../constants/actions';
import { useAddConcreteFieldInformationMutation } from '../services/concreteFieldInformationApi';
import { clearLoading, setLoading } from '../slices/loadingSlice';
import { removeFromQueue } from '../slices/offlineQueueSlice';

export const useSubmitConcreteFieldInformation = () => {
  const [submit] = useAddConcreteFieldInformationMutation();

  const submitConcreteFieldInformation = createAsyncThunk(
    actions.SUBMIT_CONCRETE_FIELD_INFO,
    async (params, thunkAPI) => {
      try {
        const { id, ...data } = params;

        await submit(data).unwrap();
        thunkAPI.dispatch(removeFromQueue(id));
      } catch (e) {
        console.error(e);
        thunkAPI.dispatch(clearLoading());
      }
    }
  );
  return { submitConcreteFieldInformation };
};
