import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import LoadingIcon from '../LoadingIcon';

const CustomAutocomplete = ({
  name,
  onChange,
  itemsArray,
  itemsQuery,
  itemsQueryParams,
  itemsMapper,
  label,
  makeRequest,
  initialValue,
  handleEmptyField,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);
  let itemsQueryResult = null;

  if (itemsArray === undefined) {
    itemsQueryResult = itemsQueryParams
      ? makeRequest(itemsQuery, itemsQueryParams)
      : makeRequest(itemsQuery);
  }

  const sortItems = (items) => {
    return items.sort((a, b) => a?.label?.localeCompare(b.label));
  };

  useEffect(() => {
    if (itemsQueryResult?.data && itemsQueryResult.data.payload) {
      const mappedItems = itemsQueryResult.data.payload.map(itemsMapper);
      setItems(sortItems(mappedItems));
    }
  }, [itemsQueryResult]);

  useEffect(() => {
    if (itemsArray && itemsArray.length > 0) {
      const mappedItems = itemsArray.map(itemsMapper);
      setItems(sortItems(mappedItems));
    }
  }, [itemsArray?.length]);

  useEffect(() => {
    if (initialValue && items && items.length > 0) {
      const initialItem = items.find((item) => item.id === initialValue);
      setSelectedItem(initialItem);
      handleAutocompleteChange("", initialItem);
    }
  }, [initialValue, items.length]);
  
  useMemo(()=>{
    setSelectedItem(null)
  },[handleEmptyField])

  const handleAutocompleteChange = useCallback(
    (event, value) => {
      setSelectedItem(value);
      onChange(name, value ? value.id : '');
    },
    [name, onChange]
  );

  return (
    <React.Fragment>
      {itemsArray === null ? (
        <LoadingIcon />
      ) : (
        <Autocomplete
          getOptionLabel={(option) => option.label ?? ''}
          isOptionEqualToValue={(option, value) => {
            return value === undefined || option.id === value.id;
          }}
          fullWidth
          onChange={handleAutocompleteChange}
          name={name}
          value={selectedItem}
          options={items}
          renderInput={(params) => <TextField {...params} label={label} {...props} />}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(CustomAutocomplete);
