// Define actions that are not already defined by Redux RTX mutations/queries

const actions = {
  POLL_AUTH_REFRESH: 'poll/auth/refresh', // This is used for polling in useTokenRefresh
  AUTH_LOGIN: 'auth/login', // Used for login logic in useLogin
  AUTH_LOGOUT: 'auth/logout', // Used for logout logic in useLogout
  POLL_USER: 'poll/user',
  GETSAMPLES: 'samples/getby/concretingsequenceID',
  SUBMIT_CONCRETE_FIELD_INFO: 'concreteFieldReport/submit/concreteFieldInformation',
  SUBMIT_CONCRETE_MIX_DESIGN: 'concreteFieldReport/submit/concreteMixDesign',
  SUBMIT_CONCRETING_SEQUENCE: 'concreteFieldReport/submit/concretingSequence',
  SUBMIT_SAMPLE: 'concreteFieldReport/submit/sample',
  SUBMIT_CYLINDER: 'concreteFieldReport/submit/cylinder',
  SUBMIT_CONCRETE_FIELD_REPORT: 'concreteFieldReport/submit',
};
export default actions;
