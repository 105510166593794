import { Autocomplete, Box, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocale } from '../../../hooks';


const FormViewConcreteMixDesign = ({ formik, btIcon, btText }) => {
   const text = useLocale();
   const labelStyle = { fontWeight: 'bold', fontSize: 18 ,};


   useEffect(() => {
       formik.setTouched(true, false);
   }, []);


   return (
       <div>
           <Typography sx={labelStyle}>{text.ConcreteMixDesign.TITLE}</Typography>
           <Grid container>
               <Grid item xs={6} lg={6} >
                   <TextField
                       label={text.ConcreteMixDesign.LABEL_CONCRETE_SUPPLIER_ORGANISATION}
                       value={formik.values.supplierOrganisation}
                       name={"supplierOrganisation"}
                       onChange={formik.handleChange}
                       sx={{ display: "flex", flex: 1, mt: 1 }}
                       type={"text"}
                   />
                   <TextField
                       label={text.ConcreteMixDesign.LABEL_PLANT}
                       value={formik.values.plant}
                       name={"plant"}
                       onChange={formik.handleChange}
                       sx={{ display: "flex", flex: 1, mt: 1.5 }}
                       type={"text"}
                   />
                   <TextField
                       label={text.ConcreteMixDesign.LABEL_FORMULA}
                       value={formik.values.formula}
                       name={"formula"}
                       onChange={formik.handleChange}
                       sx={{ display: "flex", flex: 1, mt: 1.5}}
                       type={"text"}
                   />
                   <FormHelperText
                       error={formik.touched.formula && formik.errors.formula ? true : false}
                   >
                       {formik.errors.formula}
                   </FormHelperText>
               </Grid>
               <Grid item xs={6} lg={6} pl={1}>
                  
                   <TextField
                       label={text.ConcreteMixDesign.LABEL_CSA_EXPOSURE_CLASS}
                       value={formik.values.csaExposureClass}
                       name={"csaExposureClass"}
                       onChange={formik.handleChange}
                       sx={{ display: "flex", flex: 1, mt: 1 }}
                       type={"text"}
                   />
                   <FormHelperText
                       error={formik.touched.csaExposureClass && formik.errors.csaExposureClass ? true : false}
                   >
                       {formik.errors.csaExposureClass}
                   </FormHelperText>
               </Grid>
           </Grid>
       </div>
   );
};


export default FormViewConcreteMixDesign;



