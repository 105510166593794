import { api } from './api';
import urls from '../constants/urls';

export const regionsApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getOffices: builder.query({
      query: () => ({
        url: urls.OFFICES,
      }),
    }),
  }),
});

export const { useGetOfficesQuery } = regionsApi;
