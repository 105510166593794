import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';

import useLocale from '../../../hooks/useLocale';
import CloseDialog from '../../CloseDialog';
import FormConcreteMixDesign from '../../forms/FormConcreteMixDesign';

export const ConcreteMixDesignContents = ({
  formikConcreteMixDesign,
  handleClose,
  handleSubmit,
}) => {
  const text = useLocale();

  const handleOpenDialog = (setIsOpen) => {
    if (formikConcreteMixDesign.dirty) {
      setIsOpen(true);
    } else {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', pb: 1, pt: 1, pr: 1, pl: 2 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 20, flex: 1 }}>
          {text.ConcreteMixDesign.TITLE}
        </Typography>

        <CloseDialog handleOpenDialog={handleOpenDialog} handleClose={handleClose} />
      </DialogTitle>

      <DialogContent dividers={true}>
        <FormConcreteMixDesign formik={formikConcreteMixDesign} />
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ m: 1 }}>
          {text.Buttons.SAVE}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};
