import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FlexBetween from '../../../components/FlexBetween';
import LoadingIcon from '../../../components/LoadingIcon';
import PermissionGate from '../../../components/PermissionGate';
import CustomGridToolbar from '../../../components/customMUI/CustomGridToolbar';
import CustomTextFieldView from '../../../components/customMUI/CustomTextFieldView';
import { featurePermissions } from '../../../constants/permissions';
import { useLocale } from '../../../hooks';
import useFormatDateTime from '../../../hooks/useFormatDateTime';
import { useHandleError } from '../../../hooks/useHandleError';
import useRequest from '../../../hooks/useRequest';
import { useSamplePickupSchema } from '../../../schemas/samplePickup.schema';
import { useAddPendingSamplePickUpMutation, useAddSamplePickUpMutation } from '../../../services/samplePickUpApi';
import { useGetSamplesQuery } from '../../../services/samplesApi';
import tags from '../../../services/tags';
import { addNotification } from '../../../slices/notificationSlice';
import { selectPermissions } from '../../../slices/permissionSlice';
import timeConvert from '../../../utils/timeConvert';
import FormSampleCollector from './FormSampleCollector';

const ViewFieldInfoSamples = ({ concreteFieldInfo, fromSampleDeliveries,samplePickupsQuery,samplesQuery }) => {
  const userPermissions = useSelector(selectPermissions);
  const { formatDate } = useFormatDateTime();
  const [addSamplePickup] = useAddSamplePickUpMutation();
  const [addPendingSamplePickUp] = useAddPendingSamplePickUpMutation();
  const { handleErrorResponse } = useHandleError();
  const text = useLocale();
  const { id } = useParams();
  const { formatDateTime } = useFormatDateTime();
  const tool = 'ConcreteFieldInformation';
  const dataGridBoxStyle = {
    width: '100%',
    '& .MuiDataGrid-root': { border: 'none' },
    '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
  };
  // Styling
  const titleStyle = { fontWeight: 'bold', fontSize: 20, mb: 1 };
  const subtitleStyle = { fontWeight: 'bold' }
  const columns = [
    {
      field: "projectCode",
      headerName: text.LabCylinders.PROJECTCODE,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return params.row.concretingSequence?.workOrder?.project?.projectCode;
      },
    },
    {
      field: "projectName",
      headerName: text.LabCylinders.PROJECTNAME,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return params.row.concretingSequence?.workOrder?.project?.projectName;

      },
    },
    {
      field: "workOrder",
      headerName: text.WorkOrders.WORK_ORDER,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.concretingSequence?.workOrder?.number;
      },
    },
    {
      field: "laboratory",
      headerName: text.Laboratory.TITLE,
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.concretingSequence?.workOrder?.laboratory?.name;
      },
    },
    {
      field: 'number',
      headerName: text.ConcreteFieldInformation.SAMPLE_NUMBER,
      flex: 1,
      minWidth: 180,
    },

    {
      field: 'type',
      headerName: text.Samples.LABEL_TYPE,
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <Typography>{text.SampleType[params.value]}</Typography>;
      },
    },
    { field: 'typeOfMould', headerName: text.Samples.LABEL_TYPE_OF_MOULD, flex: 1, minWidth: 180 },
    { field: 'sizeOfMould', headerName: text.Samples.LABEL_SIZE_OF_MOULD, flex: 1, minWidth: 180 },
    {
      field: 'cylinders',
      headerName: text.Samples.NUMBER_OF_CYLINDERS,
      flex: 1,
      minWidth: 180,
      valueFormatter: (params) => params.value.length,
    },
    {
      field: 'curingConditions',
      headerName: text.Samples.LABEL_CURING_CONDITIONS,
      flex: 1,
      minWidth: 400,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.value.map((item, index) => {
              if (index !== params.value.length - 1) {
                return (
                  <Typography key={index}>
                    {text.CuringConditions[item]}
                    {','} &nbsp;
                  </Typography>
                );
              } else {
                return <Typography key={index}>{text.CuringConditions[item]}</Typography>;
              }
            })}
          </React.Fragment>
        );
      },
    },
    {
      field: 'timeOfSampling',
      headerName: text.ConcreteFieldInformation.TIME_OF_SAMPLING,
      flex: 1,
      minWidth: 220,
      valueFormatter: (params) => {
        return `${formatDate(params.value)} ${timeConvert(params.value)}`;
      },
    },
  ];
  const [selectedRows, setSelectedRows] = React.useState([]);
  // Calculate the amount of cylinders under each distinct sample number
  const samplesAndCylinders = useMemo(() => {
    if (!samplesQuery?.data) return
    let samples = []

    for (const sampleData of samplesQuery.data.payload) {
      const samplesFilteredByNumber = samples.filter((s) => s.number === sampleData.number)
      if (samplesFilteredByNumber.length !== 0) {
        let sample = samplesFilteredByNumber[0]
        sample.cylinders = sample.cylinders + sampleData.cylinders.length
      } else {
        samples.push({ number: sampleData.number, cylinders: sampleData.cylinders.length })
      }
    }

    return samples.sort((previous, next) => previous.number - next.number)

  }, [samplesQuery?.data])

  // MODAL
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch();
  const { samplePickupCreateSchema } = useSamplePickupSchema();

  // Add Formik
  const initialValues = {
    assignedPickupDateTime: '',
    collectorID: '',
    workOrderID: fromSampleDeliveries ? '' : id,
    samples:  selectedRows,
    pickupInstructions: '',
  };

  // For assigning technicians to pickup samples

  const getRowelectionIds = () => {
    let arr =[];
    for (let i = 0; i < selectedRows.length; i++) {
      const sampleId = selectedRows[i];
      const foundObject = samplesQuery?.data?.payload?.find(item => item.id === sampleId);
      if(foundObject){
        const finalData = {
          "sampleId": sampleId,
          "workorderId": foundObject?.concretingSequence?.workOrderID
        }
        arr.push(finalData)
      }
    }
    return arr;
  }
  const formik = useFormik({
    initialValues:  initialValues,
    validationSchema: samplePickupCreateSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if(fromSampleDeliveries){
          values.samples = getRowelectionIds();
        }
        await fromSampleDeliveries ? addPendingSamplePickUp(values).unwrap() : addSamplePickup(values).unwrap();
        setSelectedRows([]);
        toast.success(text.SUCCESSFUL_ASSIGN);
        dispatch(
          addNotification({
            title: text.SUCCESSFUL_ASSIGN,
            description: text.SamplePickups.SUCCESSFUL_CREATE,
            color: 'success.secondary',
          })
        );
        if(samplePickupsQuery){
          samplePickupsQuery.refetch();
        }
        samplesQuery.refetch();
        setOpen(false);
      } catch (e) {
        handleErrorResponse(e, text.SamplePickups.UNSUCCESSFUL_CREATE);
      }
    },
  });

  return samplesQuery?.isLoading ? (
    <LoadingIcon />
  ) : (
    <React.Fragment>
      {!fromSampleDeliveries && <>
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography noWrap sx={titleStyle}>
              {text.ConcreteFieldInformation.SAMPLE_DETAILS}
            </Typography>
          </Grid>
          {
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container direction={'row'}>
              <Grid item xs={12} sm={12} md={6} lg={6} alignItems="center">
                <Typography noWrap sx={subtitleStyle}>
                  {text.Samples.LABEL_SAMPLE_NUMBER}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography noWrap sx={subtitleStyle}>
                  {text.Samples.NUMBER_OF_CYLINDERS}
                </Typography>
              </Grid>
              {samplesAndCylinders?.map((sampleDt) => {
                return (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: '25px' }}>
                      {sampleDt.number}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: '25px' }}>
                      {sampleDt.cylinders}
                    </Grid>
                  </>
                )
              })}
            </Grid>
          </Grid>
          }

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container direction={'column'}>
              <Grid item>
                <CustomTextFieldView
                  name="DESCRIPTION_OF_ONSITE_LOCATION"
                  tool={tool}
                  value={concreteFieldInfo?.sampleLocationDescription}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ mb: 4, mt: 4 }} />
      </>


      }

      {/* Card 2 */}
      <Box height={'45vh'} sx={dataGridBoxStyle}>
        <FlexBetween>
          <Typography sx={{ fontSize: 22, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
            {text.ConcreteFieldInformation.SAMPLES_COLLECTED}
          </Typography>
          {selectedRows.length !== 0 ? (
            <PermissionGate
              userPermissions={userPermissions}
              componentPermissions={[featurePermissions.SAMPLES_PICKUP_CREATE]}
            >
              <Button onClick={handleOpen} variant="contained" size="large" color="primary">
                {text.Buttons.ASSIGN}
              </Button>
            </PermissionGate>
          ) : (
            ''
          )}
        </FlexBetween>
        <PermissionGate
          userPermissions={userPermissions}
          componentPermissions={[featurePermissions.SAMPLES_PICKUP_CREATE]}
          fallback={
             <DataGridPro
              rows={samplesQuery?.data ? samplesQuery.data.payload : []}
              slots={{ toolbar: () => CustomGridToolbar({ tags: [tags.SAMPLE] }) }}
              columns={columns}
              columnHeader
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10 },
                },
              }}
              pageSizeOptions={[10]}
            />
          }
        >
           <DataGridPro
            rows={samplesQuery?.data ? samplesQuery.data.payload : []}
            slots={{ toolbar: () => CustomGridToolbar({ tags: [tags.SAMPLE] }) }}
            columns={columns}
            columnHeader
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10 },
              },
            }}
            rowSelectionModel={selectedRows}
            pageSizeOptions={[10]}
            checkboxSelection={true}
            isRowSelectable={(params) =>
              params.row.samplePickup === null || params.row.samplePickup === undefined
            }
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids);
            }}
          />
        </PermissionGate>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <FormSampleCollector formik={formik} btIcon={'AddCircle'} btText={text.Buttons.ASSIGN} />
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ViewFieldInfoSamples;
