import { api } from './api';
import urls from '../constants/urls';
import refreshCache from '../utils/refreshCache';
import tags from './tags';
export const clientsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => ({
        url: urls.CLIENTS,
      }),
      providesTags: [tags.CLIENT],
    }),
    getClient: builder.query({
      query: (id) => ({
        url: urls.CLIENTS + '/' + id,
      }),
      providesTags: [tags.CLIENT],
    }),
    addClient: builder.mutation({
      query: (data) => ({
        url: urls.CLIENTS,
        method: 'POST',
        body: { ...data },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CLIENT]);
        } catch (e) {}
      },
    }),
    updateClient: builder.mutation({
      query: (data) => ({
        url: urls.CLIENTS + '/' + data.id,
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CLIENT]);
        } catch (e) {}
      },
    }),
    deleteClient: builder.mutation({
      query: (id) => ({
        url: urls.CLIENTS + '/' + id,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CLIENT]);
        } catch (e) {}
      },
    }),
  }),
});

export const {
  useLazyGetClientsQuery,
  useGetClientsQuery,
  useGetClientQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientsApi;
