import { createAsyncThunk } from '@reduxjs/toolkit';
import actions from '../constants/actions';
import { useAddConcreteMixDesignMutation } from '../services/concreteMixDesignApi';
import { clearLoading } from '../slices/loadingSlice';
import { removeFromQueue, replaceConcreteMixDesignIDs } from '../slices/offlineQueueSlice';

export const useSubmitConcreteMixDesign = () => {
  const [submit] = useAddConcreteMixDesignMutation();

  const submitConcreteMixDesign = createAsyncThunk(
    actions.SUBMIT_CONCRETE_MIX_DESIGN,
    async (params, thunkAPI) => {
      try {
        const { id, ...data } = params;
        const result = await submit(data).unwrap();
        console.log(result);
        // Replace placeholder IDs with the real one in ConcretingSequences
        thunkAPI.dispatch(replaceConcreteMixDesignIDs({ oldID: id, newID: result.payload.id }));
        thunkAPI.dispatch(removeFromQueue(id));
      } catch (e) {
        console.error(e);
        thunkAPI.dispatch(clearLoading());
      }
    }
  );
  return { submitConcreteMixDesign };
};
