import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useConcretePourSchema } from '../../schemas/concretePour.schema';
import { useGetConcreteQuery, useUpdateConcreteMutation } from '../../services/concreteApi';
import { ConcretePourDialog } from './components/ConcretePourDialog';
import { getObjectChanges } from '../../utils/form';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../slices/loadingSlice';
import { useNavigate } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { MobileResponsiveDialog } from '../../components/MobileResponsiveDialog';

const ConcreteEdit = () => {
  const { id } = useParams();
  const [updateConcretePour] = useUpdateConcreteMutation();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    element: '',
    area: '',
    volume: 0,
    samplesAmount: 0,
    cylindersPerSample: 0,
  });

  const { concretePourEditSchema } = useConcretePourSchema();
  const concretePourQuery = useRequest(useGetConcreteQuery, id);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: concretePourEditSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        dispatch(setLoading());
        const changes = getObjectChanges(values, initialValues);
        const data = { changes: changes, id: id };
        await updateConcretePour(data);
        navigate(-1);
      } catch (e) {
        console.log(e);
      }
      dispatch(clearLoading());
    },
  });

  useEffect(() => {
    if (concretePourQuery.isLoading === false) {
      setInitialValues({
        element: concretePourQuery.data.payload.element,
        area: concretePourQuery.data.payload.area,
        volume: concretePourQuery.data.payload.volume,
        samplesAmount: concretePourQuery.data.payload.samplesAmount,
        cylindersPerSample: concretePourQuery.data.payload.cylindersPerSample,
        cylindersPerFieldCured:concretePourQuery.data.payload.cylindersPerFieldCured
      });
    }
  }, [concretePourQuery.isLoading]);

  return (
    <React.Fragment>
      <MobileResponsiveDialog open={true}>
        <ConcretePourDialog
          formikConcretePour={formik}
          handleSubmit={formik.handleSubmit}
          handleClose={() => navigate(-1)}
        />
      </MobileResponsiveDialog>
    </React.Fragment>
  );
};

export default ConcreteEdit;
