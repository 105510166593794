import { Box, Card, CardContent, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomAutocomplete from '../../../components/customMUI/CustomAutocomplete';
import CustomDatePicker from '../../../components/customMUI/CustomDatePicker';
import CustomTextField from '../../../components/customMUI/CustomTextField';
import CustomTimePicker from '../../../components/customMUI/CustomTimePicker';
import { useLocale } from '../../../hooks';
import useRequest from '../../../hooks/useRequest';
import { useGetLabsQuery } from '../../../services/laboratoriesApi';
import { useGetProjectsQuery, useLazyGetProjectQuery } from '../../../services/projectsApi';
import { useGetUsersByRoleQuery } from '../../../services/userApi';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/userSlice';

const FormWorkOrder = ({ formik }) => {
  const [getProject] = useLazyGetProjectQuery();
  const text = useLocale();
  const labelStyle = { fontWeight: 'bold', fontSize: 18 };
  const tool = 'WorkOrders';
  const user = useSelector(selectUser);
  const [visitTypes, setVisitTypes] = useState([]);
  const [disableField, setDisableField] = useState(false)
  useEffect(() => {
    const visitTypes = Object.keys(text.WorkOrderVisitTypes).map((key) => {
      return { id: key, label: text.WorkOrderVisitTypes[key] };
    });
    setVisitTypes(visitTypes);
  }, []);

  useEffect(() => {
    if (formik.values.projectID) {
      // Fetch data
      getProjectData(formik.values.projectID);
    }
  }, [formik.values.projectID]);

  async function getProjectData(id) {
    try {
      const projectQuery = await getProject(id).unwrap();
      formik.setFieldValue(
        'workLocation',
        projectQuery.payload ? projectQuery.payload.address : ''
      );
      formik.setFieldValue(
        'laboratoryID',
        projectQuery.payload ? projectQuery.payload.laboratory.id : ''
      );
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (user?.roles?.includes("Field Technician")) {
      if (user?.id) {
        formik.setFieldValue('fieldTechnicianID', user.id)
      }
      setDisableField(true)
    }
  }, [user])

  return (
    <Grid container spacing={2} sx={{ my: 2 }} alignItems="stretch">
      <Grid item xs={12} sm={12} lg={6}>
        <Grid container direction={'column'} rowGap={2}>
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant="h5" sx={labelStyle}>
                  {text.WorkOrders.FORM_ADD1}
                </Typography>
                <Box sx={{ m: 3, mb: 6 }}>
                  <CustomAutocomplete
                    makeRequest={useRequest}
                    initialValue={formik.initialValues.projectID}
                    itemsQuery={useGetProjectsQuery}
                    itemsMapper={(item) => {
                      return { id: item.id, label: item.projectCode + ' - ' + item.projectName };
                    }}
                    sx={{ mt: 2 }}
                    name="projectID"
                    onChange={(name, value) => {formik.setFieldValue(name, value)}}
                    label={text.WorkOrders.PROJECTID}
                  />
                  <FormHelperText
                    error={formik.touched.projectID && formik.errors.projectID ? true : false}
                  >
                    {formik.errors.projectID}
                  </FormHelperText>

                  <CustomDatePicker
                    name="siteVisitDate"
                    sx={{ mt: 2, width: '100%' }}
                    label={text.WorkOrders.SITEVISITDATE}
                    initialValue={formik.initialValues.siteVisitDate}
                    onChange={(name, value) => formik.setFieldValue(name, value)}
                    disablePast={true}
                  />
                  <FormHelperText
                    error={
                      formik.touched.siteVisitDate && formik.errors.siteVisitDate ? true : false
                    }
                  >
                    {formik.errors.siteVisitDate}
                  </FormHelperText>

                  <CustomTimePicker
                    name="arrivalTime"
                    sx={{ mt: 2, width: '100%' }}
                    label={text.WorkOrders.ARRIVALTIME}
                    initialValue={formik.initialValues.arrivalTime}
                    onChange={(name, value) => formik.setFieldValue(name, value)}
                  />
                  <FormHelperText
                    error={formik.touched.arrivalTime && formik.errors.arrivalTime ? true : false}
                  >
                    {formik.errors.arrivalTime}
                  </FormHelperText>

                  <CustomAutocomplete
                    makeRequest={useRequest}
                    label={text.WorkOrders.SITEVISITTYPE}
                    initialValue={formik.initialValues.siteVisitType}
                    itemsArray={visitTypes}
                    itemsMapper={(item) => {
                      return { id: item.id, label: item.label };
                    }}
                    sx={{ mt: 2 }}
                    name="siteVisitType"
                    onChange={(name, value) => formik.setFieldValue(name, value)}
                  />
                  <FormHelperText
                    error={
                      formik.touched.siteVisitType && formik.errors.siteVisitType ? true : false
                    }
                  >
                    {formik.errors.siteVisitType}
                  </FormHelperText>

                  {/* Convert hours into seconds */}
                  <TextField
                    label={text.WorkOrders.TIMEONSITE}
                    sx={{ mt: 2, flex: 1 }}
                    fullWidth
                    type={'number'}
                    name="timeOnSite"
                    onChange={(e) => {
                      const newValue = e.target.value;
                      // Validate that the entered value is a non-negative number
                      if (/^\d+$/.test(newValue) || newValue === '') {
                        formik.setFieldValue('timeOnSite', newValue * 3600)
                      }
                    }}
                    value={formik.values.timeOnSite === 0 ? '' : formik.values.timeOnSite / 3600}
                  />
                  <FormHelperText
                    error={formik.touched.timeOnSite && formik.errors.timeOnSite ? true : false}
                  >
                    {formik.errors.timeOnSite}
                  </FormHelperText>
                  <CustomTextField name="workLocation" tool={tool} formik={formik} />
                  <FormHelperText
                    error={formik.touched.workLocation && formik.errors.workLocation ? true : false}
                  >
                    {formik.errors.workLocation}
                  </FormHelperText>
                  <CustomAutocomplete
                    makeRequest={useRequest}
                    initialValue={formik.values.laboratoryID}
                    itemsQuery={useGetLabsQuery}
                    itemsMapper={(item) => {
                      return { id: item.id, label:`${item.name} (${item.region.name})` };
                    }}
                    sx={{ mt: 2 }}
                    name="laboratoryID"
                    onChange={(name, value) => formik.setFieldValue(name, value)}
                    label={text.WorkOrders.labReceiving}
                  />
                  <FormHelperText
                    error={formik.touched.laboratoryID && formik.errors.laboratoryID ? true : false}
                  >
                    {formik.errors.laboratoryID}
                  </FormHelperText>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} lg={6}>
        <Grid container direction={'column'} rowGap={2}>
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant="h5" sx={labelStyle}>
                  {text.WorkOrders.FORM_ADD2}
                </Typography>
                <Box sx={{ m: 3, mb: 6 }}>
                  <CustomAutocomplete
                    disabled={disableField}
                    makeRequest={useRequest}
                    label={text.WorkOrders.FIELDTECHNICIANID}
                    initialValue={formik.values.fieldTechnicianID}
                    itemsQuery={useGetUsersByRoleQuery}
                    itemsQueryParams={{ role: 'Field Technician' }}
                    itemsMapper={(item) => {
                      return { id: item.id, label: `${item.firstName} ${item.lastName}` };
                    }}
                    sx={{ mt: 2 }}
                    name="fieldTechnicianID"
                    onChange={(name, value) => formik.setFieldValue(name, value)}
                    placeholder="Leave this blank if unassigned"
                  />
                  <FormHelperText
                    error={
                      formik.touched.fieldTechnicianID && formik.errors.fieldTechnicianID
                        ? true
                        : false
                    }
                  >
                    {formik.errors.fieldTechnicianID}
                  </FormHelperText>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card>
              <CardContent>
                <Typography variant="h5" sx={labelStyle}>
                  {text.WorkOrders.FORM_ADD4}
                </Typography>
                <Box sx={{ m: 3, mb: 6 }}>
                  <CustomTextField
                    name="additionalInstructions"
                    tool={tool}
                    formik={formik}
                    multiline
                    maxRows={10}
                  />
                </Box>
              </CardContent>
            </Card>
            {Object.keys(formik.touched).length > 0 && Object.keys(formik.errors).length > 0 ? (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mr: 3 }}>
                <Typography sx={{ fontSize: 14 }} color={'error.main'}>
                  {text.INVALID_INPUT}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormWorkOrder;
