import * as yup from 'yup';
import useLocale from '../hooks/useLocale';
import { Statuses } from '../constants/options';

export const useProjectSchema = () => {
  const text = useLocale();

  const projectCreateSchema = yup.object().shape({
    onSiteContactOrganisation: yup.string().required(text.REQUIRED),
    projectCode: yup.string().required(text.REQUIRED),
    projectName: yup.string().required(text.REQUIRED),
    regionID: yup.string().required(text.REQUIRED),
    clientPurchaseOrder: yup.string().required(text.REQUIRED),
    address: yup.string().required(text.REQUIRED),
    transportationTime: yup.number(text.INVALID_NUMBER).required(text.REQUIRED),
    mileage: yup.number().required(text.REQUIRED),
    projectManagerID: yup.string().required(text.REQUIRED),
    dispatcherID: yup.string().required(text.REQUIRED),
    laboratorySupervisorID: yup.string().required(text.REQUIRED),
    clientID: yup.string().required(text.REQUIRED),
    laboratoryID: yup.string().required(text.REQUIRED),
    onSiteContactName: yup.string().required(text.REQUIRED),
    onSiteContactTelephone: yup.string().required(text.REQUIRED),
    onSiteContactEmail: yup.string().email(text.INVALID_EMAIL).required(text.REQUIRED),
    clientFullName: yup.string().required(text.REQUIRED),
    clientPhoneNumber: yup.string().required(text.REQUIRED),
    clientEmail: yup.string().email(text.INVALID_EMAIL).required(text.REQUIRED),
  });

  const projectEditSchema = yup.object().shape({
    onSiteContactOrganisation: yup.string().required(text.REQUIRED),
    projectCode: yup.string().required(text.REQUIRED),
    projectName: yup.string().required(text.REQUIRED),
    regionID: yup.string().required(text.REQUIRED),
    clientPurchaseOrder: yup.string().required(text.REQUIRED),
    address: yup.string().required(text.REQUIRED),
    transportationTime: yup.number(text.INVALID_NUMBER).required(text.REQUIRED),
    mileage: yup.number().required(text.REQUIRED),
    projectManagerID: yup.string().required(text.REQUIRED),
    dispatcherID: yup.string().required(text.REQUIRED),
    laboratorySupervisorID: yup.string().required(text.REQUIRED),
    clientID: yup.string().required(text.REQUIRED),
    laboratoryID: yup.string().required(text.REQUIRED),
    onSiteContactName: yup.string().required(text.REQUIRED),
    onSiteContactTelephone: yup.string().required(text.REQUIRED),
    onSiteContactEmail: yup.string().email(text.INVALID_EMAIL).required(text.REQUIRED),
    status: yup.string().oneOf(Object.keys(Statuses)),
    clientFullName: yup.string().required(text.REQUIRED),
    clientPhoneNumber: yup.string().required(text.REQUIRED),
    clientEmail: yup.string().email(text.INVALID_EMAIL).required(text.REQUIRED),
  });

  return { projectCreateSchema, projectEditSchema };
};
