import React from 'react';
import { MobileStepper, Button } from '@mui/material';
import { useLocale } from '../../hooks';
const CustomLinearMobileStepper = ({ maxSteps, activeStep, handleBack, handleNext, ...props }) => {
  const text = useLocale();

  return (
    <MobileStepper
      variant="text"
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
          {text.Buttons.CONTINUE}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {text.Buttons.BACK}
        </Button>
      }
      {...props}
    />
  );
};

export default CustomLinearMobileStepper;
