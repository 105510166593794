import React from 'react';
import { Box, Card, CardContent, Grid, TextField, Typography,FormHelperText } from '@mui/material';
import useLocale from '../../hooks/useLocale';

const FormSampleInformation = ({ formik, filteredSamplesOnWorkOrder }) => {
  const text = useLocale();

  const subtitleStyle = {fontWeight: 'bold'}

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>
            {text.SampleInformation.TITLE}
          </Typography>
          <br />
          <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container direction={'row'}>
                <Grid item xs={12} sm={12} md={6} lg={6} alignItems="center">
                  <Typography noWrap sx={subtitleStyle}>
                    {text.Samples.LABEL_SAMPLE_NUMBER}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography noWrap sx={subtitleStyle}>
                    {text.Samples.NUMBER_OF_CYLINDERS}
                  </Typography>
                </Grid>
                {filteredSamplesOnWorkOrder.map((sample) => {
                  return (
                    <>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: '25px' }}>
                      {sample.number}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: '25px' }}>
                      {sample.cylinders}
                    </Grid>
                  </>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <br />

          <Typography>{text.SampleInformation.SUBTITLE_SAMPLE_LOCATION}</Typography>

          <TextField
            multiline
            minRows={5}
            label={text.ConcreteFieldInformation.LABEL_SAMPLE_LOCATION}
            value={formik.values.sampleLocationDescription}
            name={'sampleLocationDescription'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'text'}
          />
        </CardContent>
      </Card>

      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>
            {text.AdditionalInformation.TITLE}
          </Typography>
        </CardContent>

        <Box sx={{ p: 2 }}>
          <Typography>{text.AdditionalInformation.LABEL_ADDITIONAL_INFORMATION}</Typography>
          <TextField
            multiline
            minRows={5}
            placeholder={text.AdditionalInformation.PLACEHOLDER_ADDITIONAL_INFORMATION}
            value={formik.values.additionalInformation}
            name={'additionalInformation'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'text'}
          />
             <FormHelperText
              error={formik.touched.additionalInformation && formik.errors.additionalInformation ? true : false}
            >
              {formik.errors.additionalInformation}
            </FormHelperText>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default FormSampleInformation;
