import React from 'react';
import { Button, Tooltip } from '@mui/material';
import Icon from './Icon';
import refreshCache from '../utils/refreshCache';
import { useDispatch } from 'react-redux';
import useLocale from '../hooks/useLocale';

/**
 *
 * @param {{tags: String[]}} params Takes in tags - an array of tags in invalidate in RTK
 * @returns
 */
const RefreshButton = ({ tags }) => {
  const dispatch = useDispatch();
  const text = useLocale();
  return (
    <Tooltip title={text.Buttons.REFRESH} placement="left" arrow>
      <Button onClick={() => refreshCache(dispatch, tags)}>
        <Icon iconName="Refresh" />
      </Button>
    </Tooltip>
  );
};

export default RefreshButton;
