import { Grid, Typography } from "@mui/material";

const TextFieldView = ({ label, value, chip }) => {
  // Styling
  const labelStyle = { fontWeight: "bold", display: "inline", fontSize: 16 };
  const dataStyle = { display: "inline", ml: "min(10px)", whiteSpace: "nowrap", fontSize: 16 };

  let varValue = "";
  chip ? (varValue = <Typography sx={dataStyle}>{value}</Typography>) : (varValue = value);

  return (
    <Grid item alignItems={"center"} sx={{ flexDirection: "column" }}>
      <Typography noWrap sx={labelStyle}>
        {label}
      </Typography>
      : {varValue}
    </Grid>
  );
};

export default TextFieldView;
