import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocale } from "../../../hooks";
import { useHandleError } from "../../../hooks/useHandleError";
import useHandleSuccess from "../../../hooks/useHandleSuccess";
import { useConcreteMixDesignSchema } from "../../../schemas/concreteMixDesign.schema";
import {
  useGetConcreteMixDesignQuery,
  useUpdateConcreteMixDesignMutation,
} from "../../../services/concreteMixDesignApi";
import { getObjectChanges } from "../../../utils/form";
import { ConcreteMixDesignDialog } from "./ConcreteMixDesignDialog";

const ConcreteMixDesignEdit = () => {
  const { id: workOrderID, concreteMixDesignID } = useParams();

  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();

  const { concreteMixDesignEditSchema } = useConcreteMixDesignSchema();

  const [updateConcreteMixDesign] = useUpdateConcreteMixDesignMutation();

  const text = useLocale();

  const concreteMixDesignQuery = useGetConcreteMixDesignQuery(concreteMixDesignID);

  const [initialValues, setInitialValues] = useState({
    workOrderID: workOrderID,
    supplierOrganisation: "",
    plant: "",
    formula: "",
    specifiedConcreteClass: "",
    concreteElementLocation: "",
    specifiedCompressiveStrength: "",
    csaExposureClass: "",
    minAirContent: "",
    maxAirContent: "",
    slump: "",
    slumpRange: "",
    plasticizer: "",
    plasticizerRange: "",
    additionalInformation: "",
    nominalAggregateSize: "",
  });

  const navigate = useNavigate();

  const formikConcreteMixDesign = useFormik({
    initialValues: initialValues,
    validationSchema: concreteMixDesignEditSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const specifiedConcreteClass =
        values.csaExposureClass +
        ", " +
        values.specifiedCompressiveStrength +
        " Mpa," +
        values.nominalAggregateSize +
        " mm," +
        values.minAirContent +
        "-" +
        values.maxAirContent +
        " AEA," +
        values.slump +
        " ± " +
        values.slumpRange;
      try {
        const changes = getObjectChanges({ ...values, specifiedConcreteClass }, initialValues);
        if (Object.keys(changes).length !== 0) {
          await updateConcreteMixDesign({
            changes: changes,
            id: concreteMixDesignID,
          }).unwrap();
          handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.ConcreteMixDesign.SUCCESSFUL_UPDATE);
          navigate(-1);
        }
      } catch (e) {
        console.log(e);
        handleErrorResponse(e, text.ConcreteMixDesign.UNSUCCESSFUL_UPDATE);
      }
    },
  });

  const handleSubmit = () => {
    formikConcreteMixDesign.handleSubmit();
  };

  useEffect(() => {
    if (!concreteMixDesignQuery.isLoading) {
      setInitialValues({
        workOrderID: concreteMixDesignQuery.data.payload.workOrderID ?? "",
        supplierOrganisation: concreteMixDesignQuery.data.payload.supplierOrganisation ?? "",
        plant: concreteMixDesignQuery.data.payload.plant ?? "",
        formula: concreteMixDesignQuery.data.payload.formula ?? "",
        specifiedConcreteClass: concreteMixDesignQuery.data.payload.specifiedConcreteClass ?? "",
        concreteElementLocation: concreteMixDesignQuery.data.payload.concreteElementLocation ?? "",
        specifiedCompressiveStrength:
          concreteMixDesignQuery.data.payload.specifiedCompressiveStrength ?? "",
        csaExposureClass: concreteMixDesignQuery.data.payload.csaExposureClass ?? "",
        minAirContent: concreteMixDesignQuery.data.payload.minAirContent ?? "",
        maxAirContent: concreteMixDesignQuery.data.payload.maxAirContent ?? "",
        slump: concreteMixDesignQuery.data.payload.slump ?? "",
        slumpRange: concreteMixDesignQuery.data.payload.slumpRange ?? "",
        plasticizer: concreteMixDesignQuery.data.payload.plasticizer ?? "",
        plasticizerRange: concreteMixDesignQuery.data.payload.plasticizerRange ?? "",
        additionalInformation: concreteMixDesignQuery.data.payload.additionalInformation ?? "",
        nominalAggregateSize: concreteMixDesignQuery.data.payload.nominalAggregateSize ?? "",
      });
    }
  }, [concreteMixDesignQuery.isLoading]);

  return (
    <ConcreteMixDesignDialog
      formikConcreteMixDesign={formikConcreteMixDesign}
      handleSubmit={handleSubmit}
    />
  );
};

export default ConcreteMixDesignEdit;
