import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomDatePicker from '../customMUI/CustomDatePicker';
import CustomTimeDurationPicker from '../customMUI/CustomTimeDurationPicker';

import { useParams } from 'react-router-dom';
import Windy from '../../assets/weather/airware_FILL0_wght400_GRAD0_opsz48.svg';
import SunnyIcon from '../../assets/weather/clear_day_FILL0_wght400_GRAD0_opsz48.svg';
import Cloudy from '../../assets/weather/cloudy_FILL0_wght400_GRAD0_opsz48.svg';
import Snowy from '../../assets/weather/cloudy_snowing_FILL0_wght400_GRAD0_opsz48.svg';
import PartlyCloudy from '../../assets/weather/partly_cloudy_day_FILL0_wght400_GRAD0_opsz48.svg';
import Rainy from '../../assets/weather/rainy_FILL0_wght400_GRAD0_opsz48.svg';
import { PreTestingChecklist, Weather } from '../../constants/options';
import useLocale from '../../hooks/useLocale';
import { useGetWorkOrderQuery } from '../../services/workordersApi';
import useRequest from '../../hooks/useRequest';

const FormFieldInformation = ({ formik, isConcreteReportEdit }) => {
  // Get Field Technician from work order
  const { id } = useParams();
  const workOrderQuery = useRequest(useGetWorkOrderQuery, id);
  const text = useLocale();
  const weatherCheckboxStyle = { pl: 4, pr: 4, m: 0 };
  const checkBoxStyle = { border: '1px solid grey', mt: 2, borderRadius: 1, ml: 0 };
  const subTitleStyle = { fontSize: 20, fontWeight: 'bold' };
  const [isCheck, setIscheck] = useState(false);
  const handleChecklistChange = (newValue, checklistItem) => {
    if (newValue === true) {
      formik.setFieldValue('checklist', [...formik.values.checklist, checklistItem]);
    } else {
      const newValues = formik.values.checklist.filter((value) => value !== checklistItem);

      formik.setFieldValue('checklist', newValues);
    }
  };
  const handleWeatherChange = (newValue, weather) => {
    if (newValue === true) {
      formik.setFieldValue('weather', [...formik.values.weather, weather]);
    } else {
      const newValues = formik.values.weather.filter((value) => value !== weather);
      formik.setFieldValue('weather', newValues);
    }
  };


  useEffect(() => {
    if (isCheck) {
      formik.setValues({
        ...formik.values,
        subContractorContactEmail: workOrderQuery.data.payload.project.onSiteContactEmail,
        subContractorContactName: workOrderQuery.data.payload.project.onSiteContactName,
        subContractorContactTelephone: workOrderQuery.data.payload.project.onSiteContactTelephone,
        subContractorOrganisation: workOrderQuery.data.payload.project.onSiteContactOrganisation
      })
      return
    }
    formik.setValues({
      ...formik.values,
      subContractorContactEmail: '',
      subContractorContactName: '',
      subContractorContactTelephone: '',
      subContractorOrganisation: ''
    })

  }, [isCheck])
  return (
    <React.Fragment>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          {/* Pre-Testing Details */}
          <Grid container direction="column" sx={{ mb: 4 }}>
            <Typography sx={subTitleStyle}>
              {text.ConcreteFieldInformation.SUBTITLE_PRETESTING_DETAILS}
            </Typography>
            <Typography sx={{ bgcolor: '#f0f0f0', borderRadius: 1, p: 1, mt: 2 }}>
              {text.ConcreteFieldInformation.LABEL_PREPARED_BY}{' '}
              {workOrderQuery.data?.payload.fieldTechnician.name}
            </Typography>
            <CustomDatePicker
              label={text.ConcreteFieldInformation.LABEL_DATE_OF_VISIT}
              initialValue={formik.initialValues.dateOfVisit}
              name="dateOfVisit"
              sx={{ mt: 2, display: 'flex', flex: 1 }}
              onChange={(name, value) => formik.setFieldValue(name, value)}
            />
            <FormHelperText
              error={formik.touched.dateOfVisit && formik.errors.dateOfVisit ? true : false}
            >
              {formik.errors.dateOfVisit}
            </FormHelperText>
            <TextField
              label={text.ConcreteFieldInformation.LABEL_EQUIPMENT_NUMBER}
              value={formik.values.equipmentNumber}
              name={'equipmentNumber'}
              onChange={formik.handleChange}
              sx={{ mt: 2, display: 'flex', flex: 1 }}
              type={'text'}
            />

            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label={text.Projects.LABEL_TRANSPORTATION_TIME}
                  value={formik.values.transportationTime}
                  name={'transportationTime'}
                  onChange={formik.handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
                <FormHelperText
                  error={
                    formik.touched.transportationTime && formik.errors.transportationTime
                      ? true
                      : false
                  }
                >
                  {formik.errors.transportationTime}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label={text.ConcreteFieldInformation.LABEL_MILEAGE}
                  value={formik.values.mileage}
                  name={'mileage'}
                  sx={{ mt: 2, display: 'flex', flex: 1 }}
                  type={'number'}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    // Validate that the entered value is a non-negative number
                    if (/^\d+$/.test(newValue) || newValue === '') {
                      formik.setFieldValue('mileage', newValue)
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* End of Pre-Testing Details */}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          {/* Pre-Testing Checklist */}
          <Grid container direction="column">
            <Typography sx={subTitleStyle}>
              {text.ConcreteFieldInformation.SUBTITLE_PRETESTING_CHECKLIST}
            </Typography>
            <Typography sx={{ mt: 1 }}>{text.ConcreteFieldInformation.INFO_CHECKLIST}</Typography>

            <FormGroup sx={{ pr: 1 }}>
              <FormControlLabel
                onChange={(event, newValue) => {
                  handleChecklistChange(
                    newValue,
                    PreTestingChecklist.PERSONAL_PROTECTIVE_EQUIPMENT
                  );
                }}
                labelPlacement="start"
                label={text.ConcreteFieldInformation.LABEL_PERSONAL_PROTECTIVE_EQUIPMENT}
                control={
                  <Checkbox
                    checked={formik.values.checklist.includes(
                      PreTestingChecklist.PERSONAL_PROTECTIVE_EQUIPMENT
                    )}
                  />
                }
                sx={checkBoxStyle}
              />
              <FormControlLabel
                onChange={(event, newValue) => {
                  handleChecklistChange(newValue, PreTestingChecklist.REQUIRED_TRAINING);
                }}
                labelPlacement="start"
                label={text.ConcreteFieldInformation.LABEL_REQUIRED_TRAINING}
                control={
                  <Checkbox
                    checked={formik.values.checklist.includes(
                      PreTestingChecklist.REQUIRED_TRAINING
                    )}
                  />
                }
                sx={checkBoxStyle}
              />
              <FormControlLabel
                onChange={(event, newValue) => {
                  handleChecklistChange(
                    newValue,
                    PreTestingChecklist.COMPLETED_PRE_WORK_ASSESSMENT
                  );
                }}
                labelPlacement="start"
                label={text.ConcreteFieldInformation.LABEL_COMPLETED_PREWORK_ASSESSMENT}
                control={
                  <Checkbox
                    checked={formik.values.checklist.includes(
                      PreTestingChecklist.COMPLETED_PRE_WORK_ASSESSMENT
                    )}
                  />
                }
                sx={checkBoxStyle}
              />
              <FormControlLabel
                onChange={(event, newValue) => {
                  handleChecklistChange(newValue, PreTestingChecklist.JOB_SAFETY_ANALYSIS);
                }}
                labelPlacement="start"
                label={text.ConcreteFieldInformation.LABEL_JOB_SAFETY_ANALYSIS}
                control={
                  <Checkbox
                    checked={formik.values.checklist.includes(
                      PreTestingChecklist.JOB_SAFETY_ANALYSIS
                    )}
                  />
                }
                sx={checkBoxStyle}
              />
            </FormGroup>
          </Grid>
          {/* End of Pre-Testing Checklist */}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          {/* Hazards */}
          <Grid container direction="column">
            <Typography sx={{ mt: 5 }}>
              {text.ConcreteFieldInformation.LABEL_HAZARDS_QUESTION}
            </Typography>
            <TextField
              value={formik.values.unexpectedHazards}
              name={'unexpectedHazards'}
              onChange={formik.handleChange}
              sx={{ display: 'flex', flex: 1 }}
              type={'text'}
            />
            {/* End of Hazards */}

            {/* Weather */}
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ mb: 2 }}>
                {text.ConcreteFieldInformation.LABEL_SELECT_WEATHER_CONDITIONS}
              </Typography>
              <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={6} sm={4} md={4} lg={4}>
                  <FormControlLabel
                    onChange={(event, newValue) => {
                      handleWeatherChange(newValue, Weather.SUNNY);
                    }}
                    labelPlacement="start"
                    control={<Checkbox checked={formik.values.weather.includes(Weather.SUNNY)} />}
                    label={<img alt="Sunny" src={SunnyIcon} />}
                    sx={weatherCheckboxStyle}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4}>
                  <FormControlLabel
                    onChange={(event, newValue) => {
                      handleWeatherChange(newValue, Weather.PARTLY_CLOUDY);
                    }}
                    control={
                      <Checkbox checked={formik.values.weather.includes(Weather.PARTLY_CLOUDY)} />
                    }
                    labelPlacement="start"
                    label={<img alt="Partly Cloudy" src={PartlyCloudy} />}
                    sx={weatherCheckboxStyle}
                  />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4}>
                  <FormControlLabel
                    onChange={(event, newValue) => {
                      handleWeatherChange(newValue, Weather.CLOUDY);
                    }}
                    control={<Checkbox checked={formik.values.weather.includes(Weather.CLOUDY)} />}
                    labelPlacement="start"
                    sx={weatherCheckboxStyle}
                    label={<img alt="Cloudy" src={Cloudy} />}
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={4} lg={4}>
                  <FormControlLabel
                    onChange={(event, newValue) => {
                      handleWeatherChange(newValue, Weather.RAINY);
                    }}
                    control={<Checkbox checked={formik.values.weather.includes(Weather.RAINY)} />}
                    labelPlacement="start"
                    sx={weatherCheckboxStyle}
                    label={<img alt="Rainy" src={Rainy} />}
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={4} lg={4}>
                  <FormControlLabel
                    onChange={(event, newValue) => {
                      handleWeatherChange(newValue, Weather.SNOWY);
                    }}
                    control={<Checkbox checked={formik.values.weather.includes(Weather.SNOWY)} />}
                    labelPlacement="start"
                    sx={weatherCheckboxStyle}
                    label={<img alt="Snowy" src={Snowy} />}
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={4} lg={4}>
                  <FormControlLabel
                    onChange={(event, newValue) => {
                      handleWeatherChange(newValue, Weather.WINDY);
                    }}
                    control={<Checkbox checked={formik.values.weather.includes(Weather.WINDY)} />}
                    labelPlacement="start"
                    sx={weatherCheckboxStyle}
                    label={<img alt="Windy" src={Windy} />}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* End of Weather */}
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ p: 1 }} />
      <br />
      <Grid container>
        <Grid item alignSelf={'center'}>
          <Typography sx={subTitleStyle}>
            {text.ConcreteFieldInformation.REPRESENTATIVE_CONTACT_ON_SITE}
          </Typography>
        </Grid>
        {
          !isConcreteReportEdit &&
          <Grid container>
            <Grid item>
              <Checkbox onChange={(e) => {
                setIscheck(e.target.checked)
              }} />
            </Grid>
            <Grid item alignSelf={'center'}>
              <Typography sx={{
                fontSize: '17px',
              }}>
                {text.ConcreteFieldInformation.COPY_INFORMATION_FROM_PROJECT}
              </Typography>
            </Grid>
          </Grid>
        }
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextField
            label={text.ConcreteFieldInformation.LABEL_SUBCONTRACTOR_ORGANISATION}
            value={formik.values.subContractorOrganisation}
            name={'subContractorOrganisation'}
            onChange={formik.handleChange}
            sx={{ display: 'flex', flex: 1, mt: 2 }}
            type={'text'}
          />
          <TextField
            label={text.ConcreteFieldInformation.LABEL_SUBCONTRACTOR_NAME}
            value={formik.values.subContractorContactName}
            name={'subContractorContactName'}
            onChange={formik.handleChange}
            sx={{ display: 'flex', flex: 1, mt: 2 }}
            type={'text'}
          />
          <TextField
            label={text.ConcreteFieldInformation.LABEL_SUBCONTRACTOR_TELEPHONE}
            value={formik.values.subContractorContactTelephone}
            name={'subContractorContactTelephone'}
            onChange={formik.handleChange}
            sx={{ display: 'flex', flex: 1, mt: 2 }}
            type={'text'}
          />
          <TextField
            label={text.ConcreteFieldInformation.LABEL_SUBCONTRACTOR_EMAIL}
            value={formik.values.subContractorContactEmail}
            name={'subContractorContactEmail'}
            onChange={formik.handleChange}
            sx={{ display: 'flex', flex: 1, mt: 2 }}
            type={'text'}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FormFieldInformation;
