import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { FlexBetween, Icon } from "..";
import CpBreadcrumb from "./AppBreadcrumbs";
import { useNavigate } from "react-router-dom";

export const AppHeader = ({ title, subtitle, icon, bcrumb, AddTo }) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <FlexBetween>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box mb="30px">
            <Typography variant="h4" fontWeight="bold" sx={{ m: "0 0 5px 0" ,alignItems:'center',display:'flex'}}>
              <Icon iconName={icon} iconFor={"Header"} />
              {title}
            </Typography>
            {bcrumb ? (
              <CpBreadcrumb title={title} bc={bcrumb} />
            ) : (
              <Typography variant="h6" color="#666666">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      </FlexBetween>

      {AddTo ? (
        <Button
          onClick={() => navigate(AddTo)}
          variant="contained"
          size="large"
          color="primary"
          startIcon={<AddCircle />}
        >
          Add
        </Button>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default AppHeader;
