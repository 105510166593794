import { createAsyncThunk } from '@reduxjs/toolkit';
import actions from '../constants/actions';
import { api } from '../services/api';
import { useLogoutMutation } from '../services/authApi';
import { clearToken } from '../slices/authSlice';
import { clearLoading, setLoading } from '../slices/loadingSlice';
import { clearPermissions } from '../slices/permissionSlice';
import { clearSamplePickups } from '../slices/samplePickupSlice';
import { clearUser } from '../slices/userSlice';

const useLogout = () => {
  const [logout] = useLogoutMutation();
  const logoutThunk = createAsyncThunk(actions.AUTH_LOGOUT, async (params, thunkAPI) => {
    thunkAPI.dispatch(setLoading());
    await logout();

    // Reset state to initial state
    thunkAPI.dispatch(api.util.resetApiState());
    thunkAPI.dispatch(clearLoading());
    thunkAPI.dispatch(clearPermissions());
    thunkAPI.dispatch(clearSamplePickups());
    thunkAPI.dispatch(clearToken());
    thunkAPI.dispatch(clearUser());
  });
  return logoutThunk;
};

export default useLogout;
