// Routes for components
export const routes = {
  LOGIN: '/login',
  HOME: '/',

  PROJECTS: '/projects',
  PROJECT_ADD: '/projects/add',
  PROJECT_DETAILS: '/projects/details/:id',
  PROJECT_EDIT: '/projects/edit/:id',

  WORKORDERS: '/workorders',
  WORKORDER_ADD: '/workorders/add/',
  WORKORDER_DETAILS: '/workorders/details/:id',
  WORKORDER_DETAILS_ADD_CONCRETE_SEQUENCE: '/workorders/details/:id/add-concrete-sequence',
  WORKORDER_EDIT: '/workorders/edit/:id',
  WORKORDERS_ASSIGNED: '/workorders/assigned',

  WORKORDER_SAMPLE: '/workorders/samples/collector',
  WORKORDER_SAMPLE_COLLECTOR: '/workorders/samples/collector',
  // WORKORDER_SAMPLE_COLLECTOR: "/workorders/samples/collector/:id",

  CONCRETE_FIELD_REPORT_ADD: '/workorders/:id/concrete-field-report/add',
  CONCRETE_FIELD_REPORT_EDIT: '/workorders/:id/concrete-field-report/edit',

  CONCRETE_ADD: '/concrete-pours/add',
  CONCRETE_EDIT: '/concrete-pours/edit/:id',

  CONCRETES: '/concrete-pours',
  SAMPLES: '/samples',

  SAMPLE_PICKUPS: '/sample-pickups',
  SAMPLE_PICKUPS_ASSIGNED: '/sample-pickups/assigned',
  SAMPLE_PICKUP_DETAILS: '/sample-pickups/details/:id',
  SAMPLE_PICKUP_EDIT: '/sample-pickups/edit/:id',

  SAMPLES_ADD: '/samples/add',
  SAMPLES_DETAILS: '/samples/details/:id',
  SAMPLES_EDIT: '/samples/edit/:id',

  RESULTS: '/results',

  CLIENTS: '/clients',
  CLIENT_ADD: '/clients/add',
  CLIENT_DETAILS: '/clients/details/:id',
  CLIENT_EDIT: '/clients/edit/:id',

  CONCRETE_MIX_DESIGNS: '/concrete-mix-designs',
  CONCRETE_MIX_DESIGNS_ADD: '/concrete-mix-designs/add',
  CONCRETE_MIX_DESIGNS_ADD_CHANGE: '/concrete-mix-designs/edit-cache/:concreteMixDesignID',
  CONCRETE_MIX_DESIGNS_EDIT: '/concrete-mix-designs/edit/:concreteMixDesignID',

  CONCRETING_SEQUENCES: '/concreting-sequences',
  CONCRETING_SEQUENCES_ADD: '/concreting-sequences/add',
  CONCRETING_SEQUENCES_ADD_CHANGE: '/concreting-sequences/edit-cache/:concretingSequenceID',

  CYLINDERS: '/cylinders',
  CYLINDERS_ADD: '/cylinders/add',
  CYLINDERS_EDIT: '/cylinders/edit/:cylinderID',

  LAB_CYLINDERS: '/laboratory/cylinders',
  LAB_CYLINDERS_DETAILS: '/laboratory/sample/:id',

  USERS:'/users',
  USER_ADD: '/users/add/',

  DATA_VIEW:'/data-view',
  DATA_VIEW_EDIT:'/data-view/edit/:id',
  DATA_VIEW_EDIT_CONCRETE_MIX_DESIGN:'/data-view/concrete-mix-design/edit/:id',
  DATA_VIEW_EDIT_SAMPLE:'/data-view/sample/edit/:id',

  EQUIPMENT_SET:'/equipment-set',
  EQUIPMENT_SET_ADD: '/equipment-set/add',
};

export default routes;
