import React from 'react';
import { useDispatch } from 'react-redux';
import { useHandleError } from '../../hooks/useHandleError';
import useHandleSuccess from '../../hooks/useHandleSuccess';
import { setLoading, clearLoading } from '../../slices/loadingSlice';
import { Box, Button, Divider, IconButton, Modal, TextField, Typography } from '@mui/material';
import { useLocale } from '../../hooks';
import { useUpdateCylinderMutation } from '../../services/cylindersApi';
import Icon from '../Icon';

export const EditCylinderCustomIdDialog = ({
  open,
  valueCylinderCustomID,
  id,
  handleClose,
  setValueCylinderCustomID,
  enableCylinderCustomID,
  setEnableCylinderCustomID,
}) => {
  const dispatch = useDispatch();
  const text = useLocale();
  const [updateObj] = useUpdateCylinderMutation();
  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff !important',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    outline: 'none !important',
  };

  function handleChangeCylinderNumber(e) {
    setValueCylinderCustomID(e.target.value);
    setEnableCylinderCustomID(true);
    if (e.target.value.length > 0) {
      setEnableCylinderCustomID(false);
    }
  }
  async function updateRecord() {
    dispatch(setLoading());
    try {
      // Only find values that have changed
      let changes = { customCylinderID: valueCylinderCustomID };
      const data = { changes: changes, id: id };
      await updateObj(data);
      handleClose();
      handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.Cylinders.SUCCESSFUL_UPDATE);
    } catch (e) {
      console.log(e);
      handleClose();
      handleErrorResponse(e, text.Cylinders.UNSUCCESSFUL_UPDATE);
    }
    dispatch(clearLoading());
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between' }}>
          <Typography variant={'h6'} pb={2} sx={{ flex: 1 }}>
            {text.Cylinders.LABEL_CYLINDER_CUSTOM_ID}
          </Typography>

          <IconButton onClick={handleClose} variant="contained" sx={{ marginBottom: '14px' }}>
            <Icon iconName={'Cancel'} />
          </IconButton>
        </Box>
        <Divider mb={3} />
        <TextField
          label={text.Cylinders.LABEL_EDIT_CYLINDER_CUSTOM_ID}
          value={valueCylinderCustomID}
          name="customCylinderID"
          sx={{ mt: 2, display: 'flex', flex: 1 }}
          type={'text'}
          onChange={handleChangeCylinderNumber}
        />
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={updateRecord}
          disabled={enableCylinderCustomID}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};
