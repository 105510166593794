import { Autocomplete, Box, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocale } from '../../../hooks';


const FormViewLocation = ({ formik }) => {
    const text = useLocale();
    const labelStyle = { fontWeight: 'bold', fontSize: 18,mt:2 };

    useEffect(() => {
        formik.setTouched(true, false);
    }, []);

    return (
        <div >
            <Typography sx={labelStyle}>Location</Typography>
            
            <Grid item >
                <TextField
                    label={text.DataView.LOCATION}
                    value={formik.values.concreteTemperature}
                    name={'concreteTemperature'}
                    onChange={formik.handleChange}
                    sx={{ mt: 2, display: 'flex', flex: 1 }}
                    type={'number'}
                />
            </Grid>
           
        </div>
    );
};

export default FormViewLocation;
