// For variables and functions shared across ConcretingSequenceAdd and ConcretingSequenceAddChange

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ConcretePourTest, SampleType } from '../constants/options';
import { useConcretingSequenceSchema } from '../schemas/concretingSequence.schema';
import { useSampleSchema } from '../schemas/sample.schema';
import { removeSampleFromQueue } from '../slices/offlineQueueSlice';
import { getPopulated } from '../utils/form';
import { toast } from 'react-toastify';
import { useLocale } from '.';

const useConcretingSequenceAdd = ({
  submitLaboratoryCuredSample,
  submitFieldCuredSample,
  submitConcretingSequence,
}) => {
  /** Variables */
  const STEPS = 3;
  const text = useLocale();


  /** Hooks */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { sampleCreateSchema } = useSampleSchema();
  const { id } = useParams();

  /** State */
  const [submitted, setSubmitted] = useState(false);

  const [commonSampleFields, setCommonSampleFields] = useState({
    number: '',
    sizeOfSample: '',
    locationInStructure: '',
  });

  const [activeStep, setActiveStep] = useState(
    location.state?.activeStep ? location?.state?.activeStep : 0
  );
  const [concretingSequenceID, setConcretingSequenceID] = useState(null);
  const [laboratoryCuredSampleID, setLaboratoryCuredSampleID] = useState(null);
  const [fieldCuredSampleID, setFieldCuredSampleID] = useState(null);
  const [openLaboratorySample, setOpenLaboratorySample] = useState(false);
  const [openFieldSample, setOpenFieldSample] = useState(false);

  /** Dependent variables */
  const initialConcretingSequence = {
    workOrderID: id,
    loadNumber: '',
    truckNumber: '',
    ticketNumber: '',
    quantityTruckLoad: 0,
    quantityCumulative: 0,
    batchTime: '',
    arrivedOnSiteTime: '',
    startTime: '',
    endTime: '',
    concreteMixDesignID: '',
    airContent: 0,
    adjustedAirContent: null,
    waterAdditive: 0.0,
    spAdditive: 0.0,
    concretePourTest: ConcretePourTest.SLUMP_TEST,
    vsiValue: 0,
    t50Time: 0,
    slumpFlow: 0,
    slump: 0.0,
    adjustedSlump: null,
    ambientTemperature: 0.0,
    concreteTemperature: 0.0,
    conformity: '',
    additionalRemarks: '',
    collectSamples: false,
    authorizedBy: '',
    typeOfAdmixtures: '',
  };

  const initialLaboratoryCuredSample = {
    concretingSequenceID: concretingSequenceID,
    type: SampleType.LABORATORY_CURED,
    number: '',
    timeOfSampling: '',
    timeOfMoulding: '',
    samplingLocation: '',
    typeOfMould: 'PLASTIC',
    sizeOfMould: 'SIZE_100_200',
    curingConditions: [],
    workOrderID: id,
    sizeOfSample:'SIZE_20L'
  };

  const initialFieldCuredSample = {
    concretingSequenceID: concretingSequenceID,
    type: SampleType.FIELD_CURED,
    number: '',
    timeOfSampling: '',
    timeOfMoulding: '',
    samplingLocation: '',
    typeOfMould: 'PLASTIC',
    sizeOfMould: 'SIZE_100_200',
    curingConditions: [],
    workOrderID: id,
    sizeOfSample:'SIZE_20L'
  };

  /** Dependent State and Hooks */
  const [initialValuesConcretingSequence, setInitialValuesConcretingSequence] =
    useState(initialConcretingSequence);

  const [initialValuesLaboratoryCured, setInitialValuesLaboratoryCured] = useState(
    initialLaboratoryCuredSample
  );
  const [initialValuesFieldCured, setInitialValuesFieldCured] = useState(initialFieldCuredSample);

  const { concretingSequenceCreateSchema } = useConcretingSequenceSchema();

  /** Formik */
  const formikSamplesLaboratoryCured = useFormik({
    initialValues: initialValuesLaboratoryCured,
    enableReinitialize: true,
    validationSchema: sampleCreateSchema,

    onSubmit: (values, { setSubmitting }) => {
      const object = getPopulated(values);

      submitLaboratoryCuredSample(object);
      setSubmitting(false);
    },
  });

  const formikSamplesFieldCured = useFormik({
    initialValues: initialValuesFieldCured,
    enableReinitialize: true,
    validationSchema: sampleCreateSchema,
    onSubmit: (values, { setSubmitting }) => {
      const object = getPopulated(values);

      submitFieldCuredSample(object);
      setSubmitting(false);
    },
  });


  const formikConcretingSequence = useFormik({
    initialValues: initialValuesConcretingSequence,
    enableReinitialize: true,
    validationSchema: concretingSequenceCreateSchema,
    onSubmit: (values) => {
      const object = getPopulated(values);

      submitConcretingSequence(object);
    },
  });

  /** Functions */
  const resetState = () => {
    setConcretingSequenceID(null);
    setLaboratoryCuredSampleID(null);
    setFieldCuredSampleID(null);
    setCommonSampleFields({
      number: '',
      sizeOfSample: '',
      locationInStructure: '',
    });
    setActiveStep(0);
    setInitialValuesLaboratoryCured(initialLaboratoryCuredSample);
    setInitialValuesFieldCured(initialFieldCuredSample);
    setInitialValuesConcretingSequence(initialConcretingSequence);
  };

  const handleSaveSamples = () => {
    if (!formikConcretingSequence.values.collectSamples) {
      if (laboratoryCuredSampleID) {
        dispatch(removeSampleFromQueue(laboratoryCuredSampleID));
      }
      if (fieldCuredSampleID) {
        dispatch(removeSampleFromQueue(fieldCuredSampleID));
      }
    } else {
      if (laboratoryCuredSampleID) {
        formikSamplesLaboratoryCured.handleSubmit();
      }
      if (fieldCuredSampleID) {
        formikSamplesFieldCured.handleSubmit();
      }
    }
  };

  const handleSave = () => {
    if (activeStep === 0) {
      formikConcretingSequence.handleSubmit();
    } else if (activeStep === 1) {
      handleSaveSamples();
    }
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleContinue = async () => {
    if (activeStep < STEPS - 1) {
      // Run formik submission
      handleSave();

      const concretingSequenceErrors = Object.keys(formikConcretingSequence.errors).length > 0;

      if (activeStep === 0 && !concretingSequenceErrors) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else if (activeStep === 1 && commonSampleFields.number !== '') {
        if (laboratoryCuredSampleID && fieldCuredSampleID) {
          if (formikSamplesLaboratoryCured.isValid && formikSamplesFieldCured.isValid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        } else if (laboratoryCuredSampleID) {
          if (formikSamplesLaboratoryCured.isValid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        } else if (fieldCuredSampleID) {
          if (formikSamplesFieldCured.isValid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }else{
          toast.error(text.Samples.FIELD_SAMPLE_INFO);
        }
      }
    }
  };

  const handleSetCommonSampleField = (event) => {
    const value = event.target.value;

    setCommonSampleFields((previous) => {
      return { ...previous, [event.target.name]: value };
    });
    formikSamplesLaboratoryCured.setFieldValue(event.target.name, value);
    formikSamplesFieldCured.setFieldValue(event.target.name, value);
  };

  const handleSaveAndComplete = () => {
    handleSave();

    const concretingSequenceErrors = Object.keys(formikConcretingSequence.errors).length > 0;

    if (!concretingSequenceErrors) {
      // Reset state
      setSubmitted(true);

      // Close dialog
      navigate(-1);
    }
  };

  const handleRemoveLaboratorySample = () => {
    formikSamplesLaboratoryCured.resetForm();
    dispatch(removeSampleFromQueue(laboratoryCuredSampleID));
    setInitialValuesLaboratoryCured({
      ...initialLaboratoryCuredSample,
      number: commonSampleFields.number,
    });
    setLaboratoryCuredSampleID(null);
    setOpenLaboratorySample(false);
  };

  const handleRemoveFieldSample = () => {
    formikSamplesFieldCured.resetForm();
    dispatch(removeSampleFromQueue(fieldCuredSampleID));
    setInitialValuesFieldCured({
      ...initialFieldCuredSample,
      number: commonSampleFields.number,
    });
    setFieldCuredSampleID(null);

    setOpenFieldSample(false);
  };

  const handleAddLaboratorySample = () => {
    setOpenLaboratorySample(true);

    const id = uuidv4();
    setLaboratoryCuredSampleID(id);
  };

  const handleAddFieldSample = () => {
    setOpenFieldSample(true);

    const id = uuidv4();
    setFieldCuredSampleID(id);
  };

  useEffect(() => {

    setInitialValuesLaboratoryCured({
      ...initialValuesLaboratoryCured,
      number: commonSampleFields.number,
      locationInStructure: commonSampleFields.locationInStructure,
      timeOfMoulding: commonSampleFields.timeOfMoulding,
      timeOfSampling: commonSampleFields.timeOfSampling,
    });

    setInitialValuesFieldCured({
      ...initialValuesFieldCured,
      number: commonSampleFields.number,
      locationInStructure: commonSampleFields.locationInStructure,
      timeOfMoulding: commonSampleFields.timeOfMoulding,
      timeOfSampling: commonSampleFields.timeOfSampling,
    });
  }, [commonSampleFields]);

  useEffect(() => {
    setInitialValuesLaboratoryCured({
      ...initialValuesLaboratoryCured,
      concretingSequenceID: concretingSequenceID,
    });

    setInitialValuesFieldCured({
      ...initialValuesFieldCured,
      concretingSequenceID: concretingSequenceID,
    });
  }, [concretingSequenceID]);

  useEffect(() => {
    if (submitted) {
      resetState();
      setSubmitted(false);
    }
  }, [submitted]);

  return {
    STEPS,
    laboratoryCuredSampleID,
    setLaboratoryCuredSampleID,
    fieldCuredSampleID,
    setFieldCuredSampleID,
    commonSampleFields,
    setCommonSampleFields,
    activeStep,
    setActiveStep,
    submitted,
    setSubmitted,
    concretingSequenceID,
    setConcretingSequenceID,
    initialValuesConcretingSequence,
    setInitialValuesConcretingSequence,
    initialValuesLaboratoryCured,
    setInitialValuesLaboratoryCured,
    initialValuesFieldCured,
    setInitialValuesFieldCured,
    openLaboratorySample,
    setOpenLaboratorySample,
    openFieldSample,
    setOpenFieldSample,
    handleBack,
    handleContinue,
    handleSave,
    handleSaveAndComplete,
    handleSetCommonSampleField,
    handleRemoveLaboratorySample,
    handleRemoveFieldSample,
    handleAddLaboratorySample,
    handleAddFieldSample,
    formikConcretingSequence,
    formikSamplesLaboratoryCured,
    formikSamplesFieldCured,
  };
};

export default useConcretingSequenceAdd;
