import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConcretePourTest } from '../../constants/options';
import useLocale from '../../hooks/useLocale';
import { formType, selectAllTypeFromQueue } from '../../slices/offlineQueueSlice';
import CustomAutocomplete from '../customMUI/CustomAutocomplete';
import CustomTimePicker from '../customMUI/CustomTimePicker';

const FormConcretingSequence = ({ formik, isConcreteSequeceEdit, concreteMixDesignsQuery,fromWorkOrderDetails }) => {
  const text = useLocale();
  const { id: workOrderID } = useParams();
  // Handling Concrete Mix Design Formulas
  const concreteMixDesigns = useSelector((state) =>
    selectAllTypeFromQueue(state, formType.CONCRETE_MIX_DESIGN).filter(
      (item) => item.workOrderID === workOrderID
    )
  );
  // Handling Toggle Group for Concrete Pour Test Type
  const [concretePourTest, setConcretePourTest] = useState(formik.values.concretePourTest);

  useEffect(() => {
    setConcretePourTest(formik.values.concretePourTest || ConcretePourTest.SLUMP_TEST)
  }, [formik.values.concretePourTest])

  const handleChangeConcretePourTest = (event, newValue) => {
    if (newValue === ConcretePourTest.SLUMP_TEST) {
      formik.setFieldValue('concretePourTest', ConcretePourTest.SLUMP_TEST);

      // Reset values associated with slump flow test
      formik.setFieldValue('vsiValue', 0);
      formik.setFieldValue('t50Time', 0);
      formik.setFieldValue('slumpFlow', 0.0);
    } else if (newValue === ConcretePourTest.SLUMP_FLOW_TEST) {
      formik.setFieldValue('concretePourTest', ConcretePourTest.SLUMP_FLOW_TEST);

      // Reset values associated with slump test
      formik.setFieldValue('slump', 0.0);
      formik.setFieldValue('adjustedSlump', null);
    }
    setConcretePourTest(newValue);
  };

  // End of Handling Concrete Pour Test Type

  // Handling Toggle Group for conformity
  const CONFORMITY = 'conformity';
  const NON_CONFORMITY = 'non-conformity';

  const [conformity, setConformity] = useState('');

  const handleChangeConformity = (event, newValue) => {
    if (newValue === CONFORMITY) {
      formik.setFieldValue('conformity', true);
      setConformity(newValue);
    } else if (newValue === NON_CONFORMITY) {
      formik.setFieldValue('conformity', false);
      setConformity(newValue);
    }else{
      formik.setFieldValue('conformity', null);
      setConformity(null);
    }
  };

  useEffect(() => {
    if (formik.values.conformity === true) {
      setConformity(CONFORMITY);
      return
    }

    if (formik.values.conformity === false) {
      setConformity(NON_CONFORMITY);
      return
    }else{
      setConformity(null);
    }
   
  }, [formik.values.conformity]);

  // End of Handling Toggle Group for conformity

  // Styling
  const subtitleLargeStyle = { fontWeight: 'bold', fontSize: 18 };
  const subtitleMediumStyle = { fontStyle: 'italic' };
  // End of Styling

  const formikMemo = useMemo(()=>{
    return formik
  },[formik.values])

  return (
    <Box>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            label={text.ConcretingSequence.LABEL_LOAD_NUMBER}
            value={formik.values.loadNumber}
            name={'loadNumber'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'text'}
          />
          <TextField
            label={text.ConcretingSequence.LABEL_TRUCK_NUMBER}
            value={formik.values.truckNumber}
            name={'truckNumber'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'text'}
          />
          <TextField
            label={text.ConcretingSequence.LABEL_TICKET_NUMBER}
            value={formik.values.ticketNumber}
            name={'ticketNumber'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'text'}
          />
          <TextField
            label={text.ConcretingSequence.LABEL_QUANTITY_TRUCK_LOAD}
            value={formik.values.quantityTruckLoad}
            name={'quantityTruckLoad'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'number'}
            inputProps={{ min: 0 }}
          />
          <TextField
            label={text.ConcretingSequence.LABEL_QUANTITY_CUMULATIVE}
            value={formik.values.quantityCumulative}
            name={'quantityCumulative'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'number'}
            inputProps={{ min: 0 }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/* Batch Time and Time Arrived On-Site */}
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomTimePicker
              
                initialValue={isConcreteSequeceEdit ? formikMemo.values.batchTime : formik.initialValues.batchTime}
                name="batchTime"
                onChange={(name, value) => formik.setFieldValue(name, value)}
                sx={{ mt: 2, display: 'flex', flex: 1 }}
                label={text.ConcretingSequence.LABEL_BATCH_TIME}
              />
              <FormHelperText
                error={formik.touched.batchTime && formik.errors.batchTime ? true : false}
              >
                {formik.errors.batchTime}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomTimePicker
                initialValue={isConcreteSequeceEdit ? formikMemo.values.arrivedOnSiteTime : formik.initialValues.arrivedOnSiteTime}
                name="arrivedOnSiteTime"
                onChange={(name, value) => formik.setFieldValue(name, value)}
                sx={{ mt: 2, display: 'flex', flex: 1 }}
                label={text.ConcretingSequence.LABEL_ARRIVED_ON_SITE}
              />
              <FormHelperText
                error={
                  formik.touched.arrivedOnSiteTime && formik.errors.arrivedOnSiteTime ? true : false
                }
              >
                {formik.errors.arrivedOnSiteTime}
              </FormHelperText>
            </Grid>
          </Grid>
          {/* End of Batch Time and Time Arrived On-Site */}
          {/* Start and End Time */}
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomTimePicker
                initialValue={isConcreteSequeceEdit ? formikMemo.values.startTime : formik.initialValues.startTime}
                name="startTime"
                onChange={(name, value) => formik.setFieldValue(name, value)}
                sx={{ mt: 2, display: 'flex', flex: 1 }}
                label={text.ConcretingSequence.LABEL_START_TIME}
              />
              <FormHelperText
                error={formik.touched.startTime && formik.errors.startTime ? true : false}
              >
                {formik.errors.startTime}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomTimePicker
                initialValue={isConcreteSequeceEdit ? formikMemo.values.endTime : formik.initialValues.endTime}
                name="endTime"
                onChange={(name, value) => formik.setFieldValue(name, value)}
                sx={{ mt: 2, display: 'flex', flex: 1 }}
                label={text.ConcretingSequence.LABEL_END_TIME}
              />
              <FormHelperText
                error={formik.touched.endTime && formik.errors.endTime ? true : false}
              >
                {formik.errors.endTime}
              </FormHelperText>
            </Grid>
          </Grid>
          {/* End of Start and End Time */}

          {/* Concrete Mix Design Formulas */}
          <CustomAutocomplete
            label={text.ConcretingSequence.LABEL_CONCRETE_FORMULA}
            initialValue={formik.initialValues.concreteMixDesignID || formik.values.concreteMixDesignID}
            itemsArray={fromWorkOrderDetails ? concreteMixDesignsQuery?.data?.payload : isConcreteSequeceEdit ? concreteMixDesignsQuery : concreteMixDesigns}
            
            itemsMapper={(item) => {
              return { id: item.id, label: item.formula };
            }}
            sx={{ mt: 2 }}
            name="concreteMixDesignID"
            onChange={(name, value) => formik.setFieldValue(name, value)}
          />
          <FormHelperText
            error={
              formik.touched.concreteMixDesignID && formik.errors.concreteMixDesignID ? true : false
            }
          >
            {formik.errors.concreteMixDesignID}
          </FormHelperText>
        </Grid>
      </Grid>
      <Divider sx={{ p: 1, mb: 1 }} />
      <Typography sx={subtitleLargeStyle}>
        {text.ConcretingSequence.SUBTITLE_CONCRETE_POUR}
      </Typography>
      <br />

      {/* Air Content */}
      <Typography sx={subtitleMediumStyle}>
        {text.ConcretingSequence.SUBTITLE_AIR_CONTENT}
      </Typography>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            label={text.ConcretingSequence.LABEL_AIR_CONTENT}
            value={formik.values.airContent}
            name={'airContent'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'number'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            label={text.ConcretingSequence.LABEL_AFTER_ADJUSTMENT}
            value={formik.values.adjustedAirContent}
            name={'adjustedAirContent'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'number'}
          />
        </Grid>
      </Grid>
      <br />
      {/* Additives */}
      <Typography sx={subtitleMediumStyle}>{text.ConcretingSequence.SUBTITLE_ADDITIVES}</Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            label={text.ConcretingSequence.LABEL_WATER_ADDITIVE}
            value={formik.values.waterAdditive}
            name={'waterAdditive'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'number'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            label={text.ConcretingSequence.LABEL_AUTHORIZED_BY}
            value={formik.values.authorizedBy}
            name={'authorizedBy'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
          />
        </Grid>
      </Grid>

      <TextField
        label={text.ConcretingSequence.LABEL_TYPE_OF_ADMIXTURES}
        value={formik.values.typeOfAdmixtures}
        name={'typeOfAdmixtures'}
        onChange={formik.handleChange}
        sx={{ mt: 2, display: 'flex', flex: 1 }}
        multiline
        maxRows={4}
      />
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            label={text.ConcretingSequence.LABEL_SP_ADDITIVE}
            value={formik.values.spAdditive}
            name={'spAdditive'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'number'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      </Grid>
      <br />

      {/* Slump */}
      <Typography sx={subtitleMediumStyle}>{text.ConcretingSequence.SUBTITLE_SLUMP}</Typography>

      <ToggleButtonGroup
        sx={{ mt: 2 }}
        value={concretePourTest}
        exclusive
        onChange={handleChangeConcretePourTest}
      >
        <ToggleButton value={ConcretePourTest.SLUMP_TEST}>
          {text.ConcretingSequence.LABEL_SLUMP_TEST}
        </ToggleButton>
        <ToggleButton value={ConcretePourTest.SLUMP_FLOW_TEST}>
          {text.ConcretingSequence.LABEL_SLUMP_FLOW_TEST}
        </ToggleButton>
      </ToggleButtonGroup>
      {concretePourTest === ConcretePourTest.SLUMP_FLOW_TEST ? (
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={text.ConcretingSequence.LABEL_VSI_VALUE}
              value={formik.values.vsiValue}
              name={'vsiValue'}
              onChange={formik.handleChange}
              sx={{ mt: 2, display: 'flex', flex: 1 }}
              type={'number'}
              inputProps={{ min: 0, max: 3 }}
            />
            <FormHelperText
              error={formik.touched.vsiValue && formik.errors.vsiValue ? true : false}
            >
              {formik.errors.vsiValue}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={text.ConcretingSequence.LABEL_T50_TIME}
              value={formik.values.t50Time}
              name={'t50Time'}
              onChange={formik.handleChange}
              sx={{ mt: 2, display: 'flex', flex: 1 }}
              type={'number'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={text.ConcretingSequence.LABEL_SLUMP_FLOW}
              value={formik.values.slumpFlow}
              name={'slumpFlow'}
              onChange={formik.handleChange}
              sx={{ mt: 2, display: 'flex', flex: 1 }}
              type={'number'}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {concretePourTest === ConcretePourTest.SLUMP_TEST ? (
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={text.ConcretingSequence.LABEL_SLUMP}
              value={formik.values.slump}
              name={'slump'}
              onChange={formik.handleChange}
              sx={{ mt: 2, display: 'flex', flex: 1 }}
              type={'number'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              label={text.ConcretingSequence.LABEL_AFTER_ADJUSTMENT}
              value={formik.values.adjustedSlump}
              name={'adjustedSlump'}
              onChange={formik.handleChange}
              sx={{ mt: 2, display: 'flex', flex: 1 }}
              type={'number'}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      <br />
      {/* Temperature */}
      <Typography sx={subtitleMediumStyle}>
        {text.ConcretingSequence.SUBTITLE_TEMPERATURE}
      </Typography>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            label={text.ConcretingSequence.LABEL_AMBIENT_TEMPERATURE}
            value={formik.values.ambientTemperature}
            name={'ambientTemperature'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'number'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            label={text.ConcretingSequence.LABEL_CONCRETE_TEMPERATURE}
            value={formik.values.concreteTemperature}
            name={'concreteTemperature'}
            onChange={formik.handleChange}
            sx={{ mt: 2, display: 'flex', flex: 1 }}
            type={'number'}
          />
        </Grid>
      </Grid>

      <br />
      {/* Conformity */}
      <Typography sx={subtitleMediumStyle}>
        {text.ConcretingSequence.SUBTITLE_CONFORMITY}
      </Typography>

      <ToggleButtonGroup
        sx={{ mt: 2, mb: 2 }}
        value={conformity}
        exclusive
        onChange={handleChangeConformity}
      >
        <ToggleButton sx={{
          pr: 4, pl: 4,
          "&.Mui-selected": {
            backgroundColor: "green !important",
            color:'white !important'
          },
        }} value={CONFORMITY}>
          {text.ConcretingSequence.LABEL_CONFORMITY}
        </ToggleButton>
        <ToggleButton sx={{ pr: 4, pl: 4,
        "&.Mui-selected": {
          backgroundColor: "#ff0055 !important",
          color:'white !important'
        },
        }} value={NON_CONFORMITY}>
          {text.ConcretingSequence.LABEL_NON_CONFORMITY}
        </ToggleButton>
      </ToggleButtonGroup>
      <br />
      {/* Additional Remarks */}
      <Typography sx={subtitleMediumStyle}>
        {text.ConcretingSequence.SUBTTILE_ADDITIONAL_REMARKS}
      </Typography>
      <TextField
        label={text.ConcretingSequence.LABEL_EQUIPMENT_NUMBER}
        value={formik.values.additionalRemarks}
        name={'additionalRemarks'}
        onChange={formik.handleChange}
        sx={{ mt: 2, display: 'flex', flex: 1 }}
        type={'text'}
      />
      <FormHelperText
        error={
          formik.touched.additionalRemarks && formik.errors.additionalRemarks ? true : false
        }
      >
        {formik.errors.additionalRemarks}
      </FormHelperText>
      {/* Collecting Samples? */}
      <Typography sx={{ mb: 2, mt: 2 }}>
        {text.ConcretingSequence.LABEL_COLLECT_SAMPLES_QUESTION}
      </Typography>

      <ToggleButtonGroup
        value={formik.values.collectSamples}
        // name={'collectSamples'}
        onChange={(event, value) => {
          return formik.setFieldValue('collectSamples', value);
        }}
        exclusive
      >
        <ToggleButton value={false} sx={{ pl: 4, pr: 4 }}>
          {text.NO}
        </ToggleButton>

        <ToggleButton value={true} sx={{ pl: 4, pr: 4 }}>
          {text.YES}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default React.memo(FormConcretingSequence);
