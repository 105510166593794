import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useCylinderSchema } from "../../schemas/cylinder.schema";
import FormCylindersLab from "../forms/FormCylindersLab";
import { DateTime } from "luxon";
import { useLocale } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { selectLocale } from "../../slices/localeSlice";
import { useUpdateCylinderMutation } from "../../services/cylindersApi";
import { getObjectChanges } from "../../utils/form";
import { toast } from "react-toastify";
import { addNotification } from "../../slices/notificationSlice";
import { useHandleError } from "../../hooks/useHandleError";
import useHandleSuccess from "../../hooks/useHandleSuccess";

const CylinderRecord = ({ cylinder, fields, setFields, mindate }) => {
  const { cylinderEditSchema } = useCylinderSchema();
  const [update] = useUpdateCylinderMutation();

  const locale = useSelector(selectLocale);

  const text = useLocale();

  const { handleErrorResponse } = useHandleError();

  const { handleSuccessResponse } = useHandleSuccess();

  const [dateToBeTested, setDateToBeTested] = useState("");

  const [initialValues, setInitialValues] = useState({
    customCylinderID: "",
    daysToBeCured: "",
    mass: "",
    length: "",
    diameter: "",
    unitWeight: "",
    load: "",
    typeOfFracture: "",
    compressiveStrength: "",
    required: true,
    conformity: true,
    status: "",
    additionalInformation: ""
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: cylinderEditSchema,
    onSubmit: async (values) => {
      try {
        const changes = getObjectChanges(values, initialValues);
        const data = { changes: changes, id: cylinder.id };
        await update(data).unwrap();
        formik.resetForm({ values });
        handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.Cylinders.SUCCESSFUL_UPDATE);
      } catch (e) {
        handleErrorResponse(e, text.Cylinders.UNSUCCESSFUL_UPDATE);
      }
    },
  });

  useEffect(() => {
    const { mass, length, diameter } = cylinder;
    let unitWeight;
    if (mass && length && diameter) {
      // Convert length and diameter from mm to meters
      const L = length / 1000;
      const D = diameter / 1000;
      const PI = 3.1415;
      unitWeight = mass / (L * PI * (D / 2) * (D / 2));
      unitWeight = unitWeight.toFixed(1);
    }

    setInitialValues({
      customCylinderID: cylinder.customCylinderID ? cylinder.customCylinderID : "",
      daysToBeCured: cylinder.daysToBeCured,
      mass: cylinder.mass ? cylinder.mass : "",
      length: cylinder.length ? cylinder.length : "",
      diameter: cylinder.diameter ? cylinder.diameter : "",
      unitWeight: unitWeight,
      load: cylinder.load ? cylinder.load : "",
      typeOfFracture: cylinder.typeOfFracture ? cylinder.typeOfFracture : "",
      compressiveStrength: cylinder.compressiveStrength ? cylinder.compressiveStrength : "",
      required:
        cylinder.required === null || cylinder.required === undefined ? true : cylinder.required,
      conformity:
        cylinder.conformity === null || cylinder.conformity === undefined
          ? true
          : cylinder.conformity,
      status: cylinder.status ? cylinder.status : "",
      additionalInformation: cylinder.additionalInformation ?? ""
    });
  }, [cylinder]);

  //calculated unit Weight
  useEffect(() => {
    const { mass, length, diameter } = formik.values;
    if (mass && length && diameter) {
      // Convert length and diameter from mm to meters
      const L = length / 1000;
      const D = diameter / 1000;
      const PI = 3.1415;
      const unitWeight = mass / (L * PI * (D / 2) * (D / 2));
      formik.setValues({ ...formik.values, unitWeight: unitWeight.toFixed(1) });
    }
  }, [formik.values.mass, formik.values.length, formik.values.diameter]);

  useEffect(() => {
    if (formik.dirty) {
      setFields({ ...fields, [cylinder.id]: true });
    } else {
      setFields({ ...fields, [cylinder.id]: false });
    }
  }, [formik.dirty]);

  // Calculate the date to be tested
  useEffect(() => {
    if (cylinder) {
      if (formik.values.daysToBeCured) {
        const dateCastedISO =
          cylinder.sample.concretingSequence.workOrder.concreteFieldInformation.dateOfVisit;
        const dateTimeObject = DateTime.fromISO(dateCastedISO, { zone: "local" }).plus({
          days: formik.values.daysToBeCured,
        });
        const dateISO = dateTimeObject.toLocaleString(DateTime.DATE_FULL, { locale: locale });
        const testedDate = new Date(dateISO);
        testedDate.setDate(testedDate.getDate() + 1);
        formik.setValues({ ...formik.values, dateToBeTested: testedDate.toISOString() });
        setDateToBeTested(dateISO);
      } else {
        setDateToBeTested("");
        formik.setValues({ ...formik.values, dateToBeTested: "" });
      }
    }
  }, [formik.values.daysToBeCured]);

  return (
    <React.Fragment>
      <FormCylindersLab
        mindate={mindate}
        formik={formik}
        dateToBeTested={dateToBeTested}
        cylinderID={cylinder.id}
      />
    </React.Fragment>
  );
};

export default CylinderRecord;
