import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearLoading } from '../../../slices/loadingSlice';
import { MobileResponsiveDialog } from '../../../components/MobileResponsiveDialog';
import { SampleEditDialogue } from '../components/SampleEditDialogue';

const FormViewCylinderSampleEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        
        navigate(-1);
      } catch (e) {
        console.log(e);
      }
      dispatch(clearLoading());
    },
  });

 
  return (
    <React.Fragment>
      <MobileResponsiveDialog fullWidth={true} open={true}>
        <SampleEditDialogue
          formik={formik}
          handleSubmit={formik.handleSubmit}
          handleClose={() => navigate(-1)}
        />
      </MobileResponsiveDialog>
    </React.Fragment>
  );
};

export default FormViewCylinderSampleEdit;
