import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useLocale } from '../../hooks';

import { useDispatch } from 'react-redux';
import { useConcreteMixDesignSchema } from '../../schemas/concreteMixDesign.schema';
import { v4 as uuidv4 } from "uuid";
import { getPopulated } from '../../utils/form';
import { ConcreteMixDesignDialog } from '../../components/ConcreteFieldReport/ConcreteMixDesigns/ConcreteMixDesignDialog';
import { useAddConcreteMixDesignMutation, useGetConcreteMixDesignsQuery } from '../../services/concreteMixDesignApi';
import useConcretingSequenceAdd from '../../hooks/useConcretingSequenceAdd';
import { ConcretingSequenceDialog } from '../../components/ConcreteFieldReport/ConcretingSequence/ConcretingSequenceDialog';
import useRequest from '../../hooks/useRequest';
import { useAddConcretingSequenceMutation } from '../../services/concretingSequenceApi';
import { useAddSampleMutation } from '../../services/samplesApi';
import { addToQueue, formType } from '../../slices/offlineQueueSlice';
function WorkOrderConcreteSequenceAdd({ concretingSequencesQuery }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    STEPS,
    laboratoryCuredSampleID,
    setLaboratoryCuredSampleID,
    fieldCuredSampleID,
    setFieldCuredSampleID,
    commonSampleFields,
    setCommonSampleFields,
    activeStep,
    setActiveStep,
    submitted,
    setSubmitted,
    concretingSequenceID,
    setConcretingSequenceID,
    initialValuesConcretingSequence,
    setInitialValuesConcretingSequence,
    initialValuesLaboratoryCured,
    setInitialValuesLaboratoryCured,
    initialValuesFieldCured,
    setInitialValuesFieldCured,
    openLaboratorySample,
    setOpenLaboratorySample,
    openFieldSample,
    setOpenFieldSample,
    handleBack,
    handleContinue,
    handleSave,
    handleSaveAndComplete,
    handleSetCommonSampleField,
    handleRemoveLaboratorySample,
    handleRemoveFieldSample,
    handleAddLaboratorySample,
    handleAddFieldSample,
    formikConcretingSequence,
    formikSamplesLaboratoryCured,
    formikSamplesFieldCured,
  } = useConcretingSequenceAdd({
    submitConcretingSequence: submitConcretingSequence,
    submitLaboratoryCuredSample: submitLaboratoryCuredSample,
    submitFieldCuredSample: submitFieldCuredSample,
  });
  const [addConcreteSeq] = useAddConcretingSequenceMutation();
  const [addSample] = useAddSampleMutation();
  useEffect(() => {
    setInitialValuesLaboratoryCured({
      ...initialValuesLaboratoryCured,
      concretingSequenceID: concretingSequenceID,
    });
    setInitialValuesFieldCured({
      ...initialValuesFieldCured,
      concretingSequenceID: concretingSequenceID,
    });
  }, [concretingSequenceID]);

  async function submitLaboratoryCuredSample(values) {
    const req = await addSample(values).unwrap();
    if (req?.payload?.id) {
      setLaboratoryCuredSampleID(req?.payload?.id);
      dispatch(
        addToQueue({
          id: req?.payload?.id,
          type: formType.SAMPLE,
          data: { ...values, id: req?.payload?.id },
        })
      );
    }
    // Need to set initial values for select boxes
    setInitialValuesLaboratoryCured(values);
  }


  async function submitFieldCuredSample(values) {
    const req = await addSample(values).unwrap();
    if (req?.payload?.id) {
      setFieldCuredSampleID(req?.payload?.id);
      dispatch(
        addToQueue({
          id: req?.payload?.id,
          type: formType.SAMPLE,
          data: { ...values, id: req?.payload?.id },
        })
      );
    }
    // Need to set initial values for select boxes
    setInitialValuesFieldCured(values);
  }

  async function submitConcretingSequence(values) {
    let id = null;
    if (concretingSequenceID === null) {
      id = uuidv4();
      setConcretingSequenceID(id);
    } else {
      id = concretingSequenceID;
    }
    const req = await addConcreteSeq(values).unwrap();
    if (req.payload.id) {
      setConcretingSequenceID(req.payload.id);
    }
    setInitialValuesConcretingSequence(values);
  }

  const concreteMixDesignsQuery = useRequest(useGetConcreteMixDesignsQuery, {
    'filter[workOrderID]': `equals,${id}`,
  });

  return (
    <React.Fragment>

        <ConcretingSequenceDialog
          formikConcretingSequence={formikConcretingSequence}
          formikSamplesLaboratoryCured={formikSamplesLaboratoryCured}
          formikSamplesFieldCured={formikSamplesFieldCured}
          laboratoryCuredSampleID={laboratoryCuredSampleID}
          fieldCuredSampleID={fieldCuredSampleID}
          commonSampleFields={commonSampleFields}
          setCommonSampleFields={setCommonSampleFields}
          handleSetCommonSampleField={handleSetCommonSampleField}
          openLaboratorySample={openLaboratorySample}
          openFieldSample={openFieldSample}
          handleSaveAndComplete={handleSaveAndComplete}
          handleContinue={handleContinue}
          handleBack={handleBack}
          handleRemoveLaboratorySample={handleRemoveLaboratorySample}
          handleRemoveFieldSample={handleRemoveFieldSample}
          handleAddLaboratorySample={handleAddLaboratorySample}
          handleAddFieldSample={handleAddFieldSample}
          activeStep={activeStep}
          steps={STEPS}
          isOpen={true}
          onClose={() => navigate(-1)}
          onOpen={(location) => navigate(location.pathname)}
          concreteMixDesignsQuery={concreteMixDesignsQuery}
          fromWorkOrderDetails={true}
        />
    </React.Fragment>
  );
}

export default WorkOrderConcreteSequenceAdd;
