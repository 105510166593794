import React from 'react';
import { SamplePickupStatus } from '../../../constants/options';
import PermissionGate from '../../../components/PermissionGate';
import { useSelector } from 'react-redux';
import { selectPermissions } from '../../../slices/permissionSlice';
import { featurePermissions } from '../../../constants/permissions';
import { Box, Button } from '@mui/material';
import { useHandleError } from '../../../hooks/useHandleError';
import useHandleSuccess from '../../../hooks/useHandleSuccess';
import {
  useUpdateSamplePickupDeliveryMutation,
  useUpdateSamplePickupMutation,
} from '../../../services/samplePickUpApi';
import { useLocale } from '../../../hooks';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { selectUser } from '../../../slices/userSlice';
import useFormatDateTime from '../../../hooks/useFormatDateTime';

const ConfirmDeliveryButton = ({ samplePickupQuery, sx }) => {
  const text = useLocale();
  const { id } = useParams();
  const userPermissions = useSelector(selectPermissions);
  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();
  const [updateSamplePickup] = useUpdateSamplePickupDeliveryMutation();
  const user = useSelector(selectUser);
  const {formatDateTime} = useFormatDateTime();
  const confirmDelivery = async () => {
    try {
      const res = await updateSamplePickup({
        changes: {
          receivedDateTime: DateTime.now().toISO(),
          status: SamplePickupStatus.DELIVERY_COMPLETED,
          additionalInformation: samplePickupQuery.data?.payload.status === SamplePickupStatus.PENDING_PICKUP ? `Sample delivery confirmed by lab (${user.name} ${formatDateTime(DateTime.utc().toISO())}) on behalf of Field Technician.`: undefined
        },
        id: id,
      }).unwrap();
      console.log(res);
      handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.SamplePickups.SUCCESSFUL_UPDATE);
    } catch (e) {
      handleErrorResponse(e, text.SamplePickups.UNSUCCESSFUL_UPDATE);
    }
  };
  return (
    <React.Fragment>
      {samplePickupQuery.data?.payload.status === SamplePickupStatus.SAMPLES_DELIVERED || samplePickupQuery.data?.payload.status === SamplePickupStatus.PENDING_PICKUP   ? (
        <PermissionGate
          userPermissions={userPermissions}
          componentPermissions={[featurePermissions.CONFIRM_SAMPLE_DELIVERY]}
          fallback={
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={confirmDelivery} sx={{ ...sx }} disabled>
                {text.CONFIRM_DELIVERY}
              </Button>
            </Box>
          }
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={confirmDelivery} sx={{ ...sx }}>
              {text.CONFIRM_DELIVERY}
            </Button>
          </Box>
        </PermissionGate>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ConfirmDeliveryButton;
