import React, { useState } from 'react';
import { CustomDataGrid, FlexBetween, Header } from '../../components';
import StatusChip from '../../components/StatusChip';
import routes from '../../constants/routes';
import { useGetElement, useLocale } from '../../hooks';
import { useFormatDateTime } from '../../hooks/useFormatDateTime';
import { useGetWorkOrdersQuery } from '../../services/workordersApi';
import tags from '../../services/tags';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TextField, Autocomplete, Card, CardContent, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddCircle } from '@mui/icons-material';
import useRequest from '../../hooks/useRequest';
import { Duration } from 'luxon';

function WorkOrders() {
  const text = useLocale();
  const navItem = useGetElement(text.WorkOrders.NAME);
  const { formatDate, formatTime } = useFormatDateTime();
  const navigate = useNavigate();
  const [status, setStatus] = useState(text.WorkOrders.ACTIVE_STATUS);
  const workordersQueryActive = useRequest(useGetWorkOrdersQuery, {
    'filter[workOrderStatus]': `notIn,Closed,Completed`,
  });
  const workordersQueryClosed = useRequest(useGetWorkOrdersQuery, {
    'filter[workOrderStatus]': `In,Closed,Completed`,
  });
  // READ Functions
  // MODAL
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };
  const typeInsp = [
    { label: 'Concrete', disable: 'n' },
    { label: 'Compaction and Concrete', disable: 'y' },
    { label: 'Compaction and Paving', disable: 'y' },
  ];

  const handleChangeWorkOrderStatus = (event, newValue) => {
    setStatus(newValue);
  };

  const columns = [
    {
      field: 'projectCode',
      headerName: text.WorkOrders.DGPROJECTCODE,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return params.row.project.projectCode;
      },
    },
    {
      field: 'projectName',
      headerName: text.WorkOrders.DGPROJECTNAME,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return params.row.project.projectName;
      },
    },

    { field: 'inspectionType', headerName: text.WorkOrdersDataGrid.TYPE, minWidth: 150, flex: 1 },

    {
      field: 'fieldTechnician',
      headerName: text.WorkOrders.DGfieldTechnician,
      flex: 1,
      minWidth: 220,
      valueGetter: (params) => {
        return params.row.fieldTechnician ? params.row.fieldTechnician.name : "";
      },
    },
    { field: 'workLocation', headerName: text.WorkOrdersDataGrid.WORK_LOCATION, minWidth: 220, flex: 1 },
    {
      field: 'siteVisitDate',
      headerName: text.WorkOrders.DGDate,
      flex: 1,
      minWidth: 160,
      valueFormatter: (params) => formatDate(params.value),
    },

    {
      field: 'arrivalTime',
      headerName: 'Arrival Time On Site',
      flex: 1,
      minWidth: 160,
      valueFormatter: (params) => formatTime(params.value),
    },

    {
      field: 'siteVisitType',
      headerName: 'Site Visit Type',
      flex: 1,
      minWidth: 160,
      valueFormatter: (params) => text.WorkOrderVisitTypes[params.value],
    },
    {
      field: 'transportationTime',
      headerName: text.WorkOrders.DGTRANSPORTATIONTIME,
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return params.row.project.transportationTime
      },
    },
    {
      field: 'mileage',
      headerName: text.WorkOrders.DGTRANSPORTATIONMILEAGE,
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return params.row.project.mileage;
      },
    },
    // {
    //   field: 'site_address3',
    //   headerName: text.WorkOrders.DGStatus,
    //   flex: 1,
    //   minWidth: 140,
    //   renderCell: (params) => {
    //     return <StatusChip status="ACTIVE" />;
    //   },
    // },

    // {
    //   field: 'site_address4',
    //   headerName: text.WorkOrders.DGLabStatus,
    //   flex: 1,
    //   minWidth: 140,
    //   renderCell: (params) => {
    //     return <StatusChip status="ACTIVE" />;
    //   },
    // },
    // {
    //   field: 'status',
    //   headerName: text.WorkOrders.DGWOStatus,
    //   flex: 1,
    //   minWidth: 140,
    //   renderCell: (params) => {
    //     return <StatusChip status={params.row.status} />;
    //   },
    // },
    {
      field: 'status',
      headerName: text.WorkOrders.DGWOStatus,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return <p style={params.row.workOrderStatus === "Closed" ? { color: 'green', fontWeight: 'bold' } : { color: '#FFA500', fontWeight: 'bold' }}>{params.row.workOrderStatus}</p>;
      },
      valueGetter:(params)=>{
        return params.row.workOrderStatus 
      }
    },
  ];

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.WorkOrders.TABLENAME} />
        <Button
          onClick={() => {
            navigate(routes.WORKORDER_ADD)
          }}
          variant="contained"
          size="large"
          color="primary"
          startIcon={<AddCircle />}
        >
          Add
        </Button>
      </FlexBetween>
      <Box>
        <Card>
          <CardContent>
            <ToggleButtonGroup
              sx={{ my: 2, height: '40px' }}
              value={status}
              exclusive
              onChange={handleChangeWorkOrderStatus}
            >
              <ToggleButton value={text.WorkOrders.ACTIVE_STATUS}>
                {text.WorkOrders.ACTIVE_STATUS}
              </ToggleButton>
              <ToggleButton value={text.WorkOrders.CLOSED_STATUS}>
                {text.WorkOrders.CLOSED_STATUS}
              </ToggleButton>
            </ToggleButtonGroup>

            {
              status == "ACTIVE" ?
                <CustomDataGrid
                  col={columns}
                  to={routes.WORKORDERS}
                  rQuery={workordersQueryActive}
                  tags={[tags.WORKORDER]}
                />
                :
                <CustomDataGrid
                  col={columns}
                  to={routes.WORKORDERS}
                  rQuery={workordersQueryClosed}
                  tags={[tags.WORKORDER]}
                />
            }
          </CardContent>
        </Card>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Autocomplete
            disablePortal
            id="combo-modal"
            options={typeInsp}
            fullWidth
            getOptionDisabled={(option) => !!typeInsp.find((element) => option.disable === 'y')}
            renderInput={(params) => <TextField {...params} label={text.WorkOrders.Modal_Type} />}
          />
          <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate(routes.WORKORDER_ADD)}>
            Confirm
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default WorkOrders;
