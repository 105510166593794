import { api } from './api';
import urls from '../constants/urls';
import tags from './tags';
import refreshCache from '../utils/refreshCache';

export const cylinderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addCylinder: builder.mutation({
      query: (data) => ({
        url: urls.CYLINDERS,
        method: 'POST',
        body: { ...data },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CYLINDER]);
        } catch (e) {
          console.log(e);
        }
      },
    }),

    getCylinders: builder.query({
      query: (params) => ({
        url: urls.CYLINDERS,
        params: params,
      }),
      providesTags: [tags.CYLINDER],
    }),

    getCylinder: builder.query({
      query: (id) => ({
        url: urls.CYLINDERS + '/' + id,
      }),
      providesTags: [tags.CYLINDER],
    }),

    getCylindersByConcreteSequenceId: builder.query({
      query: (id) => ({
        url: urls.CYLINDERS + '/by-concrete-sequence/' + id,
      }),
      providesTags: [tags.CYLINDER],
    }),

    updateCylinder: builder.mutation({
      query: (data) => ({
        url: urls.CYLINDERS + '/' + data.id,
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CYLINDER]);
        } catch (e) {
          console.log(e);
        }
      },
    }),

    deleteCylinder: builder.mutation({
      query: (id) => ({
        url: urls.CYLINDERS + '/' + id,
      }),
    }),
  }),
});

export const {
  useAddCylinderMutation,
  useGetCylinderQuery,
  useGetCylindersQuery,
  useUpdateCylinderMutation,
  useGetCylindersByConcreteSequenceIdQuery
} = cylinderApi;
