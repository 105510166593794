import { api } from './api';
import urls from '../constants/urls';

export const regionsApi = api.injectEndpoints({
  endpoints: (builder) => ({

    getRoles: builder.query({
      query: () => ({
        url: urls.ROLES,
      }),
    }),
  }),
});

export const { useGetRolesQuery } = regionsApi;
