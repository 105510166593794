export const featurePermissions = {
  CLIENTS_CREATE: 'clients.create',
  CLIENTS_READ: 'clients.read',
  CLIENTS_UPDATE: 'clients.update',
  CLIENTS_DELETE: 'clients.delete',

  CONCRETE_FIELD_INFORMATION_CREATE: 'concrete_field_information.create',
  CONCRETE_FIELD_INFORMATION_READ: 'concrete_field_information.read',
  CONCRETE_FIELD_INFORMATION_UPDATE: 'concrete_field_information.update',
  CONCRETE_FIELD_INFORMATION_DELETE: 'concrete_field_information.delete',

  CONCRETE_MIX_DESIGN_CREATE: 'concrete_mix_design.create',
  CONCRETE_MIX_DESIGN_READ: 'concrete_mix_design.read',
  CONCRETE_MIX_DESIGN_UPDATE: 'concrete_mix_design.update',
  CONCRETE_MIX_DESIGN_DELETE: 'concrete_mix_design.delete',

  CONCRETE_POUR_CREATE: 'concrete_pour.create',
  CONCRETE_POUR_READ: 'concrete_pour.read',
  CONCRETE_POUR_UPDATE: 'concrete_pour.update',
  CONCRETE_POUR_DELETE: 'concrete_pour.delete',

  CONCRETING_SEQUENCES_CREATE: 'concreting_sequences.create',
  CONCRETING_SEQUENCES_READ: 'concreting_sequences.read',
  CONCRETING_SEQUENCES_UPDATE: 'concreting_sequences.update',
  CONCRETING_SEQUENCES_DELETE: 'concreting_sequences.delete',

  CYLINDERS_CREATE: 'cylinders.create',
  CYLINDERS_READ: 'cylinders.read',
  CYLINDERS_UPDATE: 'cylinders.update',
  CYLINDERS_DELETE: 'cylinders.delete',

  PROJECTS_CREATE: 'projects.create',
  PROJECTS_READ: 'projects.read',
  PROJECTS_UPDATE: 'projects.update',
  PROJECTS_DELETE: 'projects.delete',

  SAMPLES_READ: 'samples.read',
  SAMPLES_CREATE: 'samples.create',
  SAMPLES_UPDATE: 'samples.update',
  SAMPLES_DELETE: 'samples.delete',

  SAMPLES_PICKUP_READ: 'samples_pickups.read',
  SAMPLES_PICKUP_CREATE: 'samples_pickups.create',
  SAMPLES_PICKUP_UPDATE: 'samples_pickups.update',
  SAMPLES_PICKUP_DELETE: 'samples_pickups.delete',

  REGIONS_CREATE: 'regions.create',
  REGIONS_READ: 'regions.read',
  REGIONS_UPDATE: 'regions.update',
  REGIONS_DELETE: 'regions.delete',

  OFFICES_CREATE: 'offices.create',
  OFFICES_READ: 'offices.read',
  OFFICES_UPDATE: 'offices.update',
  OFFICES_DELETE: 'offices.delete',

  USERS_CREATE: 'users.create',
  USERS_READ: 'users.read',
  USERS_UPDATE: 'users.update',
  USERS_DELETE: 'users.delete',

  WORK_ORDERS_CREATE: 'work_orders.create',
  WORK_ORDERS_READ: 'work_orders.read',
  WORK_ORDERS_UPDATE: 'work_orders.update',
  WORK_ORDERS_DELETE: 'work_orders.delete',

  CONFIRM_SAMPLE_DELIVERY: 'confirm_sample_delivery.read',
};

// Example
export const routePermissions = {
  dashboard: [
    featurePermissions.PROJECTS_READ,
    featurePermissions.WORK_ORDERS_READ,
    featurePermissions.CLIENTS_READ,
  ],
  projects: [
    featurePermissions.PROJECTS_CREATE,
    featurePermissions.PROJECTS_READ,
    featurePermissions.PROJECTS_UPDATE,
    featurePermissions.PROJECTS_DELETE,
  ],
  workorders: [
    featurePermissions.WORK_ORDERS_CREATE,
    featurePermissions.WORK_ORDERS_READ,
    featurePermissions.WORK_ORDERS_UPDATE,
    featurePermissions.WORK_ORDERS_DELETE,
  ],
  clients: [
    featurePermissions.CLIENTS_CREATE,
    featurePermissions.CLIENTS_READ,
    featurePermissions.CLIENTS_UPDATE,
    featurePermissions.CLIENTS_DELETE,
  ],
};
