import React from 'react';
import { useLocale } from '../../../hooks';
import { Grid, Typography } from '@mui/material';
import { useGetProjectQuery } from '../../../services/projectsApi';
import CustomTextFieldView from '../../../components/customMUI/CustomTextFieldView';
import useRequest from '../../../hooks/useRequest';

const ViewClient = ({ id }) => {
  const tool = 'Projects';
  const text = useLocale();

  // Get Project
  const projectQuery = useRequest(useGetProjectQuery, id);
  const data = projectQuery.data ? projectQuery.data.payload : {};

  // Styling
  const titleStyle = { fontWeight: 'bold', fontSize: 24, mb: 1 };

  return (
    <Grid container spacing={1} alignItems="stretch">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography sx={titleStyle}>{text.Projects.TITLE_CLIENT_DETAILS}</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Grid container wrap="nowrap" direction={'column'}>
          <Grid item>
            <CustomTextFieldView
              name="LABEL_ORGANIZATION_NAME"
              tool="Clients"
              value={projectQuery?.data?.payload.client.organizationName}
            />
            <CustomTextFieldView
              name="LABEL_CONTACT_NAME"
              tool="Clients"
              value={projectQuery?.data?.payload.clientFullName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Grid container wrap="nowrap" direction={'column'}>
          <Grid item>
            <CustomTextFieldView
              name="LABEL_CONTACT_TELEPHONE"
              tool="Clients"
              value={projectQuery?.data?.payload.clientPhoneNumber}
            />
            <CustomTextFieldView
              name="LABEL_CONTACT_EMAIL"
              tool="Clients"
              value={projectQuery?.data?.payload.clientEmail}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* <Divider variant="middle" sx={{ m: 2 }} /> */}
    </Grid>
  );
};

export default ViewClient;
