import { object, string, array, date, number } from 'yup';
import { SamplePickupStatus } from '../constants/options';
import useLocale from '../hooks/useLocale';

export const useSamplePickupSchema = () => {
  const text = useLocale();
  // this one is to PM assign field tech
  const samplePickupCreateSchema = object({
    assignedPickupDateTime: date().typeError(text.REQUIRED).required(text.REQUIRED), // Project Manager
    collectorID: string().required(text.REQUIRED), // Project Manager
    // workOrderID: string().required(text.REQUIRED),
    samples: array(string()).required().min(1), // Project Manager
    pickupInstructions: string(), // Project Manager
  });

  // not in use yet
  const samplePickupEditSchema = object({
    assignedPickupDateTime: date().typeError(text.REQUIRED),
    collectorID: string().nonNullable(),
    pickupInstructions: string(),
    pickupDateTime: date(),
    dropoffDateTime: date(),
    additionalInformation: string(),
    samples: array(string()),
    status: string().oneOf(Object.keys(SamplePickupStatus)),
  });

  // This one is for the Field Tech or Lab Supervisor to update
  const updateDeliverySchema = object({
    receivedDateTime: string(), // Lab Supervisor updates the date when they mark it as 'received'
    pickupDateTime: date().typeError(text.REQUIRED).required(text.REQUIRED),
    dropoffDateTime: date().typeError(text.REQUIRED).required(text.REQUIRED),
    additionalInformation: string().notRequired(),
    samples: array().of(
      object({
        id: string(),
        minimumTemperature: number().nullable().required(text.REQUIRED),
        maximumTemperature: number().nullable().required(text.REQUIRED),
      })
    ),
    status: string().oneOf(Object.keys(SamplePickupStatus)),
  });

  const updateDeliverySchemaWithoutTemp = object({
    receivedDateTime: string(), // Lab Supervisor updates the date when they mark it as 'received'
    pickupDateTime: date().typeError(text.REQUIRED).required(text.REQUIRED),
    dropoffDateTime: date().typeError(text.REQUIRED).required(text.REQUIRED),
    additionalInformation: string().notRequired(),
    status: string().oneOf(Object.keys(SamplePickupStatus)),
  });

  return {
    samplePickupCreateSchema,
    samplePickupEditSchema,
    updateDeliverySchema,
    updateDeliverySchemaWithoutTemp
  };
};
