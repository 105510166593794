import "react-toastify/dist/ReactToastify.css";

import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { LicenseInfo } from '@mui/x-license';
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import LoadingBackdrop from "./components/LoadingBackdrop";
import { store } from "./config/store";
import theme from "./config/theme";
import "./index.css";
import { CustomAdapterLuxon } from "./utils/customAdapterLuxon";
const container = document.getElementById("root");
const root = createRoot(container);
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={CustomAdapterLuxon} adapterLocale="en-gb">
          <BrowserRouter>
            <LoadingBackdrop />
            <ToastContainer theme="colored" limit={3} />
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
