import { Grid, Typography } from '@mui/material';
import { useLocale } from '../../hooks';

const CustomTextFieldView = ({ tool, name, value, chip }) => {
  const text = useLocale();
  let uppername = name.toUpperCase(); //To convert Upper Case

  // Styling
  const labelStyle = { fontWeight: 'bold', display: 'inline', fontSize: 16 };
  const dataStyle = { display: 'inline', ml: 'min(10px)', fontSize: 16 };

  let varValue = '';
  chip ? (varValue = <Typography sx={dataStyle}>{value}</Typography>) : (varValue = value);

  return (
    <Grid item alignItems={'center'} sx={{ flexDirection: 'column' }}>
      <Typography sx={labelStyle}>{text[tool][uppername]}</Typography>: {varValue}
    </Grid>
  );
};

export default CustomTextFieldView;
