import { createSlice } from "@reduxjs/toolkit";
import { EN_US } from "../constants/locale";

const initialState = EN_US;
const localeSlice = createSlice({
  name: "locale",
  initialState: initialState,
  reducers: {
    setLocale(state, action) {
      return action.payload;
    },
  },
});

// Actions
export const { setLocale } = localeSlice.actions;

// Selecters
export const selectLocale = (state) => state.locale;

export default localeSlice;
