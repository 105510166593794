import React, { useEffect } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { LoadingIcon } from '../../components';
import CustomGridToolbar from './CustomGridToolbar';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

const CustomDataGrid = ({ rQuery, col, title, tags, to, extra, dgHeight, fullPath, sx }) => {
  const navigate = useNavigate();
  const handleRowClick = (params) => {
    fullPath
      ? navigate(fullPath + '/' + params.row.id)
      : navigate(to + '/details/' + params.row.id);
  };

  const dataGridBoxStyle = {
    width: '100%',
    '& .MuiDataGrid-root': { border: 'none' },
    '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
  };

  let varHeight;
  dgHeight ? (varHeight = dgHeight) : (varHeight = '70vh');

  sx = {
    ...sx,
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
  };

  return (
    <Card>
      <CardContent>
        <Box height={varHeight} sx={{ ...dataGridBoxStyle, ...sx }}>
          {title ? (
            <Typography
              sx={{ fontSize: 22, fontWeight: 'bold' }}
              color="text.secondary"
              gutterBottom
            >
              {title}
            </Typography>
          ) : (
            <></>
          )}
          {rQuery.isLoading ? (
            <LoadingIcon />
          ) : (
             <DataGridPro
              rows={rQuery.data ? rQuery?.data?.payload : []}
              slots={{ toolbar: () => CustomGridToolbar({ tags: tags, extra: extra }) }}
              columns={col}
              onRowClick={handleRowClick}
              columnHeader
              pagination
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomDataGrid;
