import React, { useEffect, useState } from 'react';
import { useGetWorkOrdersQuery } from '../../services/workordersApi';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slices/userSlice';
import { useLocale } from '../../hooks';
import StatusChip from '../../components/StatusChip';
import useFormatDateTime from '../../hooks/useFormatDateTime';
import routes from '../../constants/routes';
import useRequest from '../../hooks/useRequest';
import { CustomDataGrid, FlexBetween } from '../../components';
import tags from '../../services/tags';
import useGetElement from '../../hooks/useGetElement';
import { Header } from '../../components';
import { Duration } from 'luxon';
import { Autocomplete, Box, Button, Card, CardContent, Modal, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddCircle } from '@mui/icons-material';

const WorkOrdersAssigned = () => {
  const text = useLocale();
  const { formatDate, formatTime } = useFormatDateTime();
  const [open, setOpen] = React.useState(false);
  const user = useSelector(selectUser);
  const navItem = useGetElement(text.Navigation.ASSIGNED_WORKORDERS);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const workOrderQuery = useRequest(useGetWorkOrdersQuery, {
  //   'filter[fieldTechnicianID]': `equals,${user.id}`,
  // });

  const workordersQueryActive = useRequest(useGetWorkOrdersQuery, {
    'filter[fieldTechnicianID]': `equals,${user.id}`,'filter[workOrderStatus]': `notIn,Closed,Completed`,
  });
  const workordersQueryClosed = useRequest(useGetWorkOrdersQuery, {
    'filter[fieldTechnicianID]': `equals,${user.id}`,'filter[workOrderStatus]': `In,Closed,Completed`,
  });



  const [status, setStatus] = useState(text.WorkOrders.ACTIVE_STATUS);
  const navigate = useNavigate();
  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };
  const typeInsp = [
    { label: 'Concrete', disable: 'n' },
    { label: 'Compaction and Concrete', disable: 'y' },
    { label: 'Compaction and Paving', disable: 'y' },
  ];

  const columns = [
    {
      field: 'projectCode',
      headerName: text.WorkOrders.DGPROJECTCODE,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return params.row.project.projectCode;
      },
    },
    {
      field: 'projectName',
      headerName: text.WorkOrders.DGPROJECTNAME,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => {
        return params.row.project.projectName;
      },
    },

    { field: 'inspectionType', headerName: text.WorkOrdersDataGrid.TYPE, minWidth: 120, flex: 1 },

    {
      field: 'fieldTechnician',
      headerName: text.WorkOrders.DGfieldTechnician,
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.fieldTechnician ? params.row.fieldTechnician.name : "";
      },
    },
    { field: 'workLocation', headerName: text.WorkOrdersDataGrid.WORK_LOCATION, minWidth: 150, flex: 1 },
    {
      field: 'siteVisitDate',
      headerName: text.WorkOrders.DGDate,
      flex: 1,
      minWidth: 140,
      valueFormatter: (params) => formatDate(params.value),
    },

    {
      field: 'arrivalTime',
      headerName: 'Arrival Time On Site',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => formatTime(params.value),
    },

    {
      field: 'siteVisitType',
      headerName: 'Site Visit Type',
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => text.WorkOrderVisitTypes[params.value],
    },
    {
      field: 'transportationTime',
      headerName: text.WorkOrders.DGTRANSPORTATIONTIME,
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.row.project.transportationTime
      },
    },
    {
      field: 'mileage',
      headerName: text.WorkOrders.DGTRANSPORTATIONMILEAGE,
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.project.mileage;
      },
    },
    // {
    //   field: 'site_address3',
    //   headerName: text.WorkOrders.DGStatus,
    //   flex: 1,
    //   minWidth: 140,
    //   renderCell: (params) => {
    //     return <StatusChip status="ACTIVE" />;
    //   },
    // },

    // {
    //   field: 'site_address4',
    //   headerName: text.WorkOrders.DGLabStatus,
    //   flex: 1,
    //   minWidth: 140,
    //   renderCell: (params) => {
    //     return <StatusChip status="ACTIVE" />;
    //   },
    // },
    // {
    //   field: 'status',
    //   headerName: text.WorkOrders.DGWOStatus,
    //   flex: 1,
    //   minWidth: 140,
    //   renderCell: (params) => {
    //     return <StatusChip status={params.row.status} />;
    //   },
    // },
    {
      field: 'status',
      headerName: text.WorkOrders.DGWOStatus,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return <p style={params.row.workOrderStatus  === "Closed" ? {color:'green',fontWeight:'bold'}:{color:'#FFA500',fontWeight:'bold'}}>{params.row.workOrderStatus }</p>;
      },
      valueGetter:(params)=>{
        return params.row.workOrderStatus 
      }
    },
  ];

  const handleChangeWorkOrderStatus = (event, newValue) => {
    setStatus(newValue);
  };

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.WorkOrders.TABLENAME} />
      </FlexBetween>
      <Box>
        <Card>
          <CardContent>
            <ToggleButtonGroup
              sx={{ my: 2, height: '40px' }}
              value={status}
              exclusive
              onChange={handleChangeWorkOrderStatus}
            >
              <ToggleButton value={text.WorkOrders.ACTIVE_STATUS}>
                {text.WorkOrders.ACTIVE_STATUS}
              </ToggleButton>
              <ToggleButton value={text.WorkOrders.CLOSED_STATUS}>
                {text.WorkOrders.CLOSED_STATUS}
              </ToggleButton>
            </ToggleButtonGroup>

            {
              status == text.WorkOrders.ACTIVE_STATUS ?
                <CustomDataGrid
                  col={columns}
                  to={routes.WORKORDERS}
                  rQuery={workordersQueryActive}
                  tags={[tags.WORKORDER]}
                />
                :
                <CustomDataGrid
                  col={columns}
                  to={routes.WORKORDERS}
                  rQuery={workordersQueryClosed}
                  tags={[tags.WORKORDER]}
                />
            }

          </CardContent>
        </Card>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Autocomplete
            disablePortal
            id="combo-modal"
            options={typeInsp}
            fullWidth
            getOptionDisabled={(option) => !!typeInsp.find((element) => option.disable === 'y')}
            renderInput={(params) => <TextField {...params} label={text.WorkOrders.Modal_Type} />}
          />
          <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate(routes.WORKORDER_ADD)}>
            Confirm
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default WorkOrdersAssigned;
