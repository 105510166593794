import { Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingIcon } from '..';
import useRequest from '../../hooks/useRequest';
import { useGetCylindersQuery } from '../../services/cylindersApi';
import CylinderRecord from './CylinderRecord';
import { DateTime } from 'luxon';
import { useGetSampleLabQuery } from '../../services/samplesApi';
const CylindersPanel = ({ sampleID, fields, setFields }) => {
  const params = { 'filter[sampleID]': `equals,${sampleID}`, 'sort[customCylinderID]': 'asc' };
  const [orderedCylinders, setOrderedCylinders] = useState([]);
  const cylindersQuery = useRequest(useGetCylindersQuery, params);
  const sampleQuery = useRequest(useGetSampleLabQuery, sampleID);
  const [mindate, setMinDate] = useState();
  const { data } = sampleQuery;
  // For Back Navigation Confirmation
  useEffect(() => {
    if (cylindersQuery.data) {
      let initialFields = {};
      for (const cylinder of cylindersQuery.data.payload) {
        initialFields = { ...initialFields, [cylinder.id]: false };
      }
      setFields(initialFields);
    }
  }, []);
 
  useEffect(() => {
    if (data?.payload) {
      const dateTime = DateTime.fromISO(data.payload?.concretingSequence?.workOrder?.concreteFieldInformation?.dateOfVisit, { zone: "utc" });
      setMinDate(dateTime);
    }

  }, [data]);

  // Reorder the cylinders array (After saving it becomes unordered)
  useEffect(() => {
    if (cylindersQuery.status === 'fulfilled') {
      const cylinders = cylindersQuery.data.payload.toSorted((previous, next) => {
        return previous.customCylinderID.localeCompare(next.customCylinderID);
      });
      setOrderedCylinders(cylinders);
    }
  }, [cylindersQuery.status]);

  if (cylindersQuery.isLoading) {
    return <LoadingIcon />;
  } else {
    return (
      <React.Fragment>
        {orderedCylinders.map((cylinder) => {
          return (
            <Card key={cylinder.id} sx={{ mb: 2 }}>
              <CardContent sx={{ p: 4 }}>
                <CylinderRecord mindate={mindate} sampleID={sampleID} cylinder={cylinder} fields={fields} setFields={setFields} />
              </CardContent>
            </Card>
          );
        })}
      </React.Fragment>
    );
  }
};

export default CylindersPanel;
