import { Box, Button, Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomGridToolbar, FlexBetween, HeaderDetails, Icon, LoadingIcon } from '../../components';
import { useLocale } from '../../hooks';
import { useFormatDateTime } from '../../hooks/useFormatDateTime';
import { useUpdateSamplePickupDeliveryMutation } from '../../services/samplePickUpApi';
import tags from '../../services/tags';
import ViewCardSample from './forms/ViewCardSample';

//Samples
import { useNavigate, useParams } from 'react-router-dom';

//Sample Pickup
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { SamplePickupStatus } from '../../constants/options';
import routes from '../../constants/routes';
import { useHandleError } from '../../hooks/useHandleError';
import { useSamplePickupSchema } from '../../schemas/samplePickup.schema';
import { useGetSamplePickupQuery } from '../../services/samplePickUpApi';
import { addNotification } from '../../slices/notificationSlice';
import {
  removeSamplePickup,
  selectSamplePickupByID,
  updateSamplePickup,
} from '../../slices/samplePickupSlice';
import { dataGridBoxStyle } from '../../styles/datagrid';
import FormSamplesPickup from './forms/FormSamplesPickup';
import StatusChip from '../../components/StatusChip';
import SamplesPickupDetails from './SamplePickupDetails';
import useRequest from '../../hooks/useRequest';
import useHandleSuccess from '../../hooks/useHandleSuccess';

function SamplePickupEdit() {
  const navigate = useNavigate();
  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();
  const text = useLocale();
  const { formatTime, formatDateTime } = useFormatDateTime();
  const { id } = useParams();
  const { updateDeliverySchema, updateDeliverySchemaWithoutTemp } = useSamplePickupSchema();

  // Sample Pickups can be 'saved', check the cache for a saved version be applied
  const samplePickupCache = useSelector((state) => selectSamplePickupByID(state, id));

  const dispatch = useDispatch();

  const samplePickupQuery = useGetSamplePickupQuery(id,{refetchOnMountOrArgChange:true});
  const [updateDelivery, updateDeliveryResult] = useUpdateSamplePickupDeliveryMutation();
  const [isCheck, setIscheck] = useState(false);
  const [updateValidationSchema, setUpdateValidationSchema] = useState(updateDeliverySchema);

  const [initialValues, setInitialValues] = useState({
    pickupDateTime: null,
    dropoffDateTime: null,
    additionalInformation: '',
    samples: [],
    status: '',
  });

  useEffect(() => {
    if (isCheck) {
      setUpdateValidationSchema(updateDeliverySchemaWithoutTemp);
      return
    }
    setUpdateValidationSchema(updateDeliverySchema);
  }, [isCheck])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: updateValidationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const changes = {
        ...values,
        samples: isCheck ? [] : values.samples
      }

      try {
        // Submit values
        const result = await updateDelivery({ changes, id: id }).unwrap();

        // Remove cached data
        dispatch(removeSamplePickup(id));
        handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.SamplePickups.SUCCESSFUL_UPDATE);

        // Navigate away and show feedback
        navigate(routes.SAMPLE_PICKUPS_ASSIGNED);
      } catch (e) {
        handleErrorResponse(e, text.SamplePickups.UNSUCCESSFUL_UPDATE);
      }
    },
  });

  async function handleSave() {
    dispatch(updateSamplePickup({ id: id, ...formik.values }));
    dispatch(
      addNotification({
        title: text.SAVED,
        description: text.SamplePickups.SAVED,
        color: 'success.secondary',
      })
    );

    toast.success(text.SAVED);
  }

  async function handleComplete() {
    formik.setFieldValue('status', SamplePickupStatus.SAMPLES_DELIVERED);
    formik.handleSubmit();
  }

  // On first load, retrieve data from api
  useEffect(() => {
    if (samplePickupQuery.data) {
      setInitialValues({
        pickupDateTime: samplePickupQuery.data.payload.pickupDateTime,
        dropoffDateTime: samplePickupQuery.data.payload.dropoffDateTime,
        additionalInformation: samplePickupQuery.data.payload.additionalInformation,
        samples: samplePickupQuery.data.payload.samples,
        status: samplePickupQuery.data.payload.status,
        laboratoryID: samplePickupQuery?.data.payload?.workOrder?.laboratory?.id
      });
    }
  }, [samplePickupQuery.data]);

  // If the page refreshes apply the cached data

  useEffect(() => {
    if (samplePickupCache !== null && samplePickupCache !== undefined) {
      setInitialValues(samplePickupCache);
    }
  }, []);

  const samplePickup = samplePickupQuery.data ? samplePickupQuery.data.payload : {};
 

  const columns = [
    {
      field: 'number',
      headerName: text.Samples.LABEL_SAMPLE_NUMBER,
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'type',
      headerName: text.Samples.LABEL_TYPE,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return <Typography>{text.SampleType[params.value]}</Typography>;
      },
    },
    {
      field: 'timeOfMoulding',
      headerName: text.Samples.LABEL_TIME_OF_MOULDING,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => {
        return formatDateTime(params.value);
      },
    },
    {
      field: 'minimumTemperature',
      headerName: text.Samples.MINIMUM_TEMPERATURE + '* (C)',
      minWidth: 200,
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return <>
          <Grid container display={'flex'} flexDirection={'column'}>
            <TextField variant="outlined" color={'secondary'} value={params.value} error={!isCheck && !params.value && invalidInput} />
            { !isCheck && !params.value && invalidInput && <Typography my={1} style={{
              position: 'absolute',
              top: 70
            }} color={'red'} fontSize={'14px'}>Min temp is required</Typography>}
          </Grid>

        </>
      },
    },
    {
      field: 'maximumTemperature',
      headerName: text.Samples.MAXIMUM_TEMPERATURE + '* (C)',
      minWidth: 200,
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return <>
          <Grid container display={'flex'} flexDirection={'column'}>
            <TextField variant="outlined" color={'secondary'} value={params.value} error={!isCheck && !params.value && invalidInput} />
            {!isCheck && !params.value && invalidInput && <Typography my={1} style={{
              position: 'absolute',
              top: 70
            }} color={'red'} fontSize={'14px'}>Max temp is required</Typography>}
          </Grid>
        </>
      },
    },
    {
      field: 'typeOfMould',
      headerName: text.Samples.LABEL_TYPE_OF_MOULD,
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'sizeOfMould',
      headerName: text.Samples.LABEL_SIZE_OF_MOULD,
      minWidth: 120,
      flex: 1,
    },

    {
      field: 'status',
      headerName: text.STATUS,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return <StatusChip status={params.value} />;
      },
    },
  ];

  const [invalidInput, setInvalidInput] = useState(false);
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubmitAttempt = async () => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length > 0) {
      setInvalidInput(true);
    } else {
      setOpenDialog(true);
      setInvalidInput(false);
    }
  };

  const handleSampleEdit = (newValues) => {
    const sampleID = newValues.id;
    const minTemp = newValues.minimumTemperature;
    const maxTemp = newValues.maximumTemperature;

    // Find index
    let index = 0;
    for (let i = 0; i < formik.values.samples.length; i++) {
      if (formik.values.samples[i].id === sampleID) {
        index = i;
      }
    }

    formik.setFieldValue(`samples[${index}].minimumTemperature`, minTemp);
    formik.setFieldValue(`samples[${index}].maximumTemperature`, maxTemp);
  };

  const [openDialog, setOpenDialog] = useState(false);

  if (samplePickupQuery.data?.payload.status === SamplePickupStatus.SAMPLES_DELIVERED) {
    return <SamplesPickupDetails />;
  } else {
    return (
      <React.Fragment>
        <FlexBetween>
          <HeaderDetails btName={text.SamplePickups.BT_RETURN} to={-1} />
        </FlexBetween>

        {/* SAMPLE Details */}
        <Card sx={{ m: 1, p: 2 }}>
          <CardContent>
            <ViewCardSample data={samplePickup} />
          </CardContent>
        </Card>

        {/* DATAGRID */}
        <Card sx={{ m: 1, p: 2 }}>
          <CardContent sx={dataGridBoxStyle}>
            {!samplePickupQuery.isLoading && samplePickupQuery.data ? (
              <DataGridPro
                rowHeight={100}
                slots={{
                  toolbar: () => CustomGridToolbar({ tags: [tags.SAMPLE_PICKUP, tags.SAMPLE] }),
                }}
                rows={formik.values.samples}
                columns={columns}
                sx={{ height: '40vh' }}
                processRowUpdate={(newValues, oldValues) => {
                  handleSampleEdit(newValues);
                  return newValues;
                }}
                onProcessRowUpdateError={(error) => console.log(error)}
                pagination
              />
            ) : (
              <LoadingIcon />
            )}
            <Divider sx={{ mt: 2, mb: 2 }} />
             <FormSamplesPickup reftechTable={()=> samplePickupQuery.refetch()} data={samplePickup} formik={formik} setIscheck={setIscheck} />
            <Box sx={{ mt: 4 }}>
              {invalidInput ? (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 2 }}>
                  <Typography sx={{ fontSize: 14, color: 'error.main' }}>
                    Please fill in all the required fields
                  </Typography>
                </Box>
              ) : (
                <></>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button
                  onClick={handleSave}
                  startIcon={<Icon iconName={'Edit'} />}
                  variant={'contained'}
                  color={'secondary'}
                >
                  {text.Buttons.SAVE}
                </Button>
                <Button
                  onClick={handleSubmitAttempt}
                  startIcon={<Icon iconName={'Edit'} />}
                  variant={'contained'}
                  color={'secondary'}
                >
                  {text.Buttons.MARK_AS_DELIVERED}
                </Button>
                <ConfirmationDialog
                  title={text.MARK_AS_DELIVERED}
                  contentText={text.UNCHANGEABLE_WARNING}
                  actionText={text.Buttons.CONFIRM}
                  handleAction={handleComplete}
                  handleClose={handleClose}
                  handleOpen={handleSubmitAttempt}
                  isOpen={openDialog}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

export default SamplePickupEdit;
