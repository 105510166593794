import React, { useState } from "react";

import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useConcreteMixDesignSchema } from "../../../schemas/concreteMixDesign.schema";
import { addToQueue, formType } from "../../../slices/offlineQueueSlice";
import { getPopulated } from "../../../utils/form";
import { ConcreteMixDesignDialog } from "./ConcreteMixDesignDialog";

export const ConcreteMixDesignAdd = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { concreteMixDesignCreateSchema } = useConcreteMixDesignSchema();

  // Concrete Mix Design
  const [initialValuesConcreteMixDesign, setInitialValuesConcreteMixDesign] = useState({
    workOrderID: id,
    supplierOrganisation: "",
    plant: "",
    formula: "",
    specifiedConcreteClass: "",
    concreteElementLocation: "",
    specifiedCompressiveStrength: "",
    csaExposureClass: "",
    minAirContent: "",
    maxAirContent: "",
    slump: "",
    slumpRange: "",
    plasticizer: "",
    plasticizerRange: "",
    additionalInformation: "",
    nominalAggregateSize: "",
  });

  const formikConcreteMixDesign = useFormik({
    initialValues: initialValuesConcreteMixDesign,
    validationSchema: concreteMixDesignCreateSchema,

    onSubmit: (values, formik) => {
      const id = uuidv4();
      const specifiedConcreteClass = 'Class ' +
      values.csaExposureClass +
      ", " +
      values.specifiedCompressiveStrength +
      " Mpa, Aggregates " +
      values.nominalAggregateSize +
      " mm,  Air Content " +
      values.minAirContent +
      "-" +
      values.maxAirContent +
      " AEA, Slump " +
      values.slump +
      " ± " +
      values.slumpRange + " mm";
      const object = getPopulated({ ...values, specifiedConcreteClass });
      // dispatch(addConcreteMixDesign({ ...object, id: id }));
      dispatch(
        addToQueue({
          id: id,
          type: formType.CONCRETE_MIX_DESIGN,
          data: { ...object, id: id },
        })
      );
      formik.resetForm();
      navigate(-1);
    },
  });

  const handleSubmit = () => {
    formikConcreteMixDesign.handleSubmit();
  };

  return (
    <ConcreteMixDesignDialog
      formikConcreteMixDesign={formikConcreteMixDesign}
      handleSubmit={handleSubmit}
    />
  );
};
