import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, CircularProgress } from "@mui/material";
import useFormatDateTime from "../../../hooks/useFormatDateTime";
import { DateTime } from "luxon";
import Divider from "@mui/material/Divider";
import { useLocale } from "../../../hooks";
import urls from "../../../constants/urls";
import { selectAuth } from "../../../slices/authSlice";
import { useSelector } from "react-redux";
import axios from "axios";

const ViewLabReport = ({ data }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [buttonId, setbuttonId] = useState(0);
  const auth = useSelector(selectAuth);
  const { formatDate } = useFormatDateTime();
  const text = useLocale();

  const report_sub_heading = {
    marginBottom: "6px !important",
    "& .MuiTypography-root": {
      fontSize: "1em",
    },
  };

  const report_sub_description = {
    "& .text": {
      fontSize: "13px",
    },
  };
  const downloadButtonStyle = {
    // marginLeft: '25px', // Adjust the margin as needed
    display: "inline-block", // Ensure the button is displayed inline
    float: "right",
    marginRight: "10px",
  };

  async function downloadOntarioReportField(concretingSequenceID) {
    try {
      setLoading(true);
      setbuttonId(concretingSequenceID);
      const pdfUrl =
        process.env.REACT_APP_BASE_URL +
        urls.PDF_LAB_REPORT_ONTARIO_RESULT +
        "/" +
        concretingSequenceID;
      const response = await axios.get(pdfUrl, {
        responseType: "blob", // Set the responseType to 'blob' for binary data
        timeout: 600000, // Set a longer timeout (in milliseconds)
        headers: { authorization: "Bearer " + auth.token },
      });

      // Create a blob URL for the PDF content
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "lab-report-ontario.pdf";
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error.message);
    } finally {
      setLoading(false);
      setbuttonId(0);
    }
  }

  async function downloadQbecReportField(qubecId) {
    try {
      setLoading(true);
      setbuttonId(qubecId);
      const pdfUrl =
        process.env.REACT_APP_BASE_URL + urls.PDF_LAB_REPORT_QBEC_RESULT + "/" + qubecId;
      const response = await axios.get(pdfUrl, {
        responseType: "blob", // Set the responseType to 'blob' for binary data
        timeout: 600000, // Set a longer timeout (in milliseconds)
        headers: { authorization: "Bearer " + auth.token },
      });

      // Create a blob URL for the PDF content
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "lab-report-quebec.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error.message);
    } finally {
      setLoading(false);
      setbuttonId(0);
    }
  }

  return (
    <>
      {data?.map((sampleDt) => {
        return (
          <>
            <Button
              variant={"contained"}
              color="primary"
              onClick={() =>
                downloadQbecReportField(sampleDt?.concretingSequence?.workOrder?.project?.id)
              }
              disabled={loading}
              style={downloadButtonStyle}
            >
              {loading && buttonId === sampleDt?.concretingSequence?.workOrder?.project?.id ? (
                <CircularProgress size={24} />
              ) : (
                "Download Qbec Report"
              )}
            </Button>
            <Grid container sx={{ padding: "10px" }}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    padding: "18px 15px 18px 15px",
                    backgroundColor: "#3d3b3b",
                    height: "65px",
                  }}
                  color="common.white"
                >
                  Sample {sampleDt.number}
                  <Button
                    variant={"contained"}
                    color="primary"
                    onClick={() => downloadOntarioReportField(sampleDt.concretingSequenceID)}
                    disabled={loading}
                    style={downloadButtonStyle}
                  >
                    {loading && buttonId === sampleDt.concretingSequenceID ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Download Ontario Report"
                    )}
                  </Button>
                </Typography>

                <Grid container sx={{ padding: "12px 20px 20px 20px" }}>
                  <Grid item xs={12} sx={report_sub_heading}>
                    <Typography variant="body1" spacing={4}>
                      Sample Information
                    </Typography>
                  </Grid>
                  <Grid container sx={report_sub_description}>
                    <Grid item xs={4}>
                      <Typography className="text">Sample {sampleDt.number}</Typography>
                      <Typography className="text">
                        Specified Strength at 28 days (MPa):{" "}
                        {sampleDt.concretingSequence.concreteMixDesign.specifiedCompressiveStrength}
                      </Typography>
                      <Typography className="text">
                        Laboratory: {sampleDt.concretingSequence.workOrder.laboratory.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="text">
                        Date Cast:{" "}
                        {formatDate(
                          sampleDt.concretingSequence.workOrder.concreteFieldInformation.dateOfVisit
                        )}
                      </Typography>
                      <Typography className="text">
                        Time Cast: {formatDate(sampleDt.timeOfSampling)}
                      </Typography>
                      <Typography className="text">
                        Date Time Received: {formatDate(sampleDt?.samplePickup?.receivedDateTime)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="text">
                        Max. Field Curing Temperature:{sampleDt.maximumTemperature}
                      </Typography>
                      <Typography className="text">
                        Min. Field Curing Temperature: {sampleDt.minimumTemperature}
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container sx={{ padding: "24px 0px 0px 0px" }}>
                    <Grid item xs={12}>
                      <Grid item xs={12} sx={report_sub_heading}>
                        <Typography variant="body1">Cylinder Information</Typography>
                      </Grid>
                      <Grid container sx={report_sub_description}>
                        <Grid item xs={4}>
                          <Typography className="text">
                            Total Number of Cylinder: {sampleDt.cylinders.length}
                          </Typography>
                          <Typography className="text">
                            Type of Mould: {sampleDt.typeOfMould}
                          </Typography>
                          <Typography className="text">
                            Size of Mould: {sampleDt.sizeOfMould}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography className="text">
                            Curing Location: {text.SampleType[sampleDt?.type]}
                          </Typography>
                          <Typography className="text">
                            Curing Conditions:{" "}
                            {sampleDt?.curingConditions.length > 0
                              ? sampleDt.curingConditions
                                  .map((cureCondition) => text.CuringConditions[cureCondition])
                                  .join(", ")
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {sampleDt?.cylinders?.map((element, index) => (
                <Grid item xs={12} className="sectionssed" gap={4}>
                  <Divider
                    sx={{
                      ...(index == 0
                        ? { border: "1.5px solid gray" }
                        : { border: "1px solid lightgray", margin: "10px" }),
                    }}
                  />
                  <Grid container sx={{ padding: "12px 0px 0px 20px" }}>
                    <Grid item xs={10} sx={report_sub_heading}>
                      <Typography className="text" variant="body1">
                        Cylinder {element.customCylinderID}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sx={report_sub_description}>
                      <Typography className="text">Status {element.status}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} spacing={4} sx={{ padding: "6px 0px 0px 20px" }}>
                    <Grid container sx={report_sub_description}>
                      <Grid item xs={2}>
                        <Typography className="text">Mass (kg): {element.mass}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className="text">Length (mm): {element.length}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className="text">Diameter (mm): {element.diameter}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className="text">
                          Unit Weight (kg/m3): {element.unitWeight}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className="text">Days Cured:{element.daysToBeCured}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className="text">
                          Date Tested:{" "}
                          {formatDate(
                            DateTime.fromISO(
                              sampleDt.concretingSequence.workOrder.concreteFieldInformation
                                .dateOfVisit,
                              { zone: "local" }
                            ).plus({
                              days: element.daysToBeCured,
                            })
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: "9px 0px 12px 20px" }}>
                    <Grid item xs={6} sx={report_sub_description}>
                      <Typography className="text" variant="h6" component="h6">
                        <b>Test Results</b>
                      </Typography>
                    </Grid>
                    <Grid container sx={report_sub_description}>
                      <Grid item xs={2}>
                        <Typography className="text">Load (kN): {element.load} </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className="text">
                          Type of Fracture: {element.typeOfFracture}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className="text">
                          Compressive Strength (MPa): {element.compressiveStrength}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        );
      })}
    </>
  );
};

export default ViewLabReport;
