import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { LoadingIcon } from '../../components';
import routes from '../../constants/routes';
import { useLocale } from '../../hooks';
import { useGetWorkOrderQuery } from '../../services/workordersApi';

import ViewClient from '../clients/forms/ViewClient';
import ViewProject from '../projects/forms/ViewProject';
import ViewFieldInfo from './forms/ViewFieldInfo';
import ViewWorkOrder from './forms/ViewWorkOrder';

import { useSelector } from 'react-redux';
import PermissionGate from '../../components/PermissionGate';
import RefreshButton from '../../components/RefreshButton';
import { featurePermissions } from '../../constants/permissions';
import useRequest from '../../hooks/useRequest';
import { useGetConcreteFieldInformationQuery } from '../../services/concreteFieldInformationApi';
import tags from '../../services/tags';
import { selectPermissions } from '../../slices/permissionSlice';
import { selectUser } from '../../slices/userSlice';
import { ConcretePourTable } from './components/ConcretePourTable';

function WorkOrderDetails() {
  const userPermissions = useSelector(selectPermissions);
  const user = useSelector(selectUser);
  const text = useLocale();
  const { id } = useParams();

  const routeToAddConcreteFieldReport = () => {
    const [empty, workOrderUrl, idUrl, concreteFieldReportUrl, addUrl] =
      routes.CONCRETE_FIELD_REPORT_ADD.split('/');
    return `/${workOrderUrl}/${id}/${concreteFieldReportUrl}/${addUrl}`;
  };
  const workorderQuery = useRequest(useGetWorkOrderQuery, id);
  const workorder = workorderQuery.data ? workorderQuery.data.payload : {};

  // Get any concrete field information
  const fieldInfoResult = useRequest(useGetConcreteFieldInformationQuery, {
    'filter[workOrderID]': `equals,${id}`,
  });

  return workorderQuery.isLoading ? (
    <LoadingIcon />
  ) : (
    <React.Fragment>
      {/* PROJECT Details */}
      <Card sx={{ m: 2, p: 0 }}>
        <CardContent>
          <ViewProject id={workorderQuery?.data?.payload.project.id} />
        </CardContent>
      </Card>

      {/* CLIENTS Details*/}
      <PermissionGate
        userPermissions={userPermissions}
        componentPermissions={[featurePermissions.CLIENTS_READ]}
      >
        <Card sx={{ m: 2, p: 0 }}>
          <CardContent>
            <ViewClient id={workorderQuery?.data?.payload.project.id} />
          </CardContent>
        </Card>
      </PermissionGate>
      {/* WORKORDER Details */}
      <Card sx={{ m: 2, p: 0 }}>
        <CardContent>
          <ViewWorkOrder data={workorder} />
        </CardContent>
      </Card>

      {/* CONCRETE SUPERVISION Table */}
      <ConcretePourTable workOrderID={id} />
      {/* FIELD REPORT View */}
      <React.Fragment>
        {fieldInfoResult.data?.payload ? (
          <React.Fragment>
            {fieldInfoResult.data && fieldInfoResult.data.payload.length > 0 ? (
              <Card sx={{ m: 1, p: 1 }}>
                <CardContent>
                  <ViewFieldInfo fieldInfoResult={fieldInfoResult.data?.payload[0]} />
                </CardContent>
              </Card>
            ) : workorderQuery.data?.payload?.fieldTechnician &&
              workorderQuery.data?.payload?.fieldTechnician?.id === user.id ? (
              <Card sx={{ m: 1, p: 1 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography>
                      {text.Buttons.REFRESH}
                      <RefreshButton tags={[tags.CONCRETE_FIELD_INFORMATION]} />
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      component={Link}
                      state={{
                        assignedTechnicianID: workorderQuery.data.payload.fieldTechnician
                          ? workorderQuery.data.payload.fieldTechnician.id
                          : '',
                      }}
                      to={routeToAddConcreteFieldReport()}
                    >
                      {text.Buttons.CREATE_CONCRETE_FIELD_REPORT}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            ) : (
              <></>
            )}
          </React.Fragment>
        ) : (
          <LoadingIcon />
        )}
      </React.Fragment>
    </React.Fragment>
  );
}

export default WorkOrderDetails;
