import { createAsyncThunk } from '@reduxjs/toolkit';
import actions from '../constants/actions';

import { useAddSampleMutation } from '../services/samplesApi';
import { clearLoading } from '../slices/loadingSlice';
import { removeFromQueue, replaceSampleIDs } from '../slices/offlineQueueSlice';

export const useSubmitSample = () => {
  const [submit] = useAddSampleMutation();

  const submitSample = createAsyncThunk(actions.SUBMIT_SAMPLE, async (params, thunkAPI) => {
    try {
      const { id, workOrderID, ...data } = params;

      const result = await submit(data).unwrap();

      // Replace placeholder IDs with the real one in Samples
      thunkAPI.dispatch(replaceSampleIDs({ oldID: id, newID: result.payload.id }));
      thunkAPI.dispatch(removeFromQueue(id));
    } catch (e) {
      console.error(e);
      thunkAPI.dispatch(clearLoading());
    }
  });
  return { submitSample };
};
