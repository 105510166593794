import React from 'react';

import { Chip } from '@mui/material';
import Icon from './Icon';
import { SamplePickupStatus, SampleStatus, Statuses } from '../constants/options';
import useLocale from '../hooks/useLocale';

const StatusChip = ({ status, sx }) => {
  const text = useLocale();
  let iconName = '';
  let bgcolor = '';
  let color = '';
  let label = '';

  switch (status) {
    case Statuses.ACTIVE:
      iconName = 'DirectionsRun';
      bgcolor = '#d1e8d4';
      color = '#43914d';
      label = text.Statuses.ACTIVE;

      break;
    case Statuses.COMPLETED:
      iconName = 'Done';
      bgcolor = '#e3e6e4';
      color = '#4e4f4e';
      label = text.Statuses.COMPLETED;

      break;
    case Statuses.CANCELLED:
      iconName = 'Block';
      bgcolor = '#f0cece';
      color = '#bf5050';
      label = text.Statuses.CANCELLED;

      break;

    case SampleStatus.ONSITE:
      iconName = 'ApartmentOutlined';
      bgcolor = '#f7eacd';
      color = '#eba754';
      label = text.SampleStatus.ONSITE;

      break;

    case SampleStatus.ASSIGNED_FOR_PICKUP:
      iconName = 'LocalShipping';
      bgcolor = '#c2f0e4';
      color = '#00ad7f';
      label = text.SampleStatus.ASSIGNED_FOR_PICKUP;

      break;
    case SampleStatus.SAMPLES_DELIVERED:
      iconName = 'Done';
      bgcolor = '#dff1f5';
      color = '#65b4c2';
      label = text.SampleStatus.SAMPLES_DELIVERED;

      break;

    case SampleStatus.PENDING_TESTS:
      iconName = 'Science';
      bgcolor = '#ccf0ef';
      color = '#37a3a0';
      label = text.SampleStatus.PENDING_TESTS;

      break;

    case SampleStatus.TESTING_COMPLETED:
      iconName = 'Done';
      bgcolor = '#e3e6e4';
      color = '#4e4f4e';
      label = text.SampleStatus.TESTING_COMPLETED;
      break;

    case SamplePickupStatus.PENDING_PICKUP:
      iconName = 'LocalShipping';
      bgcolor = '#c2f0e4';
      color = '#00ad7f';
      label = text.SamplePickupStatus.PENDING_PICKUP;
      break;

    case SamplePickupStatus.SAMPLES_DELIVERED:
      iconName = 'Done';
      bgcolor = '#dff1f5';
      color = '#65b4c2';
      label = text.SamplePickupStatus.SAMPLES_DELIVERED;
      break;

    case SamplePickupStatus.DELIVERY_COMPLETED:
      iconName = 'Done';
      bgcolor = '#e3e6e4';
      color = '#4e4f4e';
      label = text.SamplePickupStatus.DELIVERY_COMPLETED;
      break;

    default:
      iconName = 'Error';
      bgcolor = '#e3e6e4';
      break;
  }
  return (
    <Chip
      icon={<Icon iconName={iconName} color={color} />}
      sx={{ ...sx, bgcolor: bgcolor, color: color }}
      label={label}
    />
  );
};

export default StatusChip;
