import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon, LoadingIcon } from "..";
import { useLocale } from "../../hooks";
import useFormatDateTime from "../../hooks/useFormatDateTime";
import useRequest from "../../hooks/useRequest";
import { useGetSampleLabQuery, useUpdateSampleStatusMutation } from "../../services/samplesApi";
import DataFragment from "../DataFragment";
import { EditSampleNumberDialog } from "./EditSampleNumberDialog";

import { useDispatch, useSelector } from "react-redux";
import { useHandleError } from "../../hooks/useHandleError";
import useHandleSuccess from "../../hooks/useHandleSuccess";
import { setLoading, clearLoading } from "../../slices/loadingSlice";
import urls from "../../constants/urls";
import axios from "axios";
import { selectAuth } from "../../slices/authSlice";
import { Add, Download } from "@mui/icons-material";
import { EditEquipmentSetDialog } from "./EditEquipmentSetDialog";

const LabInfoPanel = ({ sampleID }) => {
  const sampleQuery = useRequest(useGetSampleLabQuery, sampleID);
  const dispatch = useDispatch();
  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();
  const [updateObj] = useUpdateSampleStatusMutation();
  const text = useLocale();
  const { formatDate, formatTime } = useFormatDateTime();
  const [loader, setLoader] = useState(false);
  const [buttonId, setbuttonId] = useState(0);
  const auth = useSelector(selectAuth);

  // Styling
  const labelStyle = { fontWeight: "bold", display: "inline-block", mr: 2 };
  const dataFieldStyle = { display: "inline-block" };

  // Modal
  const [open, setOpen] = React.useState(false);
  const [openEquipmentDialoge, setOpenEquipmentDialoge] = React.useState(false);
  const [valueSampleNumber, setValueSampleNumber] = React.useState();
  const [enableSampleNumber, setEnableSampleNumber] = React.useState(false);
  const [isAddEquipmentStatus, setIsAddEquipmentStatus] = React.useState(true);
  const handleOpen = () => {
    setOpen(true);
    setValueSampleNumber(sampleQuery.data.payload.number);
    setEnableSampleNumber(false);
  };

  useEffect(() => {
    if (sampleQuery.data?.payload?.equipmentSetId) {
      setIsAddEquipmentStatus(false);
    }
  }, [sampleQuery.data]);
  const handleOpenEquipment = () => {
    setOpenEquipmentDialoge(true);
  };

  async function downloadOntarioReportField(concretingSequenceID) {
    try {
      setLoader(true);
      setbuttonId(concretingSequenceID);
      const pdfUrl =
        process.env.REACT_APP_BASE_URL +
        urls.PDF_LAB_REPORT_ONTARIO_RESULT +
        "/" +
        concretingSequenceID;
      const response = await axios.get(pdfUrl, {
        responseType: "blob", // Set the responseType to 'blsetLoadingob' for binary data
        timeout: 600000, // Set a longer timeout (in milliseconds)
        headers: { authorization: "Bearer " + auth.token },
      });

      // Create a blob URL for the PDF content
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "lab-report-ontario.pdf";
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error.message);
    } finally {
      setLoader(false);
      setbuttonId(0);
    }
  }

  async function handleUpdateSampleStatus() {
    dispatch(setLoading());
    try {
      const data = { sampleID: sampleID };
      await updateObj(data);
      handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.Cylinders.SAMPLE_SUCCESSFUL_UPDATE);
    } catch (e) {
      console.log(e);
      handleErrorResponse(e, text.Cylinders.SAMPLE_UNSUCCESSFUL_UPDATE);
    }
    dispatch(clearLoading());
  }
  if (sampleQuery.isLoading) {
    return <LoadingIcon />;
  } else {
    return (
      <Card>
        <EditEquipmentSetDialog
          open={openEquipmentDialoge}
          sampleQuery={sampleQuery}
          handleClose={() => setOpenEquipmentDialoge(false)}
          sampleID={sampleID}
          isAddEquipmentStatus={isAddEquipmentStatus}
        />
        <EditSampleNumberDialog
          open={open}
          sampleQuery={sampleQuery}
          sampleID={sampleID}
          valueSampleNumber={valueSampleNumber}
          setValueSampleNumber={setValueSampleNumber}
          enableSampleNumber={enableSampleNumber}
          setEnableSampleNumber={setEnableSampleNumber}
          handleClose={() => setOpen(false)}
        />
        <CardContent sx={{ p: 4 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* Top  half */}
            <Box sx={{ flex: 1 }}>
              <Grid container columnSpacing={2}>
                {/* Project */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {/* Title */}
                  <Typography variant={"h5"} sx={{ mb: 2 }}>
                    {text.Projects.PROJECT}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <DataFragment
                        label={text.Projects.LABEL_PROJECT_CODE}
                        data={
                          sampleQuery.data.payload.concretingSequence.workOrder.project.projectCode
                        }
                      />
                      <DataFragment
                        label={text.Projects.LABEL_PROJECT_REGION}
                        data={
                          sampleQuery.data.payload.concretingSequence.workOrder.project.region.name
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <DataFragment
                        label={text.Projects.LABEL_PROJECT_MANAGER}
                        data={
                          sampleQuery.data.payload.concretingSequence.workOrder.project
                            .projectManager.firstName +
                          " " +
                          sampleQuery.data.payload.concretingSequence.workOrder.project
                            .projectManager.lastName
                        }
                      />

                      <DataFragment
                        label={text.Projects.LABEL_DISPATCHER}
                        data={
                          sampleQuery.data.payload.concretingSequence.workOrder.project.dispatcher
                            .firstName +
                          " " +
                          sampleQuery.data.payload.concretingSequence.workOrder.project.dispatcher
                            .lastName
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
            {/* Bottom half */}
            <Box sx={{ flex: 1 }}>
              <Grid container columnSpacing={2}>
                {/* Sample Info */}
                <Grid item xs={12} sm={12} md={6} lg={7}>
                  <Typography variant={"h5"} sx={{ mb: 2 }}>
                    {text.Samples.SAMPLE_INFORMATION}
                  </Typography>

                  <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DataFragment
                          label={text.Samples.LABEL_SAMPLE_NUMBER}
                          data={sampleQuery.data.payload.number}
                        />
                        <EditIcon
                          onClick={handleOpen}
                          variant="contained"
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                      <DataFragment
                        label={text.Samples.LABEL_TYPE}
                        data={text.SampleType[sampleQuery.data.payload.type]}
                      />
                      <DataFragment
                        label={text.Samples.MINIMUM_TEMPERATURE}
                        data={sampleQuery.data.payload.minimumTemperature}
                      />
                      <DataFragment
                        label={text.Samples.MAXIMUM_TEMPERATURE}
                        data={sampleQuery.data.payload.maximumTemperature}
                      />
                      <DataFragment
                        label={text.ConcreteMixDesign.LABEL_SPECIFIED_COMPRESSIVE_STRENGTH}
                        data={
                          sampleQuery.data.payload.concretingSequence.concreteMixDesign
                            .specifiedCompressiveStrength
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <DataFragment
                        label={text.ConcreteFieldInformation.LABEL_DATE_OF_VISIT}
                        data={
                          sampleQuery.data.payload.concretingSequence.workOrder
                            .concreteFieldInformation.dateOfVisit
                            ? formatDate(
                                sampleQuery.data.payload.concretingSequence.workOrder
                                  .concreteFieldInformation.dateOfVisit
                              )
                            : ""
                        }
                      />
                      <DataFragment
                        label={text.Samples.LABEL_TIME_OF_SAMPLING}
                        data={
                          sampleQuery.data.payload.timeOfSampling
                            ? formatTime(sampleQuery.data.payload.timeOfSampling)
                            : ""
                        }
                      />
                      <DataFragment
                        label={text.Samples.LABEL_TIME_OF_MOULDING}
                        data={
                          sampleQuery.data.payload.timeOfMoulding
                            ? formatTime(sampleQuery.data.payload.timeOfMoulding)
                            : ""
                        }
                      />
                      <DataFragment
                        label={text.Projects.LABEL_LABORATORY}
                        data={`${sampleQuery?.data?.payload?.concretingSequence?.workOrder?.laboratory?.name} (${sampleQuery?.data?.payload?.concretingSequence?.workOrder?.laboratory?.region?.name})`}
                      />
                      <DataFragment
                        label={text.Samples.EQUIPMENT_SET_NAME}
                        data={
                          sampleQuery.data.payload.equipmentSetId ? (
                            <Grid container spacing={2}>
                              <Grid item>
                                <Typography>
                                  {sampleQuery?.data?.payload?.selectedEquipmentSet &&
                                    JSON.parse(sampleQuery?.data?.payload?.selectedEquipmentSet)
                                      ?.equipmentName}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <EditIcon
                                  onClick={handleOpenEquipment}
                                  variant="contained"
                                  style={{ cursor: "pointer" }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <>
                              <Typography
                                sx={{
                                  mr: 2,
                                  height: 30,
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                                onClick={handleOpenEquipment}
                              >
                                Assign equipment Kit
                              </Typography>
                            </>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Concrete Cylinders */}
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <Typography variant={"h5"} sx={{ mb: 2 }}>
                    {text.Cylinders.TITLE}
                  </Typography>

                  <Grid container>
                    <Grid item>
                      <DataFragment
                        label={text.Samples.NUMBER_OF_CYLINDERS}
                        data={sampleQuery.data.payload.cylinders.length}
                      />
                      <DataFragment
                        label={text.Samples.LABEL_TYPE_OF_MOULD}
                        data={sampleQuery.data.payload.typeOfMould}
                      />
                      <DataFragment
                        label={text.Samples.LABEL_SIZE_OF_MOULD}
                        data={sampleQuery.data.payload.sizeOfMould}
                      />

                      <Box>
                        <Typography sx={labelStyle}>
                          {text.Samples.LABEL_CURING_CONDITIONS + ":"}
                        </Typography>
                        {sampleQuery.data.payload.curingConditions.map((item, index) => {
                          return (
                            <React.Fragment key={item}>
                              <Typography key={item} sx={dataFieldStyle}>
                                {text.CuringConditions[item]}
                              </Typography>
                              {index === sampleQuery.data.payload.curingConditions.length - 1
                                ? "."
                                : ", "}
                            </React.Fragment>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={12} justifyContent={"end"} display={"flex"}>
                  <Button
                    variant={"outlined"}
                    sx={{ mr: 2, width: 200 }}
                    onClick={handleUpdateSampleStatus}
                  >
                    {text.Cylinders.LABEL_MARK_TESTING_COMPLETED}
                  </Button>

                  <Button
                    variant={"contained"}
                    color="primary"
                    startIcon={<Download />}
                    onClick={() =>
                      downloadOntarioReportField(sampleQuery.data.payload?.concretingSequence?.id)
                    }
                    disabled={loader}
                  >
                    {loader && buttonId === sampleQuery.data.payload?.concretingSequence?.id ? (
                      <CircularProgress size={24} />
                    ) : (
                      "DOWNLOAD LAB REPORT"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }
};

export default LabInfoPanel;
