import { api } from './api';
import urls from '../constants/urls';

export const regionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createEquipmentSet: builder.mutation({
      query: (data) => ({
        url: urls.EQUIPMENT_SET,
        method:"POST",
        body:{...data}
      }),
    }),
    getAllEquipmentSet: builder.query({
      query: () => ({
        url: urls.EQUIPMENT_SET,
        method:"GET",
      }),
    }),
  }),
});

export const { useCreateEquipmentSetMutation,useGetAllEquipmentSetQuery } = regionsApi;
