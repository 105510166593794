import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { LoadingIcon } from '../../components';
import CustomGridToolbar from './CustomGridToolbar';

const CustomDataGrid2 = ({ query, columns, handleRowClick, sx, tags, title }) => {
  const dataGridBoxStyle = {
    width: '100%',
    '& .MuiDataGrid-root': { border: 'none' },
    '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
  };

  let varHeight;
  varHeight = '70vh';

  return (
    <Card>
      <CardContent>
        <Box height={varHeight} sx={{ ...dataGridBoxStyle, ...sx }}>
          {title ? (
            <Typography
              sx={{ fontSize: 22, fontWeight: 'bold' }}
              color="text.secondary"
              gutterBottom
            >
              {title}
            </Typography>
          ) : (
            <>
            </>
          )}
          {query.isLoading ? (
            <LoadingIcon />
          ) : (
             <DataGridPro
              rows={query.data ? query.data.payload : []}
              slots={{ toolbar: () => CustomGridToolbar({ tags: tags }) }}
              columns={columns}
              onRowClick={handleRowClick}
              columnHeader
              pagination
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomDataGrid2;
