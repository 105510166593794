import React from 'react';
import Icon from '../Icon';
import UserImg from '../../assets/users/default.svg';
import useNavItems from '../../hooks/useNavItems';
import { Link } from 'react-router-dom';
import { selectUser } from '../../slices/userSlice';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { Menu, MenuItem, Sidebar, useProSidebar } from 'react-pro-sidebar';

// Permissions
import PermissionGate from '../PermissionGate';
import { useSelector } from 'react-redux';
import { selectPermissions } from '../../slices/permissionSlice';

const Item = ({ name, to, icon, permi }) => {
  const userPermissions = useSelector(selectPermissions);

  return (
    <PermissionGate userPermissions={userPermissions} componentPermissions={permi}>
      <MenuItem
        active={window.location.pathname === to}
        component={<Link to={to} />}
        icon={<Icon iconName={icon} />}
      >
        <Typography variant="body2">{name}</Typography>
      </MenuItem>
    </PermissionGate>
  );
};

function AppSidebar() {
  const { collapsed } = useProSidebar();
  const theme = useTheme();
  const navItems = useNavItems();
  const user = useSelector(selectUser);

  // Styles
  const styles = {
    avatarContainer: { display: 'flex', alignItems: 'center', flexDirection: 'column', my: 5 },
    avatar: { width: '40%', height: 'auto' },
  };

  return (
    <Sidebar
      style={{ height: '100%', top: 'auto' }}
      breakPoint="lg"
      backgroundColor={theme.palette.white.main}
    >
      <Box sx={styles.avatarContainer}>
        <Avatar sx={styles.avatar} alt="userImg" src={UserImg} />
        {!collapsed ? <Typography variant="h6">{user.name}</Typography> : null}
        {!collapsed ? <Typography variant="subtitle2">{user.email}</Typography> : null}
      </Box>
      <Menu
        menuItemStyles={{
          button: ({ level, active }) => {
            return {
              backgroundColor: active ? theme.palette.neutral.medium : undefined,
            };
          },
        }}
      >
        {navItems.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Item to={item.to} name={item.name} icon={item.icon} permi={item.permissions} />
            </React.Fragment>
          );
        })}
      </Menu>
      <br />
      <br />
      <br />
    </Sidebar>
  );
}

export default AppSidebar;
