import { api } from './api';
import urls from '../constants/urls';
import tags from './tags';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSessionUser: builder.query({
      query: () => ({
        url: urls.USERS + '/session',
      }),
      providesTags: [tags.SESSION_USER],
    }),
    getUsers: builder.query({
      query: (params) => ({
        url: urls.USERS,
        params: params,
      }),
    }),
    getUsersByRole: builder.query({
      query: (params) => ({
        url: urls.USERS + '/role',
        params: params,
      }),
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: urls.USERS + '/create',
        method:'POST',
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useGetSessionUserQuery,
  useLazyGetSessionUserQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUsersByRoleQuery,
  useCreateUserMutation
} = userApi;
