import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { LoadingIcon } from '../../../components';
import ViewFieldInfoField from './ViewFieldInfoField';
import ViewFieldInfoLab from './ViewFieldInfoLab';
import ViewFieldInfoSamples from './ViewFieldInfoSamples';
import { useParams } from 'react-router-dom';
import { useGetSamplesQuery } from '../../../services/samplesApi';
import useRequest from '../../../hooks/useRequest';

const ViewFieldInfo = ({ fieldInfoResult }) => {
  const [concreteFieldInfo, setConcreteFieldInfo] = useState();
  const { id } = useParams();
  const samplesQuery = useRequest(useGetSamplesQuery, { 'filter[workOrderID]': `equals,${id}` });
  useEffect(() => {
    if (fieldInfoResult) {
      setConcreteFieldInfo(fieldInfoResult);
    }
  }, [fieldInfoResult]);


  // TAB
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ my: 3, p: 0 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (concreteFieldInfo) {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Field Report" {...a11yProps(0)} />
            <Tab label="Samples Report" {...a11yProps(1)} />
            <Tab label="Lab Report" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ViewFieldInfoField concreteFieldInfo={concreteFieldInfo} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ViewFieldInfoSamples  samplesQuery={samplesQuery} concreteFieldInfo={concreteFieldInfo} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ViewFieldInfoLab />
        </TabPanel>
      </Box>
    );
  } else {
    return <LoadingIcon />;
  }
};

export default ViewFieldInfo;
