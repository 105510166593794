import { number, object, string } from "yup";
import useLocale from "../hooks/useLocale";

export const useConcreteMixDesignSchema = () => {
  const text = useLocale();
  const concreteMixDesignCreateSchema = object({
    workOrderID: string().required(),
    suppilerOrganisation: string(),
    plant: string(),
    formula: string().min(1).required(text.REQUIRED),
    specifiedConcreteClass: string(),
    concreteElementLocation: string(),
    specifiedCompressiveStrength: number().nullable().required(text.REQUIRED),
    csaExposureClass: string().required(text.REQUIRED),
    minAirContent: number().nullable().required(text.REQUIRED),
    maxAirContent: number().nullable().required(text.REQUIRED),
    slump: number().nullable().required(text.REQUIRED),
    slumpRange: number().nullable().required(text.REQUIRED),
    plasticizer: number().nullable(),
    plasticizerRange: number().nullable(),
    additionalInformation: string(),
    nominalAggregateSize: number().required(text.REQUIRED),
  });

  const concreteMixDesignEditSchema = object({
    suppilerOrganisation: string(),
    plant: string(),
    formula: string().min(1).required(text.REQUIRED),
    specifiedConcreteClass: string(),
    concreteElementLocation: string(),
    specifiedCompressiveStrength: number().nullable().required(text.REQUIRED),
    csaExposureClass: string().required(text.REQUIRED),
    minAirContent: number().nullable().required(text.REQUIRED),
    maxAirContent: number().nullable().required(text.REQUIRED),
    slump: number().nullable().required(text.REQUIRED),
    slumpRange: number().nullable().required(text.REQUIRED),
    plasticizer: number().nullable(),
    plasticizerRange: number().nullable(),
    additionalInformation: string(),
    nominalAggregateSize: number().required(text.REQUIRED),
  });
  return { concreteMixDesignCreateSchema, concreteMixDesignEditSchema };
};
