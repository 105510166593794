import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHandleError } from '../../hooks/useHandleError';
import useHandleSuccess from '../../hooks/useHandleSuccess';
import { setLoading, clearLoading } from '../../slices/loadingSlice';
import { Autocomplete, Box, Button, Divider, FormHelperText, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import { useLocale } from '../../hooks';
import { useUpdateSampleMutation } from '../../services/samplesApi';
import Icon from '../Icon';
import { useGetCylindersQuery } from '../../services/cylindersApi';
import useRequest from '../../hooks/useRequest';
import CustomAutocomplete from '../../components/customMUI/CustomAutocomplete'
import { useGetAllEquipmentSetQuery } from '../../services/equipmentSetApi';
import { useFormik } from 'formik';
import { useEquipmentSetSchema } from '../../schemas/equipmentSet.schema';
export const EditEquipmentSetDialog = ({
  open,
  sampleQuery,
  isAddEquipmentStatus,
  handleClose,
  sampleID
}) => {
  const dispatch = useDispatch();
  const text = useLocale();
  const [updateObj] = useUpdateSampleMutation();
  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();
  const params = { 'filter[sampleID]': `equals,${sampleID}`, 'sort[customCylinderID]': 'asc' };
  const { refetch } = useRequest(useGetCylindersQuery, params);
  const {equipmentSetUpdateSchema}=  useEquipmentSetSchema();

  const keyMapping = {
    vernierHeight: text.EquipmentSet.F921_522,
    squareTemplate: text.EquipmentSet.F921_EQ,
    straightRulerTemplate: text.EquipmentSet.F921_RE,
    concretePress: text.EquipmentSet.F921_525,
    thicknessGuage: text.EquipmentSet.F921_521,
    thermometer: text.EquipmentSet.F921_512,
    copMaterialStrength: text.EquipmentSet.F921_540,
    scaleProtractor: text.EquipmentSet.F921_523,
    standardStopWatch: text.EquipmentSet.F921_532
  };

  const initialValues = {
    equipmentSetId: sampleQuery?.data?.payload && sampleQuery?.data?.payload?.equipmentSetId
  }
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: equipmentSetUpdateSchema,
    onSubmit: async (values) => {
      console.log(values, 'checkval')
      try {
        let changes = { equipmentSetId: values.equipmentSetId };
        const data = { changes: changes, id: sampleQuery.data.payload.id };
        await updateObj(data);
        handleClose();
        handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.Cylinders.SAMPLE_SUCCESSFUL_UPDATE);
        refetch();
      } catch (e) {
        console.log(e);
        handleClose();
        handleErrorResponse(e, text.Cylinders.SAMPLE_UNSUCCESSFUL_UPDATE);
      }
      dispatch(clearLoading());
    },
  });


  const styleModal = {

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    width: 600,
    minHeight: 500,
    height: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #fff !important',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    outline: 'none !important',
    position: 'relative'
  };

  const equipmentQuery = useRequest(useGetAllEquipmentSetQuery);
  const [equipmentFieils, setEquipmentFields] = useState({});


  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'between' }}>
          <Typography variant={'h6'} pb={2} sx={{ flex: 1,fontSize:'20px',fontWeight:900 }}>
            {isAddEquipmentStatus ? text.EquipmentSet.LABEL_EQUIPMENT_SET_ADD : text.EquipmentSet.LABEL_EQUIPMENT_SET_EDIT}
          </Typography>

          <IconButton onClick={() => {
            handleClose();
            setEquipmentFields({});
          }}
            variant="contained" sx={{ marginBottom: '14px' }}>
            <Icon iconName={'Cancel'} />
          </IconButton>
        </Box>
        <Divider mb={3} />
        <CustomAutocomplete
          label={text.EquipmentSet.EQUIPMENT_NAME}
          initialValue={formik?.initialValues?.equipmentSetId}
          itemsArray={equipmentQuery?.data ? equipmentQuery?.data?.payload : []}
          itemsMapper={(item) => {
            return { id: item.id, label: item.equipmentName };
          }}
          sx={{ mt: 2 }}
          name="equipmentSetId"
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
            const arr = equipmentQuery?.data?.payload;
            let selectedEuipSet = {};
            for (let i = 0; i < arr.length; i++) {
              if (value === arr[i].id) {
                selectedEuipSet = arr[i];
              }
            }
            const filteredOutput = Object.keys(selectedEuipSet).reduce((acc, key) => {
              if (selectedEuipSet[key] && key !== "id" && key !== "equipmentName") {
                const newKey = keyMapping[key] || key; // Use the mapped key or original key if not found in mapping
                acc[newKey] = selectedEuipSet[key];
              }
              return acc;
            }, {});
            setEquipmentFields(filteredOutput);
          }}
        />

        <FormHelperText
          error={formik.touched.equipmentSetId && formik.errors.equipmentSetId ? true : false}
        >
          {formik.errors.equipmentSetId}
        </FormHelperText>

        {equipmentFieils && <Grid container sx={{ my: 2 }} spacing={1}>
          {Object.entries(equipmentFieils).map(([key, value]) => (
            <Grid item xl={6} lg={6} sm={12} xs={12} key={key}>
              <div style={{ alignItems: 'center', justifyContent: 'center', marginBottom: '8px' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '8px' }}>
                  {key}:
                </Typography>
                <Typography variant="body1">
                  {value}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>}
        <Grid container justifyContent={'end'}>
          <Button
            variant="contained"
            sx={{ mt: 2, position: 'absolute', bottom: 30 }}
            onClick={formik.handleSubmit}
            disabled={!formik.dirty}
          >
            Save
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};
