// API Endpoint urls

const urls = {
  LOGIN: "/authentication/login",
  LOGOUT: "/authentication/logout",
  REFRESH: "/authentication/refresh",
  USERS: "/users",
  CLIENTS: "/clients",
  PROJECTS: "/projects",
  WORKORDERS: "/work-orders",
  REGIONS: "/regions",
  LABORATORIES: "/laboratories",

  CONCRETES: "/concrete-pours",

  CONCRETE_FIELD_INFORMATION: "/concrete-field-information",
  CONCRETE_MIX_DESIGN: "/concrete-mix-designs",
  CONCRETING_SEQUENCES: "/concreting-sequences",
  SAMPLES: "/samples",
  SAMPLE_PICKUPS: "/sample-pickups",
  CYLINDERS: "/cylinders",
  PDF_CONCRETE_FIELD_RESULT: "pdf/field-result",
  PDF_SITE_CONCRETE_FIELD_RESULT: "pdf/site-concrete/report",
  PDF_LAB_REPORT_ONTARIO_RESULT: "pdf/lab-report/ontario",
  PDF_LAB_REPORT_QBEC_RESULT: "pdf/lab-report/quebec/project",
  ROLES: "/roles",
  OFFICES: "/offices",
  EQUIPMENT_SET: "/equipment-set",
};

export default urls;
