import { createAsyncThunk } from '@reduxjs/toolkit';
import { sleep } from '../config/utils';
import actions from '../constants/actions';
import settings from '../constants/settings';
import { useLazyGetSessionUserQuery } from '../services/userApi';
import { setPermissions } from '../slices/permissionSlice';
import { setUser } from '../slices/userSlice';
import useLogout from './useLogout';

const useGetSessionUser = () => {
  const [getUser, ,] = useLazyGetSessionUserQuery();
  const logout = useLogout();
  const getSessionUser = createAsyncThunk(actions.POLL_USER, async (params, thunkAPI) => {
    try {
      const auth = thunkAPI.getState().auth;
      if (!auth.token || !auth.expiry) return;

      // Fetch data
      const data = await getUser().unwrap();

      const { permissions, ...user } = data.payload; // Destructure data to get user and permissions seperately
      thunkAPI.dispatch(setUser(user));
      thunkAPI.dispatch(setPermissions(permissions));

      await sleep(settings.USER_POLL_RATE);
      thunkAPI.dispatch(getSessionUser());
    } catch (e) {
      console.log(e.response);
      // if(online) {
      if (e.status === 401) {
        thunkAPI.dispatch(logout());
      }
      // }
    }

    return params;
  });
  return getSessionUser;
};

export default useGetSessionUser;
