import { api } from './api';
import urls from '../constants/urls';

export const laboratoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLabs: builder.query({
      query: () => ({
        url: urls.LABORATORIES,
      }),
    }),
  }),
});

export const { useGetLabsQuery, useLazyGetLabsQuery } = laboratoriesApi;
