import pHome from './home/Home';
import pLogin from './Login';

import pProjects from './projects/Projects';
import pProjectAdd from './projects/ProjectAdd';
import pProjectEdit from './projects/ProjectEdit';
import pProjectDetails from './projects/ProjectDetails';

import pClients from './clients/Clients';
import pClientAdd from './clients/ClientAdd';
import pClientEdit from './clients/ClientEdit';

import pWorkorders from './workorders/WorkOrders';
import pWorkorderAdd from './workorders/WorkOrderAdd';
import pWorkorderEdit from './workorders/WorkOrderEdit';
import pWorkorderDetails from './workorders/WorkOrderDetails';
import pWorkOrderConcreteFieldReportAdd from './workorders/WorkOrderConcreteFieldReportAdd';
import pConcreteAdd from './workorders/components/ConcreteAddButton';

import pSamples from './samples/Samples';
import pSampleCollector from './workorders/SampleCollector';
import pSamplePickupAssigned from './samples/SamplePickupAssigned';
import pSamplePickupDetails from './samples/SamplePickupDetails';
import pLabCylinders from './lab_cylinders/LabCylinders';
import pLabCylindersDetails from './lab_cylinders/LabCylindersDetails';
import pUsers from './users/Users';
import pUserAdd from './users/UserAdd';


export const Home = pHome;
export const Login = pLogin;

export const Projects = pProjects;
export const ProjectAdd = pProjectAdd;
export const ProjectEdit = pProjectEdit;
export const ProjectDetails = pProjectDetails;

export const Workorders = pWorkorders;
export const WorkorderAdd = pWorkorderAdd;
export const WorkorderEdit = pWorkorderEdit;
export const WorkorderDetails = pWorkorderDetails;
export const ConcreteAdd = pConcreteAdd;
export const WorkOrderConcreteFieldReportAdd = pWorkOrderConcreteFieldReportAdd;

export const Samples = pSamples;
export const SampleCollector = pSampleCollector;
export const SamplePickupAssigned = pSamplePickupAssigned;
export const SamplePickupDetails = pSamplePickupDetails;

export const Clients = pClients;
export const ClientAdd = pClientAdd;
export const ClientEdit = pClientEdit;

export const LabCylinders = pLabCylinders;
export const LabCylindersDetails = pLabCylindersDetails;

export const Users = pUsers; 
export const UserAdd = pUserAdd; 

