import React from 'react';
import CustomTextField from '../customMUI/CustomTextField';
import { FormHelperText } from '@mui/material';
import { useEffect } from 'react';

const FormConcrete = ({ formik }) => {
  const tool = 'Concrete';

  useEffect(() => {
    formik.setTouched(true, false);
  }, []);
  return (
    <React.Fragment>
      <CustomTextField name="element" tool={tool} formik={formik} />
      <FormHelperText error={formik.touched.element && formik.errors.element ? true : false}>
        {formik.errors.element}
      </FormHelperText>

      <CustomTextField name="area" tool={tool} formik={formik} />
      <FormHelperText error={formik.touched.area && formik.errors.area ? true : false}>
        {formik.errors.area}
      </FormHelperText>

      <CustomTextField name="volume" tool={tool} formik={formik} type={'number'} />
      <FormHelperText error={formik.touched.volume && formik.errors.volume ? true : false}>
        {formik.errors.volume}
      </FormHelperText>

      <CustomTextField name="samplesAmount" tool={tool} formik={formik} type={'number'} />
      <FormHelperText
        error={formik.touched.samplesAmount && formik.errors.samplesAmount ? true : false}
      >
        {formik.errors.samplesAmount}
      </FormHelperText>
      <CustomTextField name="cylindersPerSample" tool={tool} formik={formik} type={'number'} />
      <FormHelperText
        error={formik.touched.cylindersPerSample && formik.errors.cylindersPerSample ? true : false}
      >
        {formik.errors.cylindersPerSample}
      </FormHelperText>
      <CustomTextField name="cylindersPerFieldCured" tool={tool} formik={formik} type={'number'} />
      <FormHelperText
        error={formik.touched.samplesAmount && formik.errors.samplesAmount ? true : false}
      >
        {formik.errors.cylindersPerFieldCured}
      </FormHelperText>
      {/* <CustomTextField name="additionalDetails" tool={tool} formik={formik} /> */}
    </React.Fragment>
  );
};

export default FormConcrete;
