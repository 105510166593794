import { Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';

import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteDialog from '../../../components/DeleteDialog';
import Icon from '../../../components/Icon';
import PermissionGate from '../../../components/PermissionGate';
import { featurePermissions } from '../../../constants/permissions';
import routes from '../../../constants/routes';
import { useLocale } from '../../../hooks';
import useRequest from '../../../hooks/useRequest';
import { useDeleteConcreteMutation, useGetConcretesQuery } from '../../../services/concreteApi';
import { selectPermissions } from '../../../slices/permissionSlice';
import ConcreteAddButton from './ConcreteAddButton';

/* 
  This component requires the workOrderID, and appears in two areas:
    - WorkOrderDetails: When viewing an existing work order
    - WorkOrderAdd: When creating a work order 
    
  In WorkOrderAdd the workOrderID is a searchParameter, 
  whereas in workOrderDetails the workOrderID is a parameter 
*/

export const ConcretePourTable = ({ workOrderID }) => {
  const text = useLocale();
  const userPermissions = useSelector(selectPermissions);
  const [removeConcretePour] = useDeleteConcreteMutation();
  const navigate = useNavigate();
  const location = useLocation();

  // Styling
  const styles = {
    titleStyle: { fontWeight: 'bold', fontSize: 24, mb: 2 },
    dataGridBoxStyle: {
      width: '100%',
      '& .MuiDataGrid-root': { border: 'none' },
      '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
    },
  };

  const concreteQuery = useRequest(useGetConcretesQuery, {
    'filter[workOrderID]': `equals,${workOrderID}`,
  });

  async function deleteRecord(id) {
    try {
      await removeConcretePour(id);
    } catch (e) {
      console.log(e);
    }
  }
  // Concrete Supervision details Columns
  const ConcreteColumns = [
    {
      field: 'element',
      headerName: text.Concrete.ELEMENT,
      minWidth: 250,
    },

    {
      field: 'area',
      headerName: text.Concrete.AREA,
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'volume',
      headerName: text.Concrete.VOLUME,
      minWidth: 220,
      flex: 1,
    },
    {
      field: 'samplesAmount',
      headerName: text.Concrete.SAMPLESAMOUNT,
      minWidth: 280,
      flex: 1,
    },
    {
      field: 'cylindersPerSample',
      headerName: text.Concrete.CYLINDERSPERSAMPLE,
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'cylindersPerFieldCured',
      headerName: text.Concrete.CYLINDERSPERFIELDCURED,
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'edit',
      headerName: '',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() =>
              navigate(`${routes.CONCRETES}/edit/${params.id}`, {
                state: { background: location },
              })
            }
          >
            <Icon iconName={'Edit'} />
          </IconButton>
        );
      },
    },
    {
      field: 'delete',
      headerName: '',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return <DeleteDialog handleDelete={() => deleteRecord(params.id)} />;
      },
    },
  ];

  return (
    <Card sx={{ m: location.pathname.includes('add') ? '':2, py: 2, px: 1 }}>
      <CardContent>
        <Grid container direction="column">
          <Grid item>
            <Grid container spacing={1} justifyContent="space-between">
              <Typography sx={styles.titleStyle}>{text.Concrete.NAME}</Typography>
              <PermissionGate
                userPermissions={userPermissions}
                componentPermissions={[featurePermissions.CONCRETE_POUR_CREATE]}
              >
                <ConcreteAddButton workOrderID={workOrderID} />
              </PermissionGate>
            </Grid>
          </Grid>
          <Grid item height="35vh" sx={styles.dataGridBoxStyle}>
             <DataGridPro
              rows={concreteQuery.data ? concreteQuery.data.payload : []}
              columns={ConcreteColumns}
              pagination
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
