import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect, useRef } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
import useGetSessionUser from '../../hooks/useGetSessionUser';
import useTokenRefresh from '../../hooks/useTokenRefresh';
import { selectAuth } from '../../slices/authSlice';
import { selectUser } from '../../slices/userSlice';
import LoadingIcon from '../LoadingIcon';
import { AppSidebar, AppTopbar } from '../index';

const Protected = () => {
  const auth = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getSessionUser = useGetSessionUser();
  const tokenRefresh = useTokenRefresh();
  const effectRan = useRef(false);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!auth.token) {
      navigate(routes.LOGIN);
    }
    // eslint-disable-next-line
  }, [auth.token]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' || effectRan.current === true) {
      dispatch(tokenRefresh());
      console.log('Checking User...');
      dispatch(getSessionUser());
    }
    return () => (effectRan.current = true);
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {

  //   dispatch(clearLoading());
  // }, []);
  return (
    <React.Fragment>
      {user.id ? (
        <ProSidebarProvider>
          <CssBaseline />
          <AppTopbar />
          <Box sx={styles.container}>
            <AppSidebar />
            <Box component={'main'} sx={styles.mainSection}>
              <Outlet />
            </Box>
          </Box>
        </ProSidebarProvider>
      ) : (
        <LoadingIcon />
      )}
    </React.Fragment>
  );
};

export default Protected;

/**
 * @type {import('@mui/material').SxProps}
 */
const styles = {
  container: {
    display: 'flex',
    bgcolor: 'neutral.light',
    height: 'calc(100% - 64px)',
  },
  mainSection: {
    p: 2,
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
};
