import React from 'react';
import { useParams } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { useGetSampleByWorkOrderQuery } from '../../../services/samplesApi';
import ViewLabReport from './ViewLabReport';
import { LoadingIcon } from '../../../components';

const ViewFieldInfoLab = ({ data }) => {
  const { id } = useParams();
  const sampleByWorkOrderQuery = useRequest(useGetSampleByWorkOrderQuery, id);
  const sampleData = sampleByWorkOrderQuery.data && Array.isArray(sampleByWorkOrderQuery.data.payload) ? sampleByWorkOrderQuery.data.payload : [];

  if (sampleData) {
  return (
    <React.Fragment>
         <ViewLabReport data ={sampleData} />
    </React.Fragment>
  );
  } else {
   return <LoadingIcon />;
 }
};

export default ViewFieldInfoLab;