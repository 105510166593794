import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null, expiry: null },
    reducers: {
        setToken(state, action) {
            return {...action.payload}
        },
        clearToken(state, action) {
            return { token: null, expiry: null }
        }
        
    },
})

// Actions
export const { setToken, clearToken } = authSlice.actions

// Selecters
export const selectAuth = (state) => state.auth

export default authSlice