import { api } from "./api";
import urls from "../constants/urls";
import tags from "./tags";

export const concretingSequenceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addConcretingSequence: builder.mutation({
      query: (data) => ({
        url: urls.CONCRETING_SEQUENCES,
        method: "POST",
        body: { ...data },
      }),
    }),

    // GET all ConcretingSequences
    getConcretingSequences: builder.query({
      query: (params) => ({
        url: urls.CONCRETING_SEQUENCES,
        params: params,
      }),
      providesTags: [tags.CONCRETING_SEQUENCE],
    }),

    getConcretingSequence: builder.query({
      query: (id) => ({
        url: urls.CONCRETING_SEQUENCES + "/" + id,
      }),
    }),

    updateConcretingSequence: builder.mutation({
      query: (data) => ({
        url: urls.CONCRETING_SEQUENCES + "/" + data.id,
        method: "PATCH",
        body: { ...data.changes },
      }),
    }),

    deleteConcretingSequence: builder.mutation({
      query: (id) => ({
        url: urls.CONCRETING_SEQUENCES + "/" + id,
      }),
    }),
  }),
});

export const {
  useAddConcretingSequenceMutation,
  useGetConcretingSequenceQuery,
  useGetConcretingSequencesQuery,
  useUpdateConcretingSequenceMutation
} = concretingSequenceApi;
