import { api } from './api';
import tags from './tags';
import urls from '../constants/urls';
import refreshCache from '../utils/refreshCache';

export const workordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET all WorkOrders
    getWorkOrders: builder.query({
      query: (params) => ({
        url: urls.WORKORDERS,
        params: params,
      }),
      providesTags: [tags.WORKORDER],
    }),
    getWorkOrdersRelatedToUser: builder.query({
      query: (userID) => ({
        url: `${urls.WORKORDERS}/related-to-user/${userID}`,
      }),
      providesTags: [tags.WORKORDER],
    }),

    // GET one WorkOrder
    getWorkOrder: builder.query({
      query: (id) => ({
        url: urls.WORKORDERS + '/' + id,
      }),
      providesTags: [tags.WORKORDER],
    }),

    // CREATE a WorkOrder
    addWorkOrder: builder.mutation({
      query: (data) => ({
        url: urls.WORKORDERS,
        method: 'POST',
        body: { ...data },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.WORKORDER]);
        } catch (e) {}
      },
    }),

    // EDIT a workOrder
    updateWorkOrder: builder.mutation({
      query: (data) => ({
        url: urls.WORKORDERS + '/' + data.id,
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.WORKORDER]);
        } catch (e) {}
      },
    }),

    // DELETE a workOrder
    deleteWorkOrder: builder.mutation({
      query: (id) => ({
        url: urls.WORKORDERS + '/' + id,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.WORKORDER]);
        } catch (e) {}
      },
    }),
  }),
});

export const {
  useLazyGetWorkOrdersQuery,
  useGetWorkOrderQuery,
  useGetWorkOrdersQuery,
  useAddWorkOrderMutation,
  useUpdateWorkOrderMutation,
  useDeleteWorkOrderMutation,
  useGetWorkOrdersRelatedToUserQuery,
  useLazyGetWorkOrderQuery,
} = workordersApi;
