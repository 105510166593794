import React from 'react';
import { CustomDataGrid, CustomGridToolbar, FlexBetween, Header, LoadingIcon } from '../../components';
import routes from '../../constants/routes';
import { useGetElement, useLocale } from '../../hooks';
import tags from '../../services/tags';
import useRequest from '../../hooks/useRequest';
import { useGetAllEquipmentSetQuery } from '../../services/equipmentSetApi';
import { DataGridPro } from '@mui/x-data-grid-pro';
function EquipmentSet() {
  const text = useLocale();
  const navItem = useGetElement(text.EquipmentSet.NAME);
  const minColumnWidth = 150;

  // READ Functions
  const equipmentQuery = useRequest(useGetAllEquipmentSetQuery);

  const columns = [
    {
      field: 'equipmentName',
      headerName: text.EquipmentSet.EQUIPMENT_NAME,
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        return params.row.equipmentName
      },
      renderCell: (params) => {
        return params.row.equipmentName
      },

    },
    {
      field: 'thermometer',
      headerName: text.EquipmentSet.F921_512,
      minWidth: minColumnWidth,
      flex: 1,
      valueGetter: (params) => {
        return params.row.thermometer
      },
      renderCell: (params) => {
        return params.row.thermometer
      },
    },
    {
      field: 'thicknessGuage',
      headerName: text.EquipmentSet.F921_521,
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        return params.row.thicknessGuage
      },
      renderCell: (params) => {
        return params.row.thicknessGuage
      },
    },
    {
      field: 'vernierHeight',
      headerName: text.EquipmentSet.F921_522,
      minWidth: 210,
      flex: 1,
      valueGetter: (params) => {
        return params.row.vernierHeight
      },
      renderCell: (params) => {
        return params.row.vernierHeight
      },
    },
    {
      field: 'scaleProtractor',
      headerName: text.EquipmentSet.F921_523,
      minWidth: 210,
      flex: 1,
      valueGetter: (params) => {
        return params.row.scaleProtractor
      },
      renderCell: (params) => {
        return params.row.scaleProtractor
      },
    },
    {
      field: 'concretePress',
      headerName: text.EquipmentSet.F921_525,
      minWidth: 210,
      flex: 1,
      valueGetter: (params) => {
        return params.row.concretePress
      },
      renderCell: (params) => {
        return params.row.concretePress
      },
    },
    {
      field: 'standardStopWatch',
      headerName: text.EquipmentSet.F921_532,
      minWidth: 210,
      flex: 1,
      valueGetter: (params) => {
        return params.row.standardStopWatch
      },
      renderCell: (params) => {
        return params.row.standardStopWatch
      },
    },
    {
      field: 'copMaterialStrength',
      headerName: text.EquipmentSet.F921_540,
      minWidth: 210,
      flex: 1,
      valueGetter: (params) => {
        return params.row.copMaterialStrength
      },
      renderCell: (params) => {
        return params.row.copMaterialStrength
      },
    },
    {
      field: 'squareTemplate',
      headerName: text.EquipmentSet.F921_EQ,
      minWidth: 210,
      flex: 1,
      valueGetter: (params) => {
        return params.row.squareTemplate
      },
      renderCell: (params) => {
        return params.row.squareTemplate
      },
    },
    {
      field: 'straightRulerTemplate',
      headerName: text.EquipmentSet.F921_RE,
      minWidth: 210,
      flex: 1,
      valueGetter: (params) => {
        return params.row.straightRulerTemplate
      },
      renderCell: (params) => {
        return params.row.straightRulerTemplate
      },
    },

  ];

  return (
    <React.Fragment>
      <FlexBetween>
        <Header
          title={navItem.name}
          icon={navItem.icon}
          bcrumb={text.EquipmentSet.TABLENAME}
          AddTo={routes.EQUIPMENT_SET_ADD}
        />
      </FlexBetween>
      {equipmentQuery.isLoading ? (
        <LoadingIcon />
      ) : (
        <DataGridPro
          sx={{
            height: "60em"
          }}
          rows={equipmentQuery.data ? equipmentQuery?.data?.payload : []}
          slots={{ toolbar: () => CustomGridToolbar({ tags: tags }) }}
          columns={columns}
          columnHeader
          pagination
        />
      )}
    </React.Fragment>
  );
}

export default EquipmentSet;
