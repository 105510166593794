import { Autocomplete, Box, FormHelperText, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocale } from '../../../hooks';
import { ButtonBack } from '../../../components/customMUI/ButtonBack';
import { ButtonSubmit } from '../../../components/customMUI/ButtonSubmit';
import CustomAutocomplete from '../../../components/customMUI/CustomAutocomplete';
import useRequest from '../../../hooks/useRequest';

import { useGetRolesQuery } from '../../../services/rolesApi';
import { useGetOfficesQuery } from '../../../services/OfficeApi';

import { roleType } from '../../../constants/options';
import { useGetLabsQuery } from '../../../services/laboratoriesApi';

const FormUsers = ({ formik, btIcon, btText, userType, setUserType, setIsLabSupervisorSelected }) => {
  const text = useLocale();
  const { data } = useGetRolesQuery();
  const [allLaboratories, setAllLaboratories] = useState();
  const [currentRole, setCurrentRole] = useState('')
  // Set formik touch to true to see fields
  // labelled as 'Required' on first render
  const { data: laboratories } = useGetLabsQuery();
  useEffect(() => {
    formik.setTouched(true, false);
  }, []);

  useEffect(() => {
    const arr = [];
    for (let i in laboratories?.payload) {
      arr.push({
        id: laboratories?.payload[i].id,
        label: laboratories?.payload[i]?.name + `(${laboratories?.payload[i]?.region.name})`
      })
    }
    setAllLaboratories(arr);
  }, [laboratories]);


  const handleChangeUser = (event, newValue) => {
    setUserType(newValue);
    formik.setValues({ ...formik.values, userType: newValue });
    formik.resetForm();
    formik.setFieldValue('roleId', '');
    setCurrentRole('')
    formik.setValues({ ...formik.values, roleId: '', preferredLaboratoryName: '',preferredLaboratoryId:'' });
  };

  const findRoleType = (value) => {
    const allRoles = data?.payload;
    for (let i = 0; i < allRoles.length; i++) {
      if (allRoles[i]?.id === value) {
        setCurrentRole(allRoles[i]?.title);
        setIsLabSupervisorSelected(allRoles[i]?.title === roleType.LAB_SUPERVISOR)
      }
    }
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <ToggleButtonGroup
        sx={{ mt: 2 }}
        value={userType}
        exclusive
        onChange={handleChangeUser}
      >
        <ToggleButton value={text.Users.GHD_USER}>
          GHD User
        </ToggleButton>
        <ToggleButton value={text.Users.OUTSIDE_USER}>
          Outside User
        </ToggleButton>
      </ToggleButtonGroup>
      {userType == text.Users.GHD_USER ?
        <>
          <CustomAutocomplete
            makeRequest={useRequest}
            initialValue={formik.initialValues.roleId}
            itemsQuery={useGetRolesQuery}
            itemsMapper={(item) => {
              return { id: item.id, label: item.title };
            }}
            sx={{ mt: 1.2 }}
            name="roleId"
            onChange={(name, value) => {
              findRoleType(value)
              formik.setFieldValue(name, value)
            }}
            label={text.Users.USERTYPE}
            handleEmptyField={true}
          />

          <FormHelperText
            error={
              formik.touched.roleId && formik.errors.roleId
                ? true
                : false
            }
          >
            {formik.touched.roleId && formik.errors.roleId && formik.errors.roleId}
          </FormHelperText>

          {currentRole === roleType.LAB_SUPERVISOR ? <>

            <Autocomplete
              fullWidth
              sx={{ mt: 2 }}
              disablePortal
              options={allLaboratories}
              value={formik?.values?.preferredLaboratoryName}
              onChange={(ev, value) => {
                formik.setFieldValue('preferredLaboratoryId', value?.id);
                formik.setFieldValue('preferredLaboratoryName', value?.label);

              }}
              renderInput={(params) => <TextField {...params} label={text.Laboratory.TITLE} />
              }
            />


            <FormHelperText
              error={formik.touched.preferredLaboratoryId  &&
                formik.errors.preferredLaboratoryId
                  ? true
                  : false
              }
            >
              {!formik.values.preferredLaboratoryId && formik.touched.preferredLaboratoryId && formik.errors.preferredLaboratoryId}
            </FormHelperText>
          </> : <></>}

          <TextField
            label={text.Users.EMAIL}
            value={formik.values.email}
            name={'email'}
            onChange={formik.handleChange}
            fullWidth
            sx={{ mt: 2 }}
            type={'text'}
          />

          <FormHelperText
            error={formik.touched.email && formik.errors.email ? true : false}
          >
            {formik.touched.email && formik.errors.email && formik.errors.email}
          </FormHelperText>
        </>
        :
        <>
          <CustomAutocomplete
            makeRequest={useRequest}
            initialValue={formik.initialValues.roleId}
            itemsQuery={useGetRolesQuery}
            itemsMapper={(item) => {
              return { id: item.id, label: item.title };
            }}
            sx={{ mt: 1.2 }}
            name="roleId"
            onChange={(name, value) => {
              findRoleType(value)
              formik.setFieldValue(name, value)
            }}
            label={text.Users.USERTYPE}
          />

          <FormHelperText
            error={
              formik.touched.roleId && formik.errors.roleId
                ? true
                : false
            }
          >
            {formik.touched.roleId && formik.errors.roleId && formik.errors.roleId}
          </FormHelperText>

          {currentRole === roleType.LAB_SUPERVISOR ? <>

            <Autocomplete
              fullWidth
              sx={{ mt: 2 }}
              disablePortal
              options={allLaboratories}
              value={formik?.values?.preferredLaboratoryName}
              onChange={(ev, value) => {
                formik.setFieldValue('preferredLaboratoryId', value?.id);
                formik.setFieldValue('preferredLaboratoryName', value?.label);
              }}
              renderInput={(params) => <TextField {...params} label={text.Laboratory.TITLE} />
              }
            />
        <FormHelperText
              error={formik.touched.preferredLaboratoryId  &&
                formik.errors.preferredLaboratoryId
                  ? true
                  : false
              }
            >
              {!formik.values.preferredLaboratoryId && formik.touched.preferredLaboratoryId && formik.errors.preferredLaboratoryId}
            </FormHelperText>
          </> : <></>}

          <TextField
            label={text.Users.FIRSTNAME}
            value={formik.values.firstName}
            name={'firstName'}
            onChange={formik.handleChange}
            fullWidth
            sx={{ mt: 2 }}
            type={'text'}
          />
          <FormHelperText
            error={formik.touched.firstName && formik.errors.firstName ? true : false}
          >
            {formik.touched.firstName && formik.errors.firstName && formik.errors.firstName}
          </FormHelperText>

          <TextField
            label={text.Users.LASTNAME}
            value={formik.values.lastName}
            name={'lastName'}
            onChange={formik.handleChange}
            fullWidth
            sx={{ mt: 2 }}
            type={'text'}
          />

          <FormHelperText
            error={formik.touched.lastName && formik.errors.lastName ? true : false}
          >
            {formik.touched.lastName && formik.errors.lastName && formik.errors.lastName}
          </FormHelperText>


          <TextField
            label={text.Users.EMAIL}
            value={formik.values.email}
            name={'email'}
            onChange={formik.handleChange}
            fullWidth
            sx={{ mt: 2 }}
            type={'text'}
          />

          <FormHelperText
            error={formik.touched.email && formik.errors.email ? true : false}
          >
            {formik.touched.email && formik.errors.email && formik.errors.email}
          </FormHelperText>

          {/* <CustomAutocomplete
            makeRequest={useRequest}
            initialValue={formik.initialValues.officeID}
            itemsQuery={useGetOfficesQuery}
            itemsMapper={(item) => {
              return { id: item.id, label: item.name };
            }}
            sx={{ mt: 1.2 }}
            name="officeID"
            onChange={(name, value) => { formik.setFieldValue(name, value) }}
            label={text.Users.OFFICE}
          />

          <FormHelperText
            error={formik.touched.officeID && formik.errors.officeID ? true : false}
          >
            {formik.errors.officeID}
          </FormHelperText>

          <TextField
            label={text.Users.TELEPHONE}
            value={formik.values.telephone}
            name={'telephone'}
            onChange={formik.handleChange}
            fullWidth
            sx={{ mt: 2 }}
            type={'text'}
          />

          <FormHelperText
            error={formik.touched.telephone && formik.errors.telephone ? true : false}
          >
            {formik.errors.telephone}
          </FormHelperText> */}

          <TextField
            label={text.Users.PASSPWORD}
            value={formik.values.password}
            name={'password'}
            onChange={formik.handleChange}
            fullWidth
            sx={{ mt: 2 }}
            type={'password'}
          />

          <FormHelperText
            error={formik.touched.password && formik.errors.password ? true : false}
          >
            {formik.touched.password && formik.errors.password && formik.errors.password}
          </FormHelperText>

          <TextField
            label={text.Users.CONFIRMPASSWORD}
            value={formik.values.confirmPassword}
            name={'confirmPassword'}
            onChange={formik.handleChange}
            fullWidth
            sx={{ mt: 2 }}
            type={'password'}
          />

          <FormHelperText
            error={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
          >
            {formik.touched.confirmPassword && formik.errors.confirmPassword && formik.errors.confirmPassword}
          </FormHelperText>

        </>
      }


      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <ButtonBack />
        <ButtonSubmit btIcon={btIcon} btText={btText} />
      </Grid>
    </form>
  );
};

export default FormUsers;
