import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocale } from "../../hooks";
import EditIcon from "@mui/icons-material/Edit";
import { EditCylinderCustomIdDialog } from "../lab/EditCylinderCustomIdDialog";
import CustomDatePicker from "../customMUI/CustomDatePicker";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { selectUser } from "../../slices/userSlice";
const FormCylindersLab = ({ formik, cylinderID, mindate }) => {
  // Modal
  const [open, setOpen] = React.useState(false);
  const [valueCylinderCustomID, setValueCylinderCustomID] = React.useState();
  const [enableCylinderCustomID, setEnableCylinderCustomID] = React.useState(false);
  const [conformity, setConformity] = useState("");
  const [selected, setSelected] = useState();
  const [differenceDate, setDifferenceDate] = useState(0);
  const user = useSelector(selectUser);
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const handleOpen = () => {
    setOpen(true);
    setValueCylinderCustomID(formik.values.customCylinderID);
    setEnableCylinderCustomID(false);
  };

  const text = useLocale();

  const handleRequired = () => {
    formik.values.required === true
      ? formik.setFieldValue("required", false)
      : formik.setFieldValue("required", true);
  };

  const handleConformity = () => {
    formik.values.conformity === true
      ? formik.setFieldValue("conformity", false)
      : formik.setFieldValue("conformity", true);
  };

  useEffect(() => {
    if (formik.values.load && formik.values.diameter) {
      const radius = formik.values.diameter / 2;
      const area = Math.PI * radius * radius;
      const strength = formik.values.load / area;
      formik.setFieldValue("compressiveStrength", (strength * 1000).toFixed(1));
    }
  }, [formik.values.load, formik.values.diameter]);

  useEffect(() => {
    if (differenceDate > 0 && differenceDate == undefined) {
      setDifferenceDate(0);
      formik.setValues({ ...formik.values, daysToBeCured: 0 });
    }
    if (differenceDate) {
      formik.setValues({
        ...formik.values,
        daysToBeCured: formik.values.daysToBeCured + differenceDate,
      });
    }
  }, [differenceDate]);
  // Styling
  const XS = 12;
  const SM = 12;
  const MD = 2;
  const LG = 2;

  const handleChangeConformity = (event, newValue) => {
    if (newValue === CONFORMITY) {
      setSelected(CONFORMITY);
      formik.setFieldValue("conformity", true);
    } else if (newValue === NON_CONFORMITY) {
      formik.setFieldValue("conformity", false);
      setSelected(NON_CONFORMITY);
    }
    setConformity(newValue);
  };

  const CONFORMITY = "conformity";
  const NON_CONFORMITY = "non-conformity";

  useEffect(() => {
    if (formik.values.conformity) {
      setSelected(CONFORMITY);
    } else {
      setSelected(NON_CONFORMITY);
    }
  }, [formik.values]);

  useEffect(() => {
    if (user.roles) {
      if (user.roles.includes("Super User") || user.roles.includes("Laboratory Supervisor")) {
        setDisableSaveButton(false);
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h5" sx={{ mb: 0 }}>
            {text.Cylinders.CYLINDER}&nbsp;{formik.values.customCylinderID}
          </Typography>
          <EditIcon onClick={handleOpen} variant="contained" sx={{ cursor: "pointer", ml: 2 }} />
        </Box>
        <EditCylinderCustomIdDialog
          open={open}
          valueCylinderCustomID={valueCylinderCustomID}
          enableCylinderCustomID={enableCylinderCustomID}
          setEnableCylinderCustomID={setEnableCylinderCustomID}
          setValueCylinderCustomID={setValueCylinderCustomID}
          id={cylinderID}
          handleClose={() => setOpen(false)}
        />
        {/* Top Half */}
        <Grid container columnSpacing={2} sx={{ mb: 2 }} rowSpacing={2}>
          <Grid item xs={XS} sm={SM} md={MD} lg={LG}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={text.Cylinders.LABEL_MASS}
              value={formik.values.mass}
              name={"mass"}
              onChange={formik.handleChange}
              type={"number"}
            />
          </Grid>
          <Grid item xs={XS} sm={SM} md={MD} lg={LG}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={text.Cylinders.LABEL_LENGTH}
              value={formik.values.length}
              name={"length"}
              onChange={formik.handleChange}
              type={"number"}
            />
          </Grid>
          <Grid item xs={XS} sm={SM} md={MD} lg={LG}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={text.Cylinders.LABEL_DIAMETER}
              value={formik.values.diameter}
              name={"diameter"}
              onChange={formik.handleChange}
              type={"number"}
            />
          </Grid>

          <Grid item xs={XS} sm={SM} md={MD} lg={LG}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minWidth: 220,
                bgcolor: "#f0f0f0",
                p: 1,
                borderRadius: 1,
              }}
            >
              <Typography sx={{ color: "grey", fontSize: 12 }}>
                {text.Cylinders.LABEL_UNIT_WEIGHT}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: 15 }}>
                  {isNaN(formik.values.unitWeight) ? "" : formik.values.unitWeight}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Divider orientation={"vertical"} flexItem sx={{ p: 1 }} />

          <Grid item xs={XS} sm={SM} md={MD} lg={1}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={text.Cylinders.LABEL_DAYS_TO_BE_CURED}
              value={formik.values.daysToBeCured}
              name={"daysToBeCured"}
              onChange={(ev) => {
                if (ev.target.value == 0) {
                  formik.setValues({ ...formik.values, dateToBeTested: mindate });
                }
                formik.handleChange(ev);
              }}
              type={"number"}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Grid>
          <Grid item xs={XS} sm={SM} md={MD} lg={LG}>
            <CustomDatePicker
              name="siteVisitDate"
              minDate={mindate}
              label={text.Cylinders.LABEL_DATE_TO_BE_TESTED}
              initialValue={formik.values.dateToBeTested}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
                const selectedDate = DateTime.fromISO(
                  formik.values.dateToBeTested ? formik.values.dateToBeTested : mindate
                );
                const futureDate = DateTime.fromISO(value);
                setDifferenceDate(
                  Math.round(futureDate.diff(selectedDate, "days").toObject().days)
                );
              }}
            />
            <FormHelperText
              error={formik.touched.siteVisitDate && formik.errors.siteVisitDate ? true : false}
            >
              {formik.errors.siteVisitDate}
            </FormHelperText>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                border: '1px solid gray',
                // bgcolor: "#f0f0f0",
                p: 1,
                borderRadius: 1,
              }}
            >
              <Typography sx={{ fontSize: 12, color: "grey" }}>
                {text.Cylinders.LABEL_DATE_TO_BE_TESTED}
              </Typography>
              <Typography sx={{ fontSize: 15 }}>{dateToBeTested}</Typography>
            </Box> */}
          </Grid>
        </Grid>
        {/* Bottom Half */}
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={XS} sm={SM} md={MD} lg={LG}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={text.Cylinders.LABEL_LOAD}
              value={formik.values.load}
              name={"load"}
              onChange={formik.handleChange}
              type={"number"}
            />
          </Grid>
          <Grid item xs={XS} sm={SM} md={MD} lg={LG}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={text.Cylinders.LABEL_TYPE_OF_FRACTURE}
              value={formik.values.typeOfFracture}
              name={"typeOfFracture"}
              onChange={formik.handleChange}
              type={"number"}
            />
          </Grid>

          <Grid item xs={XS} sm={SM} md={MD} lg={LG}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minWidth: 220,
                bgcolor: "#f0f0f0",
                p: 1,
                borderRadius: 1,
              }}
            >
              <Typography sx={{ color: "grey", fontSize: 12 }}>
                {text.Cylinders.LABEL_COMPRESSIVE_STRENGTH}
              </Typography>

              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: 15 }}>{formik.values.compressiveStrength}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid lg={2}>
            <TextField
              fullWidth
              sx={{
                mt: 2,
                mx: 1
              }}
              InputLabelProps={{ shrink: true }}
              label={text.Cylinders.ADDITIONALDETAILS}
              value={formik.values.additionalInformation}
              name={"additionalInformation"}
              onChange={formik.handleChange}
              type={"text"}
            />
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Grid container rowSpacing={2}>
              <Grid item>
                <Button variant={"outlined"} sx={{ mr: 2 }} onClick={handleRequired}>
                  {formik.values.required
                    ? text.Cylinders.LABEL_MARK_AS_NOT_REQUIRED
                    : text.Cylinders.LABEL_MARK_AS_REQUIRED}
                </Button>
              </Grid>
              <Grid item>
                <ToggleButtonGroup value={conformity} exclusive onChange={handleChangeConformity}>
                  <ToggleButton
                    selected={selected === CONFORMITY}
                    sx={{
                      pr: 4,
                      pl: 4,
                      height: 37,
                      "&.Mui-selected": {
                        backgroundColor: "green !important",
                        color: "white !important",
                      },
                    }}
                    value={CONFORMITY}
                  >
                    {text.ConcretingSequence.LABEL_CONFORMITY}
                  </ToggleButton>
                  <ToggleButton
                    selected={selected === NON_CONFORMITY}
                    sx={{
                      height: 37,
                      pr: 4,
                      pl: 4,
                      "&.Mui-selected": {
                        backgroundColor: "#ff0055 !important",
                        color: "white !important",
                      },
                    }}
                    value={NON_CONFORMITY}
                  >
                    {text.ConcretingSequence.LABEL_NON_CONFORMITY}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                {!disableSaveButton && (
                  <Button
                    onClick={formik.handleSubmit}
                    disabled={formik.dirty ? false : true}
                    variant="contained"
                    color="primary"
                  >
                    {text.Buttons.SAVE}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default FormCylindersLab;
