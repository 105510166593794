import React from 'react';
import FormSampleInformation from '../forms/FormSampleInformation';
const SampleInformation = ({ formikSampleInformation, filteredSamplesOnWorkOrder = [] }) => {
  return (
    <React.Fragment>
      <FormSampleInformation formik={formikSampleInformation} filteredSamplesOnWorkOrder={filteredSamplesOnWorkOrder} />
    </React.Fragment>
  );
};

export default SampleInformation;