// DataViewConcreteMixDesignEdit
import React, { useEffect } from 'react';
import { FlexBetween, Header, LoadingIcon, CustomGridToolbar, Icon } from '../../components';
import { useGetElement, useLocale } from '../../hooks';
import { useParams } from 'react-router-dom';
import { useGetConcreteMixDesignByWorkOrderIdQuery, useGetConcreteMixDesignQuery, useUpdateConcreteMixDesignMutation } from '../../services/concreteMixDesignApi';
import { DataGridPro, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';
import { Box, Card, CardContent, Grid, TextField, styled } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import { useHandleError } from '../../hooks/useHandleError';
import useHandleSuccess from '../../hooks/useHandleSuccess';
import tags from '../../services/tags';
function DataViewConcreteMixDesignEdit() {
    const text = useLocale();
    const navItem = useGetElement(text.DataView.NAME);
    const { id } = useParams();
    const { handleErrorResponse } = useHandleError();
    const { handleSuccessResponse } = useHandleSuccess();
    const [updateConcreteMixDesign] = useUpdateConcreteMixDesignMutation();
    const [rowModesModel, setRowModesModel] = React.useState({});
    const { data, isLoading } = useGetConcreteMixDesignByWorkOrderIdQuery(id, { refetchOnMountOrArgChange: true });
    const HeaderWithIconRoot = styled('div')(({ theme }) => ({
        display: 'flex !important',
        justifyItems: 'center  !important',
        alignItems: 'center',
        width: '100% !important'
    }));
    const dataGridBoxStyle = {
        width: '100%',
        '& .MuiDataGrid-root': { border: 'none' },
        '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
    };
    let varHeight;
    varHeight = '70vh';

    const [rows, setRows] = React.useState([]);

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }

    const renderEditTextField = (params) => {
        const { id, value, field } = params;
        const handleChange = (event) => {
            const newValue = event.target.value;
            params.api.setEditCellValue({ id, field, value: newValue }, event);
        };
        return (
            <TextField
                value={value || ''}
                onChange={handleChange}
                autoFocus
            />
        );
    };
    const columns = [
        {
            field: 'supplierOrganisation',
            headerName: 'Concrete Supplier Organization',
            flex: 1,
            minWidth: 250,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'plant',
            headerName: 'Plant',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,

        },
        {
            field: 'formula',
            headerName: 'Formula',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'concreteElementLocation',
            headerName: 'Min Air Content',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'csaExposureClass',
            headerName: 'CSA Exposure Class',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'maxAirContent',
            headerName: 'Max Air Content',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'minAirContent',
            headerName: 'Min Air Content',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            headerName: '',
            flex: 1,
            minWidth: 150,
            field: 'actions',
            type: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Icon iconName={"Save"} />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<Icon iconName={"Cancel"} />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Icon iconName={"Edit"} />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },

        },
    ];

    const processRowUpdate = async (newRow) => {
        const oldRow = rows.find((row) => row.id === newRow.id);
        const updatedFields = {};
        updatedFields.id = newRow.id;
        Object.keys(newRow).forEach((key) => {
            if (newRow[key] !== oldRow[key]) {
                updatedFields[key] = newRow[key];
            }
        });
        const data = {
            id: newRow.id,
            changes: { ...updatedFields }
        }
        try {
            const req = await updateConcreteMixDesign(data);
            if (req?.error) {
                handleErrorResponse(req?.error);
                return rows.filter((row) => row.id === newRow.id)[0];
            }
            handleSuccessResponse(text.ConcreteMixDesign.SUCCESSFUL_UPDATE);
            setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
            return newRow;
        } catch (error) {
            handleErrorResponse(error);
            return rows.filter((row) => row.id === newRow.id)[0];
        }
    };

    function HeaderWithIcon(props) {
        const { icon, ...params } = props;

        return (<>

            <HeaderWithIconRoot>
                <div style={{
                    position: 'absolute',
                    left: "33vw",
                }}> {params.headerName ?? params.groupId} </div>
            </HeaderWithIconRoot>

        </>
        );
    }

    const columnGroupingModel = [
        {
            groupId: 'concrete_mix_design',
            headerName: 'Concrete Mix Design',
            description: '',
            headerClassName: 'my-super-theme--naming-group',
            renderHeaderGroup: (params) => (
                <Grid container >
                    <HeaderWithIcon {...params} icon={<BuildIcon fontSize="small" />} />
                </Grid>
            ),
            children: [
                { field: 'supplierOrganisation' },
                { field: 'plant' },
                { field: 'formula' },
                { field: 'csaExposureClass' },
                { field: 'maxAirContent' },
                { field: 'minAirContent' },
                { field: 'concreteElementLocation' },
                { field: 'actions' },
            ],
        },
    ];

    useEffect(() => {
        if (data?.payload) {
            setRows(data?.payload)
        }
    }, [data?.payload]);


    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingIcon />
            ) : (
                <Card height={varHeight} sx={{ ...dataGridBoxStyle }}>
                    <CardContent>
                        <Box
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: 500,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <DataGridPro
                                rows={rows}
                                editMode='row'
                                columns={columns}
                                columnHeader
                                processRowUpdate={processRowUpdate}
                                rowModesModel={rowModesModel}
                                experimentalFeatures={{ columnGrouping: true }}
                                columnGroupingModel={columnGroupingModel}
                                slotProps={{
                                    toolbar: { setRows, setRowModesModel },
                                }}
                                pagination
                                slots={{ toolbar: () => CustomGridToolbar({ tags: tags }) }}
                                rowHeight={65}
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                       outline: "none !important",
                                    },
                                 }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </React.Fragment>
    );
}

export default DataViewConcreteMixDesignEdit;
