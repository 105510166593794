import { api } from './api';
import urls from '../constants/urls';
import refreshCache from '../utils/refreshCache';
import tags from './tags';
export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: (params) => ({
        url: urls.PROJECTS,
        params: params,
      }),
      providesTags: [tags.PROJECT],
    }),
    getProjectsRelatedToUser: builder.query({
      query: (userID) => ({
        url: `${urls.PROJECTS}/related-to-user/${userID}`,
      }),
      providesTags: [tags.PROJECT],
    }),

    getProject: builder.query({
      query: (id) => ({
        url: urls.PROJECTS + '/' + id,
      }),
      providesTags: [tags.PROJECT],
    }),
    addProject: builder.mutation({
      query: (data) => ({
        url: urls.PROJECTS,
        method: 'POST',
        body: { ...data },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.PROJECT]);
        } catch (e) {}
      },
    }),
    updateProject: builder.mutation({
      query: (data) => ({
        url: urls.PROJECTS + '/' + data.id,
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.PROJECT]);
        } catch (e) {}
      },
    }),
    deleteProject: builder.mutation({
      query: (id) => ({
        url: urls.PROJECTS + '/' + id,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.PROJECT]);
        } catch (e) {}
      },
    }),
  }),
});

export const {
  useLazyGetProjectsQuery,
  useLazyGetProjectQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsRelatedToUserQuery,
} = projectsApi;
