import { Box, Button, Card, CardContent, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FlexBetween, Header, Icon } from '../../components';
import { useGetElement, useLocale } from '../../hooks';
import { useHandleError } from '../../hooks/useHandleError';
import { useHandleSuccess } from '../../hooks/useHandleSuccess';
import { useWorkOrderSchema, workOrderInitialValues } from '../../schemas/workorder.schema';
import {
  useAddWorkOrderMutation,
  useGetWorkOrderQuery,
  useLazyGetWorkOrderQuery,
  useUpdateWorkOrderMutation,
} from '../../services/workordersApi';
import FormWorkOrder from './forms/FormWorkOrder';
import routes from '../../constants/routes';
import { ConcretePourTable } from './components/ConcretePourTable';
import ViewWorkOrder from './forms/ViewWorkOrder';
import useRequest from '../../hooks/useRequest';

function WorkOrderAdd() {
  const steps = ['Work Order', 'Concrete Supervision Details'];
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [addWorkOrder] = useAddWorkOrderMutation();
  const [getWorkOrder] = useLazyGetWorkOrderQuery();
  const [updateWorkOrder] = useUpdateWorkOrderMutation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState(workOrderInitialValues);

  const location = useLocation();
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((previousStep) => previousStep - 1);
    } else {
      console.log('test');
      navigate(-1);
    }
  };

  const handleFinish = () => {
    navigate(`${routes.WORKORDERS}/${searchParams.id}`);
  };

  const text = useLocale();
  const { workorderCreateSchema, workorderEditSchema } = useWorkOrderSchema();
  const navItem = useGetElement(text.Navigation.WORKORDERS);
  const workorderQuery = useRequest(useGetWorkOrderQuery, searchParams.get('id') );
  const workorder = workorderQuery?.data ? workorderQuery?.data.payload : {};
  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();

  const formikCreate = useFormik({
    initialValues: workOrderInitialValues,
    validationSchema: workorderCreateSchema,
    onSubmit: async (values) => {
      try {
        const result = await addWorkOrder(values).unwrap();
        handleSuccessResponse(text.SUCCESSFUL_CREATE, text.WorkOrders.SUCCESSFUL_CREATE);
        setSearchParams({ id: result.payload.id });
      } catch (e) {
        console.log(e);
        handleErrorResponse(e, text.WorkOrders.UNSUCCESSFUL_CREATE);
      }
    },
  });

  const formikUpdate = useFormik({
    initialValues: initialValues,
    validationSchema: workorderEditSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        const id = searchParams.get('id');
        await updateWorkOrder({ changes: values, id: id }).unwrap();
        handleSuccessResponse(text.SUCCESSFUL_UPDATE, text.WorkOrders.SUCCESSFUL_UPDATE);
        resetForm({ values });
      } catch (e) {
        console.log(e);
        handleErrorResponse(e, text.WorkOrders.UNSUCCESSFUL_CREATE);
      }
    },
  });

  useEffect(() => {
    const id = searchParams.get('id');
    console.log('useEffect', id);
    if (id !== undefined && id !== null) {
      loadWorkOrder(id);
    }
  }, [location]);

  async function loadWorkOrder(id) {
    try {
      const result = await getWorkOrder(id).unwrap();

      setInitialValues({
        projectID: result.payload.project.id,
        siteVisitDate: result.payload.siteVisitDate,
        workLocation: result.payload.workLocation,
        siteVisitType: result.payload.siteVisitType,
        arrivalTime: result.payload.arrivalTime,
        timeOnSite: result.payload.timeOnSite,
        laboratoryID: result.payload.laboratory.id,
        fieldTechnicianID: result.payload.fieldTechnician ? result.payload.fieldTechnician.id : '',
        additionalInstructions: result.payload.additionalInstructions,
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.WorkOrders.FORM_ADD} />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep === 0 && !searchParams.get('id') ? (
            <Button
              color="primary"
              variant="contained"
              onClick={formikCreate.handleSubmit}
            >
              {text.Buttons.SAVE}
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </FlexBetween>
      <Box>
        {activeStep === 0 && !searchParams.get('id') ? (
          <FormWorkOrder formik={formikCreate} />
        ) : (
          <></>
        )}
        {activeStep === 0 && searchParams.get('id') ? (
          <Card sx={{my:2, p: 0 }}>
            <CardContent>
              <ViewWorkOrder workOrderId={searchParams.get('id')} data={workorder} />
            </CardContent>
          </Card>
        ) : (
          <></>
        )}


        {activeStep === 0 && searchParams.get('id') ? (<>
          <ConcretePourTable workOrderID={searchParams.get('id')} />
          <Grid container py={2} justifyContent={'end'} display={'flex'}>
            <Button
              sx={{ my: 2, background: "black" }}
              variant="contained"
              color="primary"
              onClick={() =>
                navigate(`${routes.WORKORDER_DETAILS.split(':')[0]}${searchParams.get('id')}`)
              }
            >
              {text.Buttons.COMPLETE}
            </Button>
          </Grid>
        </>
        ) : (
          <></>
        )}
      </Box>

    </React.Fragment >
  );
}

export default WorkOrderAdd;
