import React, {  useEffect, useState } from 'react';
import routes from '../../constants/routes';
// import FormClients from './forms/FormClients';
import { useGetElement, useLocale } from '../../hooks';
import { Header, FlexBetween } from '../../components';
import { useAddClientMutation } from '../../services/clientsApi';

import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Box } from '@mui/material';
import { useFormik } from 'formik';

// import loading
import { useDispatch } from 'react-redux';
import { setLoading, clearLoading } from '../../slices/loadingSlice';
import FormUsers from './forms/FormUsers';
import { useUserSchema } from '../../schemas/user.schema';
import { useCreateUserMutation } from '../../services/userApi';
import { useHandleError } from '../../hooks/useHandleError';
import useHandleSuccess from '../../hooks/useHandleSuccess';
import { toast } from 'react-toastify';
import { useGetOfficesQuery } from '../../services/OfficeApi';

function UserAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userCreateSchemaGhdUser, userCreateSchemaOutsideUser,userCreateSchemaGhdUserWithLabId ,userCreateSchemaOutsideUserWithLabId} = useUserSchema();
  const { handleSuccessResponse } = useHandleSuccess();
  const text = useLocale();
  const navItem = useGetElement(text.Navigation.USERS);
  const { data } = useGetOfficesQuery();
  const {handleErrorResponse} = useHandleError();
  const [initialValues,setInitialValues] = useState( {
    firstName: '',
    lastName: '',
    roleId: '',
    email: '',
    officeID:'',
    telephone: '9876543210',
    confirmPassword: '',
    password: '',
    userType: text.Users.GHD_USER,
    preferredLaboratoryId:''
  });

  useEffect(()=>{
  formik.setFieldValue('officeID', data?.payload[0]?.id)
  },[data])
  const [createUser] = useCreateUserMutation();
  const [userType, setUserType] = useState(text.Users.GHD_USER);
  const [IsLabSupervisorSelected, setIsLabSupervisorSelected] = useState();
  const [currentSchema,setCurrentSchema] = useState();

  useEffect(()=>{
    if(userType === text.Users.GHD_USER && IsLabSupervisorSelected){
      setCurrentSchema(userCreateSchemaGhdUserWithLabId)
    }
    if(userType === text.Users.GHD_USER && !IsLabSupervisorSelected){
      setCurrentSchema(userCreateSchemaGhdUser)
    }
    if(userType === text.Users.OUTSIDE_USER && IsLabSupervisorSelected){
      setCurrentSchema(userCreateSchemaOutsideUserWithLabId)
    }
    if(userType === text.Users.OUTSIDE_USER&& !IsLabSupervisorSelected){
      setCurrentSchema(userCreateSchemaOutsideUser)
    }
  },[userType,IsLabSupervisorSelected])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: currentSchema,
    onSubmit: (values) => addRecord(values),
  });


  useEffect(()=>{
    // formik.resetForm();
    formik.setFieldValue('roleId','')
    formik.setFieldValue('firstName','')
    formik.setFieldValue('email','')
    formik.setFieldValue('lastName','')
    formik.setFieldValue('password','')
    formik.setFieldValue('confirmPassword','')
  },[userType])

  useEffect(()=>{
    console.log(formik.errors,'IsLabSupervisorSelected')
  },[formik.errors])



  async function addRecord(values) {
    const { email, password, officeID, firstName, lastName, telephone, roleId ,userType,preferredLaboratoryId } = values;

    const changes = {
      email,
      password,
      officeID :officeID ? officeID: data?.payload[0]?.id,
      firstName: firstName ?? firstName,
      lastName: lastName ?? lastName,
      telephone,
      userType : userType == text.Users.GHD_USER ? "GHD_USER":"EXTERNAL_USER",
      roleId,
    }
    dispatch(setLoading());
    if(preferredLaboratoryId){
      changes.preferredLaboratoryId = preferredLaboratoryId;
    }

    try {
      const user = await createUser(changes);
      if (user.error) {
        if (userType !== text.Users.GHD_USER) {
          formik.setErrors({
            email: user?.error?.data?.payload?.invalid?.errors[0]
          })
          dispatch(clearLoading());
          return;
        }
        formik.setErrors({
          email: user?.error?.data?.payload?.invalid?.errors[0]
        })
        dispatch(clearLoading());
        return;
      }
      handleSuccessResponse(
        text.SUCCESSFUL_CREATE,
      );
      navigate(routes.USERS);
    } catch (e) {
      console.log(e);

    }
    dispatch(clearLoading());
  }

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.Users.ADD_USER} />
      </FlexBetween>

      <Box height="70vh" sx={{ maxWidth: 700 }}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <FormUsers userType={userType} setIsLabSupervisorSelected={setIsLabSupervisorSelected} setUserType={setUserType} formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
          </CardContent>
        </Card>
      </Box>
    </React.Fragment>
  );
}

export default UserAdd;
