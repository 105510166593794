import { Typography, Breadcrumbs, Link } from "@mui/material";
import routes from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import useGetElement from "../../hooks/useGetElement";

export const AppBreadcrumb = (props) => {
  const navigate = useNavigate();
  const navItem = useGetElement(props.title);

  return (
    <Breadcrumbs aria-label="breadcrumb" maxItems={2}>
      <Link
        underline="hover"
        sx={{ cursor: "pointer" }}
        color="grey"
        onClick={() => navigate(routes.HOME)}
      >
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ cursor: "pointer" }}
        color="grey"
        onClick={() => {
         if(window.location.pathname != '/'+ navItem.to) {
          navigate(navItem.to)
         }
        }}
      >
        {props.title}
      </Link>
      <Typography color="primary">{props.bc}</Typography>
    </Breadcrumbs>
  );
};

export default AppBreadcrumb;
