import { object, string, number, date, boolean } from 'yup';
import { useLocale } from '../hooks';

export const useConcretingSequenceSchema = () => {
  const text = useLocale();

  const concretingSequenceCreateSchema = object({
    workOrderID: string().required(text.REQUIRED),
    loadNumber: string(),
    truckNumber: string(),
    ticketNumber: string(),
    quantityTruckLoad: number().min(0),
    quantityCumulative: number().min(0),
    batchTime: date().required(text.REQUIRED),
    arrivedOnSiteTime: date().required(text.REQUIRED),
    startTime: date().required(text.REQUIRED),
    endTime: date().required(text.REQUIRED),
    concreteMixDesignID: string().required(text.REQUIRED),
    airContent: number().nullable(),
    adjustedAirContent: number().nullable(),
    waterAdditive: number().nullable(),
    spAdditive: number().nullable(),
    concretePourTest: string().nullable(),
    vsiValue: number()
      .integer(text.MUST_BE_BETWEEN_ZERO_AND_THREE)
      .min(0, text.MUST_BE_BETWEEN_ZERO_AND_THREE)
      .max(3, text.MUST_BE_BETWEEN_ZERO_AND_THREE),
    t50Time: number().min(0),
    slumpFlow: number().nullable(),
    slump: number().nullable(),
    adjustedSlump: number().nullable(),
    ambientTemperature: number().nullable(),
    concreteTemperature: number().nullable(),
    // conformity: boolean().nullable(),
    additionalRemarks: string().required(text.REQUIRED),
    collectSamples: boolean(),
    authorizedBy: string(),
    typeOfAdmixtures: string(),
  });

  const concretingSequenceEditSchema = object({
    loadNumber: string(),
    truckNumber: string(),
    ticketNumber: string(),
    quantityTruckLoad: number().min(0),
    quantityCumulative: number().min(0),
    batchTime: date(),
    arrivedOnSiteTime: date().nonNullable(),
    startTime: date().nonNullable(),
    endTime: date().nonNullable(),
    concreteMixDesignID: string().nonNullable(),
    airContent: number().nullable(),
    adjustedAirContent: number().nullable(),
    waterAdditive: number().nullable(),
    spAdditive: number().nullable(),
    concretePourTest: string(),
    vsiValue: number().integer().min(0).max(3),
    t50Time: number().min(0),
    slumpFlow: number().nullable(),
    slump: number().nullable(),
    adjustedSlump: number().nullable(),
    ambientTemperature: number().nullable(),
    concreteTemperature: number().nullable(),
    conformity: boolean().nullable(),
    additionalRemarks: string(),
    collectSamples: boolean(),
    authorizedBy: string(),
    typeOfAdmixtures: string(),
  });
  return { concretingSequenceCreateSchema, concretingSequenceEditSchema };
};
