import { string, object, number, boolean } from 'yup';
import { useLocale } from '../hooks';

export const useCylinderSchema = () => {
  const text = useLocale();
  const cylinderCreateSchema = object().shape({
    customCylinderID: string().required(text.REQUIRED),
    sampleID: string().required(text.REQUIRED),
    daysToBeCured: number().integer().min(0, text.ZERO_OR_GREATER).required(text.REQUIRED),
  });

  const cylinderEditSchema = object().shape({
    customCylinderID: string().required(text.REQUIRED),
    daysToBeCured: number().integer().min(0, text.ZERO_OR_GREATER).required(text.REQUIRED),
    mass: number().nullable(),
    length: number().nullable(),
    diameter: number().nullable(),
    unitWeight: number().nullable(),
    load: number().nullable(),
    typeOfFracture: number().integer().nullable(),
    compressiveStrength: number().nullable(),
    required: boolean(),
    conformity: boolean(),
    dateToBeTested:string(),
    status: string()
      .oneOf(['NOT_REQUIRED', 'TESTED_CONFORMITY', 'TESTED_NONCONFORMITY'])
      .nullable(),
  });

  return { cylinderCreateSchema, cylinderEditSchema };
};
