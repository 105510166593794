import * as yup from 'yup';
import { useLocale } from '../hooks';

export const useEquipmentSetSchema = () => {
  const text = useLocale();

  const equipmentSetCreateSchema = yup.object().shape({
    equipmentName: yup.string().required(text.REQUIRED),
  });

  const equipmentSetUpdateSchema = yup.object().shape({
    equipmentSetId: yup.string().required(text.REQUIRED),
  }); 

  return { equipmentSetCreateSchema,equipmentSetUpdateSchema };
};
