import * as React from 'react';

import {
  Science,
  Assignment,
  LocalShipping,
  Cancel,
  Error,
  Done,
  Block,
  DirectionsRun,
  Refresh,
  MoreHoriz,
  Logout,
  Menu,
  HomeOutlined,
  AddBox,
  Save,
  AccessTime,
  NoteAlt,
  AssignmentTurnedIn,
  AddCircle,
  Add,
  Edit,
  Schedule,
  Delete,
  Check,
  ScienceOutlined,
  Visibility,
  Notifications,
  BorderColor,
  Settings,
  ArrowBack,
  AnalyticsTwoTone,
  SourceTwoTone,
  DashboardTwoTone,
  AssignmentIndTwoTone,
  FolderCopyTwoTone,
  DashboardOutlined,
  AnalyticsOutlined,
  SourceOutlined,
  AssignmentIndOutlined,
  ArrowDropDownOutlined,
  ApartmentOutlined,
  DownloadForOffline,
  DataSaverOff,
  Construction
  
} from '@mui/icons-material';

const Icons = Object.freeze({
  ApartmentOutlined,

  Science,
  Assignment,
  Visibility,
  LocalShipping,
  Cancel,
  Error,
  Done,
  Block,
  DirectionsRun,
  Refresh,
  MoreHoriz,
  Logout,
  Menu,
  HomeOutlined,
  AddBox,
  AccessTime,
  NoteAlt,
  Save,
  Schedule,
  AssignmentTurnedIn,
  AddCircle,
  Add,
  Edit,
  Delete,
  Check,
  ScienceOutlined,
  DataSaverOff,
  Notifications,
  BorderColor,
  Settings,
  ArrowBack,
  AnalyticsTwoTone,
  SourceTwoTone,
  DashboardTwoTone,
  AssignmentIndTwoTone,
  FolderCopyTwoTone,
  DashboardOutlined,
  AnalyticsOutlined,
  SourceOutlined,
  AssignmentIndOutlined,
  ArrowDropDownOutlined,
  DownloadForOffline,
  Construction
});

const Icon = (props) => {
  switch (props.iconFor) {
    case 'NavBar':
      props = { ...props, color: 'neutral', sx: { fontSize: 18 } };
      break;

    case 'Header':
      props = { ...props, fontSize: 'large', color: 'primary', sx: { mr: 2 } };
      break;
    default:
      break;
    // do nothing
  }
  const { iconName, iconFor, ...muiProps } = props;

  return React.createElement(Icons[props.iconName], muiProps);
};

export default Icon;
