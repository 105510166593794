import { Box, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FlexBetween } from '../../../components';
import { ButtonSubmit } from '../../../components/customMUI/ButtonSubmit';
import CustomAutocomplete from '../../../components/customMUI/CustomAutocomplete';
import CustomDatePicker from '../../../components/customMUI/CustomDatePicker';
import { useLocale } from '../../../hooks';
import { useGetUsersByRoleQuery } from '../../../services/userApi';
import useRequest from '../../../hooks/useRequest';

const FormSampleCollector = ({ formik, btIcon, btText }) => {
  const text = useLocale();

  const labelStyle = { fontWeight: 'bold', fontSize: 18 };

  useEffect(() => {
    formik.setTouched(true, false);
  }, []);
  return (
    <form onSubmit={formik.handleSubmit}>
      <FlexBetween>
        <Typography sx={labelStyle}>{text.ConcreteFieldInformation.SAMPLE_COLLECTOR}</Typography>
        {/* <IconButton onClick={() => setIsOpen(true)}>
          <Icon iconName="Cancel" />
        </IconButton> */}
      </FlexBetween>

      <Box>
        <CustomDatePicker
          name="assignedPickupDateTime"
          sx={{ mt: 2, width: '100%' }}
          label={text.Samples.PICKUPDATE}
          initialValue={formik.initialValues.assignedPickupDateTime}
          onChange={(name, value) => formik.setFieldValue(name, value)}
        />
        <FormHelperText
          error={
            formik.touched.assignedPickupDateTime && formik.errors.assignedPickupDateTime
              ? true
              : false
          }
        >
          {formik.errors.assignedPickupDateTime}
        </FormHelperText>

        <CustomAutocomplete
          makeRequest={useRequest}
          label={text.ConcreteFieldInformation.SAMPLE_COLLECTOR}
          initialValue={formik.initialValues.collectorID}
          itemsQuery={useGetUsersByRoleQuery}
          itemsQueryParams={{ role: 'Field Technician' }}
          itemsMapper={(item) => {
            return { id: item.id, label: `${item.firstName} ${item.lastName}` };
          }}
          sx={{ mt: 2 }}
          name="collectorID"
          onChange={(name, value) => formik.setFieldValue(name, value)}
        />
        <FormHelperText
          error={formik.touched.collectorID && formik.errors.collectorID ? true : false}
        >
          {formik.errors.collectorID}
        </FormHelperText>

        <TextField
          label={text.Samples.EXTRAINFO}
          value={formik.values.pickupInstructions}
          name={'pickupInstructions'}
          onChange={formik.handleChange}
          fullWidth
          multiline
          sx={{ mt: 2 }}
          type={'text'}
        />
      </Box>

      <Grid container justifyContent="flex-end" sx={{ mt: 5 }}>
        <ButtonSubmit btIcon={btIcon}  btText={btText} />
      </Grid>
    </form>
  );
};

export default FormSampleCollector;
