import * as yup from 'yup';
import { useLocale } from '../hooks';

export const useConcretePourSchema = () => {
  const text = useLocale();

  const concretePourCreateSchema = yup.object().shape({
    workOrderID: yup.string().required(text.REQUIRED),
    element: yup.string().required(text.REQUIRED),
    area: yup.string().required(text.REQUIRED),
    volume: yup.number().required(text.REQUIRED),
    samplesAmount: yup.number().required(text.REQUIRED),
    cylindersPerSample: yup.number().required(text.REQUIRED),
    cylindersPerFieldCured:yup.number().required(text.REQUIRED),
  });

  const concretePourEditSchema = yup.object().shape({
    element: yup.string().nullable().required(text.REQUIRED),
    area: yup.string().nullable().required(text.REQUIRED),
    volume: yup.number().nullable().required(text.REQUIRED),
    samplesAmount: yup.number().nullable().required(text.REQUIRED),
    cylindersPerSample: yup.number().nullable().required(text.REQUIRED),
    cylindersPerFieldCured:yup.number().required(text.REQUIRED),
  });
  return { concretePourCreateSchema, concretePourEditSchema };
};
