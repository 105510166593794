import {
  Box,
  Card,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useLocale } from '../../../hooks';
import { useGetClientsQuery } from '../../../services/clientsApi';
import { useGetLabsQuery } from '../../../services/laboratoriesApi';
import { useGetRegionsQuery } from '../../../services/regionsApi';
import { useGetUsersByRoleQuery } from '../../../services/userApi';
import CustomAutocomplete from '../../../components/customMUI/CustomAutocomplete';
import CustomDatePicker from '../../../components/customMUI/CustomDatePicker';
import CustomTimeDurationPicker from '../../../components/customMUI/CustomTimeDurationPicker';
import { ButtonBack } from '../../../components/customMUI/ButtonBack';
import { ButtonSubmit } from '../../../components/customMUI/ButtonSubmit';
import useRequest from '../../../hooks/useRequest';

const FormProject = ({ formik, btIcon, btText }) => {
  const text = useLocale();

  const labelStyle = { fontWeight: 'bold', fontSize: 18 };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} alignItems="stretch">
        {/** First Half */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container direction={'column'} rowGap={2}>
            {/* PROJECT DETAILS */}
            <Grid item>
              <Card sx={{ minHeight: Object.keys(formik.errors).length > 0 ? '90vh' : '75vh' }}>
                <CardContent>
                  <Typography sx={labelStyle}>{text.Projects.SUBTITLE_PROJECT_DETAILS}</Typography>
                  <Box>
                    <TextField
                      label={text.Projects.LABEL_PROJECT_CODE}
                      value={formik.values.projectCode}
                      name={'projectCode'}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                      type={'text'}
                    />
                    <FormHelperText
                      error={formik.touched.projectCode && formik.errors.projectCode ? true : false}
                    >
                      {formik.errors.projectCode}
                    </FormHelperText>

                    <TextField
                      label={text.Projects.LABEL_PROJECT_NAME}
                      value={formik.values.projectName}
                      name={'projectName'}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                      type={'text'}
                    />
                    <FormHelperText
                      error={formik.touched.projectName && formik.errors.projectName ? true : false}
                    >
                      {formik.errors.projectName}
                    </FormHelperText>

                    <TextField
                      label={text.Projects.LABEL_ADDRESS}
                      value={formik.values.address}
                      name={'address'}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                      type={'text'}
                    />
                    <FormHelperText
                      error={formik.touched.address && formik.errors.address ? true : false}
                    >
                      {formik.errors.address}
                    </FormHelperText>

                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          label={text.Projects.LABEL_TRANSPORTATION_TIME}
                          value={formik.values.transportationTime}
                          name={'transportationTime'}
                          onChange={formik.handleChange}
                          fullWidth
                          sx={{ mt: 2 }}
                          InputProps={{
                            inputProps: {
                                min: 0,
                            },
                        }}
                        />
                        <FormHelperText
                          error={
                            formik.touched.transportationTime && formik.errors.transportationTime
                              ? true
                              : false
                          }
                        >
                          {formik.errors.transportationTime}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          label={text.Projects.LABEL_MILEAGE}
                          value={formik.values.mileage}
                          name={'mileage'}
                          onChange={formik.handleChange}
                          fullWidth
                          sx={{ mt: 2, display: 'flex', flex: 1 }}
                          type={'number'}
                        />
                        <FormHelperText
                          error={formik.touched.mileage && formik.errors.mileage ? true : false}
                        >
                          {formik.errors.mileage}
                        </FormHelperText>
                      </Grid>
                    </Grid>

                    <CustomAutocomplete
                      makeRequest={useRequest}
                      label={text.Projects.LABEL_PROJECT_REGION}
                      initialValue={formik.initialValues.regionID}
                      itemsQuery={useGetRegionsQuery}
                      itemsMapper={(item) => {
                        return { id: item.id, label: item.name };
                      }}
                      sx={{ mt: 2 }}
                      name="regionID"
                      onChange={(name, value) => formik.setFieldValue(name, value)}
                    />
                    <FormHelperText
                      error={formik.touched.regionID && formik.errors.regionID ? true : false}
                    >
                      {formik.errors.regionID}
                    </FormHelperText>

                    <CustomAutocomplete
                      makeRequest={useRequest}
                      label={text.Projects.LABEL_LABORATORY}
                      initialValue={formik.initialValues.laboratoryID}
                      itemsQuery={useGetLabsQuery}
                      itemsMapper={(item) => {
                        return { id: item.id, label: `${item.name} (${item.region.name})`  };
                      }}
                      sx={{ mt: 2 }}
                      name="laboratoryID"
                      onChange={(name, value) => formik.setFieldValue(name, value)}
                    />
                    <FormHelperText
                      error={
                        formik.touched.laboratoryID && formik.errors.laboratoryID ? true : false
                      }
                    >
                      {formik.errors.laboratoryID}
                    </FormHelperText>
                  </Box>
                  <br />
                  {/* CLIENT INFORMATION */}
                  <Typography sx={labelStyle}>
                    {text.Projects.SUBTITLE_CLIENT_INFORMATION}
                  </Typography>
                  <Box>
                    <TextField
                      label={text.Projects.LABEL_CLIENT_PURCHASE_ORDER_NUMBER}
                      value={formik.values.clientPurchaseOrder}
                      name={'clientPurchaseOrder'}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                      type={'text'}
                    />
                    <FormHelperText
                      error={
                        formik.touched.clientPurchaseOrder && formik.errors.clientPurchaseOrder
                          ? true
                          : false
                      }
                    >
                      {formik.errors.clientPurchaseOrder}
                    </FormHelperText>
                    <CustomAutocomplete
                      makeRequest={useRequest}
                      label={text.Clients.LABEL_ORGANIZATION_NAME}
                      initialValue={formik.initialValues.clientID}
                      itemsQuery={useGetClientsQuery}
                      itemsMapper={(item) => {
                        return { id: item.id, label: item.organizationName };
                      }}
                      sx={{ mt: 2 }}
                      name="clientID"
                      onChange={(name, value) => formik.setFieldValue(name, value)}
                    />
                    <FormHelperText
                      error={formik.touched.clientID && formik.errors.clientID ? true : false}
                    >
                      {formik.errors.clientID}
                    </FormHelperText>
                    <TextField
                      label={text.Projects.LABEL_CLIENT_FULL_NAME}
                      value={formik.values.clientFullName}
                      name={'clientFullName'}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                      type={'text'}
                    />
                    <FormHelperText
                      error={
                        formik.touched.clientFullName && formik.errors.clientFullName ? true : false
                      }
                    >
                      {formik.errors.clientFullName}
                    </FormHelperText>
                    <TextField
                      label={text.Projects.LABEL_CLIENT_EMAIL}
                      value={formik.values.clientEmail}
                      name={'clientEmail'}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                      type={'text'}
                    />
                    <FormHelperText
                      error={formik.touched.clientEmail && formik.errors.clientEmail ? true : false}
                    >
                      {formik.errors.clientEmail}
                    </FormHelperText>
                    <TextField
                      label={text.Projects.LABEL_CLIENT_PHONE_NUMBER}
                      value={formik.values.clientPhoneNumber}
                      name={'clientPhoneNumber'}
                      onChange={formik.handleChange}
                      fullWidth
                      sx={{ mt: 2 }}
                      type={'text'}
                    />
                    <FormHelperText
                      error={
                        formik.touched.clientPhoneNumber && formik.errors.clientPhoneNumber
                          ? true
                          : false
                      }
                    >
                      {formik.errors.clientPhoneNumber}
                    </FormHelperText>
                  </Box>
                  <br />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {/** Second Half */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container direction={'column'} rowGap={2}>
            {/* PERSONNEL FORM */}
            <Grid item>
              <Card sx={{ minHeight: Object.keys(formik.errors).length > 0 ? '90vh' : '75vh' }}>
                <CardContent>
                  <Typography sx={labelStyle} gutterBottom>
                    {text.Projects.SUBTITLE_GHD_PERSONNEL}
                  </Typography>
                  <Box>
                    <CustomAutocomplete
                      makeRequest={useRequest}
                      label={text.Projects.LABEL_PROJECT_MANAGER}
                      initialValue={formik.initialValues.projectManagerID}
                      itemsQuery={useGetUsersByRoleQuery}
                      itemsQueryParams={{ role: 'Project Manager' }}
                      itemsMapper={(item) => {
                        return { id: item.id, label: `${item.firstName} ${item.lastName}` };
                      }}
                      sx={{ mt: 2 }}
                      name="projectManagerID"
                      onChange={(name, value) => formik.setFieldValue(name, value)}
                    />
                    <FormHelperText
                      error={
                        formik.touched.projectManagerID && formik.errors.projectManagerID
                          ? true
                          : false
                      }
                    >
                      {formik.errors.projectManagerID}
                    </FormHelperText>
                    <CustomAutocomplete
                      makeRequest={useRequest}
                      label={text.Projects.LABEL_DISPATCHER}
                      initialValue={formik.initialValues.dispatcherID}
                      itemsQuery={useGetUsersByRoleQuery}
                      itemsQueryParams={{ role: 'Dispatcher' }}
                      itemsMapper={(item) => {
                        return { id: item.id, label: `${item.firstName} ${item.lastName}` };
                      }}
                      sx={{ mt: 2 }}
                      name="dispatcherID"
                      onChange={(name, value) => formik.setFieldValue(name, value)}
                    />
                    <FormHelperText
                      error={
                        formik.touched.dispatcherID && formik.errors.dispatcherID ? true : false
                      }
                    >
                      {formik.errors.dispatcherID}
                    </FormHelperText>
                    <CustomAutocomplete
                      makeRequest={useRequest}
                      label={text.Projects.LABEL_LABORATORY_SUPERVISOR}
                      initialValue={formik.initialValues.laboratorySupervisorID}
                      itemsQuery={useGetUsersByRoleQuery}
                      itemsQueryParams={{ role: 'Laboratory Supervisor' }}
                      itemsMapper={(item) => {
                        return { id: item.id, label: `${item.firstName} ${item.lastName}` };
                      }}
                      sx={{ mt: 2 }}
                      name="laboratorySupervisorID"
                      onChange={(name, value) => formik.setFieldValue(name, value)}
                    />
                    <FormHelperText
                      error={
                        formik.touched.laboratorySupervisorID &&
                          formik.errors.laboratorySupervisorID
                          ? true
                          : false
                      }
                    >
                      {formik.errors.laboratorySupervisorID}
                    </FormHelperText>
                    <br />

                    {/* ONSITE CONTACT */}
                    <Typography sx={labelStyle} gutterBottom>
                      {text.Projects.SUBTITLE_ON_SITE_CONTACT}
                    </Typography>
                    <Box>
                      <TextField
                        label={text.Projects.LABEL_ON_SITE_CONTACT_NAME_ORGANISATION}
                        value={formik.values.onSiteContactOrganisation}
                        name={'onSiteContactOrganisation'}
                        onChange={formik.handleChange}
                        fullWidth
                        sx={{ mt: 2 }}
                        type={'text'}
                      />
                      <FormHelperText
                        error={
                          formik.touched.onSiteContactOrganisation &&
                            formik.errors.onSiteContactOrganisation
                            ? true
                            : false
                        }
                      >
                        {formik.errors.onSiteContactOrganisation}
                      </FormHelperText>
                      <TextField
                        label={text.Projects.LABEL_ON_SITE_CONTACT_NAME}
                        value={formik.values.onSiteContactName}
                        name={'onSiteContactName'}
                        onChange={formik.handleChange}
                        fullWidth
                        sx={{ mt: 2 }}
                        type={'text'}
                      />
                      <FormHelperText
                        error={
                          formik.touched.onSiteContactName && formik.errors.onSiteContactName
                            ? true
                            : false
                        }
                      >
                        {formik.errors.onSiteContactName}
                      </FormHelperText>
                      <TextField
                        label={text.Projects.LABEL_ON_SITE_CONTACT_TELEPHONE}
                        value={formik.values.onSiteContactTelephone}
                        name={'onSiteContactTelephone'}
                        onChange={formik.handleChange}
                        fullWidth
                        sx={{ mt: 2 }}
                        type={'text'}
                      />
                      <FormHelperText
                        error={
                          formik.touched.onSiteContactTelephone &&
                            formik.errors.onSiteContactTelephone
                            ? true
                            : false
                        }
                      >
                        {formik.errors.onSiteContactTelephone}
                      </FormHelperText>
                      <TextField
                        label={text.Projects.LABEL_ON_SITE_CONTACT_EMAIL}
                        value={formik.values.onSiteContactEmail}
                        name={'onSiteContactEmail'}
                        onChange={formik.handleChange}
                        fullWidth
                        sx={{ mt: 2 }}
                        type={'text'}
                      />
                      <FormHelperText
                        error={
                          formik.touched.onSiteContactEmail && formik.errors.onSiteContactEmail
                            ? true
                            : false
                        }
                      >
                        {formik.errors.onSiteContactEmail}
                      </FormHelperText>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {Object.keys(formik.touched).length > 0 && Object.keys(formik.errors).length > 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mr: 3 }}>
          <Typography sx={{ fontSize: 14 }} color={'error.main'}>
            {text.INVALID_INPUT}
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <Grid item>
          <ButtonBack />
        </Grid>
        <Grid item>
          <ButtonSubmit btIcon={btIcon} btText={btText} />
        </Grid>
      </Grid>
    </form>
  );
};

export default FormProject;
