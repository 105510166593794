import ImgLogin from '../assets/login/log.svg';
import GHDLogo from '../assets/logo/logo.png';

import { Box, Button, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import cacheKeys from '../constants/cacheKeys';
import routes from '../constants/routes';
import useLocale from '../hooks/useLocale';
import useLogin from '../hooks/useLogin';
import '../login.css';
import { loginSchema } from '../schemas/login.schema';
import { useLoginMutation } from '../services/authApi';
import { selectAuth } from '../slices/authSlice';

const Login = () => {
  const text = useLocale();
  let effectRan = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const login = useLogin();
  const [, loginResult] = useLoginMutation({ fixedCacheKey: cacheKeys.LOGIN_MUTATION });

  // Check if there is an authentication code
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const authCode = urlParams.get('code');
  const [basicLogin, setBasicLogin] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: loginSchema,
    onSubmit: (values) => dispatch(login({ credentials: values })),
  });

  const redirectToMicrosoftSignIn = () => {
    const redirectUrl =
      `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}` +
      `/oauth2/v2.0/authorize?` +
      `client_id=${process.env.REACT_APP_CLIENT_ID}` +
      `&response_type=code` +
      `&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}` +
      `&response_mode=query` +
      `&scope=user.read`;
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    if (loginResult.isError) {
      if (authCode) {
        toast.error(text.FAILED_LOGIN);
        loginResult.reset();
      } else {
        formik.setValues({ email: formik.values.email, password: '' });
        toast.error(text.INVALID_CREDENTIALS);
        loginResult.reset();
      }
    }
  }, [authCode, loginResult.isError]);

  // Send auth code to API if one is present
  useEffect(() => {
    // Only want to run this once
    if (process.env.NODE_ENV !== 'development' || effectRan.current === true) {
      if (authCode) {
        dispatch(login({ credentials: { code: authCode } }));
      }
    }

    return () => (effectRan.current = true);
  }, []);

  useEffect(() => {
    if (auth.token) {
      navigate(routes.HOME);
    }
  }, [auth.token]);

  return (
    <Box className={isActive ? 'container sign-up-mode' : 'container'}>
      <Box className="forms-container">
        <Box className="signin-signup">
          <form action="#" className="sign-in-form custom-form">
            <Box component="img" alt="GHD logo" src={GHDLogo} className="" width="150px" />
            <Typography variant="h2" sx={{ fontSize: 32, fontWeight: 700, color: '#444' }}>
              GHD | Sign in
            </Typography>
            <Typography variant="body" sx={{ fontSize: 28, color: '#444' }}>
              Welcome to{' '}
              <Typography
                sx={{
                  display: 'inline-block',
                  fontSize: 28,
                  mb: 2,
                  color: '#ff0055',
                  fontWeight: 'bold',
                }}
              >
                viewLabs
              </Typography>
            </Typography>
            <Button
              variant="contained"
              onClick={redirectToMicrosoftSignIn}
              sx={{ mt: 4, height: 70, borderRadius: 49 }}
            >
              <Typography sx={{ padding: 5, textTransform: 'none' }}>
                {text.Login.LOGIN_BUTTON_MS}
              </Typography>
            </Button>
          </form>

          <form onSubmit={formik.handleSubmit} className="sign-up-form custom-form">
            <Box component="img" alt="GHD logo" src={GHDLogo} className="" width="150px" />
            <Typography variant="h2" sx={{ fontSize: 32, fontWeight: 700, color: '#444' }}>
              {text.Login.TITLE}
            </Typography>
            <Typography variant="body" sx={{ fontSize: 28, color: '#444' }}>
              {text.Login.WELCOME_TO}{' '}
              <Typography
                sx={{
                  display: 'inline-block',
                  fontSize: 28,
                  mb: 2,
                  color: '#ff0055',
                  fontWeight: 'bold',
                }}
              >
                {text.Login.SYSTEM_NAME}
              </Typography>
            </Typography>
            <TextField
              label={text.Login.EMAIL}
              type={'email'}
              name="email"
              fullWidth
              className="input-field"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <TextField
              label={text.Login.PASSWORD}
              type={'password'}
              fullWidth
              className="input-field"
              sx={{ mt: 2 }}
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <Button variant="contained" type="submit" sx={{ mt: 2, height: 70, borderRadius: 49 }}>
              <Typography sx={{ padding: 5 }}>{text.Login.LOGIN_BUTTON_PW}</Typography>
            </Button>
          </form>
        </Box>
      </Box>

      <Box className="panels-container">
        <Box className="panel left-panel">
          <Box className="content">
            <Typography variant="h3">{text.Login.GHD_CLIENT_QUESTION}</Typography>
            <p>{text.Login.GHD_CLIENT_QUESTION_SUBTITLE}</p>
            <Button
              variant="outlined"
              color="white"
              id="sign-up-btn"
              onClick={handleClick}
              sx={{ mt: 4, height: 50, borderRadius: 49 }}
            >
              <Typography sx={{ padding: 5, textTransform: 'initial' }}>
                {text.Login.LOGIN_BUTTON_PW}
              </Typography>
            </Button>
          </Box>
          <Box component="img" alt="login" src={ImgLogin} className="image" />
        </Box>

        <Box className="panel right-panel">
          <Box className="content">
            <h3>{text.Login.ONE_OF_US_QUESTION}</h3>
            <p>{text.Login.ONE_OF_US_QUESTION_SUBTITLE}</p>
            <Button
              variant="outlined"
              color="white"
              id="sign-in-btn"
              onClick={handleClick}
              sx={{ mt: 4, height: 50, borderRadius: 49 }}
            >
              <Typography sx={{ padding: 5, textTransform: 'initial' }}>
                {text.Login.LOGIN_BUTTON_MS}
              </Typography>
            </Button>
          </Box>
          <img src={ImgLogin} className="image" alt="test" />
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
