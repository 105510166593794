import { createAsyncThunk } from '@reduxjs/toolkit';
import actions from '../constants/actions';

import { useAddCylinderMutation } from '../services/cylindersApi';
import { clearLoading } from '../slices/loadingSlice';
import { removeFromQueue } from '../slices/offlineQueueSlice';

export const useSubmitCylinder = () => {
  const [submit] = useAddCylinderMutation();

  const submitCylinder = createAsyncThunk(actions.SUBMIT_CYLINDER, async (params, thunkAPI) => {
    try {
      const { id, workOrderID, ...data } = params;

      await submit(data).unwrap();

      thunkAPI.dispatch(removeFromQueue(id));
    } catch (e) {
      console.error(e);
      thunkAPI.dispatch(clearLoading());
    }
  });
  return { submitCylinder };
};
