import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";

import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import routes from "../../../constants/routes";
import useLocale from "../../../hooks/useLocale";
import useOnline from "../../../hooks/useOnline";
import { removeFromQueue } from "../../../slices/offlineQueueSlice";
import { selectUser } from "../../../slices/userSlice";
import DeleteDialog from "../../DeleteDialog";
import Icon from "../../Icon";

const ConcreteMixDesigns = ({ rows, fieldReportExists, assignedTechnicianID }) => {
  const user = useSelector(selectUser);
  const isOnline = useOnline();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const text = useLocale();
  const [concreteMixDesigns, setConcreteMixDesigns] = useState([]);
  const removeItem = (id) => {
    dispatch(removeFromQueue(id));
  };

  useEffect(() => {
    setConcreteMixDesigns(rows);
  }, [rows]);

  const concreteMixDesignColumns = [
    {
      field: "formula",
      headerName: "Formula",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "specifiedConcreteClass",
      headerName: "Specified Concrete Class",
      flex: 1,

      minWidth: 650,
    },
    {
      field: "concreteElementLocation",
      headerName: "Location of Concrete Element",
      flex: 1,

      minWidth: 250,
    },
    {
      field: "slump",
      headerName: "Slump/Air",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          params.row.slump +
          " ± " +
          params.row.slumpRange +
          " / " +
          params.row.minAirContent +
          "-" +
          params.row.maxAirContent +
          "%"
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        if (isOnline && fieldReportExists) {
          return (
            <IconButton
              onClick={() =>
                navigate(`${location.pathname}${routes.CONCRETE_MIX_DESIGNS}/edit/${params.id}`, {
                  state: { background: location },
                })
              }
            >
              <Icon iconName={"Edit"} />
            </IconButton>
          );
        } else if (!fieldReportExists && user.id === assignedTechnicianID) {
          return (
            <IconButton
              onClick={() =>
                navigate(
                  `${location.pathname}${routes.CONCRETE_MIX_DESIGNS}/edit-cache/${params.id}`,
                  {
                    state: { background: location },
                  }
                )
              }
            >
              <Icon iconName={"Edit"} />
            </IconButton>
          );
        } else {
          return <React.Fragment></React.Fragment>;
        }
      },
    },

    {
      field: "delete",
      headerName: "",
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        if (!isOnline && fieldReportExists) {
          return <React.Fragment></React.Fragment>;
        } else if (!fieldReportExists && user.id === assignedTechnicianID) {
          return <DeleteDialog handleDelete={() => removeItem(params.id)} />;
        } else {
          return <React.Fragment></React.Fragment>;
        }
      },
    },
  ];

  return (
    <Box>
      <Typography sx={{ fontWeight: "bold", fontSize: 20, mb: 2 }}>
        {text.ConcreteMixDesign.TITLE}
      </Typography>
      <Box>
        {!fieldReportExists && user.id === assignedTechnicianID ? (
          <Button
            startIcon={<Icon iconName="AddCircle" />}
            color="primary"
            component={Link}
            to={location.pathname + routes.CONCRETE_MIX_DESIGNS_ADD}
            state={{ background: location }}
            variant="contained"
            sx={{ mb: 2 }}
          >
            {text.Buttons.ADD}
          </Button>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Box>

       <DataGridPro
        sx={{ width: "100%", height: 300 }}
        columns={concreteMixDesignColumns}
        rows={concreteMixDesigns}
        pagination
      />
    </Box>
  );
};

export default ConcreteMixDesigns;
