import { createSlice } from '@reduxjs/toolkit';


export const formType = {
  CONCRETE_FIELD_INFORMATION: 'CONCRETE_FIELD_INFORMATION',
  CONCRETE_MIX_DESIGN: 'CONCRETE_MIX_DESIGN',
  CONCRETING_SEQUENCE: 'CONCRETING_SEQUENCE',
  SAMPLE: 'SAMPLE',
  CYLINDER: 'CYLINDER',
};
// {id: 09c7b0a1-af1b-4766-a09a-059727b808e2, type: '', data: {...}}}

const initialState = [];

const offlineQueueSlice = createSlice({
  name: 'offlineQueue',
  initialState: initialState,
  reducers: {
    // Add to the queue
    addToQueue(state, action) {
      return [...state, action.payload];
    },

    // Change an item from within the queue
    updateQueue(state, action) {
      for (const item of state) {
        if (item.id === action.payload.id) {
          item.data = action.payload.data;
        }
      }
    },

    // Replace placeholder IDs with the real one in ConcretingSequences
    replaceConcreteMixDesignIDs(state, action) {
      for (const item of state) {
        if (
          item.type === formType.CONCRETING_SEQUENCE &&
          item.data.concreteMixDesignID === action.payload.oldID
        ) {
          item.data.concreteMixDesignID = action.payload.newID;
        }
      }
    },
    // Replace placeholder IDs with the real one in Samples
    replaceConcretingSequenceIDs(state, action) {
      for (const item of state) {
        if (
          item.type === formType.SAMPLE &&
          item.data.concretingSequenceID === action.payload.oldID
        ) {
          item.data.concretingSequenceID = action.payload.newID;
        }
      }
    },
    // Replace placeholder IDs with the real one in Cylinders
    replaceSampleIDs(state, action) {
      for (const item of state) {
        if (item.type === formType.CYLINDER && item.data.sampleID === action.payload.oldID) {
          item.data.sampleID = action.payload.newID;
        }
      }
    },
    // Remove from the queue
    removeFromQueue(state, action) {
      let newState = [];
      for (const item of state) {
        if (item.id !== action.payload) {
          newState.push(item);
        }
      }
      return newState;
    },

    removeConcretingSequenceFromQueue(state, action) {
      let newState = [];

      // Push all items except the concreting sequence being removed, samples and cylinders
      for (const item of state) {
        if (
          item.id !== action.payload &&
          item.type !== formType.SAMPLE &&
          item.type !== formType.CYLINDER
        ) {
          newState.push(item);
        }
      }

      // Push the samples that are not related to the concreting sequence
      // and keep track of the samples that need to be removed - we need this to remove the cylinders
      let removedSamples = [];
      for (const item of state) {
        if (item.type === formType.SAMPLE) {
          if (item.data.concretingSequenceID !== action.payload) {
            newState.push(item);
          } else {
            removedSamples.push(item.id);
          }
        }
      }

      // Push the cylinders that are not related to the concreting sequence
      // Relationship: ConcretingSequence->Sample->Cylinder
      for (const item of state) {
        if (item.type === formType.CYLINDER) {
          if (!removedSamples.includes(item.data.sampleID)) {
            newState.push(item);
          }
        }
      }
      return newState;
    },

    removeSampleFromQueue(state, action) {
      let newState = [];

      // Push all items (except cylinders) that is not the sample to the new state
      for (const item of state) {
        if (item.id !== action.payload && item.type !== formType.CYLINDER) {
          newState.push(item);
        }
      }

      // Only push cylinders to the new state that are not related to the sample
      for (const item of state) {
        if (item.type === formType.CYLINDER) {
          if (item.data.sampleID !== action.payload) {
            newState.push(item);
          }
        }
      }

      return newState;
    },

    removeAllFromWorkOrder(state, action) {
      let newState = [];
      for (const item of state) {
        if (item.data.workOrderID !== action.payload) {
          newState.push(item);
        }
      }
      return newState;
    },

    resetCache(state, action) {
      return [];
    },
  },
});

// Actions
export const {
  addToQueue,
  removeFromQueue,
  removeConcretingSequenceFromQueue,
  removeSampleFromQueue,
  removeAllFromWorkOrder,
  updateQueue,
  replaceConcreteMixDesignIDs,
  replaceConcretingSequenceIDs,
  replaceSampleIDs,

  resetCache,
} = offlineQueueSlice.actions;

// Selectors
export const selectFromQueue = (state, id) => {
  for (const item of state.offlineQueue) {
    if (item.id === id) return item;
  }

  return null;
};

export const selectAllTypeFromQueue = (state, type) => {
  let items = [];
  for (const item of state.offlineQueue) {
    if (item.type === type) {
      items.push(item.data);
    }
  }
  // Item type is a concreting sequence we need to add the concrete mix design data
  if (type === formType.CONCRETING_SEQUENCE) {
    let newItems = [];
    for (const concretingSequence of items) {
      const concreteMixDesign = state.offlineQueue.filter(
        (item) => item.id === concretingSequence.concreteMixDesignID
      )[0];

      if (!concreteMixDesign) {
        const data = { ...concretingSequence, concreteMixDesign: {} };
        newItems.push(data);
      } else {
        const data = { ...concretingSequence, concreteMixDesign: concreteMixDesign.data };
        newItems.push(data);
      }
    }
    return newItems;
  }

  // If type is a cylinder we need to add the sample data
  if (type === formType.CYLINDER) {
    let newItems = [];
    for (const cylinder of items) {
      const sample = state.offlineQueue.filter((item) => item.id === cylinder.sampleID)[0];

      if (!sample) {
        const data = { ...cylinder, sample: {} };
        newItems.push(data);
      } else {
        const data = { ...cylinder, sample: sample.data };
        newItems.push(data);
      }
    }
    return newItems;
  }

  return items;
};

export const selectSampleWithCylindersFromQueue = (state) => {
  const filteredSamplesArr = state?.offlineQueue.filter((item) => item.type === formType.SAMPLE)
  state?.offlineQueue.forEach((item) => {
    if(item.type === formType.CYLINDER){      
      const sampleIndex = filteredSamplesArr.findIndex((sample) =>  sample.id === item?.data?.sampleID)
      if(sampleIndex > -1){
        filteredSamplesArr[sampleIndex] = {...filteredSamplesArr[sampleIndex], cylinders: [...(filteredSamplesArr[sampleIndex].cylinders ?? []), item]}
      }
    } 
  })
 

  return filteredSamplesArr;
};

export const selectAllFromQueue = (state) => state.offlineQueue;

export default offlineQueueSlice;
