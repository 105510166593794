import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import GHDlogo from '../../assets/logo/white_logo.png';
import profileImage from '../../assets/users/default.svg';
import useLocale from '../../hooks/useLocale';
import useLogout from '../../hooks/useLogout';
import { selectUser } from '../../slices/userSlice';
import FlexBetween from '../FlexBetween';
import Icon from '../Icon';
import { removeNotification, selectNotifications } from '../../slices/notificationSlice';

function AppTopbar() {
  const text = useLocale();
  const notifications = useSelector(selectNotifications);

  const user = useSelector(selectUser);
  const { collapseSidebar, toggleSidebar, broken } = useProSidebar();
  const dispatch = useDispatch();
  const logout = useLogout();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorE2, setAnchorE2] = React.useState(null);
  const isOpenN = Boolean(anchorE2);
  const handleClickN = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseN = () => {
    setAnchorE2(null);
  };

  return (
    <AppBar position="sticky" sx={styles.appBar}>
      <Toolbar>
        <IconButton onClick={() => (broken ? toggleSidebar() : collapseSidebar())} color="white">
          <Icon iconName="Menu" />
        </IconButton>
        <Box component={'img'} sx={styles.appLogo} src={GHDlogo} />
        <Box sx={{ flexGrow: 1 }} />
        <Box textAlign="left" sx={{ gap: '1rem', mr: 4 }}>
          <Typography fontWeight="bold" fontSize="0.85rem" color="#ffffff">
            <Hidden smDown>Hi, {user.name}</Hidden>
          </Typography>
        </Box>
        <Tooltip title="Notifications">
          <Button
            onClick={handleClickN}
            aria-controls={isOpen ? 'notification-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              textTransform: 'none',
              gap: '1rem',
              minWidth: 'auto',
            }}
          >
            <Icon iconName="Notifications" sx={{ color: '#ffffff', fontSize: '25px' }} />
          </Button>
        </Tooltip>
        <Hidden xsDown smDown>
          <Tooltip title="Settings">
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                textTransform: 'none',
                gap: '1rem',
                minWidth: 'auto',
              }}
            >
              <Icon iconName="Settings" sx={{ color: '#ffffff', fontSize: '28px' }} />
            </Button>
          </Tooltip>
        </Hidden>
        <Tooltip title="Account settings">
          <FlexBetween>
            <Button
              onClick={handleClick}
              aria-controls={isOpen ? 'account-menu' : undefined}
              aria-expanded={isOpen ? 'true' : undefined}
              aria-haspopup="true"
            >
              <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="32px"
                width="32px"
                borderRadius="50%"
                sx={{ objectFit: 'cover' }}
              />
              {/* <Icon iconName="ArrowDropDownOutlined" sx={{ color: "#ffffff", fontSize: "25px" }} /> */}
            </Button>
          </FlexBetween>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={isOpen}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              minWidth: '250px',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar />
            {text.UserDropDown.MY_ACCOUNT}
          </MenuItem>
          <Divider />

          <Hidden mdUp>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Icon iconName="Settings" fontSize="small" />
              </ListItemIcon>
              {text.UserDropDown.SETTINGS}
            </MenuItem>
          </Hidden>

          <MenuItem onClick={() => dispatch(logout())}>
            <ListItemIcon>
              <Icon iconName="Logout" fontSize="small" />
            </ListItemIcon>
            {text.UserDropDown.LOGOUT}
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={anchorE2}
          id="notification-menu"
          open={isOpenN}
          onClose={handleCloseN}
          onClick={handleCloseN}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              minWidth: '250px',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleCloseN}>{text.UserDropDown.NOTIFICATIONS}</MenuItem>
          <Divider />
          {notifications.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  width: 320,
                  borderLeftColor: item.color,
                  borderLeftStyle: 'solid',
                  borderLeftWidth: 3,

                  // borderRadius: 2,
                  m: 1,
                  display: 'flex',
                }}
              >
                <Box sx={{ flex: 1, pr: 1, pl: 2, pt: 1, pb: 1 }}>
                  <Typography sx={{ fontWeight: 'bold', color: 'grey' }}>{item.title}</Typography>
                  <Typography sx={{ fontSize: 14, color: 'grey', mt: 1 }}>
                    {item.description}
                  </Typography>
                </Box>

                <Button onClick={() => dispatch(removeNotification(index))} sx={{ p: 0 }}>
                  <Icon iconName="Cancel" color="grey" />
                </Button>
              </Box>
            );
          })}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

/** @type {import("@mui/material").SxProps} */
const styles = {
  appBar: {
    bgcolor: 'neutral.main',
  },
  appLogo: {
    borderRadius: 2,
    width: 80,
    marginLeft: 2,
    cursor: 'pointer',
  },
};

export default AppTopbar;
