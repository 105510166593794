import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

// import loading
import { useDispatch } from 'react-redux';
import { setLoading, clearLoading } from '../slices/loadingSlice';
import useRequest from './useRequest';

// Functions to change from iniValues(Formik) Value to SetIniValues(Formik)
function loadData(initialValuesData, objQuery) {
  let setIniValues = {};
  if (!objQuery.isLoading) {
    for (const key of Object.keys(initialValuesData)) {
      setIniValues = { ...setIniValues, [key]: objQuery.data.payload[key] };
    }
  }
  return setIniValues;
}

// Hook to abstract editing
export function useFormikEdit(
  useGetQuery,
  initialValuesData,
  objSchema,
  route,
  useUpdateMutation,
  setValues
) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateObj] = useUpdateMutation();
  const { id } = useParams();
  const objQuery = useRequest(useGetQuery, id);
  const [initialValues, setInitialValues] = useState(initialValuesData);

  let loadedInitialValues = '';
  setValues
    ? (loadedInitialValues = setValues)
    : (loadedInitialValues = loadData(initialValuesData, objQuery));

  useEffect(() => {
    if (!objQuery.isLoading) {
      setInitialValues(loadedInitialValues);
    }
  }, [objQuery.isLoading]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: objSchema,
    onSubmit: (values) => updateRecord(values),
  });

  async function updateRecord(values) {
    dispatch(setLoading());
    try {
      // Only find values that have changed
      let changes = {};
      for (const key of Object.keys(formik.initialValues)) {
        if (values[key] !== formik.initialValues[key]) {
          changes = { ...changes, [key]: values[key] };
        }
      }
      // console.log(changes);
      // console.log(objQuery.data.payload.id);
      const data = { changes: changes, id: objQuery.data.payload.id };

      await updateObj(data);
      // const result = await updateObj(data);
      // console.log(result);
      navigate(route);
    } catch (e) {
      console.log(e);
    }
    dispatch(clearLoading());
  }

  return formik;
}
