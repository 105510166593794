import { api } from './api';
import urls from '../constants/urls';
import tags from './tags';
import refreshCache from '../utils/refreshCache';

export const concreteMixDesignApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addConcreteMixDesign: builder.mutation({
      query: (data) => ({
        url: urls.CONCRETE_MIX_DESIGN,
        method: 'POST',
        body: { ...data },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CONCRETE_MIX]);
        } catch (e) {}
      },
    }),

    getConcreteMixDesigns: builder.query({
      query: (params) => ({
        url: urls.CONCRETE_MIX_DESIGN,
        params: params,
      }),
      providesTags: [tags.CONCRETE_MIX],
    }),

    getConcreteMixDesign: builder.query({
      query: (id) => ({
        url: urls.CONCRETE_MIX_DESIGN + '/' + id,
      }),
      providesTags: [tags.CONCRETE_MIX],
    }),

    getConcreteMixDesignByWorkOrderId: builder.query({
      query: (id) => ({
        url: urls.CONCRETE_MIX_DESIGN + '/by-workorder/' + id,
      }),
      providesTags: [tags.CONCRETE_MIX],
    }),

    updateConcreteMixDesign: builder.mutation({
      query: (data) => ({
        url: urls.CONCRETE_MIX_DESIGN + '/' + data.id,
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CONCRETE_MIX]);
        } catch (e) {}
      },
    }),

    deleteConcreteMixDesign: builder.mutation({
      query: (id) => ({
        url: urls.CONCRETE_MIX_DESIGN + '/' + id,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CONCRETE_MIX]);
        } catch (e) {}
      },
    }),
  }),
});

export const {
  useAddConcreteMixDesignMutation,
  useGetConcreteMixDesignQuery,
  useGetConcreteMixDesignsQuery,
  useUpdateConcreteMixDesignMutation,
  useGetConcreteMixDesignByWorkOrderIdQuery
} = concreteMixDesignApi;
