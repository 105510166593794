import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocale } from '../../../hooks';

import FormCylinder from '../../forms/FormCylinder';
import Icon from '../../Icon';

const CylindersDialogContents = ({ formikCylinder, samples, onClose, isConcreteSequeceEdit }) => {
  const text = useLocale();

  return (
    <React.Fragment>
      <DialogTitle sx={{ display: 'flex', pb: 1, pt: 1, pr: 1, pl: 2 }}>
        <Typography sx={{ flex: 1, fontWeight: 'bold', fontSize: 22 }}>
          {text.Cylinders.TITLE}
        </Typography>
        <IconButton onClick={onClose}>
          <Icon iconName="Cancel" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <FormCylinder formik={formikCylinder} samples={samples} isConcreteSequeceEdit={isConcreteSequeceEdit} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{ m: 1 }}
          onClick={formikCylinder.handleSubmit}
        >
          {text.Buttons.SAVE}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default CylindersDialogContents;
