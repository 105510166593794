import React from 'react';
import { useParams } from 'react-router-dom';
import routes from '../../constants/routes';
import FormClients from './forms/FormClients';
import { FlexBetween, Header, LoadingIcon } from '../../components';
import { useGetElement, useLocale } from '../../hooks';
import { useGetClientQuery, useUpdateClientMutation } from '../../services/clientsApi';
import { clientSchema, useClientSchema } from '../../schemas/client.schema';
import { useFormikEdit } from '../../hooks/useFormikEdit';
import { Card, CardContent, Box } from '@mui/material';
import useRequest from '../../hooks/useRequest';

function ClientEdit() {
  const text = useLocale();
  const { clientEditSchema } = useClientSchema();
  const navItem = useGetElement(text.Navigation.CLIENTS);
  const { id } = useParams();
  const clientQuery = useRequest(useGetClientQuery, id);

  const initialValues = {
    organizationName: '',
    clientContactName: '',
    clientContactTelephone: '',
    clientContactEmail: '',
  };

  const formik = useFormikEdit(
    useGetClientQuery,
    initialValues,
    clientEditSchema,
    routes.CLIENTS,
    useUpdateClientMutation
  );

  return clientQuery.isLoading ? (
    <LoadingIcon />
  ) : (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.Clients.FORM_EDIT} />
      </FlexBetween>
      <Box height="70vh" sx={{ maxWidth: 700 }}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <FormClients formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
          </CardContent>
        </Card>
      </Box>
    </React.Fragment>
  );
}

export default ClientEdit;
