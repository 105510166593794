import { useTheme } from '@mui/material';
import React from 'react';

const useIsMobileOrTablet = () => {
  const theme = useTheme();
  const hasMobileScreenSize = () => {
    const hasMobileBreakpointsPortrait =
      window.outerHeight < theme.breakpoints.values.md &&
      window.outerHeight > theme.breakpoints.values.sm &&
      window.outerWidth < theme.breakpoints.values.sm &&
      window.outerWidth > theme.breakpoints.values.xs;

    const hasMobileBreakpointsLandscape =
      window.outerWidth < theme.breakpoints.values.md &&
      window.outerWidth > theme.breakpoints.values.sm &&
      window.outerHeight < theme.breakpoints.values.sm &&
      window.outerHeight > theme.breakpoints.values.xs;

    if (hasMobileBreakpointsPortrait) {
      return true;
    } else if (hasMobileBreakpointsLandscape) {
      return true;
    } else {
      return false;
    }
  };

  const hasTabletScreenSize = () => {
    const hasTabletBreakpointsPortrait =
      window.outerHeight < theme.breakpoints.values.lg &&
      window.outerHeight > theme.breakpoints.values.md &&
      window.outerWidth < theme.breakpoints.values.md &&
      window.outerWidth > theme.breakpoints.values.sm;

    const hasTabletBreakpointsLandscape =
      window.outerWidth < theme.breakpoints.values.lg &&
      window.outerWidth > theme.breakpoints.values.md &&
      window.outerHeight < theme.breakpoints.values.md &&
      window.outerHeight > theme.breakpoints.values.sm;

    if (hasTabletBreakpointsPortrait) {
      return true;
    } else if (hasTabletBreakpointsLandscape) {
      return true;
    } else {
      return false;
    }
  };

  if (navigator.maxTouchPoints > 0 && (hasMobileScreenSize() || hasTabletScreenSize())) {
    return true;
  } else {
    return false;
  }
};

export default useIsMobileOrTablet;
