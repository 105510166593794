import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addNotification } from '../slices/notificationSlice';

export const useHandleSuccess = () => {
  const dispatch = useDispatch();
  const handleSuccessResponse = (title, description) => {
    toast.success(title);
    dispatch(
      addNotification({
        title: title,
        description: description,
        color: 'success.secondary',
      })
    );
  };
  return { handleSuccessResponse };
};

export default useHandleSuccess;
