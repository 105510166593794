// DataViewConcreteMixDesignEdit
import React, { useEffect } from 'react';
import { LoadingIcon, CustomGridToolbar, Icon } from '../../components';
import {  useLocale } from '../../hooks';
import { useParams } from 'react-router-dom';
import { DataGridPro, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';
import { Autocomplete, Box, Card, CardContent, Grid, MenuItem, Select, TextField, styled } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import { useHandleError } from '../../hooks/useHandleError';
import useHandleSuccess from '../../hooks/useHandleSuccess';
import { useGetCylindersByConcreteSequenceIdQuery } from '../../services/cylindersApi';
import useFormatDateTime from '../../hooks/useFormatDateTime';
import tags from '../../services/tags';
import { useUpdateSampleCylindersPickupMutation } from '../../services/samplesApi';
import CustomDatePicker from '../../components/customMUI/CustomDatePicker';

function DataViewSampleEdit() {
    const text = useLocale();
    const { id } = useParams();
    const { handleErrorResponse } = useHandleError();
    const { handleSuccessResponse } = useHandleSuccess();
    const [updateCylinderSamplePickup] = useUpdateSampleCylindersPickupMutation();
    const [rowModesModel, setRowModesModel] = React.useState({});
    const { data, isLoading, refetch } = useGetCylindersByConcreteSequenceIdQuery(id, { refetchOnMountOrArgChange: true });
    const { formatDateTime, formatDate } = useFormatDateTime();

    const HeaderWithIconRoot = styled('div')(({ theme }) => ({
        display: 'flex !important',
        justifyItems: 'center  !important',
        alignItems: 'center',
        width: '100% !important'
    }));
    const dataGridBoxStyle = {
        width: '100%',
        '& .MuiDataGrid-root': { border: 'none' },
        '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
    };
    let varHeight;
    varHeight = '70vh';
    const [rows, setRows] = React.useState([]);

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }

    const renderEditSampleType = (params) => {
        const { id, value, field } = params;
        return (<>
            <Autocomplete
                disablePortal
                value={params?.row?.sampleType === 'FIELD_CURED' ? 'Field Cured' : 'Laboratory Cured'}
                id="combo-box-demo"
                onChange={(event, value) => {

                    if (value.label === "Field Cured") {
                        params.api.setEditCellValue({ id, field, value: "FIELD_CURED" }, event);
                    } else {
                        params.api.setEditCellValue({ id, field, value: "LABORATORY_CURED" }, event);
                    }
                }}
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="Sample Type" />}
                options={[
                    { label: 'Field Cured' },
                    { label: 'Laboratory Cured' }
                ]}
            />
        </>
        );
    };


    const renderEditTextField = (params) => {
        const { id, value, field } = params;

        const handleChange = (event) => {
            const newValue = event.target.value;
            params.api.setEditCellValue({ id, field, value: newValue }, event);
        };

        return (
            <TextField
                value={value || ''}
                onChange={handleChange}
                autoFocus
            />
        );
    };

    const renderEditPickupCalender = (params) => {
        const { id, value, field } = params;

        return (
            <CustomDatePicker initialValue={value} onChange={(e, v) => {
                params.api.setEditCellValue({ id, field, value: v }, e);
            }} />
        );
    };

    const columns = [
        {
            field: 'customCylinderID',
            headerName: 'Custom Cylinder ID',
            flex: 1,
            minWidth: 250,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'sampleNumber',
            headerName: 'Sample Number',
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => {
                return params.row.sample.number
            }
        },
        {
            field: 'sampleType',
            headerName: 'Sample Type',
            flex: 1,
            editable: true,
            minWidth: 200,
            renderEditCell: renderEditSampleType,
            valueGetter: (params) => {
                return params?.row?.sampleType === 'FIELD_CURED' ? 'Field Cured' : 'Laboratory Cured'
            }
        },
        {
            field: 'timeOfMoulding',
            headerName: 'Time Of Moulding',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditPickupCalender,
            renderCell: (params) => {
                return formatDateTime(params.row.timeOfMoulding)
            },

        },
        {
            field: 'daysToBeCured',
            headerName: 'Days To Be Cured',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'compressiveStrength',
            headerName: 'Compressive Strength',
            flex: 1,
            minWidth: 150,
            renderEditCell: renderEditTextField,
        },

        {
            field: 'locationInStructure',
            headerName: 'Location In Structure',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,

        },

        {
            field: 'dropoffDateTime',
            headerName: 'Drop Off Date Time',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditPickupCalender,
            renderCell: (params) => {
                return formatDateTime(params.row.dropoffDateTime)
            },
        },
        {
            field: 'minimumTemperature',
            headerName: 'Minimum Temperature',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'maximumTemperature',
            headerName: 'Maximum Temperature',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'pickupDateTime',
            headerName: 'Pickup Date Time',
            flex: 1,
            minWidth: 150,
            editable: true,
            renderEditCell: renderEditPickupCalender,
            renderCell: (params) => {
                return formatDateTime(params.row.pickupDateTime)
            },
        },
        {
            headerName: '',
            flex: 1,
            minWidth: 150,
            field: 'actions',
            type: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Icon iconName={"Save"} />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<Icon iconName={"Cancel"} />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Icon iconName={"Edit"} />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },

        },
    ];

    const processRowUpdate = async (newRow) => {
        const oldRow = rows.find((row) => row.id === newRow.id);
        const updatedFields = {};
        Object.keys(newRow).forEach((key) => {
            if (newRow[key] !== oldRow[key]) {
                updatedFields[key] = newRow[key];

            }
        });
        updatedFields.cylinderId = newRow.id;
        updatedFields.sampleId = newRow.sampleId;
        updatedFields.samplePickupId = newRow.samplePickupId;

        try {
            const req = await updateCylinderSamplePickup(updatedFields);
            if (req?.error) {
                handleErrorResponse(req?.error);
                return rows.filter((row) => row.id === newRow.id)[0];
            }
            handleSuccessResponse(text.Cylinders.SAMPLE_SUCCESSFUL_UPDATE);
            setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
            refetch()
            return newRow;
        } catch (error) {
            handleErrorResponse(error);
            return rows.filter((row) => row.id === newRow.id)[0];
        }
    };

    function HeaderWithIcon(props) {
        const { icon, ...params } = props;

        return (<>
            <HeaderWithIconRoot>
                <div
                    style={{
                        width: '1000px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}> {params.headerName ?? params.groupId} </div>
            </HeaderWithIconRoot>
        </>
        );
    }

    const columnGroupingModel = [

        {
            groupId: 'compressive_strength',
            headerName: 'Compressive Strength',
            description: 'asasa',
            headerClassName: 'my-super-theme--naming-group',
            renderHeaderGroup: (params) => (
                <HeaderWithIcon {...params} icon={<BuildIcon fontSize="small" />} />
            ),
            children: [
                { field: 'compressiveStrength' },
                { field: 'daysToBeCured' },
                { field: 'dateOfTest' },
                { field: 'compressionStrengthMeasured' },
            ],
        },
        {
            groupId: 'location',
            headerName: 'Location',
            description: '',
            headerClassName: 'my-super-theme--naming-group',
            renderHeaderGroup: (params) => (
                <HeaderWithIcon {...params} icon={<BuildIcon fontSize="small" />} />
            ),
            children: [
                { field: 'locationInStructure' },
            ],
        },
        {
            groupId: 'pick_up',
            headerName: 'Pick-up',
            description: '',
            headerClassName: 'my-super-theme--naming-group',
            renderHeaderGroup: (params) => (
                <HeaderWithIcon {...params} icon={<BuildIcon fontSize="small" />} />
            ),
            children: [
                { field: 'dropoffDateTime' },
                { field: 'minimumTemperature' },
                { field: 'maximumTemperature' },
                { field: 'pickupTechnician' },
                { field: 'pickupDateTime' },
            ],
        },
        {
            groupId: 'sampling',
            headerName: 'Sampling',
            description: '',
            headerClassName: 'my-super-theme--naming-group',
            renderHeaderGroup: (params) => (
                <HeaderWithIcon {...params} icon={<BuildIcon fontSize="small" />} />
            ),
            children: [
                { field: 'customCylinderID' },
                { field: 'plant' },
                { field: 'formula' },
                { field: 'csaExposureClass' },
                { field: 'sampleNumber' },
                { field: 'sampleType' },
                { field: 'timeOfMoulding' },
            ],
        },
    ];

    useEffect(() => {
        if (data?.payload) {
            const fetchRow = data?.payload.map(item => ({
                ...item,
                sampleNumber: item?.sample?.number,
                sampleType: item?.sample?.type,
                locationInStructure: item?.sample?.locationInStructure,
                minimumTemperature: item?.sample?.minimumTemperature,
                maximumTemperature: item?.sample?.maximumTemperature,
                timeOfSampling: item?.sample?.timeOfSampling,
                timeOfMoulding: item?.sample?.timeOfMoulding,
                samplingLocation: item?.sample?.samplingLocation,
                typeOfMould: item?.sample?.typeOfMould,
                sizeOfMould: item?.sample?.sizeOfMould,
                curingConditions: item?.sample?.curingConditions,
                sizeOfSample: item?.sample?.sizeOfSample,
                sampleStatus: item?.sample?.status,
                sampleCreatedAt: item?.sample?.createdAt,
                sampleUpdatedAt: item?.sample?.updatedAt,
                sampleDeletedAt: item?.sample?.deletedAt,
                samplePickupID: item?.sample?.samplePickup?.id,
                createdByID: item?.sample?.samplePickup?.createdByID,
                workOrderID: item?.sample?.samplePickup?.workOrderID,
                receivedDateTime: item?.sample?.samplePickup?.receivedDateTime,
                assignedPickupDateTime: item?.sample?.samplePickup?.assignedPickupDateTime,
                pickupDateTime: item?.sample?.samplePickup?.pickupDateTime,
                dropoffDateTime: item?.sample?.samplePickup?.dropoffDateTime,
                cylinderId: item?.id,
                samplePickupId: item?.sample?.samplePickupID,
                sampleId: item?.sampleID,
            }))
            setRows(fetchRow)
        }
    }, [data?.payload]);

    const isCellEditable = (params) => {
        const { field, row } = params;
        if (field === 'maximumTemperature' || field === 'dropoffDateTime' || field === 'minimumTemperature' || field === 'pickupTechnician' || field === 'pickupDateTime') {
            if (!row?.samplePickupId) {
                return false;
            }
        }
        return true;
    };


    return (
        <React.Fragment>

            {isLoading ? (
                <LoadingIcon />
            ) : (
                <Card height={varHeight} sx={{ ...dataGridBoxStyle }}>
                    <CardContent>
                        <Box
                            sx={{
                                height: 600,
                                width: '100%',
                            }}
                        >
                            <DataGridPro
                                rows={rows}
                                editMode='row'
                                columns={columns}
                                columnHeader
                                processRowUpdate={processRowUpdate}
                                rowModesModel={rowModesModel}
                                experimentalFeatures={{ columnGrouping: true }}
                                columnGroupingModel={columnGroupingModel}
                                slotProps={{
                                    toolbar: { setRows, setRowModesModel },
                                }}
                                pagination
                                slots={{ toolbar: () => CustomGridToolbar({ tags: tags }) }}
                                rowHeight={75}
                                isCellEditable={isCellEditable}
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                       outline: "none !important",
                                    },
                                 }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </React.Fragment>
    );
}

export default DataViewSampleEdit;
