import { Box, Button, Divider, Grid, Typography, CircularProgress } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Icon } from '../../../components';
import PermissionGate from '../../../components/PermissionGate';
import CustomGridToolbar from '../../../components/customMUI/CustomGridToolbar';
import CustomTextFieldView from '../../../components/customMUI/CustomTextFieldView';
import { featurePermissions } from '../../../constants/permissions';
import routes from '../../../constants/routes';
import useFormatDateTime from '../../../hooks/useFormatDateTime';
import useLocale from '../../../hooks/useLocale';
import useRequest from '../../../hooks/useRequest';
import { useGetConcreteMixDesignsQuery } from '../../../services/concreteMixDesignApi';
import { useGetConcretingSequencesQuery } from '../../../services/concretingSequenceApi';
import {
  useGetConcreteFieldInformationQuery,
} from '../../../services/concreteFieldInformationApi';
import tags from '../../../services/tags';
import { selectPermissions } from '../../../slices/permissionSlice';
import timeConvert from '../../../utils/timeConvert';
import { selectUser } from '../../../slices/userSlice';
import urls from '../../../constants/urls';
import { selectAuth } from '../../../slices/authSlice';
import axios from 'axios';
import ConcreteAddButton from '../components/ConcreteAddButton';
import ConcreteMixDesignAddButton from '../components/ConcreteMixDesignAddButton';

const ViewFieldInfoField = ({ concreteFieldInfo }) => {
  const { id } = useParams();
  const user = useSelector(selectUser);
  const auth = useSelector(selectAuth);
  const text = useLocale();
  const { formatDate, formatTime } = useFormatDateTime();
  const [loading, setLoading] = useState(false);
  const [buttonType, setbuttonType] = useState('');
  const location = useLocation();
  const concretingSequencesQuery = useRequest(useGetConcretingSequencesQuery, {
    'filter[workOrderID]': `equals,${id}`,
  });

  const concreteMixQuery = useRequest(useGetConcreteMixDesignsQuery, {
    'filter[workOrderID]': `equals,${id}`,
  });

  const downloadButtonStyle = {
    marginLeft: '10px', // Adjust the margin as needed
    display: 'flex', // Ensure the button is displayed inline
  };
  const columns = [
    {
      field: 'loadNumber',
      headerName: text.ConcretingSequence.LABEL_LOAD_NUMBER,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'batchTime',
      flex: 1,
      minWidth: 150,
      headerName: text.ConcretingSequence.LABEL_BATCH_TIME,
      valueFormatter: (params) => {
        return formatTime(params.value);
      },
    },
    {
      field: 'endTime',
      headerName: text.ConcretingSequence.LABEL_END_TIME,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => formatTime(params.value),
    },
    {
      field: 'airContent',
      headerName: text.ConcretingSequence.LABEL_AIR_CONTENT,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.adjustedAirContent ? params.row.adjustedAirContent : params.row.airContent ? params.row.airContent : ""
      },
    },

    {
      field: 'slump',
      headerName: text.ConcretingSequence.LABEL_SLUMP,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return params.row.adjustedSlump ? params.row.adjustedSlump : params.row.slump ? params.row.slump : ""
      },
    },
    {
      field: 'concreteTemperature',
      headerName: text.ConcretingSequence.LABEL_CONCRETE_TEMPERATURE,
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'collectSamples',
      headerName: text.ConcretingSequence.LABEL_COLLECT_SAMPLES_QUESTION,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return params.value ? text.YES : text.NO;
      },
    },
    {
      field: 'truckNumber',
      headerName: text.ConcretingSequence.LABEL_TRUCK_NUMBER,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'ticketNumber',
      headerName: text.ConcretingSequence.LABEL_TICKET_NUMBER,
      flex: 1,
      minWidth: 200,
    },

    {
      field: 'quantityTruckLoad',
      headerName: text.ConcretingSequence.LABEL_QUANTITY_TRUCK_LOAD,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'quantityCumulative',
      headerName: text.ConcretingSequence.LABEL_QUANTITY_CUMULATIVE,
      flex: 1,
      minWidth: 200,
    },

    {
      field: 'arrivedOnSiteTime',
      headerName: text.ConcretingSequence.LABEL_ARRIVED_ON_SITE,
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => timeConvert(params.value),
    },
    {
      field: 'startTime',
      headerName: text.ConcretingSequence.LABEL_START_TIME,
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => timeConvert(params.value),
    },
    {
      field: 'waterAdditive',
      headerName: text.ConcretingSequence.LABEL_WATER_ADDITIVE,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'spAdditive',
      headerName: text.ConcretingSequence.LABEL_SP_ADDITIVE,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'ambientTemperature',
      headerName: text.ConcretingSequence.LABEL_AMBIENT_TEMPERATURE,
      flex: 1,
      minWidth: 200,
    },

    {
      field: 'conformity',
      headerName: text.ConcretingSequence.SUBTITLE_CONFORMITY,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (params.value ? <Typography sx={{ color: '#2ba131' }}>C</Typography> : params.value === false ? <Typography sx={{ color: '#ff0055' }}>NC</Typography> : '-'),
    },
  ];

  const columns2 = [

    {
      field: 'formula',
      headerName: text.ConcreteMixDesign.LABEL_FORMULA,
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'specifiedConcreteClass',
      headerName: text.ConcreteMixDesign.LABEL_SPECIFIED_CONCRETE_GLASS,
      flex: 1,
      minWidth: 220,
    },
    {
      field: "concreteElementLocation",
      headerName: text.ConcreteMixDesign.LABEL_LOCATION_OF_CONCRETE_ELEMENT,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "slump",
      headerName: text.ConcreteMixDesign.LABEL_SLUMP_AIR,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          params.row.slump +
          " ± " +
          params.row.slumpRange +
          " or " +
          params.row.minAirContent +
          "-" +
          params.row.maxAirContent +
          "%"
        );
      },
    },
  ];

  const userPermissions = useSelector(selectPermissions);
  // Styling
  const titleStyle = { fontWeight: 'bold', fontSize: 24, mb: 1 };

  const dataGridBoxStyle = {
    '& .MuiDataGrid-root': { border: 'none' },
    '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
  };

  const tool = 'ConcreteFieldInformation';
  const navigate = useNavigate();

  const navigateToEdit = () => {
    const [empty, workOrders, idParam, concreteFieldReport, edit] =
      routes.CONCRETE_FIELD_REPORT_EDIT.split('/');
    navigate(`/${workOrders}/${id}/${concreteFieldReport}/${edit}`);
  };

  async function downloadReportField() {
    try {
      setLoading(true);
      setbuttonType('fieldreport')
      const pdfUrl = process.env.REACT_APP_BASE_URL + urls.PDF_CONCRETE_FIELD_RESULT + '/' + id
      const response = await axios.get(pdfUrl, {
        responseType: 'blob', // Set the responseType to 'blob' for binary data
        timeout: 600000, // Set a longer timeout (in milliseconds)
        headers: { 'authorization': 'Bearer ' + auth.token },
      });

      // Create a blob URL for the PDF content
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Field_result_report.pdf';
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error.message);
    } finally {
      setLoading(false);
      setbuttonType('')
    }
  };
  async function downloadSiteConcreteReportField() {
    try {
      setLoading(true);
      setbuttonType('sitereport')
      const pdfUrl = process.env.REACT_APP_BASE_URL + urls.PDF_SITE_CONCRETE_FIELD_RESULT + '/' + id
      const response = await axios.get(pdfUrl, {
        responseType: 'blob', // Set the responseType to 'blob' for binary data
        timeout: 600000, // Set a longer timeout (in milliseconds)
        headers: { 'authorization': 'Bearer ' + auth.token },
      });

      // Create a blob URL for the PDF content
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Site_concrete_result_report.pdf';
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error.message);
    } finally {
      setLoading(false);
      setbuttonType('')
    }
  };

  return (
    <React.Fragment>
      {/* Card 1 */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {user.id === concreteFieldInfo.fieldTechnicianID ? (
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Button
              variant={'contained'}
              startIcon={<Icon iconName={'Edit'} />}
              onClick={navigateToEdit}
            >
              {text.Buttons.EDIT}
            </Button>
          </Box>
        ) : (
          <PermissionGate
            userPermissions={userPermissions}
            componentPermissions={[featurePermissions.CONCRETE_FIELD_INFORMATION_UPDATE]}
          >
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              <Button
                variant={'contained'}
                startIcon={<Icon iconName={'Edit'} />}
                onClick={navigateToEdit}
              >
                {text.Buttons.EDIT}
              </Button>
            </Box>
          </PermissionGate>
        )}

        {/* 
          // This button Disable as of now no requirement in phase-1
          <Button
            variant={'contained'}
            color="primary"
            onClick={downloadReportField}
            disabled={buttonType == 'fieldreport' ? loading : false}
            style={downloadButtonStyle}
          >
           {buttonType == 'fieldreport' ? <CircularProgress size={24} /> : 'Download Report'}
          </Button> */}

        <Button
          variant={'contained'}
          color="primary"
          startIcon={<Icon iconName={'DownloadForOffline'} />}
          onClick={downloadSiteConcreteReportField}
          disabled={buttonType == 'sitereport' ? loading : false}
          style={downloadButtonStyle}
        >
          {buttonType == 'sitereport' ? <CircularProgress size={24} /> : 'Site Concrete Supervision'}
        </Button>
      </Box>
      <Box>
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography noWrap sx={titleStyle}>
              {text.ConcreteFieldInformation.SUBTITLE_PRETESTING_DETAILS}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography noWrap sx={titleStyle}>
              {/* {text.ConcreteFieldInformation.SUBTITLE_PRETESTING_CHECKLIST} */}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography noWrap sx={titleStyle}>
              &nbsp;
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container direction={'column'}>
              <Grid item>
                {/* <CustomTextFieldView name="LABEL_PREPARED_BY" tool={tool} value={"value"} /> */}
                <CustomTextFieldView
                  name="LABEL_DATE_OF_VISIT"
                  tool={tool}
                  value={formatDate(concreteFieldInfo.dateOfVisit)}
                />
                <CustomTextFieldView
                  name="LABEL_EQUIPMENT_NUMBER"
                  tool={tool}
                  value={concreteFieldInfo.equipmentNumber}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={4} lg={4}>
              <Grid container direction={"column"}>
                <Grid item>
                  <CustomTextFieldView
                        name="LABEL_PERSONAL_PROTECTIVE_EQUIPMENT"
                        tool={tool}
                        value={"value"}
                      />
                      <CustomTextFieldView
                        name="LABEL_EQUIPMENT_NUMBER"
                        tool={tool}
                        value={"value"}
                      />
                      <CustomTextFieldView
                        name="LABEL_REQUIRED_TRAINING"
                        tool={tool}
                        value={"value"}
                      />
                      <CustomTextFieldView
                        name="LABEL_COMPLETED_PREWORK_ASSESSMENT"
                        tool={tool}
                        value={"value"}
                      />
                      <CustomTextFieldView
                        name="LABEL_JOB_SAFETY_ANALYSIS"
                        tool={tool}
                        value={"value"}
                      />
                </Grid>
              </Grid>
            </Grid> */}

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container direction={'column'}>
              <Grid item>
                <CustomTextFieldView
                  name="LABEL_HAZARDS_QUESTION"
                  tool={tool}
                  value={concreteFieldInfo.unexpectedHazards}
                />
                <CustomTextFieldView
                  name="LABEL_SELECT_WEATHER_CONDITIONS"
                  tool={tool}
                  value={concreteFieldInfo.weather}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider variant="middle" sx={{ mb: 4, mt: 4 }} />

        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography noWrap sx={titleStyle}>
              {text.ConcreteFieldInformation.SUBTITLE_CONTRACTOR_DETAILS}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container direction={'column'}>
              <Grid item>
                <CustomTextFieldView
                  name="LABEL_SUBCONTRACTOR_ORGANISATION"
                  tool={tool}
                  value={concreteFieldInfo.subContractorOrganisation}
                />
                <CustomTextFieldView
                  name="LABEL_SUBCONTRACTOR_NAME"
                  tool={tool}
                  value={concreteFieldInfo.subContractorContactName}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container direction={'column'}>
              <Grid item>
                <CustomTextFieldView
                  name="LABEL_SUBCONTRACTOR_TELEPHONE"
                  tool={tool}
                  value={concreteFieldInfo.subContractorContactTelephone}
                />
                <CustomTextFieldView
                  name="LABEL_SUBCONTRACTOR_EMAIL"
                  tool={tool}
                  value={concreteFieldInfo.subContractorContactEmail}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Grid container direction={'column'}>
              <Grid item>&nbsp;</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Divider variant="middle" sx={{ mb: 4, mt: 4 }} />

      {/* Card 2 */}
      <Box height={'40vh'} sx={dataGridBoxStyle}>

        <Grid item>
          <Grid container spacing={1} justifyContent="space-between">
            <Typography sx={{ fontSize: 22, fontWeight: 'bold' }} gutterBottom>
              {text.ConcreteMixDesign.TITLE}
            </Typography>
            <PermissionGate
              userPermissions={userPermissions}
              componentPermissions={[featurePermissions.CONCRETE_POUR_CREATE]}
            >
              <ConcreteMixDesignAddButton  />
            </PermissionGate>
          </Grid>
        </Grid>
        <DataGridPro
          rows={concreteMixQuery.data ? concreteMixQuery.data.payload : []}
          slots={{ toolbar: () => CustomGridToolbar({ tags: [tags.CONCRETE_MIX] }) }}
          columns={columns2}
          columnHeader
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          pageSizeOptions={[10]}
          pagination
        />
      </Box>

      <Divider variant="middle" sx={{ mb: 4, mt: 4 }} />

      {/* Card 3 */}
      <Box height={'40vh'} sx={dataGridBoxStyle}>
        <Grid item>
          <Grid container spacing={1} justifyContent="space-between">
            <Typography sx={{ fontSize: 22, fontWeight: 'bold' }} gutterBottom>
              {text.ConcreteFieldInformation.REVIEW_CONCRETING_SEQUENCES}
            </Typography>
            <PermissionGate
              userPermissions={userPermissions}
              componentPermissions={[featurePermissions.CONCRETE_POUR_CREATE]}
            >
                <Button
                    sx={{ my: 2 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Icon iconName={'AddCircle'} />}
                    onClick={() =>
                      navigate(
                        `${location.pathname}/add-concrete-sequence`,
                        {
                          state: { background: location },
                        }
                      )
                    }
                  >
                    {text.Buttons.ADD}
                  </Button>
            </PermissionGate>
          </Grid>
        </Grid>
        <DataGridPro
          rows={concretingSequencesQuery.data ? concretingSequencesQuery.data.payload : []}
          slots={{ toolbar: () => CustomGridToolbar({ tags: [tags.CONCRETING_SEQUENCE] }) }}
          columns={columns}
          columnHeader
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
          pageSizeOptions={[10]}
          pagination
        />
      </Box>
    </React.Fragment>
  );
};

export default ViewFieldInfoField;
