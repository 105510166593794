import React from "react";
import FlexBetween from "../../components/FlexBetween";
import { Typography, Button, Divider, Box, Grid, Hidden } from "@mui/material";
import CustomCard from "../../components/customMUI/CustomCard";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../hooks";
import Icon from "../../components/Icon";
import routes from "../../constants/routes";

// Permissions
import PermissionGate from "../../components/PermissionGate";
import { useSelector } from "react-redux";
import { selectPermissions } from "../../slices/permissionSlice";

const HomeCards = ({ tool, addPermissions, cardContent }) => {
  const navigate = useNavigate();
  const text = useLocale();
  const userPermissions = useSelector(selectPermissions);

  let cardTitle, cardRoute, cardRouteAdd;
  const viewA = text.Home.VIEW_ALL;
  const viewP = text.Home.VIEW_PENDING;
  const viewC = text.Home.VIEW_COMPLETED;

  if (tool === "Projects") {
    cardTitle = text.Home.RECENTP;
    cardRoute = routes.PROJECTS;
    cardRouteAdd = routes.PROJECT_ADD;
  } else if (tool === "WorkOrders") {
    cardTitle = text.Home.RECENTW;
    cardRoute = routes.WORKORDERS;
    cardRouteAdd = routes.WORKORDER_ADD;
  } else if (tool === "Samples") {
    cardTitle = text.Home.RECENTS;
    cardRoute = routes.SAMPLES;
    cardRouteAdd = routes.SAMPLES_ADD;
  }

  return (
    <React.Fragment>
      <FlexBetween>
        <Typography variant="h5" sx={{ fontWeight: "700", mb: 1, flex: 1 }}>
          {cardTitle}
        </Typography>
        <Button onClick={() => navigate(cardRoute)} sx={{ textTransform: "none", color: "#666" }}>
          <Icon iconName={"Visibility"} sx={{ mr: 0.3, fontSize: "1.2rem" }} />
          <Hidden xsDown smDown>
            {viewA}
          </Hidden>
        </Button>
        {/* <Button onClick={() => navigate(cardRoute)} sx={{ textTransform: "none", color: "#666" }}>
          <Icon iconName={"AccessTime"} sx={{ mr: 0.3, fontSize: "1.2rem" }} />
          <Hidden xsDown smDown>
            {viewP}
          </Hidden>
        </Button>
        <Button onClick={() => navigate(cardRoute)} sx={{ textTransform: "none", color: "#666" }}>
          <Icon iconName={"Check"} sx={{ mr: 0.3, fontSize: "1.2rem" }} />
          <Hidden xsDown smDown>
            {viewC}
          </Hidden>
        </Button> */}
      </FlexBetween>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={2}>
        {cardContent}

        <PermissionGate userPermissions={userPermissions} componentPermissions={addPermissions}>
          <CustomCard
            cContent={
              <Box
                display="flex"
                justifyContent="center"
                alignContent="center"
                onClick={() => navigate(cardRouteAdd)}
              >
                <Icon iconName="Add" sx={{ fontSize: "80px" }} />
              </Box>
            }
            cActions={
              <Typography>
                Add new <b>{tool}</b>
              </Typography>
            }
          />
        </PermissionGate>
      </Grid>
    </React.Fragment>
  );
};

export default HomeCards;
