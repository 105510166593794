import { api } from './api';
import urls from '../constants/urls';
import tags from './tags';
import refreshCache from '../utils/refreshCache';

export const samplePickUpApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addSamplePickUp: builder.mutation({
      query: (data) => ({
        url: urls.SAMPLE_PICKUPS,
        method: 'POST',
        body: { ...data },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.SAMPLE_PICKUP, tags.SAMPLE]);
        } catch (e) { }
      },
    }),

    addPendingSamplePickUp: builder.mutation({
      query: (data) => ({
        url: urls.SAMPLE_PICKUPS + '/pending-assignee',
        method: 'POST',
        body: { ...data },
      }),
    }),

    // For Field Technicians delivering then samples
    updateSamplePickupDelivery: builder.mutation({
      query: (data) => ({
        url: urls.SAMPLE_PICKUPS + '/' + data.id + '/update-delivery',
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.SAMPLE_PICKUP, tags.SAMPLE]);
        } catch (e) {
          console.log(e);
        }
      },
    }),

    // GET ALL
    getSamplePickups: builder.query({
      query: (params) => ({
        url: urls.SAMPLE_PICKUPS,
        params: params,
      }),
      providesTags: [tags.SAMPLE_PICKUP],
    }),

    // GET ONE
    getSamplePickup: builder.query({
      query: (id) => ({
        url: urls.SAMPLE_PICKUPS + '/' + id,
      }),
      providesTags: [tags.SAMPLE_PICKUP],
    }),

    updateSamplePickup: builder.mutation({
      query: (data) => ({
        url: urls.SAMPLE_PICKUPS + '/' + data.id,
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.SAMPLE_PICKUP]);
        } catch (e) { }
      },
    }),

    updateAssignedSamplePickup: builder.mutation({
      query: (data) => ({
        url: urls.SAMPLE_PICKUPS + '/' + data.id + '/update-assigned',
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.SAMPLE_PICKUP]);
        } catch (e) { }
      },
    }),
  }),
});

export const {
  useAddSamplePickUpMutation,
  useGetSamplePickupsQuery,
  useGetSamplePickupQuery,
  useUpdateSamplePickupMutation,
  useUpdateSamplePickupDeliveryMutation,
  useAddPendingSamplePickUpMutation,
  useUpdateAssignedSamplePickupMutation
} = samplePickUpApi;
