import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MobileResponsiveDialog } from '../../../components/MobileResponsiveDialog';
import { useFormikAdd } from '../../../hooks/useFormikAdd';
import { useConcretePourSchema } from '../../../schemas/concretePour.schema';
import { useAddConcreteMutation } from '../../../services/concreteApi';
import { ConcretePourDialog } from './ConcretePourDialog';
import { useLocale } from '../../../hooks';
import { Button } from '@mui/material';
import { Icon } from '../../../components';
import { useFormik } from 'formik';
import { useHandleError } from '../../../hooks/useHandleError';
import useHandleSuccess from '../../../hooks/useHandleSuccess';

function ConcreteAddButton({ workOrderID }) {
  const { concretePourCreateSchema } = useConcretePourSchema();

  const [open, setOpen] = useState(false);
  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();
  const [addConcretePour] = useAddConcreteMutation();

  const text = useLocale();
  const initialValues = {
    workOrderID: workOrderID,
    element: '',
    area: '',
    volume: '',
    samplesAmount: '',
    cylindersPerSample: '3',
    cylindersPerFieldCured:'0'
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: concretePourCreateSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await addConcretePour(values).unwrap();
        handleSuccessResponse(text.SUCCESSFUL_CREATE, text.ConcretePour.SUCCESSFUL_CREATE);
      } catch (e) {
        console.log(e);
        handleErrorResponse(e, text.ConcretePour.UNSUCCESSFUL_CREATE);
      }
      setOpen(false);
      resetForm();
    },
  });

  return (
    <React.Fragment>
      <Button
        sx={{ my: 2 }}
        variant="contained"
        color="primary"
        startIcon={<Icon iconName={'AddCircle'} />}
        onClick={() => setOpen(true)}
      >
        {text.Buttons.ADD}
      </Button>
      <MobileResponsiveDialog open={open}>
        <ConcretePourDialog
          handleClose={() => setOpen(false)}
          formikConcretePour={formik}
          handleSubmit={formik.handleSubmit}
        />
      </MobileResponsiveDialog>
    </React.Fragment>
  );
}

export default ConcreteAddButton;
