import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ConcreteMixDesignAdd } from './components/ConcreteFieldReport/ConcreteMixDesigns/ConcreteMixDesignAdd';
import { ConcreteMixDesignAddChange } from './components/ConcreteFieldReport/ConcreteMixDesigns/ConcreteMixDesignAddChange';
import { ConcretingSequenceAdd } from './components/ConcreteFieldReport/ConcretingSequence/ConcretingSequenceAdd';
import { ConcretingSequenceAddChange } from './components/ConcreteFieldReport/ConcretingSequence/ConcretingSequenceAddChange';
import ConcretingSequenceEdit from './components/ConcreteFieldReport/ConcretingSequence/ConcretingSequenceEdit';
import Protected from './components/layout/Protected';
import routes from './constants/routes';
import {
  ClientAdd,
  ClientEdit,
  Clients,
  ConcreteAdd,
  Home,
  Login,
  ProjectAdd,
  ProjectDetails,
  ProjectEdit,
  Projects,
  Samples,
  WorkorderAdd,
  WorkorderDetails,
  WorkorderEdit,
  Workorders,
  WorkOrderConcreteFieldReportAdd,
  SampleCollector,
  SamplePickupAssigned,
  SamplePickupDetails,
  LabCylinders,
  LabCylindersDetails,
  Users,
  UserAdd,
} from './pages';
import ConcreteEdit from './pages/workorders/ConcreteEdit';
import SamplePickupEdit from './pages/samples/SamplePickupEdit';
import WorkOrdersAssigned from './pages/workorders/WorkOrdersAssigned';
import WorkOrderConcreteFieldReportEdit from './pages/workorders/WorkOrderConcreteFieldReportEdit';
import ConcreteMixDesignEdit from './components/ConcreteFieldReport/ConcreteMixDesigns/ConcreteMixDesignEdit';
import SamplePickups from './pages/samples/SamplePickups';
import DataView from './pages/data_view/DataView';
import DataViewEdit from './pages/data_view/DataViewEdit';
import FormViewConcreteMixDesignEdit from './pages/data_view/forms/FormViewConcreteMixDesignEdit';
import FormViewCylinderSampleEdit from './pages/data_view/forms/FormViewCylinderSampleEdit';
import WorkOrderConcreteSequenceAdd from './pages/workorders/WorkOrderConcreteSequenceAdd';
import EquipmentSet from './pages/Equipment_Set/EquipmentSet';
import EquipmentSetAdd from './pages/Equipment_Set/EquipmentSetAdd';

function App() {
  const location = useLocation();
  const background = location.state && location.state.background;
  return (
    <React.Fragment>
      <Routes location={background || location}>
        <Route element={<Protected />}>
          <Route element={<Home />} path="/" exact />
          <Route element={<Projects />} path={routes.PROJECTS} />
          <Route element={<ProjectAdd />} path={routes.PROJECT_ADD} />
          <Route element={<ProjectEdit />} path={routes.PROJECT_EDIT} />
          <Route element={<ProjectDetails />} path={routes.PROJECT_DETAILS} />
          <Route element={<Workorders />} path={routes.WORKORDERS} />
          <Route element={<WorkorderAdd />} path={routes.WORKORDER_ADD} />
          <Route element={<WorkorderEdit />} path={routes.WORKORDER_EDIT} />
          <Route element={<WorkorderDetails />} path={routes.WORKORDER_DETAILS} />
          <Route element={<WorkOrdersAssigned />} path={routes.WORKORDERS_ASSIGNED} />
          <Route element={<ConcreteAdd />} path={routes.CONCRETE_ADD} />
          <Route
            element={<WorkOrderConcreteFieldReportAdd />}
            path={routes.CONCRETE_FIELD_REPORT_ADD}
          />
          <Route
            element={<WorkOrderConcreteFieldReportEdit />}
            path={routes.CONCRETE_FIELD_REPORT_EDIT}
          />

          <Route element={<Samples />} path={routes.SAMPLES} />
          <Route element={<SampleCollector />} path={routes.WORKORDER_SAMPLE_COLLECTOR} />
          <Route element={<SamplePickups />} path={routes.SAMPLE_PICKUPS} />

          <Route element={<SamplePickupAssigned />} path={routes.SAMPLE_PICKUPS_ASSIGNED} />

          <Route element={<SamplePickupEdit />} path={routes.SAMPLE_PICKUP_EDIT} />
          <Route element={<SamplePickupDetails />} path={routes.SAMPLE_PICKUP_DETAILS} />

          <Route element={<LabCylinders />} path={routes.LAB_CYLINDERS} />
          <Route element={<LabCylindersDetails />} path={routes.LAB_CYLINDERS_DETAILS} />

          <Route element={<Clients />} path={routes.CLIENTS} />
          <Route element={<ClientAdd />} path={routes.CLIENT_ADD} />
          <Route element={<ClientEdit />} path={routes.CLIENT_DETAILS} />

          <Route element={<Users />} path={routes.USERS} />
          <Route element={<UserAdd />} path={routes.USER_ADD} />
          <Route element={<DataView />} path={routes.DATA_VIEW} />
          <Route element={<DataViewEdit />} path={routes.DATA_VIEW_EDIT} />
          <Route element={<EquipmentSet />} path={routes.EQUIPMENT_SET} />
          <Route element={<EquipmentSetAdd />} path={routes.EQUIPMENT_SET_ADD} />

        </Route>
        <Route element={<Login />} path={routes.LOGIN} />
      </Routes>
      {background && (
        <Routes>
          <Route
            element={<ConcreteMixDesignAdd />}
            path={routes.CONCRETE_FIELD_REPORT_ADD + routes.CONCRETE_MIX_DESIGNS_ADD}
          />
          <Route
            element={<ConcreteMixDesignAddChange />}
            path={routes.CONCRETE_FIELD_REPORT_ADD + routes.CONCRETE_MIX_DESIGNS_ADD_CHANGE}
          />
          <Route
            element={<ConcreteMixDesignEdit />}
            path={routes.CONCRETE_FIELD_REPORT_EDIT + routes.CONCRETE_MIX_DESIGNS_EDIT}
          />
          <Route
            element={<ConcretingSequenceEdit />}
            path={routes.CONCRETE_FIELD_REPORT_EDIT + routes.CONCRETING_SEQUENCES_ADD_CHANGE}
          />
          <Route
            element={<ConcretingSequenceAdd />}
            path={routes.CONCRETE_FIELD_REPORT_ADD + routes.CONCRETING_SEQUENCES_ADD}
          />
          <Route
            element={<ConcretingSequenceAddChange />}
            path={routes.CONCRETE_FIELD_REPORT_ADD + routes.CONCRETING_SEQUENCES_ADD_CHANGE}
          />
          <Route element={<ConcreteEdit />} path={routes.CONCRETE_EDIT} />
          <Route element={<FormViewConcreteMixDesignEdit />} path={routes.DATA_VIEW_EDIT_CONCRETE_MIX_DESIGN} />
          <Route element={<FormViewCylinderSampleEdit />} path={routes.DATA_VIEW_EDIT_SAMPLE} />
          <Route element={<WorkOrderConcreteSequenceAdd />} path={routes.WORKORDER_DETAILS_ADD_CONCRETE_SEQUENCE} />
        </Routes>
      )}
    </React.Fragment>
  );
}
export default App;
