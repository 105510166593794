import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useConcretingSequenceAdd from '../../../hooks/useConcretingSequenceAdd';
import { addToQueue, formType } from '../../../slices/offlineQueueSlice';
import { ConcretingSequenceDialog } from './ConcretingSequenceDialog';

export const ConcretingSequenceAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    STEPS,
    laboratoryCuredSampleID,
    setLaboratoryCuredSampleID,
    fieldCuredSampleID,
    setFieldCuredSampleID,
    commonSampleFields,
    setCommonSampleFields,
    activeStep,
    setActiveStep,
    submitted,
    setSubmitted,
    concretingSequenceID,
    setConcretingSequenceID,
    initialValuesConcretingSequence,
    setInitialValuesConcretingSequence,
    initialValuesLaboratoryCured,
    setInitialValuesLaboratoryCured,
    initialValuesFieldCured,
    setInitialValuesFieldCured,
    openLaboratorySample,
    setOpenLaboratorySample,
    openFieldSample,
    setOpenFieldSample,
    handleBack,
    handleContinue,
    handleSave,
    handleSaveAndComplete,
    handleSetCommonSampleField,
    handleRemoveLaboratorySample,
    handleRemoveFieldSample,
    handleAddLaboratorySample,
    handleAddFieldSample,
    formikConcretingSequence,
    formikSamplesLaboratoryCured,
    formikSamplesFieldCured,
  } = useConcretingSequenceAdd({
    submitConcretingSequence: submitConcretingSequence,
    submitLaboratoryCuredSample: submitLaboratoryCuredSample,
    submitFieldCuredSample: submitFieldCuredSample,
  });

  useEffect(() => {
    setInitialValuesLaboratoryCured({
      ...initialValuesLaboratoryCured,
      concretingSequenceID: concretingSequenceID,
    });
    setInitialValuesFieldCured({
      ...initialValuesFieldCured,
      concretingSequenceID: concretingSequenceID,
    });
  }, [concretingSequenceID]);

  function submitLaboratoryCuredSample(values) {
    // const sample = { ...values, id: laboratoryCuredSampleID };

    dispatch(
      addToQueue({
        id: laboratoryCuredSampleID,
        type: formType.SAMPLE,
        data: { ...values, id: laboratoryCuredSampleID },
      })
    );

    // Need to set initial values for select boxes
    setInitialValuesLaboratoryCured(values);
  }

  function submitFieldCuredSample(values) {
    // const sample = { ...values, id: fieldCuredSampleID };

    dispatch(
      addToQueue({
        id: fieldCuredSampleID,
        type: formType.SAMPLE,
        data: { ...values, id: fieldCuredSampleID },
      })
    );

    // Need to set initial values for select boxes
    setInitialValuesFieldCured(values);
  }

  function submitConcretingSequence(values) {
    let id = null;
    if (concretingSequenceID === null) {
      id = uuidv4();
      setConcretingSequenceID(id);
    } else {
      id = concretingSequenceID;
    }

    dispatch(
      addToQueue({
        id: id,
        type: formType.CONCRETING_SEQUENCE,
        data: { ...values, id: id },
      })
    );

    // Need to set initial values for select boxes
    setInitialValuesConcretingSequence(values);
  }
  return (
    <ConcretingSequenceDialog
      formikConcretingSequence={formikConcretingSequence}
      formikSamplesLaboratoryCured={formikSamplesLaboratoryCured}
      formikSamplesFieldCured={formikSamplesFieldCured}
      laboratoryCuredSampleID={laboratoryCuredSampleID}
      fieldCuredSampleID={fieldCuredSampleID}
      commonSampleFields={commonSampleFields}
      setCommonSampleFields={setCommonSampleFields}
      handleSetCommonSampleField={handleSetCommonSampleField}
      openLaboratorySample={openLaboratorySample}
      openFieldSample={openFieldSample}
      handleSaveAndComplete={handleSaveAndComplete}
      handleContinue={handleContinue}
      handleBack={handleBack}
      handleRemoveLaboratorySample={handleRemoveLaboratorySample}
      handleRemoveFieldSample={handleRemoveFieldSample}
      handleAddLaboratorySample={handleAddLaboratorySample}
      handleAddFieldSample={handleAddFieldSample}
      activeStep={activeStep}
      steps={STEPS}
      isOpen={true}
      onClose={() => navigate(-1)}
      onOpen={(location) => navigate(location.pathname)}
    />
  );
};
