import { Autocomplete, Box, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocale } from '../../../hooks';


const FormViewPickUp = ({ formik, btIcon, btText }) => {
    const text = useLocale();
    const labelStyle = { fontWeight: 'bold', fontSize: 18, mt: 2 };

    useEffect(() => {
        formik.setTouched(true, false);
    }, []);

    return (
        <div >
            <Typography sx={labelStyle}>Pick up</Typography>
            <Grid container>
                <Grid item xs={6} lg={6}>
                    <TextField
                        label={text.DataView.DROPOFFDATE}
                        value={formik.values.airContent}
                        name={'airContent'}
                        onChange={formik.handleChange}
                        sx={{ mt: 2, display: 'flex', flex: 1 }}
                        type={'number'}
                    />

                    <TextField
                        label={text.DataView.MINIMUM_TEMPERATURE}
                        value={formik.values.adjustedAirContent}
                        name={'adjustedAirContent'}
                        onChange={formik.handleChange}
                        sx={{ mt: 2, display: 'flex', flex: 1 }}
                        type={'number'}
                    />
                    <TextField
                        label={text.DataView.FIELDTECHNICIANID}
                        value={formik.values.concreteTemperature}
                        name={'concreteTemperature'}
                        onChange={formik.handleChange}
                        sx={{ mt: 2, display: 'flex', flex: 1 }}
                        type={'number'}
                    />
                </Grid>
                <Grid item xs={6} lg={6} pl={1}>


                    <TextField
                        label={text.DataView.MAXIMUM_TEMPERATURE}
                        value={formik.values.concreteTemperature}
                        name={'concreteTemperature'}
                        onChange={formik.handleChange}
                        sx={{ mt: 2, display: 'flex', flex: 1 }}
                        type={'number'}
                    />
                </Grid>
            </Grid>



        </div>
    );
};

export default FormViewPickUp;
