import { Card, CardContent } from '@mui/material';
import React from 'react';
import { FlexBetween, HeaderDetails } from '../../components';
import { useLocale } from '../../hooks';
import ViewCardSample from './forms/ViewCardSample';

//Samples
import { useParams } from 'react-router-dom';

//Sample Pickup
import useRequest from '../../hooks/useRequest';
import { useGetSamplePickupQuery } from '../../services/samplePickUpApi';
import { dataGridBoxStyle } from '../../styles/datagrid';
import ConfirmDeliveryButton from './components/ConfirmDeliveryButton';
import SamplePickupInfo from './components/SamplePickupInfo';

function SamplePickupDetails() {
  const text = useLocale();
  const { id } = useParams();
  const samplePickupQuery = useRequest(useGetSamplePickupQuery, id);
  const samplePickup = samplePickupQuery.data ? samplePickupQuery.data.payload : {};

  return (
    <React.Fragment>
      <FlexBetween>
        <HeaderDetails btName={text.SamplePickups.BT_RETURN} to={-1} />
      </FlexBetween>

      {/* SAMPLE Details */}
      <Card sx={{ m: 1, p: 2 }}>
        <CardContent>
          <ViewCardSample data={samplePickup} />
        </CardContent>
      </Card>

      {/* Sample Pickup Information */}
      <Card sx={{ m: 1, p: 2 }}>
        <CardContent sx={dataGridBoxStyle}>
          <SamplePickupInfo samplePickupQuery={samplePickupQuery} reftechTable={()=> samplePickupQuery.refetch()}/>
        </CardContent>
      </Card>
      <ConfirmDeliveryButton samplePickupQuery={samplePickupQuery} sx={{ m: 1 }} />
    </React.Fragment>
  );
}

export default SamplePickupDetails;
