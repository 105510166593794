import React, { useEffect, useState } from "react";
import { useLocale } from "../../../hooks";
import { Button, Grid, Icon, IconButton, Typography } from "@mui/material";
import StatusChip from "../../../components/StatusChip";
import useFormatDateTime from "../../../hooks/useFormatDateTime";
import CustomTextFieldView from "../../../components/customMUI/CustomTextFieldView";
import { LoadingIcon } from "../../../components";
import FlexBetween from "../../../components/FlexBetween";
import CustomButton from "../../../components/customMUI/CustomButton";
import routes from "../../../constants/routes";
import PermissionGate from "../../../components/PermissionGate";
import { useSelector } from "react-redux";
import { selectUser } from "../../../slices/userSlice";
import { featurePermissions } from "../../../constants/permissions";
import { selectPermissions } from "../../../slices/permissionSlice";
import EditIcon from "@mui/icons-material/Edit";
import { EditAdditonalInfoDialog } from "../components/EditAdditionalInstructionDialogue";
import { useUpdateWorkOrderMutation } from "../../../services/workordersApi";
import { useParams } from "react-router-dom";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { useHandleError } from "../../../hooks/useHandleError";
import useHandleSuccess from "../../../hooks/useHandleSuccess";

const ViewWorkOrder = ({ data, workOrderId }) => {
  const userPermissions = useSelector(selectPermissions);
  const tool = "WorkOrders";
  const text = useLocale();
  const { formatDate, formatTime } = useFormatDateTime();
  const titleStyle = { fontWeight: "bold", fontSize: 22, mb: 1 };
  const [open, setOpen] = React.useState(false);
  const { id } = useParams();
  const [backDialogOpen, setBackDialogOpen] = useState(false);
  const [updateWorkOrder] = useUpdateWorkOrderMutation();
  const { handleErrorResponse } = useHandleError();
  const { handleSuccessResponse } = useHandleSuccess();

  // Styling
  const styles = {
    titleStyle: { fontWeight: "bold", fontSize: 24, mb: 2 },
    dataGridBoxStyle: {
      width: "100%",
      "& .MuiDataGrid-root": { border: "none" },
      "& .MuiDataGrid-columnHeaders": { backgroundColor: "#f3f3f3" },
    },
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleMarkClose = async () => {
    const data = {
      id,
      changes: {
        workOrderStatus: "Closed",
      },
    };
    try {
      await updateWorkOrder(data).unwrap();
      setBackDialogOpen(false);
      handleSuccessResponse(text.WorkOrders.SUCCESSFUL_UPDATE);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  return data.isLoading ? (
    <LoadingIcon />
  ) : (
    <Grid container spacing={1} alignItems="stretch">
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent={"space-between"} spacing={1} sx={{ m: 0, p: 1 }}>
            <Grid item>
              <Typography sx={styles.titleStyle}>{text.WorkOrders.FORM_VIEW1}</Typography>
            </Grid>
            <Grid item alignSelf={"center"}>
              <PermissionGate
                userPermissions={userPermissions}
                componentPermissions={[featurePermissions.WORK_ORDERS_UPDATE]}
              >
                <CustomButton
                  route={routes.WORKORDER_EDIT.split(":")[0] + data.id}
                  text={text.WorkOrders.EDIT_BUTTON}
                  sx={{ mr: 2 }}
                  type="NoteAlt"
                />
                <Button
                  sx={{ my: 2, mr: 1, py: 1 }}
                  variant="contained"
                  color="primary"
                  onClick={() => setBackDialogOpen(true)}
                >
                  {text.WorkOrders.MARK_AS_CLOSED}
                </Button>
              </PermissionGate>

              <ConfirmationDialog
                title={text.WorkOrders.WORK_ORDER}
                contentText={text.WorkOrders.MARK_AS_CLOSED_INFO}
                actionText={text.Buttons.CONFIRM}
                isOpen={backDialogOpen}
                handleClose={() => setBackDialogOpen(false)}
                handleOpen={() => setBackDialogOpen(true)}
                handleAction={handleMarkClose}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container direction={"column"}>
          <Grid item>
            <CustomTextFieldView name="number" tool={tool} value={data.number} />
            <CustomTextFieldView name="workLocation" tool={tool} value={data.workLocation} />
            <CustomTextFieldView
              name="ASSIGNEDTECHNICIAN"
              tool={tool}
              value={
                data.fieldTechnician
                  ? data.fieldTechnician.firstName + " " + data.fieldTechnician.lastName
                  : ""
              }
            />
            <CustomTextFieldView
              name="laboratoryID"
              tool={tool}
              value={`${data?.laboratory?.name} ${
                data?.laboratory?.region?.name ? "(" + data?.laboratory?.region?.name + ")" : ""
              }`}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid container direction={"column"}>
          <Grid item>
            <CustomTextFieldView
              name="siteVisitDate"
              tool={tool}
              value={formatDate(data.siteVisitDate)}
            />
            <CustomTextFieldView
              name="arrivalTime"
              tool={tool}
              value={formatTime(data.arrivalTime)}
            />
            <CustomTextFieldView
              name="timeOnSite"
              tool={tool}
              value={data.timeOnSite ? data.timeOnSite / 3600 : ""}
            />
            <CustomTextFieldView
              name="siteVisitType"
              tool={tool}
              value={text.WorkOrderVisitTypes[data.siteVisitType]}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} sm={12} md={12} lg={12}>
        <Grid container>
          <Grid item xs={6} display={"flex"} columnGap={1}>
            {/* <CustomTextFieldView name="laboratoryID" tool={tool} value={data.laboratoryID} /> */}
            <CustomTextFieldView
              name="additionalInstructions"
              tool={tool}
              value={data.additionalInstructions}
            />
            <EditIcon onClick={handleOpen} variant="contained" style={{ cursor: "pointer" }} />
          </Grid>
          <EditAdditonalInfoDialog
            open={open}
            handleClose={() => setOpen(false)}
            workOrderId={workOrderId || id}
            initialValue={data.additionalInstructions}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewWorkOrder;
