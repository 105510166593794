import React from 'react';

import { Box, Card, CardActions, CardContent, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { FlexBetween, Header, Icon } from '../../components';
import routes from '../../constants/routes';
import { useGetElement, useLocale } from '../../hooks';

function Samples() {
  const text = useLocale();
  const navItem = useGetElement(text.Home.NAME);

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.Samples.TABLENAME} />
      </FlexBetween>
      <Grid container>
        <Grid item xs={12} md={3} lg={3}>
          <Box component={Link} to={routes.SAMPLE_PICKUPS_ASSIGNED} sx={{ textDecoration: 'none' }}>
            <Card>
              <CardContent sx={{ height: 125, display: 'flex', justifyContent: 'center' }}>
                <Icon iconName={'LocalShipping'} sx={{ fontSize: 90 }} />
              </CardContent>
              <CardActions sx={{ backgroundColor: '#000', color: '#fff' }}>
                {text.SamplePickups.TITLE}
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Samples;
