// Component for handling changes made to
// the cylinders before submitting the field report

import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCylinderSchema } from '../../../schemas/cylinder.schema';
import { formType, selectFromQueue, updateQueue } from '../../../slices/offlineQueueSlice';
import CylindersDialog from './CylindersDialog';

const CylindersAddChange = ({
  laboratoryCuredSampleID,
  fieldCuredSampleID,
  cylinderID,
  isOpen,
  setIsOpen,
  setSubmitted,
}) => {
  const dispatch = useDispatch();

  const cylinder = useSelector((state) => selectFromQueue(state, cylinderID));

  console.log(cylinder);

  const laboratoryCuredSample = useSelector((state) => {
    const sample = selectFromQueue(state, laboratoryCuredSampleID);
    return sample ? sample.data : null;
  });

  const fieldCuredSample = useSelector((state) => {
    const sample = selectFromQueue(state, fieldCuredSampleID);
    return sample ? sample.data : null;
  });

  const { cylinderCreateSchema } = useCylinderSchema();
  const [samples, setSamples] = useState([]);

  const formikCylinder = useFormik({
    initialValues: cylinder
      ? cylinder.data
      : { customCylinderID: '', sampleID: '', daysToBeCured: 1 },
    enableReinitialize: true,
    validationSchema: cylinderCreateSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        updateQueue({
          id: cylinderID,
          type: formType.CYLINDER,
          data: { ...values, id: cylinderID },
        })
      );

      setSubmitted(true);
      resetForm();
      setIsOpen(false);
    },
  });

  useEffect(() => {
    let samples = [];
    if (laboratoryCuredSampleID) {
      samples.push(laboratoryCuredSample);
    }
    if (fieldCuredSampleID) {
      samples.push(fieldCuredSample);
    }

    setSamples(samples);
  }, [laboratoryCuredSampleID, fieldCuredSampleID]);

  return (
    <CylindersDialog
      formikCylinder={formikCylinder}
      samples={samples}
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    />
  );
};

export default CylindersAddChange;
