import { api } from './api';
import urls from '../constants/urls';
import tags from './tags';
import refreshCache from '../utils/refreshCache';

export const sampleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addSample: builder.mutation({
      query: (data) => ({
        url: urls.SAMPLES,
        method: 'POST',
        body: { ...data },
      }),
    }),
    // Collector
    addSampleCollector: builder.mutation({
      query: (data) => ({
        url: urls.SAMPLES_COLLECTOR,
        method: 'POST',
        body: { ...data },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.SAMPLE]);
        } catch (e) {}
      },
    }),

    // GET all SAMPLES
    getSamples: builder.query({
      query: (params) => ({
        url: urls.SAMPLES,
        params: params,
      }),
      providesTags: [tags.SAMPLE],
    }),

    getSample: builder.query({
      query: (id) => ({
        url: urls.SAMPLES + '/' + id,
      }),
      providesTags: [tags.SAMPLE],
    }),

    getSampleLab: builder.query({
      query: (id) => ({
        url: urls.SAMPLES + '/laboratory/' + id,
      }),
      providesTags: [tags.SAMPLE],
    }),
    getSampleByWorkOrder: builder.query({
      query: (workOrderID) => ({
        url: urls.SAMPLES + '/work-order/' + workOrderID,
      }),
      providesTags: [tags.SAMPLE],
    }),

    updateSample: builder.mutation({
      query: (data) => ({
        url: urls.SAMPLES + '/' + data.id,
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.SAMPLE]);
        } catch (e) {}
      },
    }),

    updateSampleStatus: builder.mutation({
      query: (data) =>({
        url: urls.SAMPLES + '/update-status',
        method: 'POST',
        body: { ...data },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.SAMPLE]);
        } catch (e) {}
      },
    }),
    deleteSample: builder.mutation({
      query: (id) => ({
        url: urls.SAMPLES + '/' + id,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.SAMPLE]);
        } catch (e) {}
      },
    }),
    updateSampleCylindersPickup: builder.mutation({
      query: (data) =>({
        url: 'cylinders/sample/pickup/update',
        method: 'PATCH',
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useAddSampleMutation,
  useLazyGetSamplesQuery,
  useUpdateSampleMutation,
  useUpdateSampleStatusMutation,
  useAddSampleCollectorMutation,
  useGetSampleQuery,
  useGetSamplesQuery,
  useGetSampleLabQuery,
  useGetSampleByWorkOrderQuery,
  useUpdateSampleCylindersPickupMutation
} = sampleApi;
