import { configureStore } from '@reduxjs/toolkit';
import { load, save } from 'redux-localstorage-simple'; // https://www.npmjs.com/package/redux-localstorage-simple
import { api } from '../services/api';
import authSlice from '../slices/authSlice';
import loadingSlice from '../slices/loadingSlice';
import languageSlice from '../slices/localeSlice';
import notificationSlice from '../slices/notificationSlice';
import offlineQueueSlice from '../slices/offlineQueueSlice';
import permissionSlice from '../slices/permissionSlice';
import samplePickupSlice from '../slices/samplePickupSlice';
import userSlice from '../slices/userSlice';
export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [authSlice.name]: authSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [permissionSlice.name]: permissionSlice.reducer,
    [languageSlice.name]: languageSlice.reducer,
    [loadingSlice.name]: loadingSlice.reducer,
    [samplePickupSlice.name]: samplePickupSlice.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [offlineQueueSlice.name]: offlineQueueSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware().concat(api.middleware), save()],
  preloadedState: load(),
});
