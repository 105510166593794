import React, { useEffect, useState } from 'react';
import { CustomGridToolbar, FlexBetween, Header, Icon, LoadingIcon } from '../../components';
import { useGetElement, useLocale } from '../../hooks';
import tags from '../../services/tags';
import Box from '@mui/material/Box';
import { TextField, Card, CardContent, Grid } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DataGridPro, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
import BuildIcon from '@mui/icons-material/Build';
import { useGetConcretingSequencesQuery, useUpdateConcretingSequenceMutation } from '../../services/concretingSequenceApi';
import useFormatDateTime from '../../hooks/useFormatDateTime';
import { useHandleError } from '../../hooks/useHandleError';
import useHandleSuccess from '../../hooks/useHandleSuccess';
const DataView = () => {
    const { data, isLoading, refetch } = useGetConcretingSequencesQuery('all', { refetchOnMountOrArgChange: true, refetchOnFocus: true });
    const [updateConcreteSeq] = useUpdateConcretingSequenceMutation();
    const { handleErrorResponse } = useHandleError();
    const { handleSuccessResponse } = useHandleSuccess();
    const text = useLocale();
    const { formatDate } = useFormatDateTime();
    const navItem = useGetElement(text.DataView.NAME);
    const navigate = useNavigate();
    const location = useLocation();
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    // MODAL
    const [loading, setLoading] = useState(true);
    const dataGridBoxStyle = {
        width: '100%',
        '& .MuiDataGrid-root': { border: 'none' },
        '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
    };
    let varHeight;
    varHeight = '70vh';

    const HeaderWithIconRoot = styled('div')(({ theme }) => ({
        display: 'flex !important',
        justifyItems: 'center  !important',
        alignItems: 'center',
        width: '100% !important'
    }));

    useEffect(() => {
        if (data?.payload) {
            setRows(data?.payload)
        }
    }, [data?.payload]);


    const renderEditTextField = (params) => {
        const { id, value, field } = params;

        const handleChange = (event) => {
            const newValue = event.target.value;
            params.api.setEditCellValue({ id, field, value: newValue }, event);
        };

        return (
            <TextField
                value={value || ''}
                onChange={handleChange}
                autoFocus
            />
        );
    };


    function HeaderWithIcon(props) {
        const { icon, ...params } = props;

        return (<>
            <Grid container >
                <HeaderWithIconRoot>
                    <div style={{
                        width: '1000px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}> {params.headerName ?? params.groupId} </div>
                </HeaderWithIconRoot>
            </Grid>
        </>
        );
    }


    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const processRowUpdate = async (newRow) => {
        const oldRow = rows.find((row) => row.id === newRow.id);
        const updatedFields = {};
        updatedFields.id = newRow.id;
        Object.keys(newRow).forEach((key) => {
            if (newRow[key] !== oldRow[key]) {
                updatedFields[key] = newRow[key];
            }
        });
        const data = {
            id: newRow.id,
            changes: { ...updatedFields }
        }
        try {
            const req = await updateConcreteSeq(data);
            if (req?.error) {
                handleErrorResponse(req?.error);
                return rows.filter((row) => row.id === newRow.id)[0];
            }
            handleSuccessResponse(text.ConcretingSequence.SUCCESSFUL_UPDATE);
            setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
            return newRow;
        } catch (error) {
            handleErrorResponse(error)
            setLoading(false);
            return rows.filter((row) => row.id === newRow.id)[0];
        }
    };

    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }

    const columns = [
        {
            field: 'projectName',
            headerName: 'Project Name',
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => {
                return params.row.workOrder?.project?.projectName;

            }
        },
        {
            field: 'projectCode',
            headerName: 'Project Code',
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => {
                return params.row.workOrder?.project?.projectCode;

            }
        },
        {
            field: 'clientInformationOrganization',
            headerName: 'Client Organization',
            flex: 1,
            minWidth: 120,
            valueGetter: (params) => {

                return params.row?.workOrder?.project?.client?.organizationName;

            }
        },
        {
            field: 'workOrderNumber',
            headerName: 'Work Order ',
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => {
                return params.row.workOrder.number;
            }
        },
        {
            field: 'siteVisitDate',
            headerName: text.WorkOrdersDataGrid.SITE_VISIT_DATE,
            flex: 1,
            minWidth: 100,
            valueGetter: (params) => {
                return formatDate(params.row.workOrder.siteVisitDate);
            }
        },
        {
            field: 'airContent',
            headerName: 'Air Content (%) Specified',
            flex: 1,
            minWidth: 200,
            editable: true,
            renderEditCell: renderEditTextField,


        },
        {
            field: 'adjustedAirContent',
            headerName: 'Air Content (%) Measured',
            flex: 1,
            minWidth: 120,
            editable: true,
            renderEditCell: renderEditTextField,

        },
        {
            field: 'slump',
            headerName: 'Slump/Plasticizer (mm) Specified',
            flex: 1,
            minWidth: 120,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'adjustedSlump',
            headerName: 'Slump/Plasticizer (mm) Measured',
            flex: 1,
            minWidth: 120,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            field: 'concreteTemperature',
            headerName: 'Concrete Temperature (°C)',
            flex: 1,
            minWidth: 120,
            editable: true,
            renderEditCell: renderEditTextField,
        },
        {
            headerName: '',
            flex: 1,
            minWidth: 100,
            field: 'actions',
            type: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Icon iconName={"Save"} />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<Icon iconName={"Cancel"} />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Icon iconName={"Edit"} />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },

        },
        {
            field: 'concreteMixDesign',
            headerName: 'Concrete Mix Design',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return <>
                    <p
                        style={{
                            cursor: 'pointer',
                            textDecorationLine: 'underline',
                            color: 'blue'
                        }}
                        onClick={() => {
                            navigate(`${location.pathname}/concrete-mix-design/edit/${params.row?.workOrderID}`, {
                                state: { background: location },
                            })
                        }}>View/Edit</p>
                </>
            }
        },
        {
            field: 'samples',
            headerName: 'Samples',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return <>
                    <p
                        style={{
                            cursor: 'pointer',
                            textDecorationLine: 'underline',
                            color: 'blue'
                        }}
                        onClick={() => {
                            navigate(`${location.pathname}/sample/edit/${params.row?.id}`, {
                                state: { background: location },
                            })
                        }}>View/Edit</p>
                </>
            }
        }
    ];

    const columnGroupingModel = [
        {
            groupId: 'project_details',
            headerName: 'Project Details',
            description: '',
            headerClassName: 'my-super-theme--naming-group',
            renderHeaderGroup: (params) => (
                <HeaderWithIcon {...params} icon={<BuildIcon fontSize="small" />} />

            ),
            children: [
                { field: 'projectCode' },
                { field: 'clientInformationOrganization' },
                { field: 'projectName' },
                { field: 'projectAddress' },
                { field: 'projectRegion' },
                { field: 'laboratory' },
            ],
        },
        {
            groupId: 'workOrder',
            headerName: 'Work Order Details',
            description: '',
            headerClassName: 'my-super-theme--naming-group',
            renderHeaderGroup: (params) => (
                <HeaderWithIcon {...params} icon={<BuildIcon fontSize="small" />} />

            ),
            children: [
                { field: 'workOrderNumber' },
                { field: 'siteVisitDate' },
            ],
        },

        {
            groupId: 'fresh_concrete',
            headerName: 'Concrete Sequence Details',
            description: '',
            headerClassName: 'my-super-theme--naming-group',
            renderHeaderGroup: (params) => (
                <HeaderWithIcon {...params} icon={<BuildIcon fontSize="small" />} />
            ),
            children: [
                { field: 'airContent' },
                { field: 'adjustedAirContent' },
                { field: 'slump' },
                { field: 'adjustedSlump' },
                { field: 'concreteTemperature' },
                { field: 'actions' },
            ],
        },

        {
            groupId: 'action',
            headerName: 'Details',
            description: '',
            headerClassName: 'my-super-theme--naming-group',
            children: [

                { field: 'concreteMixDesign' },
                { field: 'samples' },

            ],
            renderHeaderGroup: (params) => (
                <HeaderWithIcon {...params} icon={<BuildIcon fontSize="small" />} />
            ),

        },
    ];



    return (
        <React.Fragment>
            <FlexBetween>
                <Header title={navItem.name} icon={navItem.icon} bcrumb={text.DataView.TABLENAME} />
            </FlexBetween>
            <Card height={varHeight} sx={{ ...dataGridBoxStyle }}>
                <CardContent>
                    <Box
                        sx={{
                            height: 400,
                            width: '100%',
                            height: 800,
                            '& .my-super-theme--naming-group': {
                                border: '2px solid black',
                                justifyContent: 'center !important',
                                display: 'flex  !important',
                                width: '100%',
                            },
                        }}

                    >
                        {loading ? (
                            <LoadingIcon />
                        ) : (
                            <DataGridPro
                                rows={rows}
                                editMode='row'
                                slots={{ toolbar: () => CustomGridToolbar({ tags: tags }) }}
                                columns={columns}
                                columnHeader
                                pagination
                                processRowUpdate={processRowUpdate}
                                rowModesModel={rowModesModel}
                                experimentalFeatures={{ columnGrouping: true }}
                                columnGroupingModel={columnGroupingModel}
                                slotProps={{
                                    toolbar: { setRows, setRowModesModel },
                                }}
                                rowHeight={70}
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                       outline: "none !important",
                                    },
                                 }}
                            />
                        )}
                    </Box>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}

export default DataView;
