export const tags = {
  SESSION_USER: 'session-user',
  CLIENT: 'client',
  PROJECT: 'project',
  WORKORDER: 'work-order',
  CONCRETE: 'concrete',
  CONCRETING_SEQUENCE: 'concreting-sequence',
  CONCRETE_FIELD_INFORMATION: 'concrete-field-information',
  SAMPLE: 'sample',
  SAMPLE_PICKUP: 'sample-pickups',
  CONCRETE_MIX: 'concrete-mix',
  CYLINDER: 'cylinder',
  USER: 'user',
};

export default tags;
