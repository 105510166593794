import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

const CustomDatePicker = ({ name, onChange, initialValue, dis, disablePast, ...props }) => {
  // DateTime Object
  const [selectedDate, setSelectedDate] = useState(null);
  const getCustomDayOfWeekFormat = (date, options) => {
    const dayOfWeek = date.getDay();
    const dayOfWeekLabels = ["S", "M", "T", "W", "T", "F", "S"]; // Custom day initials
    return dayOfWeekLabels[dayOfWeek];
  };

  useEffect(() => {
    if (initialValue) {
      // Convert DateTime from string to object for selectedDate
      const dateTime = DateTime.fromISO(initialValue, { zone: "utc" });
      setSelectedDate(dateTime);
    }
  }, [initialValue]);

  useEffect(() => {
    if (selectedDate) {
      // Convert DateTime object into string for form input
      const { year, month, day, hour, minute, second, millisecond } = selectedDate;
      onChange(name, DateTime.utc(year, month, day, hour, minute, second, millisecond).toISO());
    } else {
      onChange(name, "");
    }
  }, [selectedDate]);

  return (
    <DatePicker
      value={selectedDate}
      disabled={dis}
      dayOfWeekFormat={getCustomDayOfWeekFormat}
      disablePast={disablePast ? disablePast : false}
      onChange={(newValue) => setSelectedDate(newValue)}
      {...props}
    />
  );
};

export default CustomDatePicker;
