import React from "react";
import Icon from "../Icon";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CustomButton = ({ text, route, sx, type }) => {
  const navigate = useNavigate();
  let color = "#000000";
  let bg = "white";

  if (type === "Add") {
    color = "#000000";
  } else if (type === "NoteAlt") {
    color = "#000000";
  } else if (type === "AssignmentTurnedIn") {
    color = "#4bb86a";
  } else if (type === "Delete") {
    color = "#ff0000";
  }

  return (
    <Button
      startIcon={<Icon iconName={type} sx={{ color: color }} />}
      size="large"
      onClick={() => navigate(route)}
      sx={{ ...sx }}
      variant={"contained"}
      color={bg}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
