import { useFormik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConcreteMixDesignEditDialogue } from '../components/ConcreteMixDesignEditDialogue';
import { clearLoading, setLoading } from '../../../slices/loadingSlice';
import { MobileResponsiveDialog } from '../../../components/MobileResponsiveDialog';

const FormViewConcreteMixDesignEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {

        navigate(-1);
      } catch (e) {
        console.log(e);
      }
      dispatch(clearLoading());
    },
  });

 
  return (
    <React.Fragment>
      <MobileResponsiveDialog fullWidth open={true}>
        <ConcreteMixDesignEditDialogue
          formik={formik}
          handleSubmit={formik.handleSubmit}
          handleClose={() => navigate(-1)}
        />
      </MobileResponsiveDialog>
    </React.Fragment>
  );
};

export default FormViewConcreteMixDesignEdit;
