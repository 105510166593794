import { object, string, number, date, array } from 'yup';
import { useLocale } from '../hooks';

export const useConcreteFieldInformationSchema = () => {
  const text = useLocale();
  const concreteFieldInformationCreateSchema = object({
    workOrderID: string().required(),
    fieldTechnicianID: string(),
    dateOfVisit: date().required(text.REQUIRED),
    equipmentNumber: string(),
    transportationTime: number().min(0),
    mileage: number().min(0),
    checklist: array(string()),
    unexpectedHazards: string(),
    weather: array(string()),
    subContractorOrganisation: string(),
    subContractorContactName: string(),
    subContractorContactTelephone: string(),
    subContractorContactEmail: string(),
  });

  const concreteSampleInformationCreateSchema = object({
    sampleLocationDescription: string(),
    additionalInformation: string().required(text.REQUIRED),
  });

  const concreteFieldInformationEditSchema = object({
    dateOfVisit: date(),
    equipmentNumber: string(),
    transportationTime: number().min(0),
    mileage: number().min(0),
    checklist: array(string()),
    unexpectedHazards: string(),
    weather: array(string()),
    subContractorOrganisation: string(),
    subContractorContactName: string(),
    subContractorContactTelephone: string(),
    subContractorContactEmail: string(),
  });

  const concreteSampleInformationEditSchema = object({
    sampleLocationDescription: string(),
    additionalInformation: string(),
  });

  return {
    concreteFieldInformationCreateSchema,
    concreteSampleInformationCreateSchema,
    concreteFieldInformationEditSchema,
    concreteSampleInformationEditSchema,
  };
};
