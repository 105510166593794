import { Button } from "@mui/material";
import React from "react";
import Icon from "../Icon";

export const ButtonSubmit = ({ btIcon, btText }) => {
  return (
    <Button
      size="large"
      variant="contained"
      type="submit"
      color="primary"
      startIcon={<Icon iconName={btIcon} />}
      // sx={{ mr: 3 }}
    >
      {btText}
    </Button>
  );
};
