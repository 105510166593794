import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { LoadingIcon } from '../../../components';
import FlexBetween from '../../../components/FlexBetween';
import TextFieldView from '../../../components/customMUI/TextFieldView';
import { useLocale } from '../../../hooks';

const ViewCardSample = ({ data }) => {
  const text = useLocale();

  // Styling
  const styles = {
    titleStyle: { fontWeight: 'bold', fontSize: 22, mb: 2 },
    dataGridBoxStyle: {
      width: '100%',
      '& .MuiDataGrid-root': { border: 'none' },
      '& .MuiDataGrid-columnHeaders': { backgroundColor: '#f3f3f3' },
    },
  };

  return !data ? (
    <LoadingIcon />
  ) : (
    <Grid container spacing={1} alignItems="stretch">
      <Grid container direction="column">
        <Grid item>
          <FlexBetween>
            <Typography sx={styles.titleStyle}>{text.SamplePickups.TITLE_VIEW}</Typography>
            {/* <CustomButton
              route={routes.WORKORDER_EDIT.split(":")[0] + data.id}
              text={text.WorkOrders.EDIT_BUTTON}
              sx={{ mr: 2 }}
              type="NoteAlt"
            /> */}
          </FlexBetween>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Grid container direction={'column'}>
          <Grid item>
            <Typography variant="h3" sx={{ color: '#f00' }}>
              {text.Projects.title}
            </Typography>

            <TextFieldView
              label={text.Projects.PROJECTMANAGER}
              value={
                data?.workOrder?.project?.projectManager?.firstName +
                ' ' +
                data?.workOrder?.project?.projectManager?.lastName
              }
            />

            <TextFieldView
              label={text.Projects.DISPATCHER}
              value={
                data?.workOrder?.project?.dispatcher.firstName +
                ' ' +
                data?.workOrder?.project?.dispatcher.lastName
              }
            />

            <TextFieldView
              label={text.Projects.LABEL_PROJECT_NAME}
              value={data?.workOrder?.project?.projectName}
            />

            <TextFieldView
              label={text.Projects.LABEL_PROJECT_CODE}
              value={data?.workOrder?.project?.projectCode}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <TextFieldView label={text.Projects.ADDRESS} value={data?.workOrder?.project.address} />
        <TextFieldView
          label={text.Projects.ONSITECONTACTNAME}
          value={data?.workOrder?.project?.onSiteContactName}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <TextFieldView label={text.Projects.NUMBERSAMPLES} value={data?.samples?.length} />

        <TextFieldView
          label={text.ConcreteFieldInformation.DESCRIPTION_OF_ONSITE_LOCATION}
          value={data?.workOrder?.concreteFieldInformation?.sampleLocationDescription}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container direction={'column'}>
          <Grid item>
            <Divider variant="middle" sx={{ mt: 2, mb: 2 }} />
            <h3>Please note:</h3>
            Contact project manager if minimum and maximum sample temperatures are not within 15-25
            degrees celcius.
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewCardSample;
