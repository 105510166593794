import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { selectLoading } from '../slices/loadingSlice';
import { useSelector } from 'react-redux';

const LoadingBackdrop = () => {
  const Z_INDEX_OFFSET = 1500; // Needs to be higher than the zindex of MUI's dialog which has a zindex of 1300
  const loading = useSelector(selectLoading);
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + Z_INDEX_OFFSET }}
        open={loading.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default LoadingBackdrop;
