import React from 'react';
import routes from '../../constants/routes';
// import FormProject from './forms/FormProject';
import { useParams } from 'react-router-dom';
import { useGetElement, useLocale } from '../../hooks';
import { FlexBetween, Header, LoadingIcon } from '../../components';
import useRequest from '../../hooks/useRequest';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { useFormikEdit } from '../../hooks/useFormikEdit';
import { useGetSamplePickupQuery, useUpdateSamplePickupMutation } from '../../services/samplePickUpApi';
import { useSamplePickupSchema } from '../../schemas/samplePickup.schema';
import FormFreshOfConcrete from './forms_backup/FormViewCompressiveStrength';
import FormViewCompressiveStrength from './forms_backup/FormViewCompressiveStrength';
import FormViewPickUp from './forms_backup/FormViewPickUp';
import FormViewLocation from './forms_backup/FormViewLocation';
import FormViewGhdPersonnel from './forms_backup/FormViewGhdPersonnel';
import { ButtonBack } from '../../components/customMUI/ButtonBack';
import { ButtonSubmit } from '../../components/customMUI/ButtonSubmit';
import FormViewProductDetails from './forms_backup/FormViewProductDetails';
import FormViewConcreteMixDesign from './forms_backup/FormViewCocreteMIxDesign';

function DataViewEdit() {

    const text = useLocale();
    const navItem = useGetElement(text.DataView.NAME);
    const { id } = useParams();
    const samplePickupQuery = useRequest(useGetSamplePickupQuery, id);
    const { updateDeliveryTESTSchema } = useSamplePickupSchema();
    const isLoading = false;

    const initialValues = {
        pickupDateTime: '',
        dropoffDateTime: '',
        additionalInformation: '',
    };

    const setInitialValues = {
        pickupDateTime: samplePickupQuery?.data?.payload?.pickupDateTime,
        dropoffDateTime: samplePickupQuery?.data?.payload?.dropoffDateTime,
        additionalInformation: samplePickupQuery?.data?.payload?.additionalInformation,
    };

    const formik = useFormikEdit(
        useGetSamplePickupQuery,
        initialValues,
        updateDeliveryTESTSchema,
        -1,
        useUpdateSamplePickupMutation,
        setInitialValues
    );

    return isLoading ? (
        <LoadingIcon />
    ) : (
        <React.Fragment>
            <FlexBetween>
                <Header title={navItem.name} icon={navItem.icon} bcrumb={text.DataView.EDIT_DATA_VIEW} />
            </FlexBetween>
            <form onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent>
                        <Grid container columnSpacing={2} rowSpacing={2}>
                            <Grid item xl={6} lg={6} sm={12} md={12}>
                                <FormViewProductDetails formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
                                <FormFreshOfConcrete formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
                                <FormViewCompressiveStrength formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
                            </Grid>
                            <Grid item xl={6} lg={6} sm={12} md={12}>
                                <FormViewConcreteMixDesign formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
                                <FormViewPickUp formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
                                <FormViewLocation formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
                                <FormViewGhdPersonnel formik={formik} btIcon={'Save'} btText={text.Buttons.SAVE} />
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                            <ButtonBack />
                            <ButtonSubmit btIcon={"Save"} btText={text.Buttons.SAVE} />
                        </Grid>
                    </CardContent>
                </Card>
            </form>
        </React.Fragment>
    );
}

export default DataViewEdit;