import { DateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import useIsMobileOrTablet from '../../hooks/useIsMobileOrTablet';

const CustomDateTimePicker = ({ name, onChange, initialValue, ...props }) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  // DateTime Object
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (initialValue) {
      // Convert DateTime from string to object for selectedDate
      const dateTime = DateTime.fromISO(initialValue, { zone: 'utc' });
      setSelectedDate(dateTime);
    }
  }, [initialValue]);

  useEffect(() => {
    if (selectedDate) {
      // Convert DateTime object into string for form input
      const { year, month, day, hour, minute, second, millisecond } = selectedDate;
      onChange(name, DateTime.utc(year, month, day, hour, minute, second, millisecond).toISO());
    } else {
      onChange(name, '');
    }
  }, [selectedDate]);

  return (
    <React.Fragment>
      {isMobileOrTablet ? (
        <MobileDateTimePicker
          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          {...props}
        />
      ) : (
        <DateTimePicker
          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default CustomDateTimePicker;
