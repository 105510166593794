import { Dialog, SwipeableDrawer } from '@mui/material';
import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useIsMobileOrTablet from '../../../hooks/useIsMobileOrTablet';
import { ConcreteMixDesignContents } from './ConcreteMixDesignContents';

export const ConcreteMixDesignDialog = ({ formikConcreteMixDesign, handleSubmit,isWorkOrderDetails }) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = () => {
    if(isWorkOrderDetails){
      isWorkOrderDetails();
      return
    }
    navigate(-1);
  };

  const handleOpen = () => {
    navigate(location.pathname);
  };

  return (
    <React.Fragment>
      {isMobileOrTablet ? (
        <SwipeableDrawer anchor={'bottom'} open={true} onClose={handleClose} onOpen={handleOpen}>
          <ConcreteMixDesignContents
            formikConcreteMixDesign={formikConcreteMixDesign}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        </SwipeableDrawer>
      ) : (
        <Dialog fullWidth maxWidth="lg" open={true}>
          <ConcreteMixDesignContents
            formikConcreteMixDesign={formikConcreteMixDesign}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        </Dialog>
      )}
    </React.Fragment>
  );
};
