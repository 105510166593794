import React, { useEffect, useMemo, useState } from 'react';

import { Box, FormHelperText, Grid, TextField, Typography } from '@mui/material';

import { SampleSize, SampleType, SizeOfMould, TypeOfMould } from '../../constants/options';
import useLocale from '../../hooks/useLocale';
import CustomAutocompleteMultiple from '../customMUI/CustomAutocompleteMultiple';
import CustomTimePicker from '../customMUI/CustomTimePicker';
import useRequest from '../../hooks/useRequest';
import CustomAutocomplete from '../customMUI/CustomAutocomplete';
const FormSample = ({ formik }) => {
  const text = useLocale();
  const [curingConditions, setCuringConditions] = useState([]);

  useEffect(() => {
    const conditions = Object.keys(text.CuringConditions).map((key) => {
      return { id: key, label: text.CuringConditions[key] };
    });
    setCuringConditions(conditions);
  }, []);

  const typeOfMould = useMemo(() => {
    let sizes = [];
    for (const [key, value] of Object.entries(TypeOfMould)) {
      sizes.push({ id: key, label: value });
    }
    return sizes;
  }, [TypeOfMould]);

  const sizeOfMould = useMemo(() => {
    let sizes = [];
    for (const [key, value] of Object.entries(SizeOfMould)) {
      sizes.push({ id: key, label: value });
    }
    return sizes;
  }, [TypeOfMould]);

  const sampleSizes = useMemo(() => {
    let sizes = [];
    for (const [key, value] of Object.entries(SampleSize)) {
      sizes.push({ id: key, label: value });
    }
    return sizes;
  }, [SampleSize]);
  


  return (
    <React.Fragment>
      <Box>
        <Grid container direction="column" rowGap={2}>
        <Grid item>
            <CustomAutocompleteMultiple
              label={text.Samples.LABEL_CURING_CONDITIONS}
              initialValue={formik.initialValues.curingConditions}
              itemsArray={curingConditions}
              itemsMapper={(item) => {
                return { id: item.id, label: item.label };
              }}
              name="curingConditions"
              onChange={(name, keys) => {
                formik.setFieldValue(name, keys);
              }}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={text.Samples.LABEL_SAMPLE_SIZE}
              initialValue={formik.initialValues.sizeOfSample}
              itemsArray={sampleSizes}
              itemsMapper={(item) => {
                return { id: item.id, label: item.label };
              }}
              sx={{ mt: .3 }}
              name="sizeOfSample"
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={text.Samples.LABEL_TYPE_OF_MOULD}
              initialValue={formik.initialValues.typeOfMould}
              itemsArray={typeOfMould}
              itemsMapper={(item) => {
                return { id: item.id, label: item.label };
              }}
              name="typeOfMould"
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={text.Samples.LABEL_SIZE_OF_MOULD}
              initialValue={formik.initialValues.sizeOfMould}
              itemsArray={sizeOfMould}
              itemsMapper={(item) => {
                return { id: item.id, label: item.label };
              }}
              name="sizeOfMould"
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
            />
          </Grid>
        
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default FormSample;
