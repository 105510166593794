import { createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import actions from '../constants/actions';
import { useAddConcreteFieldInformationMutation } from '../services/concreteFieldInformationApi';
import { clearLoading, setLoading } from '../slices/loadingSlice';
import {
  removeFromQueue,
  replaceConcreteMixDesignIDs,
  replaceConcretingSequenceIDs,
} from '../slices/offlineQueueSlice';
import { useAddConcretingSequenceMutation } from '../services/concretingSequenceApi';

export const useSubmitConcretingSequence = () => {
  const [submit] = useAddConcretingSequenceMutation();

  const submitConcretingSequence = createAsyncThunk(
    actions.SUBMIT_CONCRETING_SEQUENCE,
    async (params, thunkAPI) => {
      try {
        const { id, ...data } = params;

        const result = await submit(data).unwrap();

        // Replace placeholder IDs with the real one in Samples
        thunkAPI.dispatch(replaceConcretingSequenceIDs({ oldID: id, newID: result.payload.id }));
        thunkAPI.dispatch(removeFromQueue(id));
      } catch (e) {
        console.error(e);
        thunkAPI.dispatch(clearLoading());
      }
    }
  );
  return { submitConcretingSequence };
};
