import React from 'react';
import FlexBetween from '../../components/FlexBetween';
import {
  Typography,
  Button,
  Divider,
  Box,
  Grid,
  Hidden,
  CardActions,
  Card,
  CardContent,
} from '@mui/material';
import CustomCard from '../../components/customMUI/CustomCard';
import { Link, useNavigate } from 'react-router-dom';
import { useLocale } from '../../hooks';
import Icon from '../../components/Icon';
import routes from '../../constants/routes';

// Permissions
import PermissionGate from '../../components/PermissionGate';
import { useSelector } from 'react-redux';
import { selectPermissions } from '../../slices/permissionSlice';

const SamplesCards = ({ tool, addPermissions }) => {
  const navigate = useNavigate();
  const text = useLocale();
  const userPermissions = useSelector(selectPermissions);

  let cardTitle, cardRoute, cardRouteAdd;
  const viewA = text.Home.VIEW_ALL;
  const viewP = text.Home.VIEW_PENDING;
  const viewC = text.Home.VIEW_COMPLETED;

  if (tool === 'Projects') {
    cardTitle = text.Home.RECENTP;
    cardRoute = routes.PROJECTS;
    cardRouteAdd = routes.PROJECT_ADD;
  } else if (tool === 'WorkOrders') {
    cardTitle = text.Home.RECENTW;
    cardRoute = routes.WORKORDERS;
    cardRouteAdd = routes.WORKORDER_ADD;
  } else if (tool === 'Samples') {
    cardTitle = text.Home.RECENTS;
    cardRoute = routes.SAMPLES;
    cardRouteAdd = routes.SAMPLES_ADD;
  }

  return (
    <React.Fragment>
      <FlexBetween>
        <Typography variant="h5" sx={{ fontWeight: '700', mb: 1, flex: 1 }}>
          {cardTitle}
        </Typography>
        <Button onClick={() => navigate(cardRoute)} sx={{ textTransform: 'none', color: '#666' }}>
          <Icon iconName={'Visibility'} sx={{ mr: 0.3, fontSize: '1.2rem' }} />
          <Hidden xsDown smDown>
            {viewA}
          </Hidden>
        </Button>
        {/* <Button onClick={() => navigate(cardRoute)} sx={{ textTransform: "none", color: "#666" }}>
          <Icon iconName={"AccessTime"} sx={{ mr: 0.3, fontSize: "1.2rem" }} />
          <Hidden xsDown smDown>
            {viewP}
          </Hidden>
        </Button>
        <Button onClick={() => navigate(cardRoute)} sx={{ textTransform: "none", color: "#666" }}>
          <Icon iconName={"Check"} sx={{ mr: 0.3, fontSize: "1.2rem" }} />
          <Hidden xsDown smDown>
            {viewC}
          </Hidden>
        </Button> */}
      </FlexBetween>
      <Divider sx={{ mb: 3 }} />
      <Grid container>
        <Grid item xs={12} md={3} lg={3}>
          <Box component={Link} to={routes.SAMPLE_PICKUPS_ASSIGNED} sx={{ textDecoration: 'none' }}>
            <Card>
              <CardContent sx={{ height: 125, display: 'flex', justifyContent: 'center' }}>
                <Icon iconName={'LocalShipping'} sx={{ fontSize: 90 }} />
              </CardContent>
              <CardActions sx={{ backgroundColor: '#000', color: '#fff' }}>
                {text.SamplePickups.TITLE}
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <CustomCard
          cContent={
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              onClick={() => navigate(routes.SAMPLES + '/pickup/')}
            >
              <Icon iconName="Schedule" sx={{ fontSize: '80px' }} />
            </Box>
          }
          cActions={
            <Typography>
              Samples Pending <b>Pickup</b>
            </Typography>
          }
        />
      </Grid> */}
    </React.Fragment>
  );
};

export default SamplesCards;
