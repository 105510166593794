import { Button } from "@mui/material";
import React from "react";
import { useLocale } from "../../hooks";
import Icon from "../Icon";
import { useNavigate } from "react-router-dom";

export const ButtonBack = () => {
  const text = useLocale();
  const navigate = useNavigate();

  return (
    <Button
      size="large"
      onClick={() => navigate(-1)}
      startIcon={<Icon iconName="ArrowBack" />}
      sx={{ mr: 3 }}
    >
      {text.Buttons.BACK}
    </Button>
  );
};
