import { Box, Button, FormHelperText, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import useLocale from '../../../hooks/useLocale';
import ConfirmationDialog from '../../ConfirmationDialog';
import Icon from '../../Icon';
import FormSample from '../../forms/FormSample';
import CustomAutocomplete from '../../customMUI/CustomAutocomplete';
import { SampleSize } from '../../../constants/options';
import { useEffect } from 'react';
import { useMemo } from 'react';
import CustomTimePicker from '../../customMUI/CustomTimePicker';

const Samples = ({
  formikSamplesLaboratoryCured,
  formikSamplesFieldCured,
  setCommonSampleFields,
  commonSampleFields,
  handleSetCommonSampleField,
  handleRemoveLaboratorySample,
  handleRemoveFieldSample,
  handleAddLaboratorySample,
  handleAddFieldSample,
  isConcreteSequeceEdit,
  openLaboratorySample,
  openFieldSample,
}) => {
  const text = useLocale();
  const [openLaboratoryRemoveDialog, setOpenLaboratoryRemoveDialog] = useState(false);
  const [openFieldRemoveDialog, setOpenFieldRemoveDialog] = useState(false);

  const handleRemoveLaboratorySampleWrapper = () => {
    handleRemoveLaboratorySample();
    setOpenLaboratoryRemoveDialog(false);
  };

  const handleRemoveFieldSampleWrapper = () => {
    handleRemoveFieldSample();
    setOpenFieldRemoveDialog(false);
  };

  const sampleButtonVariant = 'contained';
  const lightBoxStyle = {
    mb: 4,
    border: '1px #d9d9d9 solid',
    p: 2,
    borderRadius: '0px 0px 10px 10px',
  };

  // TODO
  // Persists sizeOfSample
  // This is hacky but I need to do this

  useEffect(() => {
    setCommonSampleFields((previous) => {
      return { ...previous, sizeOfSample: commonSampleFields.sizeOfSample };
    });
  }, []);

  const commonSampleFiledMemo = useMemo(() => {
    return commonSampleFields
  }, [])



  return (
    <Box display={'flex'} flexDirection={'column'}>
      <TextField
        label={text.Samples.LABEL_SAMPLE_NUMBER}
        value={commonSampleFields.number}
        name="number"
        onChange={handleSetCommonSampleField}
        sx={{ mt: 2, display: 'flex', flex: 1 }}
        type={'text'}
      />
      <FormHelperText
        error={
          (formikSamplesLaboratoryCured.errors.number &&
            formikSamplesLaboratoryCured.touched.number) ||
          (formikSamplesFieldCured.errors.number && formikSamplesFieldCured.touched.number)
        }
      >
        {formikSamplesLaboratoryCured.errors.number && formikSamplesLaboratoryCured.touched.number
          ? formikSamplesLaboratoryCured.errors.number
          : formikSamplesFieldCured.errors.number && formikSamplesFieldCured.touched.number
            ? formikSamplesFieldCured.errors.number
            : ''}
      </FormHelperText>
      <TextField
        label={text.Samples.LABEL_LOCATION_IN_STRUCTURE}
        value={commonSampleFields.locationInStructure}
        name={'locationInStructure'}
        onChange={handleSetCommonSampleField}
        sx={{ mt: 1, display: 'flex', flex: 1 }}
        type={'text'}
      />

      <CustomTimePicker
        sx={{ width: '100%', my: 2 }}
        label={text.Samples.LABEL_TIME_OF_SAMPLING}
        initialValue={commonSampleFiledMemo.timeOfSampling}
        name={'timeOfSampling'}
        onChange={(name, value) => {
          setCommonSampleFields((previous) => {
            return { ...previous, 'timeOfSampling': value };
          })

        }}
      />
      <CustomTimePicker
        sx={{ width: '100%' }}
        label={text.Samples.LABEL_TIME_OF_MOULDING}
        initialValue={commonSampleFiledMemo.timeOfMoulding}
        name={'timeOfMoulding'}
        onChange={(name, value) => {

          setCommonSampleFields((previous) => {
            return { ...previous, 'timeOfMoulding': value };
          })
        }}

      />


      <br />
      {openLaboratorySample ? (
        <React.Fragment>
          {isConcreteSequeceEdit
            ? <Typography sx={{ my: 2, alignSelf: 'center' }}> {'Laboratory Cured Sample'}</Typography> : <Button
              startIcon={<Icon iconName="Cancel" />}
              variant={sampleButtonVariant}
              onClick={() => setOpenLaboratoryRemoveDialog(true)}
              sx={{ bgcolor: 'error.secondary' }}
            >
              {text.Samples.LABEL_REMOVE_LABORATORY_CURED_SAMPLE}
            </Button>}
          <ConfirmationDialog
            title={text.DELETE_QUESTION}
            contentText={text.DELETE_DETAILS}
            actionText={text.Buttons.CONFIRM}
            handleAction={handleRemoveLaboratorySampleWrapper}
            handleClose={() => setOpenLaboratoryRemoveDialog(false)}
            handleOpen={() => setOpenLaboratoryRemoveDialog(true)}
            isOpen={openLaboratoryRemoveDialog}
          />
        </React.Fragment>
      ) : (
        <Button
          startIcon={<Icon iconName={'AddCircle'} />}
          color="primary"
          onClick={handleAddLaboratorySample}
          variant={sampleButtonVariant}
          sx={{ mb: 2 }}
        >
          {text.Samples.LABEL_ADD_LABORATORY_CURED_SAMPLE}
        </Button>
      )}
      {openLaboratorySample ? (
        <Box sx={lightBoxStyle}>
          <FormSample formik={formikSamplesLaboratoryCured} />
        </Box>
      ) : (
        <></>
      )}
      {openFieldSample ? (
        <React.Fragment>
          {isConcreteSequeceEdit
            ? <Typography sx={{ my: 2, alignSelf: 'center' }}> {'Field Cured Sample'}</Typography> : <Button
              startIcon={<Icon iconName="Cancel" />}
              variant={sampleButtonVariant}
              sx={{ bgcolor: 'error.secondary' }}
              onClick={() => setOpenFieldRemoveDialog(true)}
            >
              {text.Samples.LABEL_REMOVE_FIELD_CURED_SAMPLE}
            </Button>}
          <ConfirmationDialog
            title={text.DELETE_QUESTION}
            contentText={text.DELETE_DETAILS}
            actionText={text.Buttons.CONFIRM}
            handleAction={handleRemoveFieldSampleWrapper}
            handleClose={() => setOpenFieldRemoveDialog(false)}
            handleOpen={() => setOpenFieldRemoveDialog(true)}
            isOpen={openFieldRemoveDialog}
          />
        </React.Fragment>
      ) : (
        <Button
          startIcon={<Icon iconName={'AddCircle'} />}
          color="primary"
          onClick={handleAddFieldSample}
          variant={sampleButtonVariant}
        >
          {text.Samples.LABEL_ADD_FIELD_CURED_SAMPLE}
        </Button>
      )}
      {openFieldSample ? (
        <Box sx={lightBoxStyle}>
          <FormSample formik={formikSamplesFieldCured} />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Samples;
