import React, { useEffect, useState } from "react";
import StatusChip from "../../../components/StatusChip";
import useFormatDateTime from "../../../hooks/useFormatDateTime";
import CustomTextFieldView from "../../../components/customMUI/CustomTextFieldView";
import { useLocale } from "../../../hooks";
import { Grid, Typography } from "@mui/material";
import { useGetProjectQuery } from "../../../services/projectsApi";
import useRequest from "../../../hooks/useRequest";

const ViewProject = ({ id }) => {
  const tool = "Projects";
  const text = useLocale();
  const { formatDate } = useFormatDateTime();
  // Get Project
  const projectQuery = useRequest(useGetProjectQuery, id);
  const data = projectQuery.data ? projectQuery.data.payload : {};
  // Styling
  const titleStyle = { fontWeight: "bold", fontSize: 24, mb: 1 };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography noWrap sx={titleStyle}>
          {text.Projects.TITLE_PROJECT_DETAILS}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Grid container direction={"column"}>
          <Grid item>
            <CustomTextFieldView name="PROJECTNAME" tool={tool} value={data.projectName} />
            <CustomTextFieldView name="PROJECTCODE" tool={tool} value={data.projectCode} />
            <CustomTextFieldView
              name="PROJECTMANAGER"
              tool={tool}
              value={
                projectQuery?.data?.payload.projectManager.firstName +
                " " +
                projectQuery?.data?.payload.projectManager.lastName
              }
            />
            <CustomTextFieldView name="ADDRESS" tool={tool} value={data.address} />
            <CustomTextFieldView
              name="PROJECTREGION"
              tool={tool}
              value={projectQuery?.data?.payload.region.name}
            />
            <CustomTextFieldView
              name="DISPATCHER"
              tool={tool}
              value={
                projectQuery?.data?.payload.dispatcher.firstName +
                " " +
                projectQuery?.data?.payload.dispatcher.lastName
              }
            />

            <CustomTextFieldView
              name="CLIENTPURCHASEORDER"
              tool={tool}
              value={data.clientPurchaseOrder}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Grid container direction={"column"}>
          <Grid item>
            {/* <CustomTextFieldView
              name="LABORATORY"
              tool={tool}
              value={
                projectQuery?.data?.payload.laboratory.name +
                "(" +
                projectQuery?.data?.payload.laboratory.region.name +
                ")"
              }
            />
            <CustomTextFieldView
              name="SUPERVISOR"
              tool={tool}
              value={data?.laboratorySupervisor?.name}
            /> */}
            <CustomTextFieldView
              name="TRANSPORTATIONTIME"
              tool={tool}
              value={projectQuery?.data?.payload?.transportationTime}
            />
            <CustomTextFieldView
              name="MILEAGE"
              tool={tool}
              value={projectQuery?.data?.payload.mileage}
            />

            <CustomTextFieldView
              name="STATUS"
              tool={tool}
              value={<StatusChip status={data.status} />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Grid container direction={"column"}>
          <Grid item>
            <CustomTextFieldView
              name="LABEL_ON_SITE_CONTACT_NAME_ORGANISATION"
              tool={tool}
              value={data.onSiteContactOrganisation}
            />
            <CustomTextFieldView
              name="ONSITECONTACTNAME"
              tool={tool}
              value={data.onSiteContactName}
            />
            <CustomTextFieldView
              name="ONSITECONTACTTELEPHONE"
              tool={tool}
              value={data.onSiteContactTelephone}
            />
            <CustomTextFieldView
              name="ONSITECONTACTEMAIL"
              tool={tool}
              value={data.onSiteContactEmail}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Divider variant="middle" sx={{ m: 2 }} /> */}
    </Grid>
  );
};

export default ViewProject;
