import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadingIcon from '../LoadingIcon';

const CustomAutocompleteMultiple = ({
  name,
  onChange,
  itemsArray,
  itemsQuery,
  itemsQueryParams,
  itemsMapper,
  label,
  makeRequest,

  initialValue,
  ...props
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);
  let itemsQueryResult = null;

  if (itemsArray === undefined) {
    itemsQueryResult = itemsQueryParams
      ? makeRequest(itemsQuery, itemsQueryParams)
      : makeRequest(itemsQuery);
  }

  useEffect(() => {
    if (itemsQueryResult?.data && itemsQueryResult.data.payload) {
      setItems(itemsQueryResult.data.payload.map(itemsMapper));
    }
  }, [itemsQueryResult]);

  useEffect(() => {
    if (itemsArray && itemsArray.length > 0) {
      setItems(itemsArray.map(itemsMapper));
    }
  }, [itemsArray]);

  useEffect(() => {
    if (initialValue && initialValue.length > 0 && items && items.length > 0) {
      let initialItems = [];

      for (const item of items) {
        for (const key of initialValue) {
          if (item.id === key) {
            initialItems.push(item);
          }
        }
      }

      setSelectedItems(initialItems);
    }
  }, [initialValue, items]);

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      const keys = selectedItems.map((item) => item.id);
      onChange(name, keys);
    }
  }, [selectedItems]);

  const handleOnChange = (event, value) => {
    setSelectedItems(value);
  };

  return (
    <React.Fragment>
      {itemsArray === null ? (
        <LoadingIcon></LoadingIcon>
      ) : (
        <Autocomplete
          multiple
          getOptionLabel={(option) => option.label ?? ''}
          isOptionEqualToValue={(option, value) => {
            return value === undefined || option.id === value.id;
          }}
          fullWidth
          onChange={handleOnChange}
          value={selectedItems}
          name={name}
          options={items}
          renderInput={(params) => <TextField {...params} label={label} />}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default CustomAutocompleteMultiple;
