import React from 'react';
import { useSelector } from 'react-redux';
import {
  CustomDataGrid,
  CustomGridToolbar,
  FlexBetween,
  Header,
  Icon,
  LoadingIcon,
} from '../../components';
import routes from '../../constants/routes';
import { useGetElement, useLocale } from '../../hooks';
import { useFormatDateTime } from '../../hooks/useFormatDateTime';
import { useGetSamplePickupsQuery } from '../../services/samplePickUpApi';
import tags from '../../services/tags';
import { selectUser } from '../../slices/userSlice';
import StatusChip from '../../components/StatusChip';
import { Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import { SamplePickupStatus } from '../../constants/options';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { dataGridBoxStyle } from '../../styles/datagrid';
import { useNavigate } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';

function SamplePickupAssigned() {
  const text = useLocale();
  const user = useSelector(selectUser);

  const navItem = useGetElement(text.Navigation.ASSIGNED_SAMPLE_DELIVERIES);
  const { formatDateTime } = useFormatDateTime();

  const navigate = useNavigate();

  // Get sample pickups assigned to user
  const assignedPickupsQuery = useRequest(useGetSamplePickupsQuery, {
    'filter[collectorID]': `equals,${user.id}`,
  });

  const columns = [
    {
      field: "projectCode",
      headerName: text.LabCylinders.PROJECTCODE,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return params.row.workOrder.project.projectCode;
      },
    },
    {
      field: "projectName",
      headerName: text.LabCylinders.PROJECTNAME,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return params.row.workOrder.project.projectName;
      },
    },
    {
      field: "workOrder",
      headerName: text.WorkOrders.WORK_ORDER,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        return params.value.number;
      },
    },
    {
      field: "laboratory",
      headerName: text.Laboratory.TITLE,
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.workOrder.laboratory.name;
      },
    },
    {
      field: "sampleNumber",
      headerName: text.Samples.LABEL_SAMPLE_NUMBER,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.samples[0]?.number;
      },
    },
    {
      field: 'type',
      headerName: text.Samples.LABEL_TYPE,
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <Typography>{text.SampleType[params.row.samples[0]?.type]}</Typography>;
      },
    },
    {
      field: "assignedTechnician",
      headerName: text.SamplePickups.FIELDTECHNICIANID,
      minWidth: 190,
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.collector?.name;
      },
    },
    {
      field: "assignedPickupDateTime",
      headerName: text.SamplePickups.ASSIGNED_PICKUP_DATE_TIME,
      minWidth: 180,
      flex: 1,
      valueGetter: (params) => {
        return formatDateTime(params?.row?.assignedPickupDateTime);
      },
    },
    {
      field: "pickupDateTime",
      headerName: text.SamplePickups.ACTUAL_PICKUP,
      minWidth: 180,
      flex: 1,
      valueFormatter: (params) => {
        if (params.value) {
          return formatDateTime(params.value);
        }
        return "";
      },
    },
    //dispatcher
    {
      field: "pickupInstructions",
      headerName: text.SamplePickups.COMMENTS_BY_DISPATCHER,
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.pickupInstructions;
      },
    },
    //technician
    {
      field: "additionalInformation",
      headerName: text.SamplePickups.COMMENTS_BY_TECHNICIAN,
      minWidth: 240,
      flex: 1,
    },
    {
      field: "dropoffDateTime",
      headerName: text.SamplePickups.DROPOFF_DATE_TIME,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => {
        if (params.value) {
          return formatDateTime(params.value);
        }
        return "";
      },
    },
    {
      field: 'status',
      headerName: text.SamplePickups.STATUS,
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return <StatusChip status={params.row.status} />;
      },
    },
    {
      field: 'edit',
      headerName: '',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        const url = routes.SAMPLE_PICKUP_EDIT.split(':')[0];
        if (params.row.status === SamplePickupStatus.PENDING_PICKUP) {
          return (
            <IconButton onClick={() => navigate(url + params.row.id)}>
              <Icon iconName="Edit" />
            </IconButton>
          );
        } else {
          return <>
            <IconButton onClick={() => navigate(url + params.row.id)}>
              <Icon iconName="Visibility" />
            </IconButton>
          </>;
        }
      },
    },
  ];

  return (
    <React.Fragment>
      <FlexBetween>
        <Header
          title={text.SamplePickups.ASSIGNED_SAMPLE_DELIVERIES}
          icon={navItem.icon}
          bcrumb={text.Samples.TITLE}
        />
      </FlexBetween>
      <Card>
        <CardContent sx={dataGridBoxStyle}>
          {!assignedPickupsQuery.isLoading && assignedPickupsQuery.data ? (
            <DataGridPro
              slots={{ toolbar: () => CustomGridToolbar({ tags: [tags.SAMPLE_PICKUP] }) }}
              rows={assignedPickupsQuery.data ? assignedPickupsQuery.data.payload : []}
              columns={columns}
              sx={{ height: '70vh', width: '158vh' }}
              pagination
            />
          ) : (
            <LoadingIcon />
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default SamplePickupAssigned;
