import React from 'react';
import routes from '../../constants/routes';
import FormProject from './forms/FormProject';
import { FlexBetween, Header } from '../../components';
import { useGetElement, useLocale } from '../../hooks';
import { useProjectSchema } from '../../schemas/project.schema';
import { useAddProjectMutation } from '../../services/projectsApi';
import { useFormikAdd } from '../../hooks/useFormikAdd';

function ProjectAdd() {
  const text = useLocale();
  const { projectCreateSchema } = useProjectSchema();
  const navItem = useGetElement(text.Navigation.PROJECTS);

  const initialValues = {
    onSiteContactOrganisation:'',
    projectCode: '',
    projectName: '',
    regionID: '',
    clientPurchaseOrder: '',
    address: '',
    transportationTime: '',
    mileage: 0,
    projectManagerID: '',
    dispatcherID: '',
    laboratorySupervisorID: '',
    clientID: '',
    laboratoryID: '',
    onSiteContactName: '',
    onSiteContactTelephone: '',
    onSiteContactEmail: '',
    clientFullName: '',
    clientPhoneNumber: '',
    clientEmail: '',
  };

  const formik = useFormikAdd(
    initialValues,
    projectCreateSchema,
    routes.PROJECTS,
    useAddProjectMutation
  );

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.Projects.ADD_PROJECT} />
      </FlexBetween>
      <FormProject formik={formik} btIcon={'AddCircle'} btText={text.Buttons.ADD} />
    </React.Fragment>
  );
}

export default ProjectAdd;