import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

// import loading
import { useDispatch } from "react-redux";
import { setLoading, clearLoading } from "../slices/loadingSlice";

// Hook to abstract adding
export function useFormikAdd(initialValues, objSchema, route, useAddMutation) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addObj] = useAddMutation();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: objSchema,
    onSubmit: (values) => addRecord(values),
    enableReinitialize: true,
  });

  async function addRecord(values) {
    dispatch(setLoading());
    try {
      // console.log(values);
      await addObj(values);
      navigate(route);
    } catch (e) {
      console.log(e);
    }
    dispatch(clearLoading());
  }
  return formik;
}
