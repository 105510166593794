import React from 'react';
import routes from '../../constants/routes';
import FormWorkOrder from './forms/FormWorkOrder';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormikEdit } from '../../hooks/useFormikEdit';
import { useWorkOrderSchema, workOrderInitialValues } from '../../schemas/workorder.schema';
import { useGetElement, useLocale } from '../../hooks';
import { FlexBetween, Header, Icon, LoadingIcon } from '../../components';
import { useGetWorkOrderQuery, useUpdateWorkOrderMutation } from '../../services/workordersApi';
import useRequest from '../../hooks/useRequest';
import { Box, Button } from '@mui/material';

function WorkOrderEdit() {
  const { workorderEditSchema } = useWorkOrderSchema();
  const text = useLocale();
  const navItem = useGetElement(text.Navigation.WORKORDERS);
  const { id } = useParams();
  const workorderQuery = useRequest(useGetWorkOrderQuery, id);
  const navigate = useNavigate();

  const setInitialValues = {
    projectID: workorderQuery.data.payload.project.id,
    siteVisitDate: workorderQuery.data.payload.siteVisitDate,
    workLocation: workorderQuery.data.payload.workLocation,
    siteVisitType: workorderQuery.data.payload.siteVisitType,
    arrivalTime: workorderQuery.data.payload.arrivalTime,
    timeOnSite: workorderQuery.data.payload.timeOnSite,
    laboratoryID: workorderQuery.data.payload.laboratory.id,
    fieldTechnicianID: workorderQuery.data.payload.fieldTechnician
      ? workorderQuery.data.payload.fieldTechnician.id
      : '',
    additionalInstructions: workorderQuery.data.payload.additionalInstructions,
  };

  const formik = useFormikEdit(
    useGetWorkOrderQuery,
    workOrderInitialValues,
    workorderEditSchema,
    routes.WORKORDER_DETAILS.split(':')[0] + id,
    useUpdateWorkOrderMutation,
    setInitialValues
  );

  return workorderQuery.isLoading ? (
    <LoadingIcon />
  ) : (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} />
      </FlexBetween>
      <FormWorkOrder formik={formik} />
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button onClick={() => navigate(-1)} startIcon={<Icon iconName={'ArrowBack'} />}>
          {text.Buttons.BACK}
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          color="primary"
          variant="contained"
          onClick={formik.handleSubmit}
          startIcon={<Icon iconName={'AddCircle'} />}
        >
          {text.Buttons.SAVE}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default WorkOrderEdit;
