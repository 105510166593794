// import { Search } from "@mui/icons-material";
// import { IconButton, InputAdornment, TextField } from "@mui/material";
// import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid-pro";
import React from "react";
import { Grid } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport
} from "@mui/x-data-grid-pro";
import RefreshButton from "../RefreshButton";

const CustomGridToolbar = ({ tags, extra }) => {
  let btExtra;
  if (extra) {
    btExtra = <Grid item>{extra}</Grid>;
  } else {
    btExtra = null;
  }

  return (
    <GridToolbarContainer>
      <Grid container>
        <Grid item>
          <GridToolbarFilterButton />
        </Grid>
        
        <Grid item sx={{ flex: 1, ml: 2 }}>
          <GridToolbarColumnsButton />
        </Grid>
        <Grid item>
          <GridToolbarExport />
        </Grid>
        {/* <Grid item>
          <GridToolbarDensitySelector />
        </Grid> */}
       
        <Grid item>
          <RefreshButton tags={tags} />
        </Grid>
        
        {btExtra}
      </Grid>
    </GridToolbarContainer>
  );
};

export default CustomGridToolbar;
