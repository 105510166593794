import React from "react";
import { Card, CardActions, CardContent, Grid } from "@mui/material";

export default function CustomCard({ cContent, cActions, click }) {
  return (
    <Grid item xs={12} md={2.4} lg={2.4}>
      <Card onClick={click} sx={{ cursor: "pointer" }}>
        <CardContent sx={{ height:"125px" }}>{cContent}</CardContent>
        {cActions ? (
          <CardActions sx={{ backgroundColor: "#000", color: "#fff" }}>{cActions}</CardActions>
        ) : (
          []
        )}
      </Card>
    </Grid>
  );
}
