import { Box, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocale } from '../../../hooks';
import { ButtonBack } from '../../../components/customMUI/ButtonBack';
import { ButtonSubmit } from '../../../components/customMUI/ButtonSubmit';

const FormClients = ({ formik, btIcon, btText }) => {
  const text = useLocale();

  // Set formik touch to true to see fields
  // labelled as 'Required' on first render
  useEffect(() => {
    formik.setTouched(true, false);
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        label={text.Clients.LABEL_ORGANIZATION_NAME}
        value={formik.values.organizationName}
        name={'organizationName'}
        onChange={formik.handleChange}
        fullWidth
        sx={{ mt: 2 }}
        type={'text'}
      />
      <FormHelperText
        error={formik.touched.organizationName && formik.errors.organizationName ? true : false}
      >
        {formik.errors.organizationName}
      </FormHelperText>

      <TextField
        label={text.Clients.LABEL_CONTACT_NAME}
        value={formik.values.clientContactName}
        name={'clientContactName'}
        onChange={formik.handleChange}
        fullWidth
        sx={{ mt: 2 }}
        type={'text'}
      />
      <FormHelperText
        error={formik.touched.clientContactName && formik.errors.clientContactName ? true : false}
      >
        {formik.errors.clientContactName}
      </FormHelperText>

      <TextField
        label={text.Clients.LABEL_CONTACT_TELEPHONE}
        value={formik.values.clientContactTelephone}
        name={'clientContactTelephone'}
        onChange={formik.handleChange}
        fullWidth
        sx={{ mt: 2 }}
        type={'text'}
      />
      <FormHelperText
        error={
          formik.touched.clientContactTelephone && formik.errors.clientContactTelephone
            ? true
            : false
        }
      >
        {formik.errors.clientContactTelephone}
      </FormHelperText>

      <TextField
        label={text.Clients.LABEL_CONTACT_EMAIL}
        value={formik.values.clientContactEmail}
        name={'clientContactEmail'}
        onChange={formik.handleChange}
        fullWidth
        sx={{ mt: 2 }}
        type={'text'}
      />
      <FormHelperText
        error={formik.touched.clientContactEmail && formik.errors.clientContactEmail ? true : false}
      >
        {formik.errors.clientContactEmail}
      </FormHelperText>

      {Object.keys(formik.touched).length > 0 && Object.keys(formik.errors).length > 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mr: 3 }}>
          <Typography sx={{ fontSize: 14 }} color={'error.main'}>
            {text.INVALID_INPUT}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <ButtonBack />
        <ButtonSubmit btIcon={btIcon} btText={btText} />
      </Grid>
    </form>
  );
};

export default FormClients;
