import { Box, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocale } from '../../../hooks';


import CustomAutocomplete from '../../../components/customMUI/CustomAutocomplete';
import useRequest from '../../../hooks/useRequest';


import { useGetRolesQuery } from '../../../services/rolesApi';
import { useGetOfficesQuery } from '../../../services/OfficeApi';
import { useGetRegionsQuery } from '../../../services/regionsApi';
import { useGetLabsQuery } from '../../../services/laboratoriesApi';


const FormViewProductDetails = ({ formik }) => {
   const text = useLocale();
   const labelStyle = { fontWeight: 'bold', fontSize: 18 };
   // Set formik touch to true to see fields
   // labelled as 'Required' on first render


   useEffect(() => {
       formik.setTouched(true, false);
   }, []);


   return (
       <div>
           <Typography sx={labelStyle}>{text.Projects.SUBTITLE_PROJECT_DETAILS}</Typography>
           <Grid container >
               <Grid item xs={6} lg={6} md={6}>
                   <TextField
                       label={text.Projects.LABEL_PROJECT_CODE}
                       value={formik.values.projectCode}
                       name={'projectCode'}
                       onChange={formik.handleChange}
                       fullWidth
                       sx={{
                           mt: 1
                       }}
                       type={'text'}
                   />
                   <FormHelperText
                       error={formik.touched.projectCode && formik.errors.projectCode ? true : false}
                   >
                       {formik.errors.projectCode}
                   </FormHelperText>


                   <TextField
                       sx={{
                           mt: 1
                       }}
                       label={text.Projects.LABEL_PROJECT_NAME}
                       value={formik.values.projectName}
                       name={'projectName'}
                       onChange={formik.handleChange}
                       fullWidth


                       type={'text'}
                   />
                   <FormHelperText
                       error={formik.touched.projectName && formik.errors.projectName ? true : false}
                   >
                       {formik.errors.projectName}
                   </FormHelperText>


                   <TextField
                       label={text.Projects.LABEL_ADDRESS}
                       value={formik.values.address}
                       name={'address'}
                       onChange={formik.handleChange}
                       fullWidth
                       sx={{
                           mt: 1
                       }}
                       type={'text'}
                   />
                   <FormHelperText
                       error={formik.touched.address && formik.errors.address ? true : false}
                   >
                       {formik.errors.address}
                   </FormHelperText>
               </Grid>
               <Grid item xs={6} lg={6} pl={1} mt={1}>
                   <CustomAutocomplete
                       makeRequest={useRequest}
                       label={text.Projects.LABEL_PROJECT_REGION}
                       initialValue={formik.initialValues.regionID}
                       itemsQuery={useGetRegionsQuery}
                       itemsMapper={(item) => {
                           return { id: item.id, label: item.name };
                       }}
                       name="regionID"
                       onChange={(name, value) => formik.setFieldValue(name, value)}
                   />
                   <FormHelperText
                       error={formik.touched.regionID && formik.errors.regionID ? true : false}
                   >
                       {formik.errors.regionID}
                   </FormHelperText>
                   <TextField
                      
                       label={text.Projects.SUBTITLE_CLIENT_INFORMATION}
                       value={formik.values.address}
                       name={'address'}
                       onChange={formik.handleChange}
                       fullWidth
                       sx={{
                           mt: 1
                       }}
                       type={'text'}
                   />
                   <FormHelperText
                       error={formik.touched.address && formik.errors.address ? true : false}
                   >
                       {formik.errors.address}
                   </FormHelperText>


                   <CustomAutocomplete
                      sx={{
                       mt: .7
                   }}
                       makeRequest={useRequest}
                       label={text.Projects.LABEL_LABORATORY}
                       initialValue={formik.initialValues.laboratoryID}
                       itemsQuery={useGetLabsQuery}
                       itemsMapper={(item) => {
                           return { id: item.id, label: `${item.name} (${item.region.name})` };
                       }}


                       name="laboratoryID"
                       onChange={(name, value) => formik.setFieldValue(name, value)}
                   />
                   <FormHelperText
                       error={
                           formik.touched.laboratoryID && formik.errors.laboratoryID ? true : false
                       }
                   >
                       {formik.errors.laboratoryID}
                   </FormHelperText>
               </Grid>
           </Grid>
       </div>
   );
};


export default FormViewProductDetails;



