import React from 'react';
import { CustomDataGrid, FlexBetween, Header } from '../../components';
import StatusChip from '../../components/StatusChip';
import routes from '../../constants/routes';
import { useGetElement, useLocale } from '../../hooks';
import { useFormatDateTime } from '../../hooks/useFormatDateTime';
import { useGetProjectsQuery } from '../../services/projectsApi';
import tags from '../../services/tags';
import useRequest from '../../hooks/useRequest';

function Projects() {
  const text = useLocale();
  const navItem = useGetElement(text.Projects.NAME);
  const { formatDate } = useFormatDateTime();
  const minColumnWidth = 150;

  // READ Functions
  const projectsQuery = useRequest(useGetProjectsQuery);

  const columns = [
    {
      field: 'projectCode',
      headerName: text.ProjectsDataGrid.PROJECT_CODE,
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'projectName',
      headerName: text.ProjectsDataGrid.PROJECT_NAME,
      minWidth: minColumnWidth,
      flex: 1,
    },
    {
      field: 'projectManager',
      headerName: text.ProjectsDataGrid.PROJECT_MANAGER,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return params.row.projectManager.name;
      },
    },
    {
      field: 'region',
      headerName: text.ProjectsDataGrid.PROJECT_REGION,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return params.row.region.name;
      },
    },
    {
      field: 'status',
      headerName: text.ProjectsDataGrid.STATUS,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return <StatusChip status={params.row.status} />;
      },
    },
  ];

  return (
    <React.Fragment>
      <FlexBetween>
        <Header
          title={navItem.name}
          icon={navItem.icon}
          bcrumb={text.Projects.TABLENAME}
          AddTo={routes.PROJECT_ADD}
        />
      </FlexBetween>
      <CustomDataGrid
        col={columns}
        to={routes.PROJECTS}
        rQuery={projectsQuery}
        tags={[tags.PROJECT]}
      />
    </React.Fragment>
  );
}

export default Projects;
