import React from 'react';
import routes from '../../constants/routes';
import FormSampleCollector from './forms/FormSampleCollector';

import { useFormikEdit } from '../../hooks/useFormikEdit';
import { sampleEditCollectorSchema } from '../../schemas/sampleCollector.schema';

import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FlexBetween, Header, Icon, LoadingIcon } from '../../components';
import { useGetElement, useLocale } from '../../hooks';

import {
  useAddSampleCollectorMutation,
  useGetSampleQuery,
  useUpdateSampleMutation,
} from '../../services/samplesApi';

function SampleCollector() {
  const navigate = useNavigate();
  const text = useLocale();
  const navItem = useGetElement(text.Navigation.WORKORDERS);
  // const { id } = useParams();
  const sampleQuery = useAddSampleCollectorMutation();

  // const { sampleEditSchema } = useSampleSchema();

  const initialValues = {
    //   // number: "",
    sampleCollectorID: '',
  };

  // const samples = sampleQuery.data ? sampleQuery.data.payload : {};
  // console.log(samples);

  const setInitialValues = {
    // number: sampleQuery?.data?.payload.number,
    // sampleCollectorID: samples.sampleCollector.id,
    sampleCollectorID: '',
  };

  const formik = useFormikEdit(
    useGetSampleQuery,
    initialValues,
    sampleEditCollectorSchema,
    routes.WORKORDERS,
    useUpdateSampleMutation,
    setInitialValues
  );

  return sampleQuery.isLoading ? (
    <LoadingIcon />
  ) : (
    <React.Fragment>
      <FlexBetween>
        <Header title={text.Navigation.SAMPLESCOLLECTOR} icon={navItem.icon} />
      </FlexBetween>
      <form onSubmit={formik.handleSubmit}>
        <FormSampleCollector formik={formik} />
        <Grid container justifyContent="flex-end" sx={{ mt: 5 }}>
          <Button
            size="large"
            sx={{ mr: 3 }}
            onClick={() => navigate(routes.WORKORDERS)}
            startIcon={<Icon iconName="ArrowBack" />}
          >
            {text.Buttons.BACK}
          </Button>
          <Button
            type="submit"
            startIcon={<Icon iconName="Edit" />}
            color="primary"
            size="large"
            variant="contained"
          >
            {text.Buttons.EDIT}
          </Button>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default SampleCollector;
