import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import React from 'react';
import Icon from './Icon';
import useIsMobileOrTablet from '../hooks/useIsMobileOrTablet';

const ConfirmationDialog = ({
  title,
  contentText,
  actionText,
  handleAction,
  handleClose,
  handleOpen,
  isOpen,

  ...props
}) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  const contents = (title, contentText, actionText, handleAction, handleClose) => {
    return (
      <React.Fragment>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', pb: 1, pt: 1, pr: 1, pl: 2 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20, flex: 1 }}>{title}</Typography>
          <IconButton onClick={handleClose}>
            <Icon iconName="Cancel" />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>{contentText}</DialogContent>
        <DialogActions>
          <Button onClick={handleAction}>{actionText}</Button>
        </DialogActions>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {isMobileOrTablet ? (
        <SwipeableDrawer
          anchor={'bottom'}
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          {...props}
        >
          {contents(title, contentText, actionText, handleAction, handleClose)}
        </SwipeableDrawer>
      ) : (
        <Dialog open={isOpen} fullWidth maxWidth="sm" {...props}>
          {contents(title, contentText, actionText, handleAction, handleClose)}
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default ConfirmationDialog;
