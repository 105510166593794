// Concrete Pour (Concrete Supervision Details) API

import { api } from './api';
import tags from './tags';
import urls from '../constants/urls';
import refreshCache from '../utils/refreshCache';

export const concreteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET all ConcretePour
    getConcretes: builder.query({
      query: (params) => ({
        url: urls.CONCRETES,
        params: params,
      }),
      providesTags: [tags.CONCRETE],
    }),

    // GET one ConcretePour
    getConcrete: builder.query({
      query: (id) => ({
        url: urls.CONCRETES + '/' + id,
      }),
      providesTags: [tags.CONCRETE],
    }),

    // CREATE a ConcretePour
    addConcrete: builder.mutation({
      query: (data) => ({
        url: urls.CONCRETES,
        method: 'POST',
        body: { ...data },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CONCRETE]);
        } catch (e) {}
      },
    }),

    // EDIT a ConcretePour
    updateConcrete: builder.mutation({
      query: (data) => ({
        url: urls.CONCRETES + '/' + data.id,
        method: 'PATCH',
        body: { ...data.changes },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CONCRETE]);
        } catch (e) {}
      },
    }),

    // DELETE a ConcretePour
    deleteConcrete: builder.mutation({
      query: (id) => ({
        url: urls.CONCRETES + '/' + id,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          await queryFulfilled;
          refreshCache(dispatch, [tags.CONCRETE]);
        } catch (e) {}
      },
    }),

    getConcreteSequence: builder.query({
      query: (params) => ({
        url: urls.CONCRETES,
      }),
      providesTags: [tags.CONCRETE],
    }),
  }),
});

export const {
  useLazyGetConcreteQuery,
  useGetConcreteQuery,
  useGetConcretesQuery,
  useAddConcreteMutation,
  useUpdateConcreteMutation,
  useDeleteConcreteMutation,
  useGetConcreteSequenceQuery
} = concreteApi;
