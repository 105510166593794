import { Checkbox, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import CustomDateTimePicker from '../../../components/customMUI/CustomDateTimePicker';
import { useLocale } from '../../../hooks';
import TextFieldView from '../../../components/customMUI/TextFieldView';
import EditIcon from "@mui/icons-material/Edit";
import { EditCardLaboratory } from './EditCardLaboratory';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../slices/userSlice';
import { SampleStatus } from '../../../constants/options';

const FormSamplesPickup = ({ formik, setIscheck, data, reftechTable }) => {
  const text = useLocale();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const user = useSelector(selectUser);
  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <CustomDateTimePicker
          ampm={true}
          name={'pickupDateTime'}
          label={text.SamplePickups.PICKUP_DATE_TIME}
          onChange={(name, value) => formik.setFieldValue(name, value)}
          initialValue={formik.initialValues.pickupDateTime}
          sx={{ mt: 2 }}
        />
        {!formik.errors.pickupDateTime ? (
          <Typography sx={{ fontSize: 12, color: 'grey', m: 0.2 }}>{text.REQUIRED}</Typography>
        ) : (
          <FormHelperText
            error={formik.touched.pickupDateTime && formik.errors.pickupDateTime ? true : false}
          >
            {formik.errors.pickupDateTime}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <CustomDateTimePicker
          ampm={true}
          name={'dropoffDateTime'}
          label={text.SamplePickups.DROPOFF_DATE_TIME}
          onChange={(name, value) => formik.setFieldValue(name, value)}
          initialValue={formik.initialValues.dropoffDateTime}
          sx={{ mt: 2 }}
        />
        {!formik.errors.dropoffDateTime ? (
          <Typography sx={{ fontSize: 12, color: 'grey', m: 0.2 }}>{text.REQUIRED}</Typography>
        ) : (
          <FormHelperText
            error={formik.touched.dropoffDateTime && formik.errors.dropoffDateTime ? true : false}
          >
            {formik.errors.dropoffDateTime}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <TextField
          label={text.SamplePickups.ADDITIONAL_INFORMATION}
          value={formik.values.additionalInformation}
          name={'additionalInformation'}
          onChange={formik.handleChange}
          fullWidth
          sx={{ mt: 2 }}
          type={'text'}
        />
      </Grid>
      <Grid item alignSelf={'center'} display={'flex'}>
        <Checkbox onChange={(e) => {
          setIscheck(e.target.checked)
        }} />
        <Typography sx={{
          fontSize: '17px',
          alignSelf: 'center'
        }}>
          {text.SamplePickups.THERMOMETER_BROKEN}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} columnGap={2}>
        <TextFieldView label={text.Projects.LABORATORY} value={data?.workOrder?.laboratory.name} />
        {data?.samples && data?.samples[0]?.status === SampleStatus.ASSIGNED_FOR_PICKUP ?
          user.roles.includes('Field Technician') && <EditIcon
            onClick={handleOpen}
            variant="contained"
            style={{ cursor: "pointer" }}
          /> : ''}
      </Grid>
      <EditCardLaboratory
        reftechTable={reftechTable}
        open={open}
        handleClose={() => setOpen(false)}
        workOrderId={data?.workOrder?.id}
        laboratoryId={data?.workOrder?.laboratory}
        formik={formik}
      />
    </Grid>
  );
};

export default FormSamplesPickup;
