// Component for handling changes made to
// the cylinders before submitting the field report

import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCylinderSchema } from '../../../schemas/cylinder.schema';
import { formType, selectFromQueue, updateQueue } from '../../../slices/offlineQueueSlice';
import CylindersDialog from './CylindersDialog';
import { useGetCylinderQuery, useUpdateCylinderMutation } from '../../../services/cylindersApi';

const CylinderEdit = ({
  laboratoryCuredSampleID,
  fieldCuredSampleID,
  cylinderID,
  isOpen,
  setIsOpen,
  setSubmitted,
  isConcreteSequeceEdit
}) => {
  const dispatch = useDispatch();
  const getCylinder = useGetCylinderQuery(cylinderID);
  const cylinder = useSelector((state) => selectFromQueue(state, cylinderID));
     const laboratoryCuredSample = useSelector((state) => {
    const sample = selectFromQueue(state, laboratoryCuredSampleID);
    return sample ? sample.data : null;
  });
  
  const fieldCuredSample = useSelector((state) => {
    const sample = selectFromQueue(state, fieldCuredSampleID);
    return sample ? sample.data : null;
  });

  const { cylinderCreateSchema } = useCylinderSchema();
  const [samples, setSamples] = useState([]);
  const [updateCylinder] = useUpdateCylinderMutation()
  const formikCylinder = useFormik({
    initialValues: cylinder
      ? cylinder.data
      : { customCylinderID: '', sampleID: '', daysToBeCured: 1 },
    enableReinitialize: true,
    validationSchema: cylinderCreateSchema,
    onSubmit:async (values, { resetForm }) => {
      const data = { changes: values, id:cylinderID};
      await updateCylinder(data).unwrap();
      setSubmitted(true);
      resetForm();
      setIsOpen(false);
    },
  });

  useEffect(() => {
    let samples = [];
    if (laboratoryCuredSampleID) {
      samples.push(laboratoryCuredSample);
    }
    if (fieldCuredSampleID) {
      samples.push(fieldCuredSample);
    }
    setSamples(samples);
  }, [laboratoryCuredSampleID, fieldCuredSampleID]);

  useEffect(()=>{
    if(getCylinder){
    if(getCylinder?.data?.payload){
      formikCylinder.setValues({
        ...formikCylinder.values,
        customCylinderID:getCylinder?.data?.payload?.customCylinderID,
        daysToBeCured:getCylinder?.data?.payload?.daysToBeCured,
      })
    }
    }
  },[getCylinder])

  return (
    <CylindersDialog
      formikCylinder={formikCylinder}
      cylinderID={cylinderID}
      samples={samples}
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      isConcreteSequeceEdit={true}
    />
  );
};

export default CylinderEdit;
