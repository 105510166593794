import React from 'react';
import ConcreteFieldReportEdit from '../../components/ConcreteFieldReport/ConcreteFieldReportEdit';

import { useParams } from 'react-router-dom';
import { LoadingIcon } from '../../components';

import { useSelector } from 'react-redux';
import PermissionGate from '../../components/PermissionGate';
import { featurePermissions } from '../../constants/permissions';
import useRequest from '../../hooks/useRequest';
import { useGetWorkOrderQuery } from '../../services/workordersApi';
import { selectPermissions } from '../../slices/permissionSlice';

const WorkOrderConcreteFieldReportEdit = () => {
  const userPermissions = useSelector(selectPermissions);
  const { id } = useParams();

  const workorderQuery = useRequest(useGetWorkOrderQuery, id);

  // Render
  return workorderQuery.isLoading ? (
    <LoadingIcon />
  ) : (
    <React.Fragment>
      {/* <PermissionGate
        userPermissions={userPermissions}
        componentPermissions={[
          featurePermissions.CONCRETE_FIELD_INFORMATION_EDIT,
          featurePermissions.CONCRETE_MIX_DESIGN_EDIT,
          featurePermissions.CONCRETING_SEQUENCES_EDIT,
          featurePermissions.CYLINDERS_EDIT,
          featurePermissions.SAMPLES_EDIT,
        ]}
      > */}
      <ConcreteFieldReportEdit />
      {/* </PermissionGate> */}
    </React.Fragment>
  );
};

export default WorkOrderConcreteFieldReportEdit;
