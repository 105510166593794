import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

import useLocale from '../../hooks/useLocale';
import FormFieldInformation from '../forms/FormFieldInformation';

const ConcreteFieldInformation = ({ formikFieldInformation ,isConcreteReportEdit}) => {
  const text = useLocale();

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {text.ConcreteFieldInformation.TITLE}
        </Typography>

        <br />

        <FormFieldInformation isConcreteReportEdit={isConcreteReportEdit} formik={formikFieldInformation} />
      </CardContent>
    </Card>
  );
};

export default ConcreteFieldInformation;
