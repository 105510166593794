import React from 'react';
import routes from '../../constants/routes';
// import FormProject from './forms/FormProject';
import { FlexBetween, Header } from '../../components';
import { useGetElement, useLocale } from '../../hooks';
import { useProjectSchema } from '../../schemas/project.schema';
import { useAddProjectMutation } from '../../services/projectsApi';
import { useFormikAdd } from '../../hooks/useFormikAdd';
import FormEquipment from './forms/FormEquipment';
import { useCreateEquipmentSetMutation } from '../../services/equipmentSetApi';
import { useEquipmentSetSchema } from '../../schemas/equipmentSet.schema';

function EquipmentSetAdd() {
  const text = useLocale();
  const navItem = useGetElement(text.Navigation.EQUIPMENT_SET);
  const { equipmentSetCreateSchema } = useEquipmentSetSchema();

  const initialValues = {
    equipmentName: "",
    vernierHeight: "",
    squareTemplate: "",
    straightRulerTemplate: "",
    concretePress: "",
    thicknessGuage: "",
    thermometer: "",
    copMaterialStrength: "",
    scaleProtractor: "",
    standardStopWatch: ""
  }
  const formik = useFormikAdd(
    initialValues,
    equipmentSetCreateSchema,
    routes.EQUIPMENT_SET,
    useCreateEquipmentSetMutation
  );

  return (
    <React.Fragment>
      <FlexBetween>
        <Header title={navItem.name} icon={navItem.icon} bcrumb={text.EquipmentSet.EQUIPMENT_SET_ADD} />
      </FlexBetween>
      <FormEquipment formik={formik} btIcon={'AddCircle'} btText={text.Buttons.ADD} />
    </React.Fragment>
  );
}

export default EquipmentSetAdd;