import { Box, Button, IconButton, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import routes from '../../../constants/routes';
import useFormatDateTime from '../../../hooks/useFormatDateTime';
import useLocale from '../../../hooks/useLocale';
import useOnline from '../../../hooks/useOnline';
import { removeConcretingSequenceFromQueue } from '../../../slices/offlineQueueSlice';
import { selectUser } from '../../../slices/userSlice';
import DeleteDialog from '../../DeleteDialog';
import Icon from '../../Icon';
const ConcretingSequence = ({ rows, fieldReportExists, assignedTechnicianID, isConcreteSequeceEdit }) => {
  const user = useSelector(selectUser);
  const { isOnline } = useOnline();
  const text = useLocale();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [concretingSequences, setConcretingSequences] = useState([]);

  const { formatTime } = useFormatDateTime();
  const removeItem = (id) => {
    dispatch(removeConcretingSequenceFromQueue(id));
  };
  useEffect(() => {
    setConcretingSequences(rows);
  }, [rows]);

  const columns = [
    {
      field: 'loadNumber',
      headerName: text.ConcretingSequence.LABEL_LOAD_NUMBER,
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'batchTime',
      flex: 1,
      minWidth: 150,
      headerName: text.ConcretingSequence.LABEL_BATCH_TIME,
      valueFormatter: (params) => {
        return formatTime(params.value);
      },
    },
    {
      field: 'endTime',
      headerName: text.ConcretingSequence.LABEL_END_TIME,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => formatTime(params.value),
    },
    {
      field: 'airContent',
      headerName: text.ConcretingSequence.LABEL_AIR_CONTENT,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.adjustedAirContent ? params.row.adjustedAirContent : params.row.airContent ? params.row.airContent : ""
      },
    },
    {
      field: 'slump',
      flex: 1,
      minWidth: 100,
      headerName: text.ConcretingSequence.LABEL_SLUMP,
      renderCell: (params) => {
        return params.row.adjustedSlump ? params.row.adjustedSlump : params.row.slump ? params.row.slump : ""
      },
    },
    {
      field: 'concreteTemperature',
      flex: 1,
      minWidth: 180,
      headerName: text.ConcretingSequence.LABEL_CONCRETE_TEMPERATURE,
    },
    {
      field: 'collectSamples',
      flex: 1,
      minWidth: 150,
      headerName: text.ConcretingSequence.LABEL_COLLECT_SAMPLES_QUESTION,
      valueFormatter: (params) => {
        return params.value ? text.YES : text.NO;
      },
    },
    {
      field: 'startTime',
      flex: 1,
      minWidth: 100,
      headerName: text.ConcretingSequence.LABEL_START_TIME,
      valueFormatter: (params) => {
        return formatTime(params.value);
      },
    },
    {
      field: 'concreteMixDesign',
      flex: 1,
      minWidth: 100,
      headerName: text.ConcretingSequence.LABEL_FORMULA_MIX,
      renderCell: (params) => {
        return params.value.formula;
      },
    },
    {
      field: 'conformity',
      headerName: text.ConcretingSequence.SUBTITLE_CONFORMITY,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (params.value ? <Typography sx={{ color: '#2ba131' }}>C</Typography> : params.value === false ? <Typography sx={{ color: '#ff0055' }}>NC</Typography> : '-'),
    },
    {
      field: 'edit',
      headerName: '',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {
              !fieldReportExists && user.id === assignedTechnicianID ? (
                <IconButton
                  onClick={() =>
                    navigate(
                      `${location.pathname}${routes.CONCRETING_SEQUENCES}/edit-cache/${params.id}`,
                      {
                        state: { background: location },
                      }
                    )
                  }
                >
                  <Icon iconName={'Edit'} />
                </IconButton>
              ) : (
                <React.Fragment></React.Fragment>
              )
            }
          </React.Fragment>
        );
      },
    },
    {
      field: 'delete',
      headerName: '',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {

        return (
          <React.Fragment>
            {!fieldReportExists && user.id === assignedTechnicianID ? (
              <DeleteDialog handleDelete={() => removeItem(params.id)} />
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  const filteredColumns = isConcreteSequeceEdit
    ? columns.filter(column => column.field !== 'delete')
    : columns;

  return (
    <Box>
      <Typography sx={{ fontWeight: 'bold', fontSize: 20, mb: 2 }}>
        {text.ConcretingSequence.TITLE}
      </Typography>

      {!isConcreteSequeceEdit ? (
        <Button
          startIcon={<Icon iconName="AddCircle" />}
          color="primary"
          component={Link}
          to={`${location.pathname}${routes.CONCRETING_SEQUENCES_ADD}`}
          state={{ background: location }}
          variant={'contained'}
          sx={{ mb: 2 }}
        >
          {text.Buttons.ADD}
        </Button>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <DataGridPro
        columns={filteredColumns}
        rows={concretingSequences}
        sx={{ width: '100%', height: '40vh', minHeight: 300 }}
        pagination
      />
    </Box>
  );
};

export default ConcretingSequence;
