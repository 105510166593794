import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useLocale } from '../../../hooks';
import CloseDialog from '../../../components/CloseDialog';
import DataViewSampleEdit from '../DataViewSampleEdit'
export const SampleEditDialogue = ({ handleClose, handleSubmit, formik }) => {
  const text = useLocale();
  const handleOpenDialog = (setIsOpen) => {
    if (formik.dirty) {
      setIsOpen(true);
    } else {
      handleClose();
    }
  };
  return (
    <React.Fragment>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', pb: 1, pt: 1, pr: 1, pl: 2 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 20, flex: 1 }}>
          {text.DataView.SAMPLING}
        </Typography>

        <CloseDialog handleOpenDialog={handleOpenDialog} handleClose={handleClose} />
      </DialogTitle>

      <DialogContent sx={{}} dividers={true}>
        <DataViewSampleEdit />
      </DialogContent>

    </React.Fragment>
  );
};
