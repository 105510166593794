import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { MobileResponsiveDialog } from '../../../components/MobileResponsiveDialog';
import { useLocale } from '../../../hooks';
import { Button } from '@mui/material';
import { Icon } from '../../../components';
import { useFormik } from 'formik';
import { useHandleError } from '../../../hooks/useHandleError';
import useHandleSuccess from '../../../hooks/useHandleSuccess';
import { ConcreteMixDesignAdd } from '../../../components/ConcreteFieldReport/ConcreteMixDesigns/ConcreteMixDesignAdd';
import { useDispatch } from 'react-redux';
import { useConcreteMixDesignSchema } from '../../../schemas/concreteMixDesign.schema';
import { v4 as uuidv4 } from "uuid";
import { getPopulated } from '../../../utils/form';
import { ConcreteMixDesignDialog } from '../../../components/ConcreteFieldReport/ConcreteMixDesigns/ConcreteMixDesignDialog';
import { useAddConcreteMixDesignMutation } from '../../../services/concreteMixDesignApi';
import { toast } from 'react-toastify';

function ConcreteMixDesignAddButton() {
    const { id } = useParams();
    const { concreteMixDesignCreateSchema } = useConcreteMixDesignSchema();
    const [open, setOpen] = useState("");
    const text = useLocale();
    const [addConcreteMIxDesign] = useAddConcreteMixDesignMutation();
    const { handleSuccessResponse } = useHandleSuccess();
    const { handleErrorResponse } = useHandleError();

    // Concrete Mix Design
    const [initialValuesConcreteMixDesign, setInitialValuesConcreteMixDesign] = useState({
        workOrderID: id,
        supplierOrganisation: "",
        plant: "",
        formula: "",
        specifiedConcreteClass: "",
        concreteElementLocation: "",
        specifiedCompressiveStrength: "",
        csaExposureClass: "",
        minAirContent: "",
        maxAirContent: "",
        slump: "",
        slumpRange: "",
        plasticizer: "",
        plasticizerRange: "",
        additionalInformation: "",
        nominalAggregateSize: "",
    });

    const formikConcreteMixDesign = useFormik({
        initialValues: initialValuesConcreteMixDesign,
        validationSchema: concreteMixDesignCreateSchema,

        onSubmit: async (values, formik) => {
            const id = uuidv4();
            const specifiedConcreteClass = 'Class ' +
                values.csaExposureClass +
                ", " +
                values.specifiedCompressiveStrength +
                " Mpa, Aggregates " +
                values.nominalAggregateSize +
                " mm,  Air Content " +
                values.minAirContent +
                "-" +
                values.maxAirContent +
                " AEA, Slump " +
                values.slump +
                " ± " +
                values.slumpRange + " mm";
            const object = getPopulated({ ...values, specifiedConcreteClass });
            try {
                const req = await addConcreteMIxDesign(object);
                if (req.error?.data) {
                    toast.error(req.error?.data);
                    return
                }
                handleSuccessResponse(text.ConcreteMixDesign.SUCCESSFUL_UPDATE);

            } catch (error) {
                handleErrorResponse(error)
            }

            formik.resetForm();
            setOpen(false);
        },
    });

    const handleSubmit = () => {
        formikConcreteMixDesign.handleSubmit();
    };

    return (
        <React.Fragment>
            <Button
                sx={{ my: 2 }}
                variant="contained"
                color="primary"
                startIcon={<Icon iconName={'AddCircle'} />}
                onClick={() => setOpen(true)}
            >
                {text.Buttons.ADD}
            </Button>
            <MobileResponsiveDialog open={open}>
                <ConcreteMixDesignDialog
                    isWorkOrderDetails={() => {
                        setOpen(false);
                    }}
                    formikConcreteMixDesign={formikConcreteMixDesign}
                    handleSubmit={handleSubmit}
                />
            </MobileResponsiveDialog>
        </React.Fragment>
    );
}

export default ConcreteMixDesignAddButton;
