import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useLocale } from '../../../hooks';
import CloseDialog from '../../../components/CloseDialog';
import FormConcrete from '../../../components/forms/FormConcrete';

import DataViewConcreteMixDesignEdit from '../DataViewConcreteMixDesignEdit'
export const ConcreteMixDesignEditDialogue = ({ handleClose, handleSubmit, formik }) => {
  const text = useLocale();
  const handleOpenDialog = (setIsOpen) => {
    if (formik.dirty) {
      setIsOpen(true);
    } else {
      handleClose();
    }
  };
  return (
    <React.Fragment>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', pb: 1, pt: 1, pr: 1, pl: 2 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 20, flex: 1 }}>
          {text.DataView.CONCRETE_MIX_DESIGN}
        </Typography>

        <CloseDialog handleOpenDialog={handleOpenDialog} handleClose={handleClose} />
      </DialogTitle>

      <DialogContent dividers={true}>
        <DataViewConcreteMixDesignEdit />
      </DialogContent>

      <DialogActions>
        {/* <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ m: 1 }}>
          {text.Buttons.SAVE}
        </Button> */}
      </DialogActions>
    </React.Fragment>
  );
};
