import * as yup from 'yup';
import { useLocale } from '../hooks';

export const useUserSchema = () => {
  const text = useLocale();

  const userCreateSchemaOutsideUser = yup.object().shape({
    firstName: yup.string().required(text.REQUIRED),
    lastName: yup.string().required(text.REQUIRED),
    roleId: yup.string().required(text.REQUIRED),
    officeID: yup.string().required(text.REQUIRED),
    email: yup.string().email(text.INVALID_EMAIL).required(text.REQUIRED),
    telephone: yup.number().required(text.REQUIRED),
    password: yup
      .string()
      .required(text.REQUIRED)
      .min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol'),
    confirmPassword: yup
      .string()
      .required(text.REQUIRED)
      .oneOf([yup.ref('password'), null], 'Must match "password" field value'),
  });

  const userCreateSchemaOutsideUserWithLabId = yup.object().shape({
    firstName: yup.string().required(text.REQUIRED),
    lastName: yup.string().required(text.REQUIRED),
    roleId: yup.string().required(text.REQUIRED),
    officeID: yup.string().required(text.REQUIRED),
    email: yup.string().email(text.INVALID_EMAIL).required(text.REQUIRED),
    telephone: yup.number().required(text.REQUIRED),
    preferredLaboratoryId: yup.string().required(text.REQUIRED),
    password: yup
      .string()
      .required(text.REQUIRED)
      .min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol'),
    confirmPassword: yup
      .string()
      .required(text.REQUIRED)
      .oneOf([yup.ref('password'), null], 'Must match "password" field value'),
  });
  
  

  const userCreateSchemaGhdUser = yup.object().shape({
    roleId: yup.string().required(text.REQUIRED),
    officeID: yup.string().required(text.REQUIRED),
    email: yup.string().email(text.INVALID_EMAIL).required(text.REQUIRED),
    telephone: yup.number().required(text.REQUIRED),
  });

  const userCreateSchemaGhdUserWithLabId = yup.object().shape({
    roleId: yup.string().required(text.REQUIRED),
    officeID: yup.string().required(text.REQUIRED),
    email: yup.string().email(text.INVALID_EMAIL).required(text.REQUIRED),
    telephone: yup.number().required(text.REQUIRED),
    preferredLaboratoryId: yup.string().required(text.REQUIRED)
  });
  

  return { userCreateSchemaGhdUser,userCreateSchemaOutsideUser,userCreateSchemaGhdUserWithLabId,userCreateSchemaOutsideUserWithLabId };
};
