// This component asks the user whether they want to delete an item

import { IconButton } from '@mui/material';
import React from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import { useLocale } from '../hooks';
import { useState } from 'react';
import Icon from './Icon';

const DeleteDialog = ({ handleDelete }) => {
  const text = useLocale();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  return (
    <React.Fragment>
      <IconButton onClick={() => setOpenConfirmationDialog(true)}>
        <Icon iconName={'Delete'} />
      </IconButton>
      <ConfirmationDialog
        isOpen={openConfirmationDialog}
        title={text.DELETE_QUESTION}
        contentText={text.DELETE_DETAILS}
        actionText={text.Buttons.CONFIRM}
        handleAction={handleDelete}
        handleClose={() => setOpenConfirmationDialog(false)}
        handleOpen={() => setOpenConfirmationDialog(true)}
      />
    </React.Fragment>
  );
};

export default DeleteDialog;
